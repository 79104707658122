import React, {useState, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {FileUpload} from "primereact/fileupload";
import {API_URL} from "../../../../redux/config";
import {getCampaignContactsWithPagination, getContactsExcel, getContactsExcelForDelete} from "../../../../redux/api/api";
import {SplitButton} from "primereact/splitbutton";
import {Menu} from "primereact/menu";

const FileUploader: React.FC = () => {
    const dispatch = useAppDispatch()
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const companies = useAppSelector(state => state.CompaniesListReducer)
    // @ts-ignore
    const campaignId = companies?.companiesData?.campaign?.campaignId;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const handleRemove = () => {
        setSelectedFile(null);
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    };

    const onFileSelect = (event: any) => {
        if (event.files && event.files.length > 0) {
            setSelectedFile(event.files[0]);
        }
    };

    const uploadFile = () => {
        if (selectedFile) {
            const dataSort = {
                pageNumber: 1,
                pageSize: 10,
                sort: {
                    orderBy: "ContactName",
                    isDesc: true
                },
                campaignId: campaignId
            }
            dispatch(getCampaignContactsWithPagination(dataSort, jwtToken?.jwtToken));
        }
    };

    const [visible, setVisible] = useState(false);
    const menuRef = useRef(null);
    const handleClick = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if(!visible){
            setVisible(true);
        }
        // @ts-ignore
        menuRef?.current?.toggle(event);
    };

    const exportItems = [
        {
            label: 'Загрузить файл',
            template: (
                <FileUpload
                    name="file"
                    accept=".xlsx"
                    mode="basic"
                    uploadLabel="Загрузить"
                    cancelLabel="Отменить"
                    chooseLabel={selectedFile ? selectedFile.name : 'Імпорт'}
                    maxFileSize={1000000}
                    url={`${API_URL}CampaignContacts/UploadContactsExcel/${campaignId}?userId=${user?.userId}`}
                    onSelect={onFileSelect}
                    onClear={handleRemove}
                    onUpload={uploadFile}
                />
            )
        },
        {
            label: 'Експорт',
            icon: 'pi pi-file-export',
            command: () => {
                dispatch(getContactsExcel(campaignId, user));
            }
        },
        {
            label: 'Видалити',
            icon: 'pi pi-trash',
            command: () => {
                dispatch(getContactsExcelForDelete(campaignId, user?.userId, user));
            }
        }
    ];

    const statItem = {
        label: 'Статистика',
        icon: 'pi pi-chart-bar',
        command: () => {

        }
    };

    const items = [...exportItems, statItem];

    return (
        <>
            <div className="flex flex-column w-7">
                <SplitButton
                    icon="pi pi-file-excel"
                    label="Excel"
                    className="fileUpload excel-button"
                    onClick={handleClick}
                    />
                <Menu
                    ref={menuRef}
                    model={items}
                    popup={true}
                    onHide={() => setVisible(false)}
                    className="p-buttonset p-m-0"
                />
            </div>
        </>
    );
};

export default FileUploader;
