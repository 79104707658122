import {
    ADD_ROLES_FOR_BRANCH,
    ADD_SKILL_GROUP_FOR_BRANCH,
    ARCHIVE_BRANCH_TABLE_TOGGLE,
    BRANCH_DIALOG_VISIBLE,
    BRANCH_FIELD, DELETE_ROLE_FROM_BRANCH, DELETE_SKILL_GROUP_FROM_BRANCH, HIDE_BRANCH_DETAILS_LOADER, HIDE_BRANCH_LOADER,
    INITIAL_BRANCHES_DATA,
    SELECTED_BRANCH_ID,
    SET_BRANCH_CURRENT_PAGE,
    SET_BRANCH_CURRENT_SIZE, SET_BRANCH_ERROR_FIELDS,
    SET_BRANCHES_LOADING, SHOW_BRANCH_DETAILS_LOADER, SHOW_BRANCH_LOADER, TOGGLE_USER_LIDER
} from "../types/typesBranchesList";
import {CLEAR_FILTERS, COMPANY_STATE_SAVE, HIDE_ERROR, HIDE_ERROR_MESSAGE, SHOW_ERROR_MESSAGE} from "../types/types";

export function setArchiveBranchTableToggle(state:boolean) {
    return {
        type: ARCHIVE_BRANCH_TABLE_TOGGLE,
        payload: state,
    }
}

export function setBranchDialogVisible(state:boolean) {
    return {
        type: BRANCH_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setSelectedBranchId(state:number) {
    return {
        type: SELECTED_BRANCH_ID,
        payload: state,
    }
}

export function setBranchField(state:{ fieldName: string, value: any}) {
    return {
        type: BRANCH_FIELD,
        payload: state,
    }
}

export function setClearFilters() {
    return {
        type: CLEAR_FILTERS,
    }
}

export function showBranchLoader() {
    return {
        type: SHOW_BRANCH_LOADER,
    }
}

export function hideBranchLoader() {
    return {
        type: HIDE_BRANCH_LOADER,
    }
}

export function showBranchDetailsLoader() {
    return {
        type: SHOW_BRANCH_DETAILS_LOADER,
    }
}


export function hideBranchDetailsLoader() {
    return {
        type: HIDE_BRANCH_DETAILS_LOADER,
    }
}
export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function initialBranchesData() {
    return {
        type: INITIAL_BRANCHES_DATA,
    }
}

export function setCompanyStateSave(state: boolean) {
    return {
        type: COMPANY_STATE_SAVE,
        payload: state,
    }
}

export function hideErrorMessage() {
    return {
        type: HIDE_ERROR_MESSAGE,
    }
}

export function showErrorMessage(message: string) {
    return {
        type: SHOW_ERROR_MESSAGE,
        payload: message
    }
}
export function setBranchErrorFields(state:any) {
    return {
        type: SET_BRANCH_ERROR_FIELDS,
        payload: state,
    }
}

export function setBranchCurrentPage(state: any) {
    return {
        type: SET_BRANCH_CURRENT_PAGE,
        payload: state
    }
}

export function setBranchCurrentSize(state: any) {
    return {
        type: SET_BRANCH_CURRENT_SIZE,
        payload: state
    }
}

export function setBranchesLoading(state: boolean) {
    return {
        type: SET_BRANCHES_LOADING,
        payload: state
    }
}

export const toggleUserLider = (userId: string) => {
    return {
        type: TOGGLE_USER_LIDER,
        payload: userId,
    };
};

export function addSkillGroupForBranch(state: {branchId:string, id:any}) {
    return {
        type: ADD_SKILL_GROUP_FOR_BRANCH,
        payload: state,
    }
}

export function deleteSkillGroupFromBranch(state: string) {
    return {
        type: DELETE_SKILL_GROUP_FROM_BRANCH,
        payload: state,
    }
}

export function addRoleForBranch(state: {branchId:string, id:any}) {
    return {
        type: ADD_ROLES_FOR_BRANCH,
        payload: state,
    }
}

export function deleteRoleFromBranch(state: string) {
    return {
        type: DELETE_ROLE_FROM_BRANCH,
        payload: state,
    }
}

