import React, {useEffect, useRef, useState} from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import RolesListDialogInfo from "../RolesListDialogInfo";
import RolesListDialogGeneralSettings from "../RolesListDialogGeneralSettings";
import {setRoleDialogVisible, setRoleErrorFields, setRoleField} from "../../../../redux/actions/actionsRoles";
import RoleModel from "../RoleModel";
import {archiveRole, createRole, updateRole} from "../../../../redux/api/apiRoles";
import {validateRoleForm} from "../../../../redux/validator/validator";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../../../redux/cache/cacheService";
import {codes} from "../../../../redux/notificationCodes";
import RolesDialogStatusMatrix from "../RolesDialogStatusMatrix";
import {getUsersManagerUserBranchesData} from "../../../../redux/api/apiUsersList";

const RolesListDialog = (props: any) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    const toastBC = useRef<Toast>(null);
    const [activeIndex, setActiveIndex] = useState(0)
    // @ts-ignore
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;
    const roleDialogVisible = useAppSelector(state => state.RolesReducer.roleDialogVisible);
    const rolesPermissions = checkPermissions(rolesVision, 'Role');
    const archiveRolesTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle);
    const dataSort = useAppSelector(state => state.RolesReducer.dataSort);
    const [visible, setVisible] = useState<boolean>(false);
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const [matrixVisible, setMatrixVisible] = useState(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission,
        visible: hasRoleVisionPermission
    } = rolesPermissions;

    let archiveRoleNotification = getItem(codes.archive_role_question);
    const archivedRoleNotificationMessage = <div>{notifications && notifications.archiveRoleMessage ? notifications.archiveRoleMessage ? notifications.archiveRoleMessage?.text : archiveRoleNotification : archiveRoleNotification}</div>

    useEffect(() => {
        setActiveIndex(0);
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setRoleField({section: 'user', fieldName: 'actionByUserId', value: user.userId}));
    }, [rolesData?.actionByUserId]);

    const showSaveChangesNotification = () => {
        dispatch(setRoleDialogVisible(false));
        setActiveIndex(0);
    }


    const createRoleData = (data: any) => {
        let updatedRoleData = data;
        const validationErrors = validateRoleForm(data);
        dispatch(setRoleErrorFields(validationErrors));
        if (!validationErrors) {
            dispatch(createRole(updatedRoleData, jwtToken?.jwtToken, dataSort));
        }
        if (validationErrors?.name || validationErrors?.description || validationErrors?.branchId) {
            setActiveIndex(0);
        }
        if (!validationErrors?.name && !validationErrors?.description && validationErrors?.permissionId) {
            setActiveIndex(1);
        }
    };

    const updateRoleData = (data: any) => {
        let updatedRoleData = data;
        const validationErrors = validateRoleForm(data);
        dispatch(setRoleErrorFields(validationErrors));
        if (!validationErrors) {
            if (props?.branchId) {
                dispatch(updateRole(updatedRoleData, jwtToken?.jwtToken, dataSort, props?.branchId));
            } else {
                dispatch(updateRole(updatedRoleData, jwtToken?.jwtToken, dataSort));
            }
        }
        if (validationErrors?.name || validationErrors?.description || validationErrors?.branchId) {
            setActiveIndex(0);
        }
        if (!validationErrors?.name && !validationErrors?.description && validationErrors?.permissionId) {
            setActiveIndex(1);
        }
    };

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <Toast ref={toast}/>
            <Toast ref={toastBC} position="center"/>
            <div className="buttons">
                {archiveRolesTableToggle && hasRoleCreatePermission && rolesData?.isEdit && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                                                    className="p-button-outlined"
                                                                                                    label="Видалити"
                                                                                                    onClick={() => {
                                                                                                        setVisible(true);
                                                                                                    }}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            dispatch(setRoleDialogVisible(false));
                        }
                        }
                />
                {!rolesData?.id && archiveRolesTableToggle && hasRoleCreatePermission && rolesData?.isEdit && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                                      className=""
                                                                                                                      label="Створити та зберегти"
                                                                                                                      onClick={() => {
                                                                                                                          createRoleData(rolesData);
                                                                                                                      }
                                                                                                                      }
                />}
                {rolesData?.id && archiveRolesTableToggle && hasRoleUpdatePermission && rolesData?.isEdit ? <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                                    label="Зберегти"
                                                                                                                    onClick={() => {
                                                                                                                        updateRoleData(rolesData);
                                                                                                                    }}
                /> : ''}
                {activeIndex === 2 && <Button icon="pi pi-window-maximize"
                                              className={"p-button-info text-white"}
                                              label="Розширити"
                                              onClick={() => {
                                                  setMatrixVisible(true);
                                              }
                                              }
                />}
            </div>
        </div>
    );

    const bodyHeader = () => {
        return <RolesListDialogInfo/>;
    }

    const createdUserTime = () => {
        const time = new Date(rolesData?.roleData?.createdDateTime || '').toLocaleString("uk-UA")
        if (rolesData?.roleData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(rolesData?.roleData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (rolesData?.roleData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    return (
        <Dialog
            header={bodyHeader}
            footer={!hasRoleVisionPermission ? null : footer}
            visible={roleDialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
            onHide={() => showSaveChangesNotification()}
        >
            {rolesData && <div className="company-info-popup">
                <div className="grid align-items-center">
                    <div className="field col-12 md:col-6">
                        {!!rolesData.id && <div className="item pl-2">
                            ID: {rolesData?.id ? rolesData?.id : ''}
                        </div>}
                    </div>
                    <div className="field col-12 md:col-6 flex justify-content-end mb-0">
                        {!!rolesData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                            {rolesData?.roleData?.createdByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{rolesData?.roleData?.userCreate ? rolesData?.roleData?.userCreate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                            </div>}
                            {rolesData?.roleData?.updatedByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{rolesData?.roleData?.userUpdate ? rolesData?.roleData?.userUpdate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                            </div>}
                        </div>}
                    </div>
                </div>
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Загальні налаштування">
                        {rolesData && <RolesListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Рольова модель">
                        <RoleModel/>
                    </TabPanel>
                    <TabPanel header="Матриця статусів">
                        <RolesDialogStatusMatrix height={'47vh'}/>
                        <Dialog header={rolesData?.name} style={{minWidth: '80vw', height: '90vw', width: '98vw'}} visible={matrixVisible} onHide={() => setMatrixVisible(false)}>
                            <RolesDialogStatusMatrix height={'100vh'}/>
                        </Dialog>
                    </TabPanel>
                </TabView>
            </div>}

            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={archivedRoleNotificationMessage}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                dispatch(archiveRole(rolesData?.id, jwtToken?.jwtToken));
                dispatch(setRoleDialogVisible(false));
            }}/>
        </Dialog>
    )
}

export default RolesListDialog
