import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import {Divider} from "primereact/divider";
import {useAppSelector} from "../../../../redux/hooks";
import {Badge} from "primereact/badge";

const StatusesListDialogInfo = () => {
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.statusName || '-'}</p>
            </>
        );
    }

    const activeBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.active ? 'Активна' : 'Неактивна'}</p>
            </>
        );
    }

    const renderStatusColumn = (rowData: any) => {
        const replaceStatusName = rowData?.statusName?.replace(/Copy.*/, 'Copy...');

        return (
            <Badge value={replaceStatusName} className={`p-badge text-white customer-badge text-xs p-0 px-2`} style={{borderRadius: '6px', backgroundColor: `${rowData.statusColor}`, minWidth: '135px', minHeight: '20px'}}/>
        );
    };

    return (
        <>
            <div id="pr_id_3_header" className="p-dialog-title mb-3">Налаштування статусу</div>
            <DataTable value={[statusData]}>
                <Column className="w-4" field="name" body={nameBodyTemplate} header="Назва"></Column>
                <Column className="w-4" field="active" body={activeBodyTemplate} header="Стан"></Column>
                <Column className="w-4" field="statusName" body={renderStatusColumn} header="Зовнішній вигляд"></Column>

            </DataTable>
            <Divider style={{margin: '0 0 20px 0'}}/>
        </>

    )
}

export default StatusesListDialogInfo
