import axios from "axios";
import {API_URL, base64ToBlob} from "../config";
import {
    showStatisticsExportLoader,
    showStatisticsLoader,
    updateStatisticsSortFields
} from "../actions/actionsStatisticsList";
import {
    GET_STATISTIC,
    GET_STATISTICS,
    GET_STATISTICS_PAGINATION,
    GET_STATISTICS_USER_SORT_FIELDS,
    SHOW_DETAIL_LOADING
} from "../types/typesStatisticsList";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {GET_NOTIFICATION_ERROR} from "../types/types";
import {setUserError} from "../actions/actionsUsersList";
import {SET_USER_STATUS_ERROR} from "../types/typesUsersList";
import {hideError, hideErrorMessage} from "../actions/actions";

export function getStatisticsWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showStatisticsLoader(true))
        axios.post(`${API_URL}Statistic/GetStatistic`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_STATISTICS, payload: response.data.data.items})
                dispatch({type: GET_STATISTICS_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(showStatisticsLoader(false))
            })
    }
}

export function getStatisticsUserSortFields(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Statistic/GetUserSortFields`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_STATISTICS_USER_SORT_FIELDS, payload: response.data.data});
                localStorage.setItem('callcenter:user_statistic_sort_fields', JSON.stringify(response.data.data?.fields));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SET_USER_STATUS_ERROR, payload: {message: 'Помилка завантаження даних'}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getEventStatisticToUserDetail(id: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: SHOW_DETAIL_LOADING, payload: true})
        dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
        axios.get(`${API_URL}Statistic/GetStatisticCallDetailById?callId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_STATISTIC, payload: response.data.data});
            })
            .catch(() => {
                dispatch({type: SHOW_DETAIL_LOADING, payload: false})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SHOW_DETAIL_LOADING, payload: false})
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    if (e.data) {
                        dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                    } else {
                        dispatch({type: SET_USER_STATUS_ERROR, payload: {message: 'Щось пішло не так.'}})
                    }
                }
            })
            .finally(() => {
                    dispatch(showAccessDeniedNotifications(false));
                    dispatch({type: SHOW_DETAIL_LOADING, payload: false});
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
                    dispatch({type: SET_USER_STATUS_ERROR, payload: false})
                }
            )
    }
}

export function getStatisticExcel(dataSort: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(showStatisticsExportLoader(true));
        axios.post(`${API_URL}Statistic/GetStatisticExcelWithData`, dataSort, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            },
            responseType: 'json'
        })
            .then((response) => {
                const fileName = response.data.data.fileDownloadName;
                const fileContentsBase64 = response.data.data.fileContents;

                const blob = base64ToBlob(fileContentsBase64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showStatisticsExportLoader(false));
                dispatch(showAccessDeniedNotifications(false));
                return dispatch(setUserError(false))
            })
    }
}

export function updateUserStatisticFields(sortFields: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}Statistic/UpdateUserSortFields`, sortFields, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(updateStatisticsSortFields(response.data.data?.fields));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SET_USER_STATUS_ERROR, payload: {message: 'Помилка оновлення стовпців'}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}
