import {combineReducers, configureStore} from '@reduxjs/toolkit'
import { CompaniesListReducer } from "./reducers/СompaniesListReducer";
import { UsersReducer } from "./reducers/UsersReducer";
import { UsersListReducer } from "./reducers/UsersListReducer";
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {MonitoringListReducer} from "./reducers/MonitoringListReducer";
import {BranchesListReducer} from "./reducers/BranchReducer";
import {RolesReducer} from "./reducers/RolesReducer";
import {NotificationsReducer} from "./reducers/NotificationsReducer";
import {StatusesListReducer} from "./reducers/StatusesListReducer";
import {WebRTCReducer} from "./reducers/WebRTCReducer";
import {QueueListReducer} from "./reducers/QueueReducer";
import {QualificationGroupListReducer} from "./reducers/QualificationGroupReducer";
import {TrunksListReducer} from "./reducers/TrunksReducer";
import {AudioListReducer} from "./reducers/AudioReducer";
import {StatisticsListReducer} from "./reducers/StatisticsListReducer";
import {StatisticsCallsListReducer} from "./reducers/CallsStatisticsListReducer";
import {StatisticsStatusListReducer} from "./reducers/StatusStatisticsListReducer";

const usersPersistConfig = {
    key: 'callcenter:user',
    storage
}

const rolesPersistConfig = {
    key: 'callcenter:role_vision',
    storage,
    whitelist: ['rolesVision', 'userSortFields', 'userStatisticSortFields']
}

const rootReducer = combineReducers({
    CompaniesListReducer: CompaniesListReducer,
    UsersListReducer: UsersListReducer,
    BranchesListReducer: BranchesListReducer,
    MonitoringListReducer: MonitoringListReducer,
    NotificationsReducer: NotificationsReducer,
    StatusesListReducer: StatusesListReducer,
    QualificationGroupListReducer: QualificationGroupListReducer,
    WebRTCReducer: WebRTCReducer,
    QueueListReducer: QueueListReducer,
    AudioListReducer: AudioListReducer,
    TrunksListReducer: TrunksListReducer,
    StatisticsListReducer: StatisticsListReducer,
    StatisticsCallsListReducer: StatisticsCallsListReducer,
    StatisticsStatusListReducer: StatisticsStatusListReducer,
    UsersReducer: persistReducer(usersPersistConfig, UsersReducer),
    RolesReducer: persistReducer(rolesPersistConfig, RolesReducer)
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }) as any,
})

export default store;
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
