import React, {useEffect, useState} from 'react';
import {classNames} from "primereact/utils";
import {ProgressBar} from "primereact/progressbar";
import {setEditType} from "../../../redux/actions/actions";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {setQualificationGroupDialogVisible} from "../../../redux/actions/actionsQualificationGroup";
import {getQualificationGroupDetails} from "../../../redux/api/apiQualificationGroup";

const QualificationGroupListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData) as unknown as any;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const detailsLoading = useAppSelector(state => state.QualificationGroupListReducer.detailsLoading);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolePermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const archiveQualificationGroupTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const updatedUserCampaignInfo = (qualificationData?.campaignInfo || []).map((item: { id: number; name: string; skillGroupLevel: number; }) => ({
        campaignId: item.id,
        campaignName: item.name,
        skillGroupLevel: item.skillGroupLevel
    }));

    const updatedUserQueuesInfo = (qualificationData?.queuesInfo || []).map((item: { id: number; name: string; skillGroupLevel: number; }) => ({
        queueId: item.id,
        queueName: item.name,
        skillGroupLevel: item.skillGroupLevel
    }));

    const combinedArrayWithSpread = [...updatedUserCampaignInfo, ...updatedUserQueuesInfo];


    const queueIdWithName = (rowData: any) => {
        if (rowData?.queueId) {
            return (
                <p>{rowData?.queueId} {rowData?.queueName}</p>
            );
        }

        return null;
    }

    const campaignIdWithName = (rowData: any) => {
        if (rowData?.campaignId) {
            return (
                <p>{rowData?.campaignId} {rowData?.campaignName}</p>
            );
        }

        return null;
    }

    const {
        update: hasUpdatePermission,
    } = rolePermissions;

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchItems = qualificationData?.skillGroupBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div className="field col-12 md:col-12 flex m-0 px-0" key={index}>
                        {branchItem.label}
                    </div>
                )
            }
        })
    });

    return (
        <div className="relative">
            {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
            <div className={classNames('grid', {'opacity-50': detailsLoading})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                    <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                        <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                            <p className="w-full">ID: {qualificationData?.id}</p>
                        </div>
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            <p className="w-full">Оператори: {qualificationData.operatorsCount || '0'}</p>
                        </div>
                        <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                            <p className="w-full">{qualificationData?.name}</p>
                        </div>
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {/*<p className="w-full">Стратегія операторів: {queueData?.queue?.strategyOperators ? operatorsStrategyBodyTemplate() : '- - -'}</p>*/}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 flex align-items-center m-0 p-0 justify-content-end text-right">
                        <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                            {/*<p className="w-full">Приоритет: {queueData?.queue?.priority || '0'}</p>*/}
                        </div>
                        <div className="field col-12 md:col-4 flex align-items-center justify-content-end m-0 p-0">
                            <p className="w-full">{qualificationData?.isActive ? 'Активна' : 'Не активна'}</p>
                        </div>
                        <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                            <div className="field col-12 md:col-12 flex align-items-center justify-content-end m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1 ml-0" onClick={() => {
                                    dispatch(getQualificationGroupDetails(qualificationData?.id, jwtToken?.jwtToken));
                                    if (hasUpdatePermission) {
                                        dispatch(setEditType(true));
                                    } else {
                                        dispatch(setEditType(false));
                                    }
                                    dispatch(setQualificationGroupDialogVisible(true));
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveQualificationGroupTableToggle && hasUpdatePermission,
                                    'pi pi-eye': !archiveQualificationGroupTableToggle || !hasUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                {archiveQualificationGroupTableToggle && windowWidth <= 1248 ?
                    <>
                        {windowWidth <= 1248 && <div className="flex col-12 md:col-12 mb-0 flex-wrap justify-content-between">
                            <div className="md:col-12 flex align-items-center m-0 px-0">
                                {qualificationData.name}
                            </div>
                            {windowWidth <= 1248 && <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>}

                        </div>}
                    </> : ''}
                {windowWidth <= 1248 && (
                    <>
                        {[
                            {label: "ID", value: qualificationData.id},
                            {label: "Стан", value: qualificationData.isActive ? 'Активна' : 'Не активна'},
                            {label: "Оператори", value: qualificationData.operatorsCount},
                        ].map(({label, value}, index) => (
                            <React.Fragment key={index}>
                                <div className="field col-12 md:col-4 flex m-0">
                                    <div>{label}</div>
                                </div>
                                <div className="field col-12 md:col-8 flex align-items-center m-0">
                                    <div className="w-full">{value}</div>
                                </div>
                            </React.Fragment>
                        ))}
                    </>
                )}
                {windowWidth > 1248 && <div className="field col-12 md:col-12 flex flex-wrap m-0 p-0">
                    <div className="field col-12 md:col-6 flex m-0">Бранч</div>
                    <div className="field col-12 md:col-6 flex m-0">Черги/Кампанії</div>
                    <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                    <div className="field col-12 md:col-12 flex flex-wrap scroll-detail-items">
                        <div className="field col-12 md:col-6 flex flex-wrap align-self-start m-0">{branchItems || '- - -'}</div>
                        <div className="field col-12 md:col-6 flex m-0">
                            {(!!updatedUserQueuesInfo?.length || !!updatedUserCampaignInfo?.length) ? <div className="field col-12 md:col-12 flex m-0 px-0">
                                <DataTable value={combinedArrayWithSpread}
                                           className='qualGroup'
                                           emptyMessage="Нічого не знайдено"
                                           style={{cursor: 'pointer', minWidth: '100%'}}
                                >
                                    <Column
                                        style={{cursor: 'pointer', width: '20%'}}
                                        field="skillGroupLevel"
                                        className='border-bottom-1'
                                        sortable={false}
                                        header="Квал рiвень"
                                        showFilterMatchModes={false}
                                        showFilterMenuOptions={false}
                                    />
                                    <Column
                                        style={{cursor: 'pointer', minWidth: '40%'}}
                                        field="qualGroup"
                                        className='border-bottom-1'
                                        sortable={false}
                                        header="ID/ Назва Кампанii"
                                        body={campaignIdWithName}
                                        showFilterMatchModes={false}
                                        showFilterMenuOptions={false}
                                    />
                                    <Column
                                        style={{cursor: 'pointer', minWidth: '40%'}}
                                        className='border-bottom-1'
                                        sortable={false}
                                        header="ID/ Назва Черги"
                                        body={queueIdWithName}
                                        showFilterMatchModes={false}
                                        showFilterMenuOptions={false}
                                    />
                                </DataTable>
                            </div> : '- - -'}
                        </div>
                    </div>
                </div>}
                {!!updatedUserCampaignInfo?.length && windowWidth <= 1248 && <div className="field col-12 md:col-12 flex m-0 min-w-full px-0">
                    <DataTable value={updatedUserCampaignInfo}
                               className='qualGroup'
                               style={{cursor: 'pointer', minWidth: '100%'}}
                               emptyMessage="Нічого не знайдено"
                    >
                        <Column
                            style={{cursor: 'pointer', width: '20%'}}
                            field="skillGroupLevel"
                            className='border-bottom-1'
                            sortable={false}
                            header="Квал рiвень"
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                        />
                        <Column
                            style={{cursor: 'pointer', minWidth: '80%'}}
                            field="qualGroup"
                            className='border-bottom-1'
                            sortable={false}
                            header="ID/ Назва Кампанii"
                            body={campaignIdWithName}
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                        />
                    </DataTable>
                </div>}
                {!!updatedUserQueuesInfo?.length && windowWidth <= 1248 && <div className="field col-12 md:col-12 flex m-0 min-w-full px-0">
                    <DataTable value={updatedUserQueuesInfo}
                               className='qualGroup'
                               emptyMessage="Нічого не знайдено"
                               style={{cursor: 'pointer', minWidth: '100%'}}
                    >
                        <Column
                            style={{cursor: 'pointer', width: '20%'}}
                            field="skillGroupLevel"
                            className='border-bottom-1'
                            sortable={false}
                            header="Квал рiвень"
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                        />
                        <Column
                            style={{cursor: 'pointer', minWidth: '80%'}}
                            className='border-bottom-1'
                            sortable={false}
                            header="ID/ Назва Черги"
                            body={queueIdWithName}
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                        />
                    </DataTable>
                </div>}
                {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                    <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Бранч:</label></div></div>
                    <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                    <div className="field col-12 md:col-12 flex m-0 flex-column px-0">{qualificationData?.skillGroupBranches?.length ? branchItems : '- - -'}</div>
                </div>}
            </div>
        </div>
    );
};

export default QualificationGroupListDetails;
