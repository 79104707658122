import React, {useEffect, useState} from 'react';
import {classNames} from "primereact/utils";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import RolesListDialog from "../../Settings/RolesList/RolesListDialog";
import {ProgressBar} from "primereact/progressbar";
import {setRoleDialogVisible, setSelectedRolesIndex} from "../../../redux/actions/actionsRoles";
import {setEditType} from "../../../redux/actions/actions";
import {getRoleDetails} from "../../../redux/api/apiRoles";
import {Button} from "primereact/button";

const BranchListOfDependenciesRoles = () => {
    const dispatch = useAppDispatch();
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const errorFields = useAppSelector(state => state.BranchesListReducer.errorFields);
    const archiveRolesTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolePermissions = checkPermissions(rolesVision, 'Role');
    const detailsLoading = useAppSelector(state => state.RolesReducer.detailsLoading);
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [id, setId] = useState<boolean>(false);

    const {
        visible: hasVisible,
        update: hasRoleUpdatePermission
    } = rolePermissions;

    useEffect(() => {
        dispatch(setRoleDialogVisible(false));
    }, []);

    const openEditor = (id: number) => {
        dispatch(setSelectedRolesIndex(id));
        dispatch(getRoleDetails(id, jwtToken?.jwtToken));
        if (hasRoleUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    useEffect(() => {
        if (!detailsLoading && id) {
            dispatch(setRoleDialogVisible(true));
            setId(false);
        }
    }, [detailsLoading]);


    const rolesItems = () => {
        return (
            <>
                {branchData?.roles?.map((item: any, index: number) => (
                    <div key={index} className="flex align-items-center mb-2 w-full">
                        {hasVisible && <Button disabled={id && detailsLoading} loading={id === item.id && detailsLoading} onClick={() => {
                            if (hasVisible) {
                                openEditor(item?.id);
                                setId(item?.id);
                            }
                        }} icon={archiveRolesTableToggle && hasRoleUpdatePermission ? 'pi pi-pencil' : !archiveRolesTableToggle || !hasRoleUpdatePermission ? 'pi pi-eye' : ''} style={{minHeight: '37px', minWidth: '37px'}} className={classNames('p-button-secondary cursor-card-item mr-2 px-2 h-full w-full flex cursor-pointer align-items-center justify-content-center')}></Button>}
                        <div className={`field col-12 md:col-12 flex align-items-center justify-content-between m-0 w-full px-2 cursor-card-item`}>
                            <p className="mb-0 py-2">{item.id} {item.name}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };


    return (
        <div className="grid p-fluid">
            <div className="grid field col-12 md:col-12">
                {hasVisible && <RolesListDialog branchId={branchData?.id}/>}
                <div className="field col-12 md:col-12 mb-0" style={{minHeight: '15px'}}>
                    {branchDetailsLoader && <ProgressBar mode="indeterminate" className="w-full" style={{height: '6px'}}></ProgressBar>}
                </div>
                <div className="field col-12 md:col-4">
                    <h6>Ролі користувачів:</h6>
                    {
                        branchData?.roles?.length ?
                            <div className="users-list mt-3">
                                {/*{id && detailsLoading && <div className="bg-loading-animation"><i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i></div>}*/}
                                {rolesItems()}
                            </div>
                            :
                            <>

                                {
                                    errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.roleId})}>Нічого не вибрано</span> :
                                        <span className={classNames('block no-users-message mt-4')}>Нічого не вибрано</span>
                                }
                            </>

                    }
                </div>
            </div>
        </div>
    );
};

export default BranchListOfDependenciesRoles;
