
import {HIDE_ERROR} from "../types/types";
import {
    GET_CALL_STATISTIC,
    SET_CALLS_STATISTICS_FILTER,
    SET_CALLS_STATISTICS_LOADING,
    SET_CURRENT_CALLS_STATISTICS_PAGE,
    SET_CURRENT_CALLS_STATISTICS_SIZE,
    SET_FIRST_PAGE,
    SHOW_EXPORT_CALLS_STATISTICS_LOADING,
    UPDATE_STATISTIC_SORT_FIELDS
} from "../types/typesCallsStatisticsList";

export function setCallsStatisticsCurrentPage(state: any) {
    return {
        type: SET_CURRENT_CALLS_STATISTICS_PAGE,
        payload: state
    }
}

export function setCallsStatisticsCurrentPageSize(state: any) {
    return {
        type: SET_CURRENT_CALLS_STATISTICS_SIZE,
        payload: state
    }
}

export function setPageForFirst(state: any) {
    return {
        type: SET_FIRST_PAGE,
        payload: state
    }
}

export function showCallsStatisticsExportLoader(state: boolean) {
    return {
        type: SHOW_EXPORT_CALLS_STATISTICS_LOADING,
        payload: state
    }
}


export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function setCallStatisticsFilter(payload: any) {
    return {
        type: SET_CALLS_STATISTICS_FILTER,
        payload: payload,
    };
}

export function showCallStatisticsLoader(payload: any) {
    return {
        type: SET_CALLS_STATISTICS_LOADING,
        payload: payload,
    };
}

export function updateCallsStatisticsSortFields(updatedFields: any) {
    return {
        type: UPDATE_STATISTIC_SORT_FIELDS,
        payload: updatedFields,
    };
}

export function getUserCallsDetailsInfo(data: any) {
    return {
        type: GET_CALL_STATISTIC,
        payload: data,
    };
}


