import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import {Divider} from "primereact/divider";
import {useAppSelector} from "../../../redux/hooks";

const QualificationGroupDialogInfo = () => {
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData) as unknown as any;

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.name || '-'}</p>
            </>
        );
    };

    const activeBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.active ? 'Активна' : 'Неактивна'}</p>
            </>
        );
    };

    const operatorsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.operatorsCount}
            </div>
        )
    };

    return (
        <>
            <div id="pr_id_3_header" className="p-dialog-title mb-3">Налаштування групи</div>
            <DataTable value={[qualificationData]}>
                <Column className="w-4" field="name" body={nameBodyTemplate} header="Назва"></Column>
                <Column className="w-4" field="active" body={activeBodyTemplate} header="Стан"></Column>
                <Column className="w-4" field="active" body={operatorsBodyTemplate} header="Оператори"></Column>
            </DataTable>
            <Divider style={{margin: '0 0 20px 0'}}/>
        </>

    )
}

export default QualificationGroupDialogInfo
