import axios from "axios";
import {API_URL} from "../config";
import {GET_NOTIFICATION_ERROR} from "../types/types";
import {
    GET_BRANCH,
    GET_BRANCHES, GET_BRANCHES_ACD,
    GET_BRANCHES_OWNERS,
    GET_USER_BRANCHES_LIST, SET_BRANCH_RESPONSE_MESSAGE,
    SHOW_BRANCH_CREATED_MESSAGE,
    SHOW_BRANCH_DELETE_MESSAGE,
    SHOW_BRANCH_ERROR_CREATED_MESSAGE,
    SHOW_BRANCH_ERROR_DELETE_MESSAGE,
    SHOW_BRANCH_ERROR_UPDATED_MESSAGE,
    SHOW_BRANCH_UPDATED_MESSAGE
} from "../types/typesBranchesList";
import {hideBranchDetailsLoader, hideBranchLoader, hideError, setBranchDialogVisible, showBranchDetailsLoader, showBranchLoader} from "../actions/actionsBranchList";
import {hideLoader} from "../actions/actions";
import {setUserError} from "../actions/actionsUsersList";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {setQueueDialogVisible} from "../actions/actionsQueue";

export function getBranchesList(jwtToken: any, archived:boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showBranchLoader());
        dispatch(hideError());
        axios.get(`${API_URL}Branch/GetAllBranchesTree${!archived ? `?isArchived=false`: ''}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCHES, payload: response.data.data})
                dispatch(hideBranchLoader());
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideBranchLoader())
            });
    }
}

export function getAllBranchesListWithArchive(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showBranchLoader());
        dispatch(hideError());
        axios.get(`${API_URL}Branch/GetAllBranchesTree`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCHES, payload: response.data.data})
                dispatch(hideBranchLoader());
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideBranchLoader())
            });
    }
}

export function getUserBranchesList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        axios.get(`${API_URL}Branch/GetUserBranchTree?isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_USER_BRANCHES_LIST, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getBranchesOwner(jwtToken: any, parentId: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        axios.get(`${API_URL}Branch/GetAllUsersWithOwners?parentId=${parentId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCHES_OWNERS, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader());
            })
    }
}

export function getBranchesACD(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        axios.get(`${API_URL}Branch/GetIntegrationUrls`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCHES_ACD, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getBranchDetailById(id: number, jwtToken: any, remote?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showBranchDetailsLoader());
        dispatch(hideError());
        axios.get(`${API_URL}Branch/GetBranchWithDetailsById?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCH, payload: response.data.data});
                if (remote) {
                    dispatch(setBranchDialogVisible(true));
                    dispatch(setQueueDialogVisible(false));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })

            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideBranchDetailsLoader());
            })
    }
}

export function createBranch(data: any, jwtToken: any, archived: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        dispatch({type: SHOW_BRANCH_CREATED_MESSAGE, payload: false});
        dispatch({type: SHOW_BRANCH_ERROR_CREATED_MESSAGE, payload: false});
        axios.post(`${API_URL}Branch/CreateBranch`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200 && response.data.message !== 'Access Denied') {
                    dispatch({type: GET_BRANCH, payload: response.data.data})
                    dispatch(getBranchesList(jwtToken, archived));
                    dispatch({type: SHOW_BRANCH_CREATED_MESSAGE, payload: true});
                } else {
                    dispatch({type: SHOW_BRANCH_ERROR_CREATED_MESSAGE, payload: true});
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SHOW_BRANCH_ERROR_CREATED_MESSAGE, payload: false});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_BRANCH_CREATED_MESSAGE, payload: false});
                return dispatch({type: SHOW_BRANCH_ERROR_CREATED_MESSAGE, payload: false});
            })
    }
}

export function updateBranch(data: any, jwtToken: any, archived: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        dispatch({type: SHOW_BRANCH_UPDATED_MESSAGE, payload: false});
        dispatch({type: SHOW_BRANCH_ERROR_UPDATED_MESSAGE, payload: false});
        axios.post(`${API_URL}Branch/UpdateBranch`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200 && response.data.message !== 'Access Denied') {
                    dispatch({type: GET_BRANCH, payload: response.data.data});
                    dispatch({type: SHOW_BRANCH_UPDATED_MESSAGE, payload: true});
                    dispatch(getBranchesList(jwtToken, archived));
                } else {
                    dispatch({type: SHOW_BRANCH_ERROR_UPDATED_MESSAGE, payload: true})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SHOW_BRANCH_ERROR_UPDATED_MESSAGE, payload: false});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_BRANCH_UPDATED_MESSAGE, payload: false});
                return dispatch({type: SHOW_BRANCH_UPDATED_MESSAGE, payload: false});
            })
    }
}

export function deleteBranch(id: any, jwtToken: any, archived: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        dispatch({type: SET_BRANCH_RESPONSE_MESSAGE, payload: null});
        dispatch({type: SHOW_BRANCH_CREATED_MESSAGE, payload: false});
        dispatch({type: SHOW_BRANCH_ERROR_CREATED_MESSAGE, payload: false});
        axios.get(`${API_URL}Branch/ArchiveBranch?branchId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200 && response.data.message !== 'Access Denied') {
                    dispatch(getBranchesList(jwtToken, archived));
                    dispatch({type: SHOW_BRANCH_DELETE_MESSAGE, payload: true});
                    dispatch({
                        type: SET_BRANCH_RESPONSE_MESSAGE, payload: {
                            message: response.data.message,
                            statusCode: response.data.statusCode
                        }
                    });

                } else {
                    dispatch({type: SHOW_BRANCH_ERROR_DELETE_MESSAGE, payload: true});
                    dispatch({
                        type: SET_BRANCH_RESPONSE_MESSAGE, payload: {
                            message: response.data.message,
                            statusCode: response.data.statusCode
                        }
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SHOW_BRANCH_ERROR_DELETE_MESSAGE, payload: true});
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
                dispatch({type: SET_BRANCH_RESPONSE_MESSAGE, payload: false});
                dispatch(setUserError(false))
                return dispatch({type: SHOW_BRANCH_ERROR_DELETE_MESSAGE, payload: false});
            })
    }
}

