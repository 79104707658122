export const GET_ROLES_VISION = 'GET_ROLES_VISION'
export const SHOW_ROLES_VISION_ERROR = 'SHOW_ROLES_VISION_ERROR'
export const GET_ROLES_LIST = 'GET_ROLES_LIST'
export const GET_ROLE_DETAILS = 'GET_ROLE_DETAILS'
export const GET_ROLES_DICTIONARY = 'GET_ROLES_DICTIONARY'
export const SELECTED_ROLES_ID = 'SELECTED_ROLES_ID'
export const ROLE_DIALOG_VISIBLE = 'ROLE_DIALOG_VISIBLE'
export const ROLE_FIELD = 'ROLE_FIELD'

export const SET_ROLE_MODULES_FIELD = 'SET_ROLE_MODULES_FIELD'
export const UPDATE_PERMISSION_ACTIVE = 'UPDATE_PERMISSION_ACTIVE';
export const HIDE_ROLE_SAVE_MESSAGE = 'HIDE_ROLE_SAVE_MESSAGE';
export const SHOW_ROLE_SAVE_MESSAGE = 'SHOW_ROLE_SAVE_MESSAGE'
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const ARCHIVE_ROLE_TABLE_TOGGLE = 'ARCHIVE_ROLE_TABLE_TOGGLE'
export const GET_ROLE_PAGINATION = 'GET_ROLE_PAGINATION'
export const SHOW_ROLES_LIST_LOADER = 'SHOW_ROLES_LIST_LOADER'
export const SHOW_ROLE_DETAILS_LOADER = 'SHOW_ROLE_DETAILS_LOADER'
export const HIDE_ROLE_LOADER = 'HIDE_ROLE_LOADER'
export const HIDE_ROLE_DETAILS_LOADER = 'HIDE_ROLE_DETAILS_LOADER'
export const SET_ROLE_CURRENT_PAGE = 'SET_ROLE_CURRENT_PAGE'
export const SET_ROLE_CURRENT_SIZE = 'SET_ROLE_CURRENT_SIZE'
export const SET_ROLES_LIST_DATA_SORT = 'SET_ROLES_LIST_DATA_SORT'
export const SET_ROLE_ARCHIVED_MESSAGE_SUCCESS = 'SET_ROLE_ARCHIVED_MESSAGE_SUCCESS'
export const HIDE_ROLE_ARCHIVED_MESSAGE = 'HIDE_ROLE_ARCHIVED_MESSAGE'
export const SHOW_ROLE_ARCHIVED_MESSAGE = 'SHOW_ROLE_ARCHIVED_MESSAGE '
export const SET_STATUS_MATRIX = 'SET_STATUS_MATRIX'
export const GET_TRUNKS_DICTIONARY = 'GET_TRUNKS_DICTIONARY'
export const ADD_ROLE_GROUP_BRANCH = 'ADD_ROLE_GROUP_BRANCH'
export const DELETE_ROLE_BRANCH = 'DELETE_ROLE_BRANCH'
