import React from "react";
import {useAppSelector} from "redux/hooks";

const QueuesListDialogManagement = () => {
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData)

    const createdUserTime = () => {
        const time = new Date(queueData?.queueData?.createdDateTime).toLocaleString("uk-UA")
        return queueData?.queueData?.createdDateTime ? time : '-'
    }

    const updatedUserTime = () => {
        const time = new Date(queueData?.queueData?.updatedDateTime).toLocaleString("uk-UA")
        return queueData.queueData?.updatedDateTime ? time : '-'
    }

    return (
        <div className="grid p-fluid management">
            <div className="field col-12 md:col-6 flex justify-content-start align-items-center mb-0 py-0">
                {!!queueData.queue.id && <div className="item">
                    ID: {queueData.queue.id ? queueData.queue.id : ''}
                </div>}
            </div>
            <div className="field col-12 md:col-6 mb-0">
                {!!queueData.queue.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                    {queueData.queueData?.createdDateTime && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{queueData?.queueData?.userCreate ? queueData?.queueData?.userCreate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                    </div>}
                    {queueData.queueData?.updatedDateTime &&<div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{queueData?.queueData?.userUpdate ? queueData?.queueData?.userUpdate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                    </div>}
                </div>}
            </div>
        </div>
    )
}

export default QueuesListDialogManagement
