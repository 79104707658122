import {
    CHANGE_RECALL_NUMBER, CLEAR_RECALL_SESSION,
    GET_ERROR_USERS_WEB_RTC, GET_MUTE_ID,
    GET_USERS_WEB_RTC, RECONNECT_SESSION, SET_ACTIVE_SESSION, SET_RECALL_NUMBER, SUCCESS_SESSION_RECONNECTED
} from "../types/types";
import {CHANGE_INTERNET_STATUS} from "../types/typesWebRTC";

const initialState = {
    searchResults: [],
    error: false,
    muteId: 0,
    activeSession: true,
    reconnectSession: false,
    successReconnect: false,
    recallNumber: null,
    sessionId: null,
    recallNumberChanged: false,
    internetStatusError: false
};

export const WebRTCReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USERS_WEB_RTC:
            return { ...state, searchResults: action.payload };
        case GET_ERROR_USERS_WEB_RTC:
            return { ...state, error: action.payload };
        case GET_MUTE_ID:
            return { ...state, muteId: action.payload };
        case SET_ACTIVE_SESSION:
            return { ...state, activeSession: action.payload };
        case RECONNECT_SESSION:
            return { ...state, reconnectSession: action.payload };
        case SUCCESS_SESSION_RECONNECTED:
            return { ...state, successReconnect: action.payload };
        case SET_RECALL_NUMBER:
            return { ...state, recallNumber: action.payload.nameTo, sessionId: action.payload.sessionId };
        case CLEAR_RECALL_SESSION:
            return { ...state, sessionId: null};
        case CHANGE_RECALL_NUMBER:
            return { ...state, recallNumberChanged: action.payload };
        case CHANGE_INTERNET_STATUS:
            console.log(action.payload)
            return { ...state, internetStatusError: action.payload };
        default:
            return state;
    }
};
