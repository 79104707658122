import axios from "axios";
import {getItem, setItem} from "../cache/cacheService";

import {
    ACCOUNT_WEB_LOGIN,
    ERROR_ACCOUNT_WEB_LOGIN,
    GET_CAMPAIGN_CONTACTS,
    GET_COMPANIES,
    GET_COMPANIES_ARCHIVED,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE,
    GET_COMPANIES_DIRECTORIES_PHONE_TYPE, GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE,
    GET_COMPANIES_DIRECTORIES_TRANKS_PHONE,
    GET_COMPANY,
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    GET_PAGINATION,
    GET_USERS, SET_LOADING, SHOW_CAMPAIGN_DETAIL_LOADING,
    SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR,

} from "../types/types";
import {CompaniesData, StateCampaign, UserLoginData} from "types/types";
import {API_URL} from "redux/config";
import {
    getCampaignDirectoriesCampaignTypeError, googleAuthLoginError,
    hideCompanyCreateMessage,
    hideCompanySaveMessage,
    hideError,
    hideErrorMessage,
    hideLoader,
    hideLoaderStrategyDict,
    setArchiveTableToggle,
    setCompanyStateSave,
    setContactsLoader, setErrorFields,
    showCompanyCreateMessage,
    showCompanyDeleteMessage,
    showCompanySaveMessage,
    showError,
    showErrorMessage,
    showGetCampaignDirectoriesPhoneTypeError,
    showLoader,
    showLoaderStrategyDict,
    showUpdatedError
} from "../actions/actions";
import {showRolesErrorMessage} from "../actions/actionsRoles";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {setUserError} from "../actions/actionsUsersList";
import {SET_CHECK_USER_FOR_CALL, SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";

let dataSort = {
    isArchived: false,
    pageNumber: 1,
    pageSize: 10,
    sort: {
        orderBy: "CampaignId",
        isDesc: true
    }
}

export function getCampaignDirectoriesPhoneType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(showGetCampaignDirectoriesPhoneTypeError(false))
        axios.get(`${API_URL}DialerDictionary/GetDictionary/true?parentId=100`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANIES_DIRECTORIES_PHONE_TYPE, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showGetCampaignDirectoriesPhoneTypeError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getCampaignDirectoriesCampaignType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(getCampaignDirectoriesCampaignTypeError(false))
        axios.get(`${API_URL}DialerDictionary/GetDictionary/true?parentId=200`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(getCampaignDirectoriesCampaignTypeError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                return dispatch(getCampaignDirectoriesCampaignTypeError(false))
            })
    }
}

export function getCampaignDirectoriesSchedulerType(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(getCampaignDirectoriesCampaignTypeError(false))
        axios.get(`${API_URL}DialerDictionary/GetDictionary/true?parentId=400`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(getCampaignDirectoriesCampaignTypeError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                return dispatch(getCampaignDirectoriesCampaignTypeError(false))
            })
    }
}

export function getCampaignDirectoriesGetTranksPhones(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: false})
        axios.get(`${API_URL}DialerDictionary/GetTrunksPhones`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANIES_DIRECTORIES_TRANKS_PHONE, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    return dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: true})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getCampaignDirectoriesCampaignStrategy(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showLoaderStrategyDict())
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch({type: GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR, payload: false})
        axios.get(`${API_URL}DialerDictionary/GetDictionary/true?parentId=300`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY, payload: response.data.data})
                dispatch(hideLoaderStrategyDict())
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR, payload: true})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getCampaignId(id: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        dispatch(hideErrorMessage());
        dispatch({type: SHOW_CAMPAIGN_DETAIL_LOADING, payload: true});
        axios.get(`${API_URL}Campaign/CampaignId/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANY, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_CAMPAIGN_DETAIL_LOADING, payload: false});
            })
    }
}

export function getUsers(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}Users/AllSimpleUsers?isActive=true`, {
            headers: {
                'Content-Type': 'application/json',
                "x-access-userid": jwtToken?.userId
                // "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_USERS, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function deleteCompanies(CampaignId: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(showCompanyDeleteMessage(false));
        axios.delete(`${API_URL}Campaign/${CampaignId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then(() => {
                let data = {
                    "isArchived": false,
                    "pageNumber": 1,
                    "pageSize": 10,
                    "sort": {
                        "orderBy": "CampaignId",
                        "isDesc": true
                    },
                    "filter": []
                }
                dispatch(getCompaniesWithPaginationFilterAndSorting(sort || data, jwtToken));
                dispatch(showCompanyDeleteMessage(true));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function createCompanies(data: CompaniesData, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideCompanyCreateMessage())
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.post(`${API_URL}Campaign/Create`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_COMPANY, payload: response.data.data})
                    dispatch(setCompanyStateSave(false))
                    dispatch(getCompaniesWithPaginationFilterAndSorting(dataSort, jwtToken));
                    dispatch(showCompanyCreateMessage())
                }
                if (response.data.statusCode === 400) {
                    let errorMessage = {"nameUniq": "Назва кампанії вже існує"};
                    dispatch(setErrorFields(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function updateCompanies(data: CompaniesData, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideCompanySaveMessage())
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.put(`${API_URL}Campaign/Update`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_COMPANY, payload: response.data.data})
                dispatch(getCompaniesWithPaginationFilterAndSorting(dataSort, jwtToken));
                dispatch(showCompanySaveMessage())
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showUpdatedError())
                }
            })
            .finally(() => {
                dispatch(hideCompanySaveMessage())
                dispatch(showAccessDeniedNotifications(false));
            })

    }
}

//old login method

export function accountWebLogin(data: UserLoginData) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: SET_LOADING, payload: true});
        dispatch(showRolesErrorMessage(false))
        axios.post(`${API_URL}AccountWeb/login`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                dispatch({type: SET_LOADING, payload: false});
                if (response.data.statusCode === 200) {
                    dispatch({type: ACCOUNT_WEB_LOGIN, payload: response.data.data});
                    const user = {userName: response.data.data?.userName, userId: response.data.data?.userId};
                    let userData = response.data.data;
                    const expirationTime = Date.now() + response.data.data.jwtToken?.expiresIn * 1000;
                    localStorage.setItem('expirationTime', String(expirationTime));
                    userData.pass = data.password;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('callcenter:user', JSON.stringify(userData));
                    localStorage.setItem('callcenter:accountWebLogin', response.data.statusCode);
                    dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}});
                } else {
                    dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: response.data})
                    dispatch({type: SET_LOADING, payload: false});
                }
            })
            .catch(() => {
                dispatch({type: SET_LOADING, payload: false});
                return dispatch(showError())
            })
    }
}

export function googleAccountWebLogin(id: any) {
    return (dispatch: (arg0: any) => void) => {
        let hash = window.location.hash;
        if (id && hash?.includes("auth_google?id")) {
            dispatch({type: SET_LOADING, payload: true});
            dispatch(showRolesErrorMessage(false))
            axios.get(`${API_URL}AccountWeb/GoogleLogin?id=${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    cache: 'no-store',
                    'Cache-Control': 'no-cache'
                }
            })
                .then((response) => {
                    dispatch({type: SET_LOADING, payload: false});
                    if (response.data.statusCode === 200) {
                        dispatch({type: ACCOUNT_WEB_LOGIN, payload: response.data.data});
                        const user = {userName: response.data.data?.userName, userId: response.data.data?.userId};
                        let userData = response.data.data;
                        const expirationTime = Date.now() + response.data.data.jwtToken?.expiresIn * 1000;
                        localStorage.setItem('expirationTime', String(expirationTime));
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('callcenter:user', JSON.stringify(userData));
                        localStorage.setItem('callcenter:accountWebLogin', '300');
                        dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}})
                    } else {
                        dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: response.data})
                        dispatch({type: SET_LOADING, payload: false});
                    }
                })
                .catch((e) => {
                    dispatch({type: SET_LOADING, payload: false});
                    dispatch(googleAuthLoginError(e?.response?.data?.title || true));
                })
        }
    }
}

export function updateStateCampaign(data: StateCampaign, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideCompanySaveMessage());
        dispatch(hideError());
        dispatch(hideErrorMessage());
        axios.post(`${API_URL}Campaign/StateCampaing`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 100) {
                    dispatch(showErrorMessage(response.data.message));
                } else {
                    dispatch({type: GET_COMPANY, payload: response.data.data});
                    dispatch(getCompaniesWithPaginationFilterAndSorting(dataSort, jwtToken));
                    dispatch(showCompanySaveMessage());
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(hideCompanySaveMessage());
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function copyCampaign(id: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}Campaign/CopyCampaing/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_COMPANY, payload: response.data.data});
                    dispatch(setArchiveTableToggle(true));
                    dispatch(getCompaniesWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(showErrorMessage(response.data.message));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getNotification(userId: any, code: number, jwtToken: any, callback?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        axios.get(`${API_URL}Notification/LangNotification/${userId}/${code}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                setItem(`notification_code:${response.data.code}`, response.data.text, 5)
                dispatch({type: GET_NOTIFICATION, payload: response.data});
            })
            .catch(() => {
                dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
            }).finally(() => {
            if (callback) {
                callback(getItem(`notification_code:${code}`));
            }
        });
    }
}

export function getCompaniesWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showLoader())
        dispatch(hideError())
        axios.post(`${API_URL}Campaign/AllCampaignsWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dataSort = data;
                if (data.isArchived === false) {
                    dispatch({type: GET_COMPANIES, payload: response.data.data.items})
                } else {
                    dispatch({type: GET_COMPANIES_ARCHIVED, payload: response.data.data.items})
                }
                dispatch({type: GET_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(hideLoader())
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getCampaignContactsWithPagination(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(setContactsLoader(true));
        axios.post(`${API_URL}Campaign/GetCampaignContactsByIdWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_CAMPAIGN_CONTACTS, payload: response.data.data});
                dispatch(setContactsLoader(false));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getContactsExcel(campaignId: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}CampaignContacts/GetContactsExcel/${campaignId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                window.location.href = <string>response.config.url;
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getContactsExcelForDelete(campaignId: number, userId: string | null, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}CampaignContacts/GetContactsExcel/${campaignId}`, {responseType: 'blob'})
            .then((response) => {
                const formData = new FormData();
                formData.append('file', response.data);
                dispatch(deleteCampaignContacts(formData, campaignId, userId, jwtToken));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function deleteCampaignContacts(formData: any, campaignId: number, userId: string | null, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.post(`${API_URL}CampaignContacts/UploadContactsExcel/${campaignId}?userId=${userId}&action=delete`, formData, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then(() => {
                const dataSort = {
                    pageNumber: 1,
                    pageSize: 10,
                    sort: {
                        orderBy: "ContactName",
                        isDesc: true
                    },
                    campaignId: campaignId
                }
                dispatch(getCampaignContactsWithPagination(dataSort, jwtToken?.jwtToken));
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function checkUserForCall(userName: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.get(`${API_URL}UserResource/CheckUserForCall?callTo=${userName}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch({type: SET_CHECK_USER_FOR_CALL, payload: true});
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                    dispatch({type: SET_CHECK_USER_FOR_CALL, payload: false});
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: SET_CHECK_USER_FOR_CALL, payload: false});
            })
    }
}
