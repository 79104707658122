import React from "react";
import {useAppSelector} from "redux/hooks";

const AudioDialogManagement = () => {
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);

    const createdUserTime = () => {
        const time = new Date(audioData?.audioData?.createdDateTime).toLocaleString("uk-UA")
        return audioData?.audioData?.createdDateTime ? time : '-'
    }

    const updatedUserTime = () => {
        const time = new Date(audioData?.audioData?.updatedDateTime).toLocaleString("uk-UA")
        return audioData.audioData?.updatedDateTime ? time : '-'
    }

    return (
        <div className="grid p-fluid management">
            <div className="field col-12 md:col-6 flex justify-content-start align-items-center mb-0 py-0">
                {!!audioData.audioId && <div className="item">
                    ID: {audioData.audioId ? audioData.audioId : ''}
                </div>}
            </div>
            <div className="field col-12 md:col-6 mb-0">
                {!!audioData.audioId && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                    {audioData.audioData?.createdDateTime && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{audioData?.audioData?.userCreate ? audioData?.audioData?.userCreate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                    </div>}
                    {audioData.audioData?.updatedDateTime &&<div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{audioData?.audioData?.userUpdate ? audioData?.audioData?.userUpdate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                    </div>}
                </div>}
            </div>
        </div>
    )
}

export default AudioDialogManagement
