import {CHANGE_RECALL_NUMBER, CLEAR_RECALL_SESSION, GET_MUTE_ID, GET_USERS_WEB_RTC, RECONNECT_SESSION, SET_ACTIVE_SESSION, SET_RECALL_NUMBER, SUCCESS_SESSION_RECONNECTED} from "../types/types";
import {CHANGE_INTERNET_STATUS} from "../types/typesWebRTC";

export const setSearchResults = (results: []) => ({
    type: GET_USERS_WEB_RTC,
    payload: results,
});

export const setSearchError = (error: boolean) => ({
    type: GET_USERS_WEB_RTC,
    payload: error,
});


export const getMuteId = (muteId: number) => ({
    type: GET_MUTE_ID,
    payload: muteId,
});

export const setActiveSession = (active: any) => ({
    type: SET_ACTIVE_SESSION,
    payload: active,
});

export const setReconnectSession = (activeConnect: any) => ({
    type: RECONNECT_SESSION,
    payload: activeConnect,
});

export const sessionReconnected = (reconnected: any) => ({
    type: SUCCESS_SESSION_RECONNECTED,
    payload: reconnected,
});

export const setRecallNumber = (data: any) => ({
    type: SET_RECALL_NUMBER,
    payload: data,
});

export const clearRecallSession = () => ({
    type: CLEAR_RECALL_SESSION,
});


export const changeRecallNumber = (bool: boolean) => ({
    type: CHANGE_RECALL_NUMBER,
    payload: bool,
});

export const changeInternetStatus = (state: any) => (
{
    type: CHANGE_INTERNET_STATUS,
    payload: state,
})

