export const codes = {
    cancel: 'notification_code:107',
    suspend: 'notification_code:106',
    launch: 'notification_code:105',
    copy: 'notification_code:108',
    success: 'notification_code:109',
    update: 'notification_code:110',
    error: 'notification_code:111',
    error_updated: 'notification_code:112',
    save_changes: 'notification_code:113',
    user_delete_questions: 'notification_code:130',
    user_deleted_messages: 'notification_code:126',
    user_access_denied_messages: 'notification_code:131',
    not_found_message: 'notification_code:132',
    archive_branch_question: 'notification_code:133',
    archive_role_question: 'notification_code:144',
    archive_status_question: 'notification_code:153',
    archive_queue_question: 'notification_code:171',
    un_archive_queue_question: 'notification_code:173',
    copy_queue_question: 'notification_code:164',
    archive_qualification_group_question: 'notification_code:190',
    un_archive_qualification_group_question: 'notification_code:173',
    copy_qualification_group_question: 'notification_code:193',
    copy_status: 'notification_code:199',
    copy_role: 'notification_code:200',
    audio_delete_questions: 'notification_code:219',
    un_archive_audio_question: 'notification_code:220'
};
