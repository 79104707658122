import {Calendar} from "primereact/calendar";
import {setChangedScheduler, setCompanyField} from "../../../../redux/actions/actions";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import React from "react";
import moment from "moment";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

const TimeFromTo = (props: any) => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const statusDone = companiesData?.campaign?.statusId === 6;
    const editType = useAppSelector(state => state.UsersReducer.editType);

    moment.suppressDeprecationWarnings = true;
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');

    const {
        // create: hasDialerCreatePermission,
        // update: hasDialerUpdatePermission,
    } = dialerPermissions;
    const isValidTime = (value: any) => {
        const format = 'HH:mm';
        return moment(value, format, true).isValid();
    };
    const handleDateChange = (date: any) => {
            return moment(date).format('MM/DD/YYYY HH:mm:ss');
    };

    const transformToDate = (dateString: any) => {
        return new Date(moment(dateString).format('MM/DD/YYYY HH:mm:ss'));
    }

    return (
        <>
            <div className="flex field col-12">
                <div className="md:col-6 p-0 pr-2">
                    <div className="time-item">
                        <span className="title">З</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            className={classNames('flex-grow-1', {'p-invalid': errorFields?.startTime})}
                            value={companiesData?.scheduler?.startTime ? transformToDate(companiesData?.scheduler?.startTime) : transformToDate(props.startTime)}
                            onChange={e => {if (isValidTime(e.target.value)) {dispatch(setChangedScheduler(true)); dispatch(setCompanyField({section: 'scheduler', fieldName: 'startTime', value: handleDateChange(e.target.value)}))}}}
                            timeOnly
                            hourFormat="24"
                            disabled={!props.archiveTableToggle || statusDone || !editType}
                        />
                    </div>
                </div>
                <div className="md:col-6 p-0 pl-2">
                    <div className="time-item">
                        <span className="title">По</span>
                        <Calendar
                            className={classNames('flex-grow-1', {'p-invalid': errorFields?.endTime})}
                            id="icon"
                            value={companiesData?.scheduler?.endTime ? transformToDate(companiesData?.scheduler?.endTime) : transformToDate(props.endTime)}
                            hourFormat="24"
                            timeOnly
                            onChange={e => {if (isValidTime(e.target.value)) {dispatch(setChangedScheduler(true)); dispatch(setCompanyField({section: 'scheduler', fieldName: 'endTime', value: handleDateChange(e.target.value)}))}}}
                            disabled={!props.archiveTableToggle || statusDone || !editType}
                        />
                    </div>
                </div>
            </div>
            {errorFields?.endTime && <div className="col-12 md:col-12 pt-0 pb-4 pl-5"><span className="title p-error text-sm">Час запуску не може перевищувати час завершення*</span></div>}
        </>
    );
};

export default TimeFromTo;
