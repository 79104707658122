import {Calendar} from "primereact/calendar";
import {setChangedScheduler, setCompanyField} from "../../../../redux/actions/actions";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import moment from "moment/moment";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

const LaunchDate = (props: any) => {
    moment.suppressDeprecationWarnings = true;
    const dispatch = useAppDispatch();
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const statusDone = companiesData?.campaign?.statusId === 6;
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    const editType = useAppSelector(state => state.UsersReducer.editType);

    const {
        // create: hasDialerCreatePermission,
        // update: hasDialerUpdatePermission,
    } = dialerPermissions;
    const isValidDate = (value: any) => {
        const format = 'MM/DD/YYYY';
        return moment(value, format, true).isValid();
    };

    const handleDateChange = (date: any) => {
            return moment(date).format('MM/DD/YYYY HH:mm:ss');
    };

    const transformToDate = (dateString: any) => {
        return new Date(moment(dateString).format('MM/DD/YYYY HH:mm:ss'));
    }


    return (
        <>
            <div>
                <label htmlFor="icon" className="block field">Дата запуску</label>
                <Calendar
                    appendTo="self"
                    id="icon"
                    dateFormat="dd-mm-yy"
                    className={classNames('', {'p-invalid': errorFields?.startDate})}
                    minDate={new Date()}
                    value={companiesData?.scheduler?.startDate ? transformToDate(companiesData?.scheduler?.startDate) : transformToDate(props.startDate)}
                    onChange={e => {if (isValidDate(e.target.value)) {
                        dispatch(setChangedScheduler(true)); dispatch(setCompanyField({section: 'scheduler', fieldName: 'startDate', value: handleDateChange(e.target.value)}))}}}
                    showIcon
                    disabled={!props.archiveTableToggle || statusDone || !editType}
                />
                {errorFields?.startDate && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">Дата запуску не може перевищувати дату завершення.*</span></div>}
            </div>
        </>
    );
};

export default LaunchDate;
