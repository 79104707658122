import {
    GET_NOTIFICATION_MESSAGE,
    GET_NOTIFICATION_MESSAGE_ERROR,
    SHOW_ACCESS_DENIED_NOTIFICATION, SHOW_NOT_FOUND_NOTIFICATION
} from "../types/typesNotification";

const initialState = {
    error: false,
    accessDeniedError: false,
    notFoundError: false,
    accessDeniedErrorMessage: null,
    notFoundErrorMessage: null,
    archiveBranchMessage: null,
    archiveRoleMessage: null,
    archiveStatusMessage: null,
    archiveQueueMessage: null,
    unzipQueueMessage: null,
    copyQueueMessage: null,
    copyQualificationGroupMessage: null,
    archiveQualificationGroupMessage: null,
    unzipQualificationGroupMessage: null,
    copyStatusMessage: null,
    copyRoleMessage: null,
    audioDeleteMessage: null,
    unzipAudioMessage: null
}

export const NotificationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SHOW_ACCESS_DENIED_NOTIFICATION:
            return {...state, accessDeniedError: action.payload};
        case SHOW_NOT_FOUND_NOTIFICATION:
            return {...state, notFoundError: action.payload};
        case GET_NOTIFICATION_MESSAGE:
            switch (action.payload.code) {
                case 131:
                    return {...state, accessDeniedErrorMessage: action.payload}
                case 132:
                    return {...state, notFoundErrorMessage: action.payload}
                case 133:
                    return {...state, archiveBranchMessage: action.payload}
                case 144:
                    return {...state, archiveRoleMessage: action.payload}
                case 153:
                    return {...state, archiveStatusMessage: action.payload}
                case 164:
                    return {...state, copyQueueMessage: action.payload}
                case 171:
                    return {...state, archiveQueueMessage: action.payload}
                case 173:
                    return {...state, unzipQueueMessage: action.payload}
                case 190:
                    return {...state, archiveQualificationGroupMessage: action.payload}
                case 191:
                    return {...state, unzipQualificationGroupMessage: action.payload}
                case 199:
                    return {...state, copyStatusMessage: action.payload}
                case 200:
                    return {...state, copyRoleMessage: action.payload}
                case 219:
                    return {...state, audioDeleteMessage: action.payload}
                case 220:
                    return {...state, unzipAudioMessage: action.payload}
                default:
                    return state
            }
        case GET_NOTIFICATION_MESSAGE_ERROR:
            return {...state, error: action.payload}
        default:
            return state;
    }
};
