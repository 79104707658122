import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setUserErrorFields, setUserField} from "../../../../redux/actions/actionsUsersList";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import React, {useEffect, useRef, useState} from "react";
import {getBufferHash, getUsersManagerUserBranchesData, sendRecoveryPassword, updateUserStatus} from "../../../../redux/api/apiUsersList";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {ToggleButton} from "primereact/togglebutton";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {DecodedToken} from "../../../../types/types";
import {jwtDecode} from "jwt-decode";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import {stopGlobalJsSIP} from "../../../JsSip/GlobalJsSIP";
import {initialAccountData} from "../../../../redux/actions/actions";
import {setRolesVisible} from "../../../../redux/actions/actionsRoles";
import {ERROR_ACCOUNT_WEB_LOGIN} from "../../../../redux/types/types";
import {useNavigate} from "react-router-dom";

const UsersListDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    // @ts-ignore
    const roles = useAppSelector(state => state.UsersListReducer.roles);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    // @ts-ignore
    const hashLoader = useAppSelector(state => state.UsersListReducer.hashLoading);
    // @ts-ignore
    const changePasswordLoading = useAppSelector(state => state.UsersListReducer.changePasswordLoading);
    const changeCurrentUserPasswordSuccess = useAppSelector(state => state.UsersReducer.changeCurrentUserPasswordSuccess);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const [initialUserRole, setInitialUserRole] = useState<any>(false);
    const userNameField = useRef(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const usersDialogUnzipping = useAppSelector(state => state.UsersListReducer.usersDialogUnzipping);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [checked, setChecked] = useState<boolean>(false);
    const [hasEditRole, setHasEditRole] = useState(false);
    // @ts-ignore
    const userDialogVisible = useAppSelector(state => state.UsersListReducer.dialogVisible);
    const [visible, setVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState(false);
    const [userRecoveryData, setRecoveryData] = useState<any>('');
    const user = useAppSelector(state => state.UsersReducer.userData);

    let decodedToken: DecodedToken;
    let decodedUserId: string | undefined;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
        decodedUserId = decodedToken?.[`x-access-userid`]
    }

    useEffect(() => {
        setRecoveryData(userData?.user?.userName);
    }, []);

    useEffect(() => {
        if (changeCurrentUserPasswordSuccess) {
            if (currentUser) {
                let data = {
                    userId: user?.userId,
                    statusesId: 1
                };
                dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, false, true));
                navigate('/login');
                stopGlobalJsSIP();
                localStorage.clear();
                localStorage.removeItem('persist:callcenter:user');
                dispatch(initialAccountData());
                dispatch(setRolesVisible(null));
                dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}})
            } else {
                let data = {
                    userId: userData?.user?.userId,
                    statusesId: 1
                }
                dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, false, true));
            }
        }

    }, [changeCurrentUserPasswordSuccess]);

    useEffect(() => {
        let isCurrent = userData?.user?.userId === decodedUserId;
        setCurrentUser(isCurrent);
        dispatch(setUserField({section: 'password', fieldName: 'currentUser', value: isCurrent}))
    }, [decodedUserId, jwtToken, userData?.user?.userId]);

    const {
        create: hasUsersManagerCreatePermission,
        update: hasUsersManagerUpdatePermission,
    } = usersManagerPermissions;

    useEffect(() => {
        if (userData?.user?.branchId && branches?.length) {
            const foundObject = branches.find((item: any) => item.id === userData?.user?.branchId);
            if (!foundObject) {
                dispatch(setUserField({section: 'user', fieldName: 'branchId', value: null}))
            }
        }
    }, [branches, userData?.user?.branchId]);

    useEffect(() => {
        setChecked(true);
    }, [userDialogVisible]);

    const scrollIntoEmailView = () => {
        // @ts-ignore
        userNameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (errorFields?.userName) {
            scrollIntoEmailView();
        }
    }, [errorFields]);

    let foundBranch = null;

    if (userData?.user?.branchId) {
        foundBranch = branches.find((branch: any) => branch.id === userData.user.branchId);
    }

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        if (userData?.user?.branchId) {
            foundBranch = branches.find((branch: any) => branch.id === userData.user.branchId);
        }
    }, [branches]);

    useEffect(() => {
        setInitialUserRole(setRolesField());
    }, []);

    const removeNonExistingRoles = (array: any, otherArray: any) => {
        if (Array.isArray(otherArray)) {
            return otherArray.filter((item1: { id: any; }) => !array.some((item2: { id: any; }) => item2.id === item1.id));
        } else {
            return null;
        }
    }

    const existingRoles = (array: any, otherArray: any) => {
        if (Array.isArray(otherArray)) {
            return array.filter((item1: { id: any; }) => otherArray.some((item2: { id: any; }) => item2.id === item1.id));
        } else {
            return null;
        }
    }

    const setRolesField = () => {
        return removeNonExistingRoles(roles, userData?.user?.roles);
    }

    const addNewRole = (value: any) => {
        const roles: any = [];
        if (initialUserRole) {
            roles.concat(initialUserRole);
            initialUserRole.map((item: any) => {
                roles.push(item);
            })
            roles.push(value);
            dispatch(setUserField({section: 'user', fieldName: 'roles', value: roles}))
        } else {
            roles.push(value);
            dispatch(setUserField({section: 'user', fieldName: 'roles', value: roles}))
        }
    };

    let existRole = existingRoles(roles, userData?.user?.roles);

    function hasEditPermission(array: any) {
        if (array && array.length) {
            return array.some((item: { isEdit: boolean; }) => item.isEdit)
        } else {
            return true;
        }
    }

    useEffect(() => {
        setHasEditRole(hasEditPermission(userData?.user?.roles));
    }, []);

    useEffect(() => {
        setHasEditRole(hasEditPermission(userData?.user?.roles));
    }, [userData?.user?.roles]);

    const handleSubmit = () => {
        if (currentUser) {
            dispatch(sendRecoveryPassword(userRecoveryData, true));
            dispatch(setUserErrorFields(null));
            setVisible(false);
        } else {
            dispatch(sendRecoveryPassword(userRecoveryData, true, true));
            dispatch(setUserErrorFields(null));
            setVisible(false);
        }
    };

    const names = userData?.user?.roles?.map((user: { name: string; }) => user.name.trim()).join(', ');


    return (
        <div className="grid p-fluid">
            <Toast position="bottom-right" ref={toast}/>
            {<div className="field col-12 md:col-4" ref={userNameField}>
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.userName || errorFields?.userNameError})}>Логін *</label>
                <InputText
                    id="username"
                    value={userData?.user?.userName || ''}
                    maxLength={32}
                    className={classNames('', {'p-invalid': errorFields?.userName || errorFields?.userNameError})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'userName', value: e.target.value}));
                    }}
                    disabled={!usersActiveTableToggle || (!editType) || userData?.user?.id}
                />
                {errorFields && errorFields?.userName && errorFields?.userName?.name && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.userName})}>{errorFields?.userName?.name}</label>}
                {errorFields && errorFields?.userNameError && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.userNameError})}>{errorFields?.userNameError}</label>}
            </div>}
            {!userData?.user?.id && <div className="field col-12 md:col-4">
                <label htmlFor="password" className={classNames('', {'p-error': errorFields?.password})}>Пароль *</label>
                <InputText
                    value={userData?.user?.password || ''}
                    maxLength={90}
                    className={classNames('', {'p-invalid': errorFields?.password})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'password', value: e.target.value}));
                    }}
                    disabled={!usersActiveTableToggle || (!editType)}
                />
                {errorFields && errorFields?.password && errorFields?.password && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.password})}>{errorFields?.password?.password}</label>}
            </div>}
            {userData?.user?.id && <div className="field col-12 md:col-3">
                {hasUsersManagerUpdatePermission && <label htmlFor="name" className={classNames('w-full', {'p-error': errorFields?.password})}>Пароль</label>}
                {hasUsersManagerUpdatePermission && <ToggleButton onLabel="Змінити пароль" offLabel="Залишити пароль" className="max-w-14rem" onIcon="pi pi-pencil" offIcon="pi pi-times"
                                                                  checked={checked} onChange={(e) => {
                    dispatch(setUserErrorFields(null));
                    setVisible(true);
                }}/>}
            </div>}
            {userData?.user?.id && <div className="field col-12 md:col-5">
                {(hasUsersManagerUpdatePermission || hasUsersManagerCreatePermission) && <label>HashPasswordIntegration</label>}
                {(hasUsersManagerUpdatePermission || hasUsersManagerCreatePermission) && <div className="flex">
                    <InputText disabled className="max-w-14rem mr-2" value="***************************"/>
                    <Button loading={hashLoader} className="max-w-10rem" icon="pi pi-copy" label="Копіювати" onClick={() => dispatch(getBufferHash(userData?.user?.userName, jwtToken?.jwtToken))}/>
                </div>}
            </div>}
            {!userData?.user?.id && <div className="field col-12 md:col-4"></div>}

            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.roles})}>Роль *</label>
                {hasEditRole ? <Dropdown
                    value={existRole?.[0] || ''}
                    onChange={(e) =>
                        addNewRole(e.target.value)
                    }
                    placeholder='Виберіть роль'
                    className={classNames('w-full', {'p-invalid': errorFields?.roles})}
                    options={roles}
                    optionLabel="name"
                    disabled={!hasEditPermission(userData?.user?.roles) || !usersDialogUnzipping && (!usersActiveTableToggle || (!editType))}
                /> : <InputText value={names} disabled/>}
            </div>
            <div className="field col-12 md:col-4"></div>
            <div className="field col-12 md:col-4"></div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !userData?.user?.branchId})}>Бранч *</label>
                <Dropdown
                    id="role"
                    value={userData?.user?.branchId || ''}
                    options={branches}
                    filter
                    className={classNames('w-full', {'p-invalid': errorFields?.branchId && !userData?.user?.branchId})}
                    optionValue="id"
                    optionLabel="name"
                    placeholder='Виберіть бранч'
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'branchId', value: e.target.value}));
                    }}
                    disabled={!hasEditPermission(userData?.user?.roles) || !usersDialogUnzipping && (!usersActiveTableToggle || (!editType))}
                />
            </div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name && !userData?.user?.fullName})}>Керiвник</label>
                <InputText
                    id="name"
                    value={foundBranch && foundBranch?.ownerName || ''}
                    maxLength={90}
                    disabled
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name && !userData?.user?.fullName})}>Коментар</label>
                <InputTextarea
                    id="description"
                    value={userData?.user?.description || ''}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'description', value: e.target.value}))
                    }}
                    autoResize
                    rows={3}
                    maxLength={500}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || !editType)}
                />
            </div>
            {currentUser && <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={'Ви будете разлогінени.\n' +
                'Інформація для зміни пароля буде відправлена на вказану пошту.'}
                                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити"
                                           accept={() => handleSubmit()}/>}
            {!currentUser && <Dialog className="recoveryPassDialog" visible={visible} header="Змінити пароль" onHide={() => setVisible(false)}>
                <div className="flex flex-column max-w-18rem p-0">
                    <p className="text-sm mb-1">Користувача буде вилогінено з системи.<br></br>
                        Посилання для зміни пароля користувач отримає на свій Email.</p>
                    <form className="field col-12 md:col-12 flex flex-column px-0 max-w-18rem" autoComplete="off">
                        <div className="field col-12 md:col-12 flex justify-content-end mb-0 px-0">
                            <Button loading={changePasswordLoading} icon="pi pi-ban" label="Відміна" onClick={() => setVisible(false)} className="p-button-outlined mr-2"/>
                            <Button loading={changePasswordLoading} icon="pi pi-save" label="Відправити" className="ml-2" onClick={handleSubmit}/>
                        </div>
                    </form>
                </div>
            </Dialog>}
        </div>
    );
};

export default UsersListDialogGeneralSettings;
