import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ADD_AUDIO_USER,
    ARCHIVE_AUDIO_TABLE_TOGGLE,
    DELETE_AUDIO_BRANCH,
    GET_AUDIO,
    GET_AUDIO_PAGINATION,
    GET_AUDIOS, INITIAL_AUDIO_DATA,
    SELECTED_AUDIO_ID,
    SET_AUDIO_CURRENT_PAGE,
    SET_AUDIO_CURRENT_SIZE,
    SET_AUDIO_ERROR_FIELDS,
    SET_AUDIO_IDS,
    SHOW_AUDIO_DETAIL_LOADING,
    SHOW_AUDIO_LOADER,
    AUDIO_CLEAR_FILTERS,
    AUDIO_DIALOG_VISIBLE,
    AUDIO_FIELD, SHOW_AUDIO_CREATE_LOADER
} from "../types/typesAudioList";

const initialState = {
    archiveAudioTableToggle: true,
    audioDialogVisible: false,
    selectedAudioId: null,
    clearFilters: {
        audioId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        audioName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        isActive: {value: null, matchMode: FilterMatchMode.IN},
        isHold: {value: null, matchMode: FilterMatchMode.IN},
        isRingback: {value: null, matchMode: FilterMatchMode.IN}
    },
    audiosList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    audioData: {
        description: null,
        audioName: null,
        isActive: false,
        isHold: false,
        isRingback: false
    },
    AudioIds: null,
    loading: false,
    detailsLoading: false,
    errorFields: null,
    createLoader: false
}

export const AudioListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_AUDIO_TABLE_TOGGLE:
            return {...state, archiveAudioTableToggle: action.payload}
        case AUDIO_DIALOG_VISIBLE:
            return {...state, audioDialogVisible: action.payload}
        case SELECTED_AUDIO_ID:
            return {...state, selectedAudioId: action.payload}
        case ADD_AUDIO_USER:
            let usersId = action.payload.userId.map((item: any) => {
                return item
            })
            return {
                ...state, audioData:
                    {
                        ...state.audioData,
                        audioUsers: usersId.map((item: { userId: any; userLevel: any; userName: any; }) => {
                            return {userId: item.userId, userLevel: item.userLevel, userName: item.userName}
                        })
                    }
            }
        case DELETE_AUDIO_BRANCH:
            return {
                ...state, AUDIOData:
                    {
                        ...state.audioData,
                        // @ts-ignore
                        audioBranches: state.audioData.audioBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        case AUDIO_FIELD:
            return {
                ...state,
                audioData: {
                    ...state.audioData,
                    [action.payload.fieldName]: action.payload.value,
                }
            }
        case AUDIO_CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    audioId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    audioName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    isActive: {value: null, matchMode: FilterMatchMode.IN},
                    isHold: {value: null, matchMode: FilterMatchMode.IN},
                    isRingback: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case GET_AUDIOS:
            return {...state, audiosList: action.payload}
        case GET_AUDIO_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_AUDIO_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_AUDIO_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_AUDIO:
            return {...state, audioData: action.payload}
        case SHOW_AUDIO_LOADER:
            return {...state, loading: action.payload}
        case SHOW_AUDIO_CREATE_LOADER:
            return {...state, createLoader: action.payload}
        case SHOW_AUDIO_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case INITIAL_AUDIO_DATA:
            return {...state, audioData: initialState.audioData}
        case SET_AUDIO_IDS:
            return {...state, audioIds: action.payload};
        case SET_AUDIO_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        default:
            return state
    }
}
