import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {OrganizationChart} from "primereact/organizationchart";
import {Divider} from "primereact/divider";
import {TabPanel, TabView} from "primereact/tabview";
import BranchDialogGeneralSettings from "../BranchDialogGeneralSettings";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {validateBranchForm} from "../../../redux/validator/validator";
import {setBranchDialogVisible, setBranchErrorFields} from "../../../redux/actions/actionsBranchList";
import {Button} from "primereact/button";
import BranchListOfDependenciesRoles from "../BranchListOfDependenciesRoles";
import BranchListOfDependenciesStatuses from "../BranchListOfDependenciesStatuses";
import BranchListOfDependenciesQueues from "../BranchListOfDependenciesQueues";
import BranchListOfDependenciesSkillGroups from "../BranchListOfDependenciesSkillGroups";
import BranchACDSettings from "../BranchACDSettings";
import {ConfirmDialog} from "primereact/confirmdialog";
import {createBranch, deleteBranch, updateBranch} from "../../../redux/api/apiBranchList";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {setEditType} from "../../../redux/actions/actions";
import {setQueueDialogVisible} from "../../../redux/actions/actionsQueue";
import {setQualificationGroupDialogVisible} from "../../../redux/actions/actionsQualificationGroup";
import {setRoleDialogVisible} from "../../../redux/actions/actionsRoles";
import {setStatusDialogVisible} from "../../../redux/actions/actionsStatuses";

const BranchDialog = () => {
    const dispatch = useAppDispatch();
    const branchesList = useAppSelector(state => state.BranchesListReducer.branchesList);
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const dialogVisible = useAppSelector(state => state.BranchesListReducer.dialogVisible);
    const archiveTableToggle = useAppSelector(state => state.BranchesListReducer.archiveTableToggle);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [branchId] = useState(null);
    const [branchVisible, setBranchVisible] = useState(false);
    const [editBranchVisible, setEditBranchVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollRef = useRef<any>(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const branchDeleteResponseMessage = useAppSelector(state => state.BranchesListReducer.branchDeleteResponseMessage);
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const archive_branch_question = getItem(codes.archive_branch_question);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const deleteBranchMessage = <div>{notifications && notifications?.archiveBranchMessage ? notifications?.archiveBranchMessage.text : archive_branch_question ? archive_branch_question : progressBar}</div>

    const {
        update: hasBranchUpdatePermission
    } = branchPermissions;

    useEffect(() => {
        dispatch(setBranchErrorFields(null));
        setActiveIndex(0);
    }, [editBranchVisible]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setActiveIndex(0);
        if (dialogVisible) {
            setEditBranchVisible(true);
            dispatch(setQueueDialogVisible(false));
            dispatch(setQualificationGroupDialogVisible(false));
            dispatch(setRoleDialogVisible(false));
            dispatch(setStatusDialogVisible(false));
            setActiveIndex(0);
            if (hasBranchUpdatePermission) {
                dispatch(setEditType(true));
            } else {
                dispatch(setEditType(false));
            }
            // dispatch(setBranchVisible(false));
        } else {
            setEditBranchVisible(false);
        }
    }, [dialogVisible]);

    const footer = () => {
        const updatedUsers = branchData?.users?.map((user: any) => {
            if (!('isLider' in user)) {
                return {
                    ...user,
                    isLider: false,
                };
            }
            return user;
        });

        let newData = {
            parentId: branchData?.parentId,
            ownerId: branchData?.ownerId,
            users: updatedUsers ? updatedUsers : [],
            description: branchData?.description,
            name: branchData?.name,
            actionByUserId: branchData?.actionByUserId,
            isArchived: branchData?.isArchived
        };

        let updatedData = {
            id: branchData?.id,
            parentId: branchData?.parentId,
            ownerId: branchData?.ownerId,
            users: updatedUsers ? updatedUsers : [],
            description: branchData?.description,
            name: branchData?.name,
            actionByUserId: branchData?.actionByUserId,
            isArchived: branchData?.isArchived
        };

        const createBranchData = (data: any) => {
            const validationErrors = validateBranchForm(branchData);
            dispatch(setBranchErrorFields(validationErrors));
            if (!validationErrors) {
                dispatch(createBranch(data, jwtToken?.jwtToken, !archiveTableToggle));
            } else {
                setActiveIndex(0);
            }
        };

        const updateBranchData = (data: any) => {
            const validationErrors = validateBranchForm(branchData);
            dispatch(setBranchErrorFields(validationErrors));
            if (!validationErrors) {
                dispatch(updateBranch(data, jwtToken?.jwtToken, !archiveTableToggle));
            } else {
                setActiveIndex(0);
            }
        };

        return <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                <Button icon="pi pi-ban"
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => dispatch(setBranchDialogVisible(false))}
                />
                {!branchData?.id ? <Button icon="pi pi-save"
                                           className=""
                                           label="Створити та зберегти"
                                           onClick={() => {
                                               createBranchData(newData);
                                           }}
                /> : ''}
                {branchData?.id ? <Button icon="pi pi-save"
                                          label="Зберегти"
                                          onClick={() => {
                                              updateBranchData(updatedData);
                                          }}
                /> : ''}
            </div>
        </div>
    };

    const createdUserTime = () => {
        const time = new Date(branchData?.branchData?.createdDateTime || '').toLocaleString("uk-UA")
        if (branchData?.branchData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(branchData?.branchData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (branchData?.branchData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const branchTemplate = (node: any) => {
        return (
            <div className="flex flex-column">
                {node?.ref && <div ref={scrollRef}></div>}
                <div className="flex flex-column align-items-center">
                    <span className="font-bold mb-2">{node.label}</span>
                    <span className="text-xs">{node.data.ownerName}</span>
                </div>
            </div>
        );
    };


    function reformatKeys(dataArray: any[], parentKey = "") {
        let index = 0;
        return dataArray?.map((item) => {
            index++;
            const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
            const newItem = {
                ...item,
                key: key,
                data: {
                    ...item.data,
                    keyName: key + '\u00A0\u00A0\u00A0\u00A0' + `${item?.data?.name}`
                }
            };

            if (item.children !== null && item.children && item.children.length > 0) {
                newItem.children = reformatKeys(item.children, key);
            }
            return newItem;
        });
    }

    function reformatKeysWithExpanded(dataArray: any[], parentKey = "") {
        let index = 0;
        return dataArray?.map((item) => {
            index++;
            const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
            const newItem = {
                ...item,
                key: key,
                expanded: true,
                className: branchData?.id === item.data.id ? "active-branch text-white" : "bg-bluegray-400 text-white",
                label: item.data.name,
                ref: branchData?.id === item.data.id
            };

            if (item.children !== null && item.children && item.children.length > 0) {
                newItem.children = reformatKeysWithExpanded(item.children, key);
            }
            return newItem;
        });
    }

    let newBranch = reformatKeysWithExpanded(branchesList);

    const deleteBranchById = () => {
        dispatch(deleteBranch(branchId, jwtToken?.jwtToken, !archiveTableToggle));
        if (branchDeleteResponseMessage && branchDeleteResponseMessage?.statusId === 200) {
            setEditBranchVisible(false);
        }
    }

    return <>
    <Dialog header="Архітектура гілок" style={{height: '100vh', width: '98vw'}} visible={branchVisible} className={classNames('', {'branch-info': isMobile()})} onHide={() => setBranchVisible(false)}>
        <OrganizationChart className="branch-organisation-chat" value={newBranch} nodeTemplate={branchTemplate}/>
    </Dialog>
    <Dialog
        header={branchData?.id ? "Редагувати гілку" : "Створити гілку"}
        footer={footer}
        dismissableMask={false}
        closeOnEscape={false}
        draggable={true}
        blockScroll={true}
        position="top"
        onHide={() => {
            setEditBranchVisible(false)
            dispatch(dispatch(setBranchDialogVisible(false)));
        }}
        style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
        visible={editBranchVisible} className='monitoring-info'>
        <Divider style={{margin: '0 0 20px 0'}}/>
        {!!branchData.id && <div className="grid align-items-center user-dialog-info">
            <div className="field col-12 md:col-6 mb-0">
                {!!branchData.id && <div className="item pl-2">
                    ID: {branchData?.id ? branchData.id : ''}
                </div>}
            </div>
            <div className="field col-12 md:col-6 mb-0">
                {!!branchData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                    {branchData?.branchData?.userCreate && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{branchData?.branchData?.userCreate ? branchData?.branchData?.userCreate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                    </div>}
                    {branchData?.branchData?.userUpdate && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{branchData?.branchData?.userUpdate ? branchData?.branchData?.userUpdate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                    </div>}
                </div>}
            </div>
        </div>}
        <TabView className="user-dialog-info branch-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Загальна iнформацiя">
                <BranchDialogGeneralSettings/>
            </TabPanel>
            {branchData?.id && <TabPanel header="Ролі користувачів">
                <BranchListOfDependenciesRoles/>
            </TabPanel>}
            {branchData?.id &&<TabPanel header="Статуси користувачів">
                <BranchListOfDependenciesStatuses/>
            </TabPanel>}
            {branchData?.id &&<TabPanel header="Вхідні черги">
                <BranchListOfDependenciesQueues/>
            </TabPanel>}
            {/*<TabPanel header="Вихідні кампанії">*/}
            {/*    /!*<BranchDialogGeneralSettings/>*!/*/}
            {/*</TabPanel>*/}
            {branchData?.id &&<TabPanel header="Групи кваліфікацій">
                <BranchListOfDependenciesSkillGroups/>
            </TabPanel>}
            <TabPanel header="Інтеграція">
                <BranchACDSettings/>
            </TabPanel>
        </TabView>
    </Dialog>
    <ConfirmDialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} message={deleteBranchMessage}
                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити"
                   accept={() => deleteBranchById()}
    />
    </>
}

export default BranchDialog;
