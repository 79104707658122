export const ARCHIVE_TRUNK_TABLE_TOGGLE = 'ARCHIVE_TRUNK_TABLE_TOGGLE'
export const TRUNK_DIALOG_VISIBLE = 'COMPANIES_LIST/DIALOG_VISIBLE'
export const TRUNK_CLEAR_FILTERS = 'TRUNK_CLEAR_FILTERS'
export const SELECTED_TRUNK_ID = 'SELECTED_TRUNK_ID'
export const TRUNK_FIELD = 'TRUNK_FIELD'
export const TRUNK_ACD_FIELD = 'TRUNK_ACD_FIELD'
export const SHOW_TRUNK_LOADER = 'SHOW_TRUNK_LOADER'
export const HIDE_TRUNK_LOADER = 'HIDE_TRUNK_LOADER'
export const INITIAL_TRUNK_DATA = 'INITIAL_TRUNK_DATA'
export const GET_TRUNKS = 'GET_TRUNKS'
export const GET_TRUNK = 'GET_TRUNK'
export const SET_TRUNK_ERROR_FIELDS = 'SET_TRUNK_ERROR_FIELDS';
export const GET_TRUNK_PAGINATION = 'GET_TRUNK_PAGINATION'
export const SET_TRUNK_CURRENT_PAGE = 'SET_TRUNK_CURRENT_PAGE'
export const SET_TRUNK_CURRENT_SIZE = 'SET_TRUNK_CURRENT_SIZE'
export const SET_TRUNK_LOADING = 'SET_TRUNK_LOADING'
export const SHOW_TRUNK_DETAIL_LOADING = 'SHOW_TRUNK_DETAIL_LOADING'
export const SET_TRUNK_TRANK_ID = 'SET_TRUNK_TRANK_ID'
export const ADD_TRUNK_USER = 'ADD_TRUNK_USER'
export const DELETE_TRUNK_USER = 'DELETE_TRUNK_USER'
export const UPDATE_SCHEDULER_TIME = 'UPDATE_SCHEDULER_TIME'
export const TOGGLE_DAY_ACTIVE = 'TOGGLE_DAY_ACTIVE'
export const CHANGE_TRUNK_USER_LEVEL = 'CHANGE_TRUNK_USER_LEVEL'
export const GET_TRUNK_DIRECTORIES_TRUNK_TYPE = 'GET_TRUNK_DIRECTORIES_TRUNK_TYPE'
export const GET_TRUNK_DIRECTORIES_TRUNK_OPERATORS_TYPE = 'GET_TRUNK_DIRECTORIES_TRUNK_OPERATORS_TYPE'
export const GET_TRUNK_DIRECTORIES_TRUNK_AUDIO_TYPE = 'GET_TRUNK_DIRECTORIES_TRUNK_AUDIO_TYPE'
export const GET_TRUNK_DIRECTORIES_TRUNK_DIRECTIONS_TYPE = 'GET_TRUNK_DIRECTORIES_TRUNK_DIRECTIONS_TYPE'
export const SET_TRANK_IDS = 'SET_TRANK_IDS'
export const ADD_OUTBOUND_PHONE = 'ADD_OUTBOUND_PHONE';
export const REMOVE_OUTBOUND_PHONE = 'REMOVE_OUTBOUND_PHONE';
export const UPDATE_OUTBOUND_PHONE = 'UPDATE_OUTBOUND_PHONE';
export const ADD_INBOUND_PHONE = 'ADD_INBOUND_PHONE';
export const REMOVE_INBOUND_PHONE = 'REMOVE_INBOUND_PHONE';
export const UPDATE_INBOUND_PHONE = 'UPDATE_INBOUND_PHONE';
export const CHANGE_UTILIZATION_INDEX = 'CHANGE_UTILIZATION_INDEX';
export const DELETE_UTILIZATION_SKILL_GROUP = 'DELETE_UTILIZATION_SKILL_GROUP';
export const SET_TRUNK_IDS = 'SET_TRUNK_IDS'
export const DELETE_TRUNK_BRANCH = 'DELETE_TRUNK_BRANCH'
