import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";
import {Dialog} from "primereact/dialog";
import UsersListNav from "./UsersListNav";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import UserListDialog from "./UserDialod";
import {deleteUser, getAllRolesList, getPhoneDictionary, getUserDetails, getUsersDirectoriesGetTrunksPhones, getUsersListWithPaginationFilterAndSorting, getUsersManagerBranchesList, getUserStatusesForDetails, updateUserStatus} from "../../../redux/api/apiUsersList";
import {initialUserData, setCurrentPage, setCurrentPageSize, setUserDialogUnzipping, setUserListDataSort, setUsersActiveTableToggle, setUsersDialogVisible, setUserTrunkIds} from "../../../redux/actions/actionsUsersList";
import {Toast} from "primereact/toast";
import {getNotification} from "../../../redux/api/api";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ConfirmDialog} from "primereact/confirmdialog";
import {ProgressBar} from "primereact/progressbar";
import {Paginator} from "primereact/paginator";
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {OrganizationChart} from "primereact/organizationchart";
import {setEditType} from "../../../redux/actions/actions";
import UserDialogCampaign from "./UserDialogCampaign";
import {Divider} from "primereact/divider";
import {getTrunksDictionary} from "../../../redux/api/apiRoles";
import {changeRecallNumber, setRecallNumber} from "../../../redux/actions/actionsWebRTC";

interface DataRow {
    trunkPhonesNo: any;
    userId: number;
    id: number;
    fullName: string;
    role: string;
    branchName: string;
    direction: string;
    login: string;
    phoneNo: string;
    ownerName: string;
    status: string;
    userName: string;
    lastName: string;
    firstName: string;
    middleName: string;
    roles: any;
    email: string;
    sipName: string;
    statusColor: string;
    isEdit: boolean;
}

interface DataSort {
    isActive: boolean;
    pageNumber: number;
    pageSize: number;
    sort: {
        orderBy: string;
        isDesc: boolean;
    };
    filter?: { key: string; value: string }[];
}

const UsersList = (props: any) => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const usersList = useAppSelector(state => state.UsersListReducer.usersList);
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    // @ts-ignore
    const getNotificationError = useAppSelector(state => state.UsersListReducer.getNotificationError);
    // @ts-ignore
    const detailsLoading = useAppSelector(state => state.UsersListReducer.detailLoading);
    // @ts-ignore
    const loading = useAppSelector(state => state.UsersListReducer.loading);
    // @ts-ignore
    const userSaveMessage = useAppSelector(state => state.UsersListReducer.userSaveMessage);
    // @ts-ignore
    const userDeleteMessage = useAppSelector(state => state.UsersListReducer.userDeleteMessage);
    // @ts-ignore
    const errorMessage = useAppSelector(state => state.UsersListReducer.errorMessage);
    // @ts-ignore
    const userDeleteSuccess = useAppSelector(state => state.UsersListReducer.userDeleteSuccess);
    // @ts-ignore
    const userCreateMessage = useAppSelector(state => state.UsersListReducer.userCreateMessage);
    // @ts-ignore
    const userDeleteQuestionsText = useAppSelector(state => state.CompaniesListReducer.userDeleteQuestionsText);
    // @ts-ignore
    const pagination = useAppSelector(state => state.UsersListReducer.pagination);
    // @ts-ignore
    const paginationPage = useAppSelector(state => state.UsersListReducer.paginationPage);
    // @ts-ignore
    const paginationSize = useAppSelector(state => state.UsersListReducer.paginationSize);
    // @ts-ignore
    const allRoles = useAppSelector(state => state.UsersListReducer.allRoles);
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const phonesDictionary = useAppSelector(state => state.UsersListReducer.phonesDictionary);
    // @ts-ignore
    const trunksDictionary = useAppSelector(state => state.UsersListReducer.userTrunksDictionary);
    // @ts-ignore
    const companiesDirectoryTranksPhones = useAppSelector(state => state.UsersListReducer.usersDirectoryTrunksPhone);
    const [selectedPairs, setSelectedPairs] = useState<any>([{selectedGateway: null, selectedPhone: null, trunkOrder: 1, trunkType: 501}]);
    // @ts-ignore
    const statuses = useAppSelector(state => {
        // @ts-ignore
        const currentStatuses = state.UsersListReducer.statusesById;
        const additionalStatus = {
            id: 1,
            externalName: "LOGOUT",
            statusName: "Logout",
            displayOrder: 1,
            statusColor: "rgb(177, 177, 177)",
            statusImagePath: null,
            description: "",
            isAuto: false
        };
        return [...currentStatuses, additionalStatus];
    });
    // @ts-ignore
    const statusesList = useAppSelector(state => state.UsersListReducer.statuses)
    // @ts-ignore
    const clearFilters = useAppSelector(state => state.UsersListReducer.clearFilters)
    // @ts-ignore
    const userDeleteMessageText = useAppSelector(state => state.CompaniesListReducer.userDeleteMessageText);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const [selectedRowData, setSelectedRowData] = useState<DataRow | any | null>(null);
    const [data, setData] = useState<DataRow[]>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const dataTableRef = useRef<DataTable | null>(null);
    const [branchClick, setBranchClick] = useState<any>(false);
    const [visibleDeletedMessage, setVisibleDeletedMessage] = useState<any>(false);
    const [visibleUnzipMessage, setVisibleUnzipMessage] = useState<any>(false);
    const [deletedUserInfo, setDeletedUserInfo] = useState<any>(false);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterUserId, setFilterUserId] = useState<any>(null);
    const [filterUsername, setFilterUsername] = useState<any>(null);
    const [filterUserStatusId, setFilterStatusId] = useState<any>(null);
    const [filterUserRole, setFilterUserRole] = useState<any>(null);
    const [filterUserBranch, setFilterUserBranch] = useState<any>(null);
    const [filterUserOwner, setFilterUserOwner] = useState<any>(null);
    const [filterTrunkNumber, setFilterTrunkNumber] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [first, setFirst] = useState<number>(0);
    const [dataSort, setDataSort] = useState<any>(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dropdownRef = useRef<any>(null);
    const [branchVisible, setBranchVisible] = useState(false);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const userBranchesList = useAppSelector(state => state.BranchesListReducer.userBranchesList);
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const userInInfo = userData?.user?.userInInfo;
    const recallNumberChanged = useAppSelector(state => state.WebRTCReducer.recallNumberChanged);
    const [sortData, setSortData] = useState<any>({
        isActive: usersActiveTableToggle,
        pageNumber: paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        }
    });
    const {
        update: hasUsersManagerUpdatePermission,
        delete: hasUsersManagerDeletePermission,
        visible: hasUsersManagerVisionPermission
    } = usersManagerPermissions;

    let user_deleted_messages = getItem(codes.user_deleted_messages);
    let user_delete_questions = getItem(codes.user_delete_questions);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;

    const userDeletedQuestion = <div>{userDeleteQuestionsText ? userDeleteQuestionsText.text : user_delete_questions ? user_delete_questions : progressBar}</div>
    const userDeletedMessage = <div>{userDeleteMessageText ? userDeleteMessageText.text : user_deleted_messages ? user_deleted_messages : progressBar}</div>

    let newData = usersList?.map((item: { lastName: any; firstName: any; middleName: any; }) => ({
        ...item,
    }));

    const handleBadgeClick = () => {
        if (dropdownRef.current) {
            dropdownRef.current.show();
        }
    };

    useEffect(() => {
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
        resetSorting();
        dispatch(setUsersActiveTableToggle(true));
    }, []);

    useEffect(() => {
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    }, [usersActiveTableToggle]);

    useEffect(() => {
        dispatch(getUsersManagerBranchesList(jwtToken?.jwtToken));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0; // Сброс прокрутки
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        setSelectedRowData(null);
        let filter: { key: string; value: string }[] = [];

        const dataSort: DataSort = {
            isActive: usersActiveTableToggle,
            pageNumber: paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            }
        }
        if (filterUserId?.value) {
            filter.push(filterUserId);
        }
        if (filterUsername?.value) {
            filter.push(filterUsername);
        }
        if (filterTrunkNumber?.value) {
            filter.push(filterTrunkNumber);
        }
        if (filterUserStatusId) {
            filterUserStatusId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterUserRole) {
            filterUserRole.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterUserBranch?.value) {
            filter.push(filterUserBranch);
        }
        if (filterUserOwner?.value) {
            filter.push(filterUserOwner);
        }
        if (filter.length) {
            dataSort['filter'] = filter;
        }
        resetTableHorizontalScroll();
        dispatch(getUsersListWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        dispatch(setUserListDataSort(dataSort));
        setSortData(dataSort);
        setDataSort(dataSort);
    }, [paginationPage, paginationSize, usersActiveTableToggle, orderBy, isDescFilter, filterUserId, filterUsername, filterUserBranch, filterUserOwner]);

    useEffect(() => {
        if (activeClick?.item === "/usersmanager" && activeClick?.from ==='#/usersmanager') {
            dispatch(getUsersListWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCurrentPage(event.page + 1));
        dispatch(setCurrentPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'id':
                    newOrderBy = 'Id';
                    break;
                case 'fullName':
                    newOrderBy = 'FullName';
                    break;
                case 'status':
                    newOrderBy = 'StatusId';
                    break;
                case 'role':
                    newOrderBy = 'Roles';
                    break;
                case 'branchName':
                    newOrderBy = 'BranchName';
                    break;
                case 'ownerName':
                    newOrderBy = 'OwnerName';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const customFilter = (event?: any) => {
        if (event) {
            const {id, fullName, role, branchName, ownerName, status, trunkNumbers} = event.filters;
            setFilterUserId(id ? {key: 'Id', value: id.constraints[0].value} : null);
            setFilterTrunkNumber(id ? {key: 'TrunkNumbers', value: trunkNumbers.constraints[0].value} : null);
            setFilterUsername(fullName ? {key: 'FullName', value: fullName.constraints[0].value} : null);
            setFilterUserBranch(branchName ? {key: 'BranchName', value: branchName.constraints[0].value} : null);
            setFilterUserOwner(ownerName ? {key: 'OwnerName', value: ownerName.constraints[0].value} : null);
            if (role.value) {
                setFilterUserRole(role.value.map((item: any) => ({key: 'Roles', value: String(item)})));
            } else {
                setFilterUserRole(null);
            }
            if (status.value) {
                setFilterStatusId(status.value.map((item: any) => ({key: 'StatusId', value: String(item)})));
            } else {
                setFilterStatusId(null);
            }
        } else {
            setFilterUserId(null);
            setFilterUsername(null);
            setFilterUserRole(null);
            setFilterUserBranch(null);
            setFilterUserOwner(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterStatusId(null);
            setFilterTrunkNumber(null);
        }
    };

    useEffect(() => {
        dispatch(getAllRolesList(jwtToken?.jwtToken));
        dispatch(getUsersDirectoriesGetTrunksPhones(jwtToken?.jwtToken));
        dispatch(getTrunksDictionary(jwtToken?.jwtToken));
    }, [])

    useEffect(() => {
        if (user && user.userId) {
            if (!user_delete_questions && !getNotificationError) {
                dispatch(getNotification(user.userId, 130, user));
            }
            if (!user_deleted_messages && !getNotificationError) {
                dispatch(getNotification(user.userId, 126, user));
            }
        }
    }, [getNotificationError]);

    useEffect(() => {
        if (userSaveMessage) {
            toast?.current?.show({severity: 'success', summary: 'Користувач успішно збережений.', detail: '', life: 3000});
        }

    }, [userSaveMessage]);

    useEffect(() => {
        if (userCreateMessage) {
            toast?.current?.show({severity: 'success', summary: 'Користувач успішно створений.', detail: '', life: 3000});
        }

    }, [userCreateMessage]);

    useEffect(() => {
        if (userDeleteMessage) {
            toast?.current?.show({severity: 'success', summary: 'Користувача успішно видалено.', detail: '', life: 3000});
        }

    }, [userDeleteMessage]);

    useEffect(() => {
        if (userDeleteSuccess) {
            toast?.current?.show({severity: 'success', summary: userDeletedMessage, detail: '', life: 3000});
        }

    }, [userDeleteSuccess]);


    useEffect(() => {
        if (errorMessage) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }

    }, [errorMessage]);

    useEffect(() => {
        setData(newData);
    }, [usersList]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const [status] = useState<any>({
        "id": 7,
        "externalName": "OUTBOUNDCALLS",
        "statusName": "Вихідні дзвінки",
        "displayOrder": 1,
        "statusColor": null,
        "statusImagePath": null,
        "description": "Вихідні дзвінки в ручному режимі\r\n"
    });

    useEffect(() => {
        if (userData?.user?.userTrunks?.length) {
            setSelectedPairs([]);
        }
    }, [userData?.user?.userTrunks, userData?.user?.id]);

    useEffect(() => {
        if (userData?.user?.userTrunks) {
            userData?.user?.userTrunks?.map((queueTrank: any) => {
                let foundObject = companiesDirectoryTranksPhones?.find((item: { id: any; }) => item.id === queueTrank?.trunkId);
                if (queueTrank?.trunkId === foundObject?.id) {
                    addPair(foundObject?.gatewayName, foundObject?.id, queueTrank?.trunkType, queueTrank?.trunkOrder, true);
                }
            });
        }
    }, [companiesDirectoryTranksPhones, userData?.user?.userTrunks]);

    useEffect(() => {
        // @ts-ignore
        const updatedTrunks = selectedPairs.map(({selectedGateway, ...rest}) => rest);
        const updatedArray = updatedTrunks.map((item: { selectedPhone: any; trunkType: any; trunkOrder: any; }) => {
            return {
                ...item,
                trunkId: item.selectedPhone,
                trunkType: item.trunkType,
                trunkOrder: item.trunkOrder
            };
        });
        // @ts-ignore
        const cleanedArray = updatedArray.map(({selectedPhone, ...rest}) => rest);
        dispatch(setUserTrunkIds(cleanedArray));
    }, [selectedPairs]);

    const addPair = (selectedGateway: null, selectedPhone: null, trunkType: number, order?: null, saved = false) => {
        setSelectedPairs((prevPairs: any[]) => {
            if (selectedGateway != null && selectedPhone != null) {
                const pairExists = prevPairs.some(pair => pair.selectedGateway === selectedGateway && pair.selectedPhone === selectedPhone);
                if (pairExists) {
                    return prevPairs;
                }
            }

            const newPair = { selectedGateway, selectedPhone, trunkOrder: prevPairs.length + 1, trunkType, saved };
            const newPairs = [...prevPairs, newPair];

            return newPairs.map((pair, index) => ({
                ...pair,
                trunkOrder: index + 1 // Установить trunkOrder как index + 1
            }));
        });
    };

    let windowForRender: any = windowWidth;

    useEffect(() => {
        if (!!props.profileMenuPin && windowWidth < 1550) {
            windowForRender = 1247;
        }
    }, [windowWidth]);

    useEffect(() => {
        if (!!props.profileMenuPin && windowWidth < 1550) {
            windowForRender = 1247;
        }
    }, [props.profileMenuPin]);

    const renderDetailPanel = (rowData: DataRow) => {
        const statusClass = classNames({
            'p-badge status-unsuccessful': rowData.status === 'Unsuccess',
            'p-badge status-success': !rowData.status
        });

        if (!!props.profileMenuPin && windowWidth < 1550) {
            windowForRender = 1247;
        }

        const checkBanUser = () => {
            let data = {
                nameTo: rowData?.userName,
            }
            dispatch(setRecallNumber(data));
            dispatch(changeRecallNumber(!recallNumberChanged));
            if (windowWidth < 1550) {
                setVisible(false);
            }
        }

        const rolesList = () => {
            if (rowData?.roles && Array.isArray(rowData.roles)) {
                return (
                    <ul className="m-0 p-0 roles-list-details list-none">
                        {rowData?.roles.map((item: any, index: number) => (
                            <li className="pb-3" key={index}>{item.name || '-'}</li>
                        ))}
                    </ul>
                );
            }
            if (rowData?.roles && !Array.isArray(rowData.roles)) {
                return (
                    <ul className="m-0 p-0 roles-list-details list-none">
                        <li className="pb-3">{rowData?.roles?.name || '-'}</li>
                    </ul>
                );
            } else {
                return <p className="pb-2">-</p>
            }
        }

        const handleStatusChange = (event: any) => {
            let data = {
                userId: userData?.user?.userId,
                statusesId: event.value
            }
            dispatch(updateUserStatus(data, jwtToken?.jwtToken, dataSort));
        };

        const filteredStatusList = statuses?.filter((status: { isAutoStatus: boolean; }) => !status.isAutoStatus);

        function reformatKeysWithExpanded(dataArray: any[], parentKey = "") {
            let index = 0;
            return dataArray?.map((item) => {
                index++;
                const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
                const newItem = {
                    ...item,
                    key: key,
                    expanded: true,
                    className: userData?.user?.branchId === item.data.id ? "active-branch text-white" : "bg-bluegray-400 text-white",
                    label: item.data.name
                };

                if (item.children && item.children.length > 0) {
                    newItem.children = reformatKeysWithExpanded(item.children, key);
                }
                return newItem;
            });
        }

        let newBranch = reformatKeysWithExpanded(userBranchesList);

        const nodeTemplate = (node: any) => {
            return (
                <div className="flex flex-column">
                    <div className="flex flex-column align-items-center">
                        <span className="font-bold mb-2">{node.label}</span>
                        <span className="text-xs">{node.data.ownerName}</span>
                    </div>
                </div>
            );
        };
        const replaceStatusName = rowData?.status?.replace(/Copy.*/, 'Copy...');

        const trunkType = (rowData: any) => {
            const foundItem = trunksDictionary?.find((item: { dictionaryId: number; }) => item.dictionaryId === rowData?.trunkType);
            return <p>{foundItem?.name || ''}</p>
        }

        const trunkName = (rowData: any) => {
            return <p>{rowData?.selectedGateway || ''}</p>
        }

        const trunkNumber = (rowData: any) => {
            const foundItem = companiesDirectoryTranksPhones?.find((item: { id: number; }) => item.id === rowData?.selectedPhone);
            return <p>{foundItem?.phoneNo || ''}</p>
        }

        const renderType = () => {
            return <div className="field col-12 md:col-3 m-0 mb-2 mt-1 p-0">{phonesDictionary?.length > 0 && phonesDictionary.map((phone: any, index: number) => {
                if (userData?.user?.callTypeReceive === phone?.dictionaryId) {
                    return <label key={index} htmlFor="ingredient2" className="mb-0 pl-0">{phone.name}: {userData?.user?.callTypeReceive === 403 && <div style={{paddingBottom: '2px'}}>{userData?.user?.mobilePhone}</div>}</label>
                }
            })}</div>
        }

        return (
            <div className="relative">
                {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': detailsLoading})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowForRender > 1248 && <div className="grid field col-12 md:col-12 mb-0 pt-2 px-0">
                        <div className="field col-12 md:col-6 flex m-0 flex-wrap justify-content-between">
                            <div className="field col-12 md:col-7 flex m-0 flex-column justify-content-between px-0">
                                <div className="field col-12 md:col-12 flex m-0 px-0 mb-2">
                                    ID: {rowData?.id}
                                </div>
                                <div className="field col-12 md:col-12 flex align-items-center justify-content-between m-0 px-0">
                                    {`${rowData.lastName} ${rowData.firstName} ${rowData.middleName ? rowData.middleName : ''}`}
                                        <Button icon="pi pi-phone" label="Подзвонити" className="status-success call-button border-none max-w-9rem" style={{minWidth: '120px'}} onClick={() => {
                                            checkBanUser();
                                        }}></Button>
                                </div>
                            </div>
                            <div className="field col-12 md:col-4 flex m-0 px-0">
                                <div className="field col-12 md:col-3 p-0 m-0 ml-2">Роль:</div> <div className="field col-12 md:col-9 p-0 m-0">{rowData.roles && rowData.roles.length ? rolesList() : '- - -'}</div>
                            </div>
                        </div>
                        <div className="field col-12 md:col-2 flex m-0 flex-column justify-content-between">
                            <div className="field col-12 md:col-12 flex m-0 px-0">
                                <div className="field col-12 md:col-4 p-0 m-0 mr-3"><p className="m-0 mb-2">Branch:</p></div>  <div className="w-full">{rowData.branchName && hasUsersManagerVisionPermission ? <div className="w-full flex justify-content-between align-items-center text-primary cursor-pointer" onClick={() => setBranchVisible(true)} ><p className="m-0" >{rowData.branchName}</p></div> :
                                rowData.branchName && !hasUsersManagerVisionPermission ? <div className="w-full flex justify-content-between align-items-center"><p className="m-0" >{rowData.branchName}</p></div> : '- - -'}</div>
                            </div>
                            <div className="field col-12 md:col-12 flex m-0 px-0">
                                <div className="field col-12 md:col-4 px-0 pb-0 m-0 mr-3" style={{paddingTop: '2px'}}>Керiвник:</div> <div className="field col-12 md:col-8 p-0 m-0"><p>{rowData.ownerName ? rowData.ownerName : '- - -'}</p></div>
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 flex m-0 px-0">
                            <div className="field col-12 md:col-3 flex flex-column justify-content-between m-0  mt-1">{phonesDictionary?.length > 0 && phonesDictionary.map((phone: any, index: number) => {
                                if (userData?.user?.callTypeReceive === phone?.dictionaryId) {
                                    return <label key={index} htmlFor="ingredient2" className="mb-0">{phone.name}{userData?.user?.callTypeReceive === 403 && ' :'}</label>
                                }
                            })}
                                {userData?.user?.callTypeReceive === 403 && <div style={{paddingBottom: '2px'}}>{userData?.user?.mobilePhone}</div>}
                            </div>
                            <div className="field col-12 md:col-6 align-items-start flex m-0 px-0">
                                <div className="field col-12 md:col-12 align-items-center flex flex-column m-0 px-0">
                                {usersActiveTableToggle ?
                                    <>
                                        <div className="field col-12 md:col-12 flex justify-content-end m-0 mb-2 mt-1">
                                            <Badge onClick={handleBadgeClick} value={rowData.status ? replaceStatusName : 'OFFLINE'} className={`${statusClass} text-white customer-badge px-2 text-xs p-0 cursor-pointer`} style={{borderRadius: '6px 0 0 6px', backgroundColor: `${rowData.statusColor}`}}/>
                                            <div className="status-selector">
                                                <Dropdown
                                                    ref={dropdownRef}
                                                    value={status}
                                                    options={filteredStatusList}
                                                    onChange={handleStatusChange}
                                                    filter
                                                    showClear
                                                    panelClassName="statusName"
                                                    itemTemplate={customItemTemplate}
                                                    style={{borderRadius: '0 6px 6px 0', borderColor: `${rowData.statusColor}`}}
                                                    optionLabel="statusName"
                                                    optionValue="id"
                                                    className={classNames({'p-inputtext-filled h-full shadow-none': status})}
                                                    disabled={!hasUsersManagerUpdatePermission}
                                                />
                                            </div>
                                        </div>
                                    </> : ''}
                                </div>
                            </div>
                            <div className="field col-12 md:col-3 flex m-0 px-0">
                                <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                    dispatch(setUsersDialogVisible(true));
                                    if (hasUsersManagerUpdatePermission) {
                                        dispatch(setEditType(true));
                                    } else {
                                        dispatch(setEditType(false));
                                    }
                                }}><span className={classNames('', {
                                    'pi pi-pencil': usersActiveTableToggle && hasUsersManagerUpdatePermission,
                                    'pi pi-eye': !usersActiveTableToggle || !hasUsersManagerUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => {setSelectedRowData(null); setVisible(false); dispatch(initialUserData())}}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>}
                    <div className="field col-12 md:col-12 p-0 my-2" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                    {windowForRender > 1248 && <div className="field col-12 md:col-12 flex align-items-center m-0 p-0">
                        <div className="field col-12 md:col-4 flex align-items-center m-0 px-0">
                            Транк
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center m-0 px-0">
                            Email:
                        </div>
                        <div className="field col-12 md:col-6 flex align-items-center m-0 px-0">
                            Черги/Кампанії
                        </div>
                    </div>}
                    {windowForRender > 1248 && <div className="field col-12 md:col-12 flex align-items-center m-0 p-0">
                        <Divider className="my-2"/>
                    </div>}
                    {windowForRender > 1248 && <div className="field col-12 md:col-12 flex align-items-start m-0 p-0 scroll-detail-items">
                        <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                            {rowData.trunkPhonesNo ? <DataTable value={selectedPairs} className="w-full">
                                <Column body={trunkType} headerStyle={{display: 'none'}} style={{width: '15rem'}}/>
                                <Column body={trunkName} headerStyle={{display: 'none'}} style={{width: '15rem'}}/>
                                <Column body={trunkNumber} headerStyle={{display: 'none'}} style={{width: '15rem'}}/>
                            </DataTable> : '- - -'}
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-start m-0 px-0">
                            <div className="field col-12 md:col-10 p-0 m-0">{rowData.email ? <div className="field col-12 md:col-12 p-0 m-0 align-items-center"><a href={`mailto:${rowData.email}`}><p className="m-0" style={{wordWrap: "break-word", hyphens: "auto"}}>{rowData.email}</p></a>
                        </div> : '- - -'}</div>
                        </div>
                        <div className="field col-12 md:col-6 flex align-items-center m-0 p-0">
                            {!!userInInfo?.length ? <UserDialogCampaign scrollable={true}/> : '- - -'}
                        </div>
                    </div>}
                    {usersActiveTableToggle ?
                        <>
                            {windowForRender <= 1248 && <div className="field col-12 md:col-12 flex justify-content-end m-0 p-0">
                                <div className="field col-12 md:col-7 flex align-items-center justify-content-end m-0 px-0">
                                    <Badge onClick={handleBadgeClick} value={rowData.status ? replaceStatusName : 'OFFLINE'} className={`${statusClass} text-white customer-badge px-2 text-xs p-0 cursor-pointer`} style={{borderRadius: '6px 0 0 6px', backgroundColor: `${rowData.statusColor}`}}/>
                                    <div className="status-selector">
                                        <Dropdown
                                            ref={dropdownRef}
                                            value={status}
                                            options={filteredStatusList}
                                            onChange={handleStatusChange}
                                            filter
                                            showClear
                                            panelClassName="statusName"
                                            itemTemplate={customItemTemplate}
                                            style={{borderRadius: '0 6px 6px 0', borderColor: `${rowData.statusColor}`, minHeight: '20px'}}
                                            optionLabel="statusName"
                                            optionValue="id"
                                            className={classNames({'p-inputtext-filled h-full shadow-none': status})}
                                            disabled={!hasUsersManagerUpdatePermission}
                                        />
                                    </div>
                                </div>
                            </div>}
                            {windowForRender <= 1248 && <div className="flex col-12 md:col-12 mb-0 justify-content-between">
                                <div className="w-full flex align-items-center justify-content-between m-0 px-0">
                                    {`${rowData.lastName} ${rowData.firstName} ${rowData.middleName ? rowData.middleName : ''}`}
                                    <div className="field col-5 md:col-5 flex align-items-center justify-content-end m-0 px-0">
                                        <Button icon="pi pi-phone" className="status-success call-button border-none border-circle max-w-9rem" style={{width: '32px', height: '32px'}} onClick={() => {
                                            checkBanUser();
                                        }}></Button>
                                    </div>
                                </div>
                            </div>}
                        </> : ''}
                    {windowForRender <= 1248 && <>{[
                        {label: "ID", value: rowData.id},
                        {label: "Транк", value: rowData.trunkPhonesNo ? trunkBodyDetailsTemplate(rowData) : '- - -'},
                        {label: "Роль", value: rowData.roles && rowData.roles.length ? rolesList() : '- - -'},
                        {label: "Керiвник", value: rowData.ownerName ? rowData.ownerName : '- - -'},
                        {label: "Опрацювання дзвінків", value: renderType() || '- - -'},
                    ].map(({label, value}, index) => (
                        <React.Fragment key={index}>
                            <div className="field col-6 md:col-4 flex m-0">
                                <div>{label}</div>
                            </div>
                            <div className="field col-6 md:col-8 flex align-items-center m-0">
                                <div className="w-full">{value}</div>
                            </div>
                        </React.Fragment>
                    ))}</>}
                    {windowForRender <= 1248 && <div className="field col-12 md:col-4 flex m-0 align-items-center">
                        <div>Branch</div>
                    </div>}
                    {windowForRender <= 1248 && <div className="field col-12 md:col-8 flex align-items-center m-0">
                        <div className="w-full">{rowData.branchName ? <div className="w-full flex justify-content-between align-items-center"><p className="m-0">{rowData.branchName}</p>
                            {(hasUsersManagerVisionPermission && <div><i className="pi pi-sitemap bg-primary p-2 border-circle text-white cursor-pointer ml-auto" onClick={() => {
                                setBranchVisible(true);
                            }
                            }>
                            </i></div>)}
                        </div> : '- - -'}</div>
                    </div>}
                    {windowForRender <= 1248 && <div className="field col-12 md:col-4 flex m-0 align-items-center">
                        <div>Email:</div>
                    </div>}
                    {windowForRender <= 1248 &&<div className="field col-12 md:col-12 flex align-items-center m-0">
                        <div className="w-full">{rowData.email ? <div className="w-full flex justify-content-between align-items-center"><p className="m-0" style={{wordWrap: "break-word", maxWidth: `80%`, hyphens: "auto"}}>{rowData.email}</p>
                            <div><a href={`mailto:${rowData.email}`}><i className="pi pi-envelope bg-primary p-2 border-circle text-white cursor-pointer ml-auto"></i></a></div>
                        </div> : '- - -'}</div>
                    </div>}
                    {!!userInInfo?.length && windowForRender <= 1248 && <UserDialogCampaign/>}
                </div>
                <Dialog header="Архітектура гілок" style={{minWidth: '80vw', height: '90vw', width: '98vw'}} visible={branchVisible} onHide={() => setBranchVisible(false)}>
                    <OrganizationChart className="branch-organisation-chat" value={newBranch} nodeTemplate={nodeTemplate}/>
                </Dialog>
            </div>
        );
    };

    const renderStatusColumn = (rowData: DataRow) => {
        if (!rowData.status && !usersActiveTableToggle) {
            rowData.status = 'INACTIVE'
        }

        const statusClass = classNames({
            'p-badge status-unsuccessful': rowData.status === 'INACTIVE',
            'p-badge status-success': !rowData.status
        });

        const replaceStatusName = rowData?.status?.replace(/Copy.*/, 'Copy...');

        return (
            <Badge value={rowData.status ? replaceStatusName : 'active'} className={`${statusClass} text-white customer-badge text-xs p-0 mr-4 px-2`} style={{borderRadius: '6px', backgroundColor: `${rowData.statusColor}`, minWidth: '180px'}}/>
        );
    };

    const resetSorting = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        setFirst(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    };

    const actionTemplate = (rowData: any) => {
        const deletionToastShow = (isActive: boolean) => {
            if (!isActive) {
                setVisibleDeletedMessage(true);
            } else {
                setVisibleUnzipMessage(true)
            }
            if (!user_delete_questions && user.userId && !isActive) {
                dispatch(getNotification(user.userId, 130, user));
            }
            let userInfo = {
                userId: rowData.userId,
                isActive: isActive,
                actionByUserId: user?.userId,
                userName: user?.userName
            }

            setDeletedUserInfo(userInfo);
        }

        const copyToastShow = () => {
            setVisibleUnzipMessage(true);
            setSelectedUserId(rowData?.userId);
            setSelectedRowData(null);
        }

        return (
            <div className="flex">
                {usersActiveTableToggle ?
                    <Button className="p-button-text" icon="pi pi-trash" onClick={() => deletionToastShow(false)}></Button>
                    :
                    <Button className="p-button-text" icon="pi pi-folder-open" onClick={() => copyToastShow()}></Button>
                }
            </div>
        );
    };

    const roleBodyTemplate = (rowData: any) => {
        if (rowData?.roles && Array.isArray(rowData.roles)) {
            return (
                <ul className="m-0 p-0">
                    {rowData?.roles.map((item: any, index: number) => (
                        <li className="pb-1" key={index}>{item.name || '-'}</li>
                    ))}
                </ul>
            );
        }
        if (rowData?.roles && !Array.isArray(rowData.roles)) {
            return (
                <ul className="m-0 p-0">
                    <li className="pb-1">{rowData?.roles || '-'}</li>
                </ul>
            );
        } else {
            return <p className="pb-1">-</p>
        }
    }

    const trunkBodyDetailsTemplate = (rowData: any) => {
        if (rowData?.trunkPhonesNo && Array.isArray(rowData.trunkPhonesNo)) {
            return (
                <ul className="m-0 p-0 list-none">
                    {rowData?.trunkPhonesNo?.map((item: any, index: number) => (
                        <div className="flex" key={index}>
                            {index === 0 && <i className="pi pi-circle-fill mr-2" style={{fontSize: '5px', paddingTop: '6px'}}></i>}
                            {index !== 0 && <i className="mr-2 pt-1" style={{fontSize: '10px', minWidth: '5px'}}></i>}
                            <li className="pb-1" key={index}>{item || '-'}</li>
                        </div>
                    ))}
                </ul>
            );
        }
        if (rowData?.trunkPhonesNo && !Array.isArray(rowData.trunkPhonesNo)) {
            return (
                <ul className="m-0 p-0 list-none">
                    <li className="pb-1">{rowData?.trunkPhonesNo || '-'}</li>
                </ul>
            );
        } else {
            return <p className="pb-1">-</p>
        }
    }

    const trunkBodyTemplate = (rowData: any) => {
        if (rowData?.trunkPhonesNo && Array.isArray(rowData.trunkPhonesNo)) {
            const firstNumber = rowData.trunkPhonesNo[0] || '-';
            const additionalCount = rowData.trunkPhonesNo.length - 1;
            return (
                <ul className="m-0 p-0 list-none">
                    <li className="pb-1">
                        <div className="flex">
                            <i className="pi pi-circle-fill mr-2" style={{fontSize: '5px', paddingTop: '6px'}}></i>
                            {firstNumber}
                            {additionalCount > 0 && <span className="ml-2">(+{additionalCount})</span>}
                        </div>
                    </li>
                </ul>
            );
        }
        if (rowData?.trunkPhonesNo && !Array.isArray(rowData.trunkPhonesNo)) {
            return (
                <ul className="m-0 p-0 list-none">
                    <li className="pb-1">{rowData?.trunkPhonesNo || '-'}</li>
                </ul>
            );
        } else {
            return <p className="pb-1">-</p>
        }
    }

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }


    const roleFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть роль:</div>
                <MultiSelect
                    value={options.value}
                    options={allRoles && allRoles.map((item: { id: any; }) => item.id)}
                    itemTemplate={roleItemTemplate}
                    selectedItemTemplate={selectedRoleTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const selectedRoleTemplate = (option: any) => {
        let rolesName = allRoles.map((item: { id: any; name: any; }) => {
            if (item.id === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rolesName},
                </span>
            );
        }

        return "Оберіть роль";
    }

    const roleItemTemplate = (option: any) => {
        let rolesName = allRoles && allRoles.map((item: { id: any; name: any; }) => {
            if (item.id === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rolesName}
                </span>
            </div>
        );
    }

    const userDialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    dispatch(setUsersDialogVisible(true));
                    if (hasUsersManagerUpdatePermission) {
                        dispatch(setEditType(true));
                    } else {
                        dispatch(setEditType(false));
                    }
                    setSelectedRowData(null);
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': usersActiveTableToggle && hasUsersManagerUpdatePermission,
                        'pi pi-eye': !usersActiveTableToggle || !hasUsersManagerUpdatePermission
                    })}></span></Button>
            </div>
        </>
    };

    function customItemTemplate(option: any) {
        return (<>
                {option?.id !== 1 ? <div className="custom-dropdown-option">
                        {option.statusName}
                    </div> :
                    <ul className="list-none p-0">
                        <li className="layout-inline-menu-action-item">
                            <button className="p-link text-white">
                                <i className="pi pi-fw pi-sign-out mr-2"></i>
                                <span>{option.statusName}</span>
                            </button>
                        </li>
                    </ul>}
            </>
        );
    }

    const statusFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    options={statusesList}
                    optionValue="id"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                    optionLabel="statusName"
                />
            </>
        );
    };

    const renderTable = () => {
        return (
            <div className="layout-dashboard">
                <Toast ref={toast} position="bottom-right"/>

                <div className="flex col-12 md:col-12 flex-wrap">
                    <div className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table align-self-start', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <UsersListNav resetSorting={resetSorting} sortData={sortData} setSelectedRowData={setSelectedRowData}/>
                        <DataTable
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '40vh' : '70vh'}
                            ref={dataTableRef}
                            value={data}
                            className="p-datatable-customers monitoring-table align-self-start "
                            selectionMode="single"
                            responsiveLayout="scroll"
                            rowHover={false}
                            onSort={customSort}
                            onFilter={customFilter}
                            loading={loading}
                            filters={clearFilters}
                            selection={selectedRowData}
                            onRowClick={(e) => {
                                let data = e.data.userId;

                                dispatch(getUserDetails(data, jwtToken?.jwtToken));
                                dispatch(getUserStatusesForDetails(jwtToken?.jwtToken, data));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.data);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.data);
                                    }
                                } else {
                                    setSelectedRowData(e.data);
                                    setVisible(true);
                                }
                            }}
                            dataKey="userId"
                        >
                            <Column
                                field="id"
                                header={renderHeader('ID', 'Id')}
                                sortable
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID користувача"
                                className={classNames('', {'active-header-column': orderBy === 'Id'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '8%', minWidth: '90px'}}
                            />
                            <Column
                                field="fullName"
                                sortable
                                header={renderHeader('ПІБ', 'FullName')}
                                filterPlaceholder="Введіть ПІБ користувача"
                                className={classNames('', {'active-header-column': orderBy === 'FullName'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                style={{cursor: 'pointer', width: '25%', minWidth: '120px'}}
                            />
                            <Column
                                field="status"
                                header={renderHeader('Статус', 'Status')}
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'Status'})}
                                filterElement={statusFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                body={renderStatusColumn}
                                style={{cursor: 'pointer', width: '10%', minWidth: '220px'}}
                            />
                            <Column
                                field="role"
                                body={roleBodyTemplate}
                                sortable
                                header={renderHeader('Роль', 'Roles')}
                                filterPlaceholder="Виберіть роль користувача"
                                className={classNames('', {'active-header-column': orderBy === 'Roles'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterElement={roleFilterTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '110px'}}
                            />
                            <Column
                                field="branchName"
                                header={renderHeader('Branch', 'BranchName')}
                                sortable
                                filterPlaceholder="Введіть branch користувача"
                                className={classNames('', {'active-header-column': orderBy === 'BranchName'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                style={{
                                    cursor: 'pointer',
                                    width: hasUsersManagerDeletePermission ? '20%' : '30%',
                                    minWidth: '120px'
                                }}
                            />
                            <Column
                                field="trunkPhonesNo"
                                // header={renderHeader('Транк', 'TrunkNumbers')}
                                body={trunkBodyTemplate}
                                header="Транк"
                                sortable={false}
                                filterPlaceholder="Введіть номер транка"
                                className={classNames('', {'active-header-column': orderBy === 'TrunkPhonesNo'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterField={'trunkNumbers'}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                style={{
                                    cursor: 'pointer',
                                    width: hasUsersManagerDeletePermission ? '20%' : '30%',
                                    minWidth: '170px'
                                }}
                            />
                            <Column
                                field="ownerName"
                                header={renderHeader('Керiвник', 'OwnerName')}
                                sortable
                                filterPlaceholder="Введіть керiвника користувача"
                                className={classNames('', {'active-header-column': orderBy === 'OwnerName'})}
                                filter
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                style={{cursor: 'pointer', width: '20%', minWidth: '130px'}}
                            />
                            {hasUsersManagerDeletePermission && (<Column header={usersActiveTableToggle ? "Дія" : 'Розпакувати'} style={{cursor: 'pointer', width: '10%', minWidth: '90px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData !== null && userData && userData.user && (
                        <>
                            {windowWidth >= 1550 &&
                                <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                    {renderDetailPanel(userData.user)}
                                </div>
                            }

                            {windowWidth < 1550 && windowWidth > 1248 && !props.profileMenuPin ?
                                <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                    {renderDetailPanel(userData.user)}
                                </div> : windowWidth < 1550  && !!props.profileMenuPin && <div>
                                <Dialog header={userDialogHeader} visible={visible} className="monitoring-info" onHide={() => {setVisible(false); setSelectedRowData(null)}} >
                                    {renderDetailPanel(userData.user)}
                                </Dialog>
                            </div>
                            }

                            {windowWidth <= 1248 && selectedRowData && <div>
                                <Dialog header={userDialogHeader} visible={visible} className="monitoring-info" onHide={() => {setVisible(false); setSelectedRowData(null)}}>
                                    {renderDetailPanel(userData.user)}
                                </Dialog>
                            </div>}
                        </>
                    )}
                </div>
                <UserListDialog setBranchClick={setBranchClick} branchClick={branchClick} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData}/>
                <ConfirmDialog visible={visibleDeletedMessage} onHide={() => setVisibleDeletedMessage(false)} message={userDeletedQuestion}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                    dispatch(deleteUser(deletedUserInfo, jwtToken?.jwtToken, dataSort));
                    if (deletedUserInfo.userId === selectedRowData?.userId) {
                        setSelectedRowData(null);
                    }
                }
                }/>
                <ConfirmDialog visible={visibleUnzipMessage} onHide={() => setVisibleUnzipMessage(false)} message={'Хочете відновити користувача ?'}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                    // dispatch(deleteUser(deletedUserInfo, user));
                    dispatch(setUserDialogUnzipping(true));
                    dispatch(getUserDetails(selectedUserId, jwtToken?.jwtToken));
                    dispatch(setUsersDialogVisible(true));
                    if (deletedUserInfo.userId === selectedRowData?.userId) {
                        setSelectedRowData(null);
                    }
                }
                }/>
            </div>
        );
    };

    return (
        <div>
            {renderTable()}
        </div>
    );
};

export default UsersList;
