import React from 'react';
import {Button} from 'primereact/button';

const buttonNumbers = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    '*', '0', '#'
];

const PhoneKeyboard = (props: any) => {
    return (
        <div className="flex flex-wrap justify-content-center mb-0">
            <div className="phone-keyboard flex flex-wrap justify-content-between">
                {buttonNumbers.map((number) => (
                    <Button
                        style={{width: '32%', height: '35px'}}
                        key={number}
                        label={number}
                        className="p-button-secondary mb-1 text-xl"
                        onClick={() => props.handleClickAdd(number)}
                    />
                ))}
            </div>
            <div className="w-full flex justify-content-between">
                <Button
                    style={{width: '32%', height: '35px'}}
                    label='R'
                    className="p-button-secondary mb-1"
                    onClick={() => props.handleClickRemove('R')}
                />
                <Button
                    style={{width: '32%', height: '35px'}}
                    label='+'
                    className="p-button-secondary mb-1"
                    onClick={() => props.handleClickAdd('+')}
                />
                <Button
                    style={{width: '32%', height: '35px'}}
                    label='C'
                    className="p-button-secondary mb-1"
                    onClick={() => props.handleClickDelete('C')}
                />
            </div>
        </div>
    );
}

export default PhoneKeyboard;
