import React from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {setArchiveBranchTableToggle} from "../../../redux/actions/actionsBranchList";

const BranchListNav = () => {
    const dispatch = useAppDispatch();
    const archiveTableToggle = useAppSelector(state => state.BranchesListReducer.archiveTableToggle);
    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => dispatch(setArchiveBranchTableToggle(true))}
                        label="Активні гілки"
                        className={archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => dispatch(setArchiveBranchTableToggle(false))}
                        label="Всі гілки"
                        icon="pi pi-plus"
                        className={!archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
            </div>
        </div>
    )
}

export default BranchListNav;
