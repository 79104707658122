import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {useNavigate} from "react-router-dom";
import {Message} from "primereact/message";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {setUserErrorFields, setUserField} from "../../redux/actions/actionsUsersList";
import {Button} from "primereact/button";
import {validateChangePassword} from "../../redux/validator/validator";
import {changeUserPasswordByEmail, checkRecoveryPassword} from "../../redux/api/apiUsersList";

const RecoveryPass = () => {
    const dispatch = useAppDispatch();
    const replacedValue = window.location.hash?.replace('#/pass/recovery?id=', '');
    const rolesError = useAppSelector<any>(state => state.RolesReducer.error);
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const [error, setError] = useState<any>(null);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    // @ts-ignore
    const changePasswordLoading = useAppSelector(state => state.UsersListReducer.changePasswordLoading);
    // @ts-ignore
    const changePasswordSuccess = useAppSelector(state => state.UsersListReducer.changePasswordSuccess);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    // @ts-ignore
    const googleAuthError = useAppSelector(state => state.CompaniesListReducer.googleAuthError) as any;

    useEffect(() => {
        dispatch(setUserField({section: 'password', fieldName: 'confirmationPassword', value: ''}));
        dispatch(setUserField({section: 'password', fieldName: 'newPassword', value: ''}));
    }, []);

    useEffect(() => {
        if (changePasswordSuccess) {
            navigate('/login');
        }
    }, [changePasswordSuccess]);

    useEffect(() => {
        if (googleAuthError) {
            navigate('/login');
        }
    }, [googleAuthError]);

    useEffect(() => {
        if (replacedValue && replacedValue?.length && replacedValue !== '#/login') {
            dispatch(checkRecoveryPassword(replacedValue));
        } else {
            navigate('/login')
        }
    }, [replacedValue]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.getModifierState && e.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.getModifierState && e.getModifierState('CapsLock')) {
                setIsCapsLockOn(true);
            } else {
                setIsCapsLockOn(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyDown);
        };
    }, []);

    const togglePasswordVisibility = (inputName: string) => {
        if (inputName === 'oldPassword') {
            setShowOldPassword(!showOldPassword);
            setError(true);
        }
        if (inputName === 'newPassword') {
            setShowNewPassword(!showNewPassword);
        }
        if (inputName === 'confirmationPassword') {
            setShowPassword(!showPassword);
            setError(true);
        }
    };


    useEffect(() => {
        if (rolesError) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }
    }, [rolesError]);

    useEffect(() => {
        if (!replacedValue || !replacedValue?.length) {
            navigate('/login')
        }
    }, [replacedValue]);

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let validateError = validateChangePassword(userData?.password);
        setError(validateError);
        if (!validateError?.newPassword && !validateError?.confirmationPassword && !validateError?.oldPassword ) {
            let data = {
                id: replacedValue,
                newPassword: userData?.password?.newPassword,
                confirmationPassword: userData?.password?.confirmationPassword
            }
            dispatch(changeUserPasswordByEmail(data));
            setError(null);
            dispatch(setUserErrorFields(null));
        }
    };

    return (
        <div className="login-body">
            <Toast position="bottom-right" ref={toast}/>
            <div className="login-image">
                <img src={`assets/layout/images/pages/login-ondark.png`} alt="atlantis"/>
            </div>

            <div className="login-panel p-fluid flex-column justify-content-center align-items-start">
                <div className="flex flex-column">
                    <h3 className="mb-0">Зміна пароля</h3>
                    <form className="field col-12 md:col-12 flex flex-column px-0 max-w-18rem" autoComplete="off">
                        {isCapsLockOn && <Message severity="warn" className="w-full flex justify-content-start mb-3" text="Включений Caps Lock" />}
                        {error && error?.oldPassword && error?.oldPassword?.password && <label htmlFor="address" className={classNames('my-2 text-xs inline', {'p-error': error?.oldPassword})}>{error?.oldPassword?.password}</label>}
                        <label htmlFor="newPassword" className={classNames('pt-3', {'p-error': error?.newPassword || errorFields?.passwordError})}>Новий пароль *</label>
                        <span className="p-input-icon-left">
                <InputText
                    autoComplete="new-password"
                    name="uniqueFieldNameNewPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    value={userData?.password?.newPassword || ''}
                    maxLength={90}
                    onKeyDown={(e) => handleKeyPress(e)}
                    className={classNames('w-full w-18rem pl-2 pr-6', {'p-invalid': error?.newPassword || errorFields?.passwordError})}
                    onChange={e => {
                        dispatch(setUserField({section: 'password', fieldName: 'newPassword', value: e.target.value}));
                    }}
                />
                    <i className={showNewPassword ? 'pi pi-eye-slash cursor-pointer text-black show-pass show-pass-input-icon text-primary' : 'pi pi-eye cursor-pointer show-pass show-pass-input-icon text-primary'} onClick={() => togglePasswordVisibility('newPassword')}></i>
                </span>
                        {error && error?.newPassword && error?.newPassword?.password && <label htmlFor="address" className={classNames('my-2 text-xs inline', {'p-error': error?.newPassword})}>{error?.newPassword?.password}</label>}

                        <label htmlFor="confirmationPassword" className={classNames('pt-3', {'p-error': error?.confirmationPassword})}>Підтвердьте новий пароль *</label>
                        <span className="p-input-icon-left">
                <InputText
                    autoComplete="new-password"
                    name="uniqueFieldNameconfirmationPassword"
                    onKeyDown={(e) => handleKeyPress(e)}
                    type={showPassword ? 'text' : 'password'}
                    value={userData?.password?.confirmationPassword || ''}
                    maxLength={90}
                    className={classNames('w-full pl-2 pr-6', {'p-invalid': error?.confirmationPassword})}
                    onChange={e => {
                        dispatch(setUserField({section: 'password', fieldName: 'confirmationPassword', value: e.target.value}));
                    }}
                />
                <i className={showPassword ? 'pi pi-eye-slash cursor-pointer text-black show-pass show-pass-input-icon text-primary' : 'pi pi-eye cursor-pointer show-pass show-pass-input-icon text-primary'} onClick={() => togglePasswordVisibility('confirmationPassword')}></i>
                </span>
                        {error && error?.confirmationPassword && error?.confirmationPassword?.password && <label htmlFor="address" className={classNames('mt-2 mb-0 text-xs inline', {'p-error': error?.confirmationPassword})}>{error?.confirmationPassword?.password}</label>}
                        {errorFields && errorFields?.passwordError && errorFields?.passwordError?.name && <label htmlFor="address" className={classNames('mt-3 mb-0 text-xs inline', {'p-error': errorFields?.passwordError?.name})}>{errorFields?.passwordError?.name}</label>}
                        <div className="field col-12 md:col-12 flex justify-content-between mt-4 px-0">
                            <Button loading={changePasswordLoading} icon="pi pi-ban" label="Відміна" onClick={() => navigate('/login')} className="p-button-outlined mr-2"/>
                            <Button loading={changePasswordLoading} icon="pi pi-save" label="Зберегти" className="ml-2" onClick={handleSubmit}/>
                        </div>
                    </form>
                </div>
                <div className="login-footer flex align-items-center">
                    <span>Copyright {new Date().getFullYear()}</span>
                </div>
                </div>
            </div>
    );
};

export default RecoveryPass;
