import React from "react";
import {useAppSelector} from "redux/hooks";

const trunksListDialogManagement = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);

    const createdUserTime = () => {
        const time = new Date(trunkData?.trunkData?.createdDateTime).toLocaleString("uk-UA")
        return trunkData?.trunkData?.createdDateTime ? time : '-'
    }

    const updatedUserTime = () => {
        const time = new Date(trunkData?.trunkData?.updatedDateTime).toLocaleString("uk-UA")
        return trunkData.trunkData?.updatedDateTime ? time : '-'
    }

    return (
        <div className="grid p-fluid management">
            <div className="field col-12 md:col-6 flex justify-content-start align-items-center mb-0 py-0">
                {!!trunkData.id && <div className="item">
                    ID: {trunkData.id ? trunkData.id : ''}
                </div>}
            </div>
            <div className="field col-12 md:col-6 mb-0">
                {!!trunkData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                    {trunkData.trunkData?.createdDateTime && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{trunkData?.trunkData?.userCreate ? trunkData?.trunkData?.userCreate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                    </div>}
                    {trunkData.trunkData?.updatedDateTime &&<div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{trunkData?.trunkData?.userUpdate ? trunkData?.trunkData?.userUpdate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                    </div>}
                </div>}
            </div>
        </div>
    )
}

export default trunksListDialogManagement
