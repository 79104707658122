import React, {useEffect, useRef, useState} from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {getNotification} from "../../../redux/api/api";
import {setQualificationGroupDialogVisible, setQualificationGroupErrorFields} from "../../../redux/actions/actionsQualificationGroup";
import QualificationGroupDialogInfo from "../QualificationGroupDialogInfo";
import QualificationGroupDialogGeneralSettings from "../QualificationGroupDialogGeneralSettings";
import QualificationGroupDialogCampaign from "../QualificationGroupDialogCampaign";
import QualificationGroupDialogUsers from "../QualificationGroupDialogUsers";
import {archiveQualificationGroup, createQualificationGroup, getQualificationGroupUsers, updateQualificationGroup} from "../../../redux/api/apiQualificationGroup";
import {validateQualificationGroup} from "../../../redux/validator/validator";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";

const QualificationGroupDialog = (props: any) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    const toastBC = useRef<Toast>(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData) as unknown as any;
    const qualificationDialogVisible = useAppSelector(state => state.QualificationGroupListReducer.qualificationDialogVisible);
    const rolesPermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const dataSort = useAppSelector(state => state.QualificationGroupListReducer.dataSort);
    const archiveQualificationGroupTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);
    const notifications = useAppSelector(state => state.NotificationsReducer);
    let inSystem = qualificationData?.inSystem;
    const [visible, setVisible] = useState<boolean>(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission,
        visible: hasRoleVisionPermission,
        delete: hasRoleDeletePermission,
    } = rolesPermissions;

    let cancelNotification = getItem(codes.archive_qualification_group_question);
    // let copyNotification = getItem(codes.copy_qualification_group_question);
    // let unzipNotification = getItem(codes.un_archive_qualification_group_question);


    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    // const copyMessage = <div>{companies && companies.notificationCopy ? companies.notificationCopy.text : copyNotification ? copyNotification : progressBar}</div>
    const deleteMessage = <div>{notifications && notifications.archiveQualificationGroupMessage ? notifications.archiveQualificationGroupMessage.text : cancelNotification ? cancelNotification : progressBar}</div>

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setActiveIndex(0);
        dispatch(getQualificationGroupUsers(jwtToken?.jwtToken, user));
    }, [qualificationDialogVisible]);

    const showSaveChangesNotification = () => {
        dispatch(setQualificationGroupDialogVisible(false));
        setActiveIndex(0);
    }

    const deletionToastShow = () => {
        if (!cancelNotification) {
            dispatch(getNotification(user.userId, 190, jwtToken?.jwtToken));
        }
        setVisible(true);
    }


    const createQualificationData = (data: any) => {
        let updatedQualificationData = data;
        const validationErrors = validateQualificationGroup(updatedQualificationData);
        dispatch(setQualificationGroupErrorFields(validationErrors));

        if (Object.keys(validationErrors)?.length === 0) {
            dispatch(createQualificationGroup(updatedQualificationData, jwtToken?.jwtToken, dataSort));
        }
        if (validationErrors?.name || validationErrors?.branchId) {
            setActiveIndex(0);
        } else if (validationErrors?.users) {
            setActiveIndex(1);
        }
    };

    const updatedQualificationData = (data: any) => {
        let updatedQualificationData = data;
        const validationErrors = validateQualificationGroup(updatedQualificationData);
        dispatch(setQualificationGroupErrorFields(validationErrors));
        if (Object.keys(validationErrors).length === 0) {
            if (props?.branchId) {
                dispatch(updateQualificationGroup(updatedQualificationData, jwtToken?.jwtToken, dataSort, props?.branchId));
            } else {
                dispatch(updateQualificationGroup(updatedQualificationData, jwtToken?.jwtToken, dataSort));
            }
        }
        if (validationErrors?.name || validationErrors?.branchId) {
            setActiveIndex(0);
        } else if (validationErrors?.users) {
            setActiveIndex(1);
        }
    };

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <Toast ref={toast} position="bottom-right"/>
            <Toast ref={toastBC} position="center"/>
            <div className="buttons">
                {qualificationData?.id && archiveQualificationGroupTableToggle && hasRoleDeletePermission && !inSystem && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                                                                                  className="p-button-outlined"
                                                                                                                                  label="Видалити"
                                                                                                                                  onClick={() => {
                                                                                                                                      deletionToastShow();
                                                                                                                                  }}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            dispatch(setQualificationGroupDialogVisible(false));
                        }
                        }
                />
                {!qualificationData?.id && archiveQualificationGroupTableToggle && hasRoleCreatePermission && !inSystem && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                                                   className=""
                                                                                                                                   label="Створити та зберегти"
                                                                                                                                   onClick={() => {
                                                                                                                                       createQualificationData(qualificationData);
                                                                                                                                   }
                                                                                                                                   }
                />}
                {qualificationData?.id && archiveQualificationGroupTableToggle && hasRoleUpdatePermission && !inSystem ? <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                                                 label="Зберегти"
                                                                                                                                 onClick={() => {
                                                                                                                                     updatedQualificationData(qualificationData);
                                                                                                                                 }}
                /> : ''}
            </div>
        </div>
    );

    const bodyHeader = () => {
        return <QualificationGroupDialogInfo/>;
    }

    const createdUserTime = () => {
        const time = new Date(qualificationData?.skillGroupsData?.createdDateTime || '').toLocaleString("uk-UA")
        if (qualificationData?.skillGroupsData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(qualificationData?.skillGroupsData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (qualificationData?.skillGroupsData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    const deleteQualificationGroup = () => {
        if (user.userId) {
            const archiveStatusData = {
                skillGroupId: qualificationData?.id,
                isArchive: true
            };
            dispatch(archiveQualificationGroup(archiveStatusData, jwtToken?.jwtToken));
        }
    };

    return (
        <Dialog
            header={bodyHeader}
            footer={!hasRoleVisionPermission ? null : footer}
            visible={qualificationDialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
            onHide={() => showSaveChangesNotification()}
        >
            {qualificationData && <div className="company-info-popup">
                <div className="grid align-items-center">
                    <div className="field col-12 md:col-6">
                        {!!qualificationData.id && <div className="item pl-2">
                            ID: {qualificationData?.id ? qualificationData?.id : ''}
                        </div>}
                    </div>
                    <div className="field col-12 md:col-6 flex  mb-0">
                        {!!qualificationData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                            {qualificationData?.skillGroupsData?.createdByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{qualificationData?.skillGroupsData?.userCreate ? qualificationData?.skillGroupsData?.userCreate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                            </div>}
                            {qualificationData?.skillGroupsData?.updatedByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{qualificationData?.skillGroupsData?.userUpdate ? qualificationData?.skillGroupsData?.userUpdate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                            </div>}
                        </div>}
                    </div>
                </div>
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Загальні налаштування">
                        {qualificationData && <QualificationGroupDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Додати операторів">
                        <QualificationGroupDialogUsers/>
                    </TabPanel>
                    {!!qualificationData?.id &&
                    <TabPanel header="Черги/Кампанii">
                        <QualificationGroupDialogCampaign/>
                    </TabPanel>
                    }
                </TabView>
            </div>}
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteMessage}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteQualificationGroup()}/>
        </Dialog>
    )
}

export default QualificationGroupDialog
