import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import QueuesDialogInfo from "../TrunkDialogInfo";
import QueuesListDialogManagement from "../DialogManagement";
import QueuesListDialogGeneralSettings from "../DialogGeneralSettings";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {setTrunkDialogVisible, setTrunkErrorField, setTrunkIds} from "../../../../redux/actions/actionsTrunk";
import PhoneSettings from "../PhoneSettings";
import {createTrunk, updateTrunk} from "../../../../redux/api/apiTrunks";
import {validateTrunkData} from "../../../../redux/validator/validator";
import BlackList from "../BlackList";

const TrunkListDialog = (props: any) => {
    const dispatch = useAppDispatch()
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);
    const trunkPhones = useAppSelector(state => state.TrunksListReducer.trunkIds);
    const dialogVisible = useAppSelector(state => state.TrunksListReducer.trunkDialogVisible);
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectIndex, setSelectIndex] = useState(0);
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false);
    const [createdSchedule, setCreatedSchedule] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const dialerPermissions = checkPermissions(rolesVision, 'Trunks');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        // delete: hasDialerDeletePermission,
    } = dialerPermissions;

    useEffect(() => {
        setActiveIndex(0);
    }, [dialogVisible]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

    }, [dialogVisible]);

    useEffect(() => {
        if (trunkData?.phones?.length) {
            dispatch(setTrunkIds(trunkData?.phones));
        } else {
            dispatch(setTrunkIds(null));
        }
    }, [dialogVisible]);

    useEffect(() => {
        if (errorFields && errorFields?.nameUniq) {
            setActiveIndex(0);
        }
    }, [errorFields])

    const closeDialogVisible = () => {
        dispatch(setTrunkDialogVisible(false));
        dispatch(setTrunkErrorField(null));
    }

    const updateCampaign = (type: string) => {
        let trunkCopy = {...trunkData};
        const filteredData = trunkPhones.filter((item: { phoneNo: null; }) => item.phoneNo !== null);

        if (filteredData?.length) {
            trunkCopy.phones = filteredData;
        }

        const validationErrors = validateTrunkData(trunkCopy);
        if (!validationErrors) {
            dispatch(updateTrunk(trunkCopy, jwtToken?.jwtToken));
            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setTrunkErrorField(validationErrors));
            if (validationErrors.name) {
                setActiveIndex(0);
            } else if (validationErrors.phones) {
                setActiveIndex(1);
            } else if (validationErrors.listOutboundPhones || validationErrors.listInboundPhones) {
                setActiveIndex(2);
            }
        }
        setCreatedSchedule(false);
    };

    const createCampaign = (type: string) => {
        let trunkCopy = {...trunkData};

        if (trunkPhones?.length) {
            trunkCopy.phones = trunkPhones;
        }

        const validationErrors = validateTrunkData(trunkCopy);
        if (!validationErrors) {
            dispatch(createTrunk(trunkCopy, jwtToken?.jwtToken));
            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setTrunkErrorField(validationErrors));
            if (validationErrors.name) {
                setActiveIndex(0);
            } else if (validationErrors.phones) {
                setActiveIndex(1);
            } else if (validationErrors.listOutboundPhones || validationErrors.listInboundPhones) {
                setActiveIndex(2);
            }
        }
        setCreatedSchedule(false);
    };

    const deleteCampaign = () => {
        // if (jwtToken?.jwtToken) {
        //     dispatch(setArchiveTrunkById(queueData?.queue?.id, jwtToken?.jwtToken));
        // }
        let trunkCopy = {...trunkData};
        trunkCopy.isActive = false;
        dispatch(updateTrunk(trunkCopy, jwtToken?.jwtToken));
        dispatch(setTrunkDialogVisible(false));
        props.setSelectedRowData(null);
    }

    const showSaveChangesNotification = () => {
        dispatch(setTrunkDialogVisible(false));
    }

    const setActiveTabIndex = (e: any) => {
        if ((createdSchedule) && activeIndex === 2) {
            setVisible(true);
            setSelectIndex(e.index);
        } else {
            setActiveIndex(e.index);
        }
    }

    const closeNotification = () => {
        setVisible(false);
        setActiveIndex(selectIndex);
        setCreatedSchedule(false);
    }

    const saveNewScheduler = () => {
        closeNotification();
    }

    const cancelSaveScheduler = () => {

    }

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                {/*{hasDialerDeletePermission && trunkData?.id && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}*/}
                {/*                                                       className="p-button-outlined"*/}
                {/*                                                       label="Видалити"*/}
                {/*                                                       onClick={showDeletionNotification}*/}
                {/*/>}*/}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            showSaveChangesNotification()
                        }}
                />
                {hasDialerCreatePermission && !trunkData?.id && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                        className=""
                                                                        // disabled
                                                                        label="Створити та зберегти"
                                                                        onClick={() => {
                                                                            createCampaign('')
                                                                        }}
                />}
                {hasDialerUpdatePermission && trunkData?.id && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                       label="Зберегти"
                                                                       // disabled
                                                                       onClick={() => {
                                                                           updateCampaign('')
                                                                       }}
                />}
            </div>
        </div>
    );

    return (
        <Dialog
            header="Налаштування Транка"
            footer={archiveTableToggle ? footer : ''}
            visible={dialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            className='dialogInfo'
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh", zIndex: '1101px !important'}}
            onHide={() => showSaveChangesNotification()}
        >
            {trunkData && <div className="company-info-popup">
                <ConfirmDialog visible={showDeleteNotification} onHide={() => setShowDeleteNotification(false)} message={'Транк буде архівован та деактивован. Архівувати транк?'}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
                {/*{!companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}*/}
                {/*                                     icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {*/}
                {/*    updateCampaign('with_close')*/}
                {/*}}/>}*/}
                {/*{companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}*/}
                {/*                                    icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {*/}
                {/*    createCampaign('with_close')*/}
                {/*}}/>}*/}
                <QueuesDialogInfo/>
                <Divider style={{margin: '0 0 20px 0'}}/>
                {trunkData?.id &&
                    <>
                        <QueuesListDialogManagement/>
                        <Divider style={{margin: '0 0 20px 0'}}/>
                    </>
                }
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveTabIndex(e)}>
                    <TabPanel header="Загальні налаштування">
                        {trunkData && <QueuesListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Налаштування номерів">
                        <PhoneSettings/>
                    </TabPanel>
                    <TabPanel header="Black List">
                        <BlackList/>
                    </TabPanel>
                    {/*<TabPanel header="Налаштування ACD">*/}
                    {/*    <ACDSettingsTab/>*/}
                    {/*</TabPanel>*/}
                </TabView>
            </div>}
            <Dialog visible={visible} style={{width: '25vw'}} onHide={() => setVisible(false)}>
                <p className="m-0">
                    Застосувати розклад запуску та завершення кампанії?
                </p>
                <div className="flex justify-content-end mt-4">
                    <Button className="mr-2" onClick={() => saveNewScheduler()}>Так</Button>
                    <Button className="p-button-outlined mr-2" onClick={() => cancelSaveScheduler()}>Ні</Button>
                    <Button className="p-button-outlined" onClick={() => setVisible(false)}>Відміна</Button>
                </div>
            </Dialog>
        </Dialog>
    )
}

export default TrunkListDialog
