
import {HIDE_ERROR} from "../types/types";
import {
    SET_CURRENT_STATISTICS_PAGE,
    SET_CURRENT_STATISTICS_SIZE,
    SET_FIRST_PAGE, SET_STATISTICS_FILTER, SET_STATISTICS_LOADING,
    SHOW_EXPORT_STATISTICS_LOADING, UPDATE_STATISTIC_SORT_FIELDS
} from "../types/typesStatisticsList";
import {GET_AUDIO_URL} from "../types/typesStatisticsList";
import {UPDATE_SORT_FIELDS} from "../types/typesMonitoringList";

export function setStatisticsCurrentPage(state: any) {
    return {
        type: SET_CURRENT_STATISTICS_PAGE,
        payload: state
    }
}

export function setStatisticsCurrentPageSize(state: any) {
    return {
        type: SET_CURRENT_STATISTICS_SIZE,
        payload: state
    }
}

export function setPageForFirst(state: any) {
    return {
        type: SET_FIRST_PAGE,
        payload: state
    }
}

export function getAudioUrlForPlayer(state: any) {
    return {
        type: GET_AUDIO_URL,
        payload: state
    }
}

export function showStatisticsExportLoader(state: boolean) {
    return {
        type: SHOW_EXPORT_STATISTICS_LOADING,
        payload: state
    }
}


export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function setStatisticsFilter(payload: any) {
    return {
        type: SET_STATISTICS_FILTER,
        payload: payload,
    };
}

export function showStatisticsLoader(payload: any) {
    return {
        type: SET_STATISTICS_LOADING,
        payload: payload,
    };
}

export function updateStatisticsSortFields(updatedFields: any) {
    return {
        type: UPDATE_STATISTIC_SORT_FIELDS,
        payload: updatedFields,
    };
}


