import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {setRoleDialogVisible, setSelectedRolesIndex} from "../../../../redux/actions/actionsRoles";
import {setEditType} from "../../../../redux/actions/actions";
import {Divider} from "primereact/divider";
import {getUsersManagerUserBranchesData} from "../../../../redux/api/apiUsersList";
import RolesDialogStatusMatrix from "../RolesDialogStatusMatrix";
import {Dialog} from "primereact/dialog";
import RoleModel from "../RoleModel";

const RolesListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const loader = useAppSelector(state => state.RolesReducer.detailsLoading);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;
    const rolesDictionary = useAppSelector(state => state.RolesReducer.rolesDictionary) as unknown as any;
    const archiveRolesTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle);
    const rolePermissions = checkPermissions(rolesVision, 'Role');
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [matrixVisible, setMatrixVisible] = useState(false);

    const {
        // create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission
    } = rolePermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, [])

    const fields = [
        {label: "ID", value: rolesData?.id},
        {label: "Стан ролі", value: rolesData?.active ? 'Активна' : 'Не активна'},
    ];

    const openEditor = () => {
        dispatch(setSelectedRolesIndex(rolesData?.id || props.selectedRowData));
        dispatch(setRoleDialogVisible(true));
        props.setSelectedRowData(null);
        if (hasRoleUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const hasPermissionId = rolesData?.roleModules.some((item: any) => item.permissionId !== null);

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchItems = rolesData?.roleBranches?.map((queueItem: any, index: number) => {
        if (queueItem.id === 0) {
            return <div key={index} className="field col-12 md:col-10 flex m-0 border-round-sm">- - -</div>;
        }
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return formattedBranches?.map((branchItem: any) => {
                    if (queueItem.id === branchItem.id) {
                        return (
                            <div className="field col-12 md:col-10 flex m-0 border-round-sm pl-0" key={index}>
                                {branchItem.label}
                            </div>
                        )
                    } else if (queueItem.id === 0) {
                        return '- - -'
                    }
                })
            }
        });
    });

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid flex-wrap', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    <Dialog header={rolesData?.name} style={{minWidth: '80vw', height: '90vw', width: '98vw'}} visible={matrixVisible} onHide={() => setMatrixVisible(false)}>
                        <RolesDialogStatusMatrix height={'100vh'}/>
                    </Dialog>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between pr-0">
                        <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-6 flex flex-wrap align-items-center m-0 p-0">
                                <div className="field col-12 md:col-12 flex align-items-center m-0 p-0">
                                    <p className="w-full mb-2">ID: {rolesData?.id}</p>
                                </div>
                                <div className="field col-12 md:col-12 flex align-items-center m-0 p-0">
                                    <p className="w-full">{rolesData?.name}</p>
                                </div>
                            </div>
                            <div className="field col-12 md:col-6 flex flex-wrap align-self-start m-0 py-0">
                                <p className="text-primary cursor-pointer" onClick={() => {
                                    setMatrixVisible(true);
                                }}>Матриця статусів</p>
                            </div>
                            </div>
                        <div className="col-12 md:col-6 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-2 flex align-items-center justify-content-end m-0 p-0"></div>
                            <div className="field col-12 md:col-8 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full mr-2">{rolesData?.active ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex align-items-center justify-content-end m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1 ml-0" onClick={() => {
                                    openEditor();
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveRolesTableToggle && hasRoleUpdatePermission && rolesData?.isEdit,
                                    'pi pi-eye': !archiveRolesTableToggle || !hasRoleUpdatePermission || !rolesData?.isEdit
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-6 flex align-items-center m-0">
                            <p className="w-full">{rolesData?.name}</p>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && fields.map((field: any, index: number) => (
                        <React.Fragment key={index}>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <p>{field.label}</p>
                            </div>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <p>{field.value}</p>
                            </div>
                        </React.Fragment>
                    ))}
                    {hasPermissionId && windowWidth <= 1248 && <div className="field col-12 md:col-8 flex align-items-center m-0">
                        <p>Доступні розділи:</p>
                    </div>}
                    {windowWidth > 1248 && <div>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex align-items-center m-0">
                                <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                    <p className="w-full">Бранч</p>
                                </div>
                                <div className="field col-12 md:col-9 flex align-items-center m-0 py-0">
                                    <p className="w-full">Доступні розділи</p>
                                </div>
                            </div>
                        <Divider className="my-0"/>
                        <div className="field col-12 md:col-12 flex m-0 scroll-detail-items">
                            <div className="field col-12 md:col-3 flex align-items-center align-self-start m-0 p-0">
                                <div className="w-full">{branchItems || '- - -'}</div>
                            </div>
                            <div className="field col-12 md:col-9 flex align-items-center align-self-start m-0 p-0">
                                <RoleModel isDisabled={true}/>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && rolesData && rolesData?.roleModules && rolesData?.roleModules?.map((module: any, index: number) => (
                        <React.Fragment key={index}>
                            {module.permissionId ? <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <p>{module.name}</p>
                            </div>: ''}
                            {module.permissionId ? <div className="field col-12 md:col-6 flex align-items-center m-0">
                                {rolesDictionary && rolesDictionary.map((desc: any, i: number) => (
                                    <div key={i}>
                                        <p className="m-0">{desc.dictionaryId === module.permissionId ? desc.name : ''}</p>
                                    </div>
                                ))}
                            </div>: ''}
                        </React.Fragment>
                    ))}
                    {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                        <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Бранч:</label></div></div>
                        <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="field col-12 md:col-12 flex m-0 flex-column px-0">{rolesData?.roleBranches?.length ? branchItems : '- - -'}</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default RolesListDetails;
