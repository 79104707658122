import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {MultiSelect} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import CompaniesListNav from "../QueueListNav";
import {setCurrentPage, setCurrentPageSize, setEditType} from "redux/actions/actions";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Paginator} from "primereact/paginator";
import {classNames} from "primereact/utils";
import {Dialog} from "primereact/dialog";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {DataSort} from "../../../types/types";
import {ProgressBar} from "primereact/progressbar";
import QueueListDetails from "./QueueListDetails";
import QueuesListDialog from "../QueueDialog";
import {
    setArchiveQueueTableToggle,
    setQueueCurrentPage,
    setQueueCurrentPageSize,
    setQueueDialogVisible,
    setQueueErrorField,
    setSelectedQueueIndex
} from "../../../redux/actions/actionsQueue";
import {copyQueueById, getAllQueuesWithPagination, getQueueDirectoriesQueueOperatorsType, getQueueId, setArchiveQueueById} from "../../../redux/api/apiQueue";
import {getNotificationMessage} from "../../../redux/api/apiNotifications";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";
import BranchDialog from "../../Branch/BranchDialog";
import {getCompaniesWithPaginationFilterAndSorting} from "../../../redux/api/api";

const QueueList = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const clearFilters = useAppSelector(state => state.QueueListReducer.clearFilters);
    // @ts-ignore
    const companiesList = useAppSelector(state => state.QueueListReducer.queuesList);
    // @ts-ignore
    const pagination = useAppSelector(state => state.QueueListReducer.pagination);
    // @ts-ignore
    const paginationPage = useAppSelector(state => state.QueueListReducer.paginationPage);
    // @ts-ignore
    const paginationSize = useAppSelector(state => state.QueueListReducer.paginationSize);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle);
    // @ts-ignore
    const loader = useAppSelector(state => state.QueueListReducer.loading);
    const [visible, setVisible] = useState<boolean>(false);
    const [showUnArchiveNotification, setShowUnArchiveNotification] = useState<boolean>(false);
    const [showCopyNotification, setShowCopyNotification] = useState<boolean>(false);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data] = useState<any>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterCampaignTypeId, setFilterCampaignTypeId] = useState<any>(null);
    const [filterStatusId, setFilterStatusId] = useState<any>(null);
    const [filterBranchId, setFilterBranchId] = useState<any>(null);
    const [filterQueueActive, setFilterQueueActive] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Queues');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const archiveQueueMessage = useAppSelector(state => state.NotificationsReducer.archiveQueueMessage);
    const unzipQueueMessage = useAppSelector(state => state.NotificationsReducer.unzipQueueMessage);
    const copyQueueMessage = useAppSelector(state => state.NotificationsReducer.copyQueueMessage);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const [sortData, setSortData] = useState<any>({
        isArchived: !archiveTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    const isGeneral = [
        {name: 'Транк черги', value: true},
        {name: 'Транк оператора', value: false},
    ];

    const isActive = [
        {name: 'Активна', value: true},
        {name: 'Не активна', value: false},
    ];

    const priorityType = [
        {value: 0},
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
        {value: 10},
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setQueueCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(getQueueDirectoriesQueueOperatorsType(jwtToken?.jwtToken));
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(setQueueCurrentPageSize(10));
        dispatch(setArchiveQueueTableToggle(true));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0; // Сброс прокрутки
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        let filter: { key: string; value: string }[] = [];
        const dataSort: DataSort = {
            isArchived: !archiveTableToggle,
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            }
        }
        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterCampaignTypeId) {
            filterCampaignTypeId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterStatusId) {
            filterStatusId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterBranchId) {
            filterBranchId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterQueueActive) {
            filterQueueActive.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCampaignName?.value) {
            filter.push(filterCampaignName);
        }

        if (filter) {
            dataSort['filter'] = filter;
        }
        dispatch(getAllQueuesWithPagination(dataSort, jwtToken?.jwtToken));
        setSortData(dataSort);
        setPage(null);
        resetTableHorizontalScroll();
    }, [paginationPage, paginationSize, data, archiveTableToggle, orderBy, filterQueueActive, isDescFilter, filterCampaignId, filterCampaignName, filterCampaignTypeId, filterBranchId]);

    useEffect(() => {
        if (activeClick?.item === "/queue" && activeClick?.from ==='#/queue') {
            dispatch(getAllQueuesWithPagination(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    let archiveQueueNotification = getItem(codes.archive_queue_question);
    let unzipQueueNotification = getItem(codes.un_archive_queue_question);
    let copyQueueNotification = getItem(codes.copy_queue_question);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const deleteQueueQuestion = <div>{archiveQueueMessage && archiveQueueMessage?.text ? archiveQueueMessage?.text : archiveQueueNotification ? archiveQueueNotification : progressBar}</div>
    const unArchiveQueueQuestion = <div>{unzipQueueMessage && unzipQueueMessage?.text ? unzipQueueMessage?.text : unzipQueueNotification ? unzipQueueNotification : progressBar}</div>
    const copyQueueQuestion = <div>{copyQueueMessage && copyQueueMessage?.text ? copyQueueMessage?.text : copyQueueNotification ? copyQueueNotification : progressBar}</div>

    useEffect(() => {
        setSelectedRowData(null);
    }, [archiveTableToggle, paginationPage, paginationSize]);

    useEffect(() => {
        setFirst(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    }, [archiveTableToggle]);

    const typeBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData?.isGeneralTrunk ? 'Транк Черги' : 'Транк Оператора'}
                </span>
            </React.Fragment>
        );
    };

    const branchBodyTemplate = (rowData: any) => {
        let element = branches?.find((element: { id: number; }) => element?.id === rowData?.branchId);

        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {element?.id} {element?.name}
                </span>
            </React.Fragment>
        );
    };

    const copyQueue = () => {
        if (jwtToken?.jwtToken) {
            dispatch(copyQueueById(id, jwtToken?.jwtToken, sortData));
        }
    }

    const setArchiveQueue = () => {
        if (jwtToken?.jwtToken) {
            dispatch(setArchiveQueueById(id, jwtToken?.jwtToken, sortData));
        }
    }

    const operatorsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.activeOperators} / {rowData.countOperators}
            </div>
        )
    }

    const actionTemplate = (rowData: any) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const archiveToastShow = (queueId: number) => {
            if (!archiveQueueNotification) {
                dispatch(getNotificationMessage(user.userId, 171, jwtToken?.jwtToken));
            }
            setVisible(true);
            setId(queueId);
        }

        const unArchiveToastShow = (queueId: number) => {
            if (!unzipQueueNotification) {
                dispatch(getNotificationMessage(user.userId, 173, jwtToken?.jwtToken));
            }
            setShowUnArchiveNotification(true);
            setId(queueId);
        }

        const copyToastShow = (queueId: number) => {
            if (!copyQueueNotification) {
                dispatch(getNotificationMessage(user.userId, 164, jwtToken?.jwtToken));
            }
            setShowCopyNotification(true);
            setId(queueId);
        }
        return (
            <div className="flex">
                <div className="controls-buttons">
                    {hasDialerCreatePermission && (<Button className="p-button-text p-button-icon"
                        onClick={() => copyToastShow(rowData.id)}
                                                           icon="pi pi-copy"></Button>)}
                </div>
                {archiveTableToggle && hasDialerDeletePermission &&
                    <Button
                        className="p-button-text"
                        icon="pi pi-trash"
                        onClick={() => archiveToastShow(rowData.id)}
                    ></Button>}
                {!archiveTableToggle && hasDialerUpdatePermission &&
                    <Button
                        className="p-button-text"
                        icon="pi pi-folder-open"
                        onClick={() => unArchiveToastShow(rowData.id)}
                    ></Button>}
            </div>
        );
    };

    const nameBodyTemplate = (rowData: any) => {
        let campaignName = '';
        companiesList.map((item: any) => {
            if (item.id === rowData.id) {
                let name = item.name;
                const truncateWithEllipses = (text: string, max: number) => {
                    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
                }

                if (name) {
                    campaignName = truncateWithEllipses(name, 41)
                }
            }
        });
        return <span>{campaignName}</span>;
    };

    const isActiveFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={isActive}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const typeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={isGeneral}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={formattedBranches}
                    optionLabel="label"
                    optionValue="id"
                    selectedItemsLabel={options?.value?.length ? `Обрано: ${options?.value?.length}` : "0"}
                    maxSelectedLabels={0}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const priorityFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={priorityType}
                    optionLabel="value"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<DataTable>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setQueueCurrentPageSize(10));
        dispatch(setQueueCurrentPage(1));
        setFirst(0);
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setQueueCurrentPage(event.page + 1));
        dispatch(setQueueCurrentPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'Id':
                    newOrderBy = 'Id';
                    break;
                case 'isGeneralTrunk':
                    newOrderBy = 'IsGeneralTrunk';
                    break;
                case 'name':
                    newOrderBy = 'Name';
                    break;
                case 'priority':
                    newOrderBy = 'Priority';
                    break;
                case 'isActive':
                    newOrderBy = 'IsActive';
                    break;
                case 'activeOperators':
                    newOrderBy = 'ActiveOperators';
                    break;
                case 'branchId':
                    newOrderBy = 'BranchId';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {id, name, isGeneralTrunk, priority, isActive, branchId} = event.filters;

            setFilterCampaignId(id ? {key: 'Id', value: id.constraints[0].value} : null);
            setFilterCampaignName(name ? {key: 'Name', value: name.constraints[0].value} : null);
            if (isGeneralTrunk.value) {
                setFilterCampaignTypeId(isGeneralTrunk.value.map((item: any) => ({key: 'IsGeneralTrunk', value: String(item)})));
            } else {
                setFilterCampaignTypeId(null);
            }
            if (priority.value) {
                setFilterStatusId(priority.value.map((item: any) => ({key: 'Priority', value: String(item)})));
            } else {
                setFilterStatusId(null);
            }
            if (branchId.value) {
                setFilterBranchId(branchId.value.map((item: any) => ({key: 'BranchId', value: String(item)})));
            } else {
                setFilterBranchId(null);
            }
            if (isActive.value) {
                setFilterQueueActive(isActive.value.map((item: any) => ({key: 'IsActive', value: String(item)})));
            } else {
                setFilterQueueActive(null);
            }
        } else {
            setFilterCampaignTypeId(null);
            setFilterCampaignId(null);
            setFilterCampaignName(null);
            setFilterStatusId(null);
            setFilterQueueActive(null);
            setFilterBranchId(null);
            setOrderBy(null);
            setIsDescFilter(true);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = () => {
        dispatch(setQueueDialogVisible(true));
        dispatch(getQueueId(selectedRowData, jwtToken?.jwtToken));
        dispatch(setQueueErrorField(null));
        setSelectedRowData(null);
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                        'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                    })}></span></Button>
            </div>
        </>
    }

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex col-12 md:col-12 p-0 flex-wrap">
                    {/*<div className="card widget-table">*/}
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'mb-4': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <CompaniesListNav handleReset={handleReset} setSelectedRowData={setSelectedRowData}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '35vh' : '70vh'}
                            className="p-datatable-customers monitoring-table"
                            value={companiesList}
                            dataKey="id"
                            filters={clearFilters}
                            rowsPerPageOptions={[10, 15, 25, 50]}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.id);
                                dispatch(getQueueId(e.data.id, jwtToken?.jwtToken));
                                dispatch(setSelectedQueueIndex(e.data.id));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData) =>
                                rowData?.id === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="id"
                                filterField="id"
                                header={renderHeader('ID', 'Id')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID черги"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'Id'})}
                                body={(data) => data.id}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '6%', minWidth: '90px'}}
                            />
                            <Column
                                field="branchId"
                                filterField="branchId"
                                header={renderHeader('ID / Branch', 'BranchId')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'Id'})}
                                body={branchBodyTemplate}
                                filterElement={branchFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '6%', minWidth: '200px'}}
                            />
                            <Column
                                filterField="isGeneralTrunk"
                                header={renderHeader('А номер', 'IsGeneralTrunk')}
                                className={classNames('', {'active-header-column': orderBy === 'IsGeneralTrunk'})}
                                showFilterMatchModes={false}
                                body={typeBodyTemplate}
                                filter
                                sortable
                                field="isGeneralTrunk"
                                filterElement={typeFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '15%', minWidth: '150px'}}
                            />
                            <Column
                                field="name"
                                filterField="name"
                                header={renderHeader('Назва', 'Name')}
                                className={classNames('', {'active-header-column': orderBy === 'Name'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву черги"
                                filter
                                sortable
                                body={nameBodyTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '40%', minWidth: '150px'}}
                            />
                            <Column
                                field="priority"
                                filterField="priority"
                                header={renderHeader('Приорітет', 'Priority')}
                                className={classNames('', {'active-header-column': orderBy === 'Priority'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.priority || 0}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={priorityFilterTemplate}
                                style={{cursor: 'pointer', width: '15%', minWidth: '150px'}}
                            />
                            <Column
                                field="isActive"
                                filterField="isActive"
                                header={renderHeader('Стан', 'IsActive')}
                                className={classNames('', {'active-header-column': orderBy === 'IsActive'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.isActive ? 'Активна' : 'Не активна'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={isActiveFilterTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            <Column
                                field="activeOperators"
                                header={renderHeader('Оператори', 'ActiveOperators')}
                                className={classNames('', {'active-header-column': orderBy === 'ActiveOperators'})}
                                sortable body={operatorsBodyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '115px'}}
                            />
                            {(hasDialerCreatePermission || hasDialerDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '5%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <QueueListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <QueueListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <QueuesListDialog/>
                    <ConfirmDialog visible={showUnArchiveNotification} onHide={() => setShowUnArchiveNotification(false)} message={unArchiveQueueQuestion}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => setArchiveQueue()}/>
                    <ConfirmDialog visible={showCopyNotification} onHide={() => setShowCopyNotification(false)} message={copyQueueQuestion}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => copyQueue()}/>
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteQueueQuestion}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => setArchiveQueue()}/>
                </div>
            </div>
            <BranchDialog/>
        </div>
    );
};

export default QueueList;
