import React, {useEffect, useState} from "react";
import {InputText} from 'primereact/inputtext';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {getCampaignDirectoriesPhoneType} from "../../../redux/api/api";
import {InputNumber} from "primereact/inputnumber";
import {setCallCycles, setChangedCompaniesData, setErrorFields} from "../../../redux/actions/actions";
import {classNames} from "primereact/utils";

const QueueACDSetting = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle);
    // @ts-ignore
    const campaignDirectoriesPhoneType = useAppSelector(state => state.CompaniesListReducer.campaignDirectoriesPhoneType);
    // @ts-ignore
    const callCycles = useAppSelector(state => state.CompaniesListReducer.companiesData.callCycles);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    const statusDone = companiesData?.campaign?.statusId === 6;
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const [cycles, setCycles] = useState([
        {typeCall: 101, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 1},
        {typeCall: 102, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 2},
        {typeCall: 103, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 3}
    ]);


    useEffect(() => {
        dispatch(getCampaignDirectoriesPhoneType(jwtToken?.jwtToken));
        if (callCycles && callCycles.length) {
            setCycles(callCycles)
        }
    }, [callCycles]);

    const updateState = (indexItem: number, name: string, value: any) => {
        const newState = cycles.map((obj, index: number) => {
                if (index === indexItem) {
                    if (name === 'countCalls' && value === 1) {
                        return {...obj, [name]: value, timeBetweenCalls: 0};
                    }
                    if (name === 'countCalls' && value === 0) {
                        return {...obj, [name]: 1, timeBetweenCalls: 0};
                    } else {
                        return {...obj, [name]: value};
                    }
                }
            return obj;
        });
        dispatch(setCallCycles(newState));
        dispatch(setErrorFields(null));
        setCycles(newState);
    };

    const setDefaultValue = (indexItem: number, name: string, value: any) => {
        const newState = cycles.map((obj, index: number) => {
            if (index === indexItem && value === '') {
                return {...obj, [name]: 1, timeBetweenCalls: 0};
            }
            return obj;
        });
        dispatch(setCallCycles(newState));
        dispatch(setErrorFields(null))
        setCycles(newState);
    };

    const countCallsTemplate = (data: any, dataRow: any) => {
        return (
            <div className="mt-3 relative">
                <InputNumber value={data.countCalls}
                             style={{width: '80px'}}
                             min={1}
                             inputMode="numeric"
                             className={classNames('', {'p-invalid': errorFields?.callCycles?.[dataRow.rowIndex]?.countCalls})}
                             onChange={(e) => {
                                 updateState(dataRow.rowIndex, 'countCalls', e.value);
                                 dispatch(setChangedCompaniesData(true));
                             }}
                             onBlur={(e) => {
                                 setDefaultValue(dataRow.rowIndex, 'countCalls', e.target.value);
                               dispatch(setChangedCompaniesData(true));
                             }}
                             disabled={!archiveTableToggle || statusDone || !editType}
                />
            </div>
        )
    }

    const timeBetweenCallsTemplate = (data: any, dataRow: any) => {
        let errorTimeBetweenCalls = errorFields?.callCycles?.[dataRow.rowIndex]?.timeBetweenCalls;
        return (
            <div className="mt-3 relative op">
                <InputNumber value={data.timeBetweenCalls}
                             style={{width: '80px'}}
                             min={0}
                             className={classNames('', {'p-invalid': errorTimeBetweenCalls})}
                             onChange={(e) => {
                                 updateState(dataRow.rowIndex, 'timeBetweenCalls', e.value);
                                 dispatch(setChangedCompaniesData(true));
                             }}
                             disabled={!archiveTableToggle || data.countCalls === 1 || statusDone || !editType}
                />
                {/*<label htmlFor="name" className={classNames('error-msg', {'p-error': errorFields?.callCycles?.[dataRow.rowIndex]?.timeBetweenCalls})}>{errorFields?.callCycles?.[dataRow.rowIndex]?.timeBetweenCalls}</label>*/}
            </div>

        )
    }

    const typeTemplate = (data: any, dataRow : any) => {
        let errorTimeBetweenCalls = errorFields?.callCycles?.[dataRow.rowIndex]?.timeBetweenCalls;
        let errorCountCalls = errorFields?.callCycles?.[dataRow.rowIndex]?.countCalls;
        let name = campaignDirectoriesPhoneType.find((item: { dictionaryId: any; }) => item.dictionaryId === data.typeCall);
        return (
            <div className='mt-3 relative'>
                {errorTimeBetweenCalls || errorCountCalls ? <label htmlFor="name" className={classNames('error-msg', {'p-error': errorTimeBetweenCalls || errorCountCalls})}>Відсутнє значення *</label> : ''}
                {campaignDirectoriesPhoneType && campaignDirectoriesPhoneType.length && <InputText value={name.name}
                                                                                                   style={{opacity: '1'}}
                                                                                                   className={classNames('', {'opacity-40': statusDone || !editType})}
                                                                                                   disabled
                />}
            </div>
        )
    }

    return (
        <div className="ring-cycle">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-8">
                    <DataTable value={cycles}
                               reorderableRows={false}
                               responsiveLayout="scroll"
                                     defaultSortOrder={1}
                        sortField="orderBy">
                        <Column key="type" columnKey="type" field="type" header="Тип контакту ліда" body={typeTemplate}/>
                        <Column key="countCalls" columnKey="countCalls" field="countCalls" header="Кількість спроб" body={countCallsTemplate}/>
                        <Column key="timeBetweenCalls" columnKey="timeBetweenCalls" field="timeBetweenCalls" header="Час між спробами хв." body={timeBetweenCallsTemplate}/>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default QueueACDSetting
