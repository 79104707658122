import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {Divider} from "primereact/divider";
import {checkPermissions} from "../../../../../redux/permissions/permissionsUtils";
import {setSelectedTrunkIndex, setTrunkDialogVisible, setTrunkErrorField} from "../../../../../redux/actions/actionsTrunk";
import {getTrunkById} from "../../../../../redux/api/apiTrunks";
import {setEditType} from "../../../../../redux/actions/actions";
import {InputText} from "primereact/inputtext";

const TrunkListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);
    const loader = useAppSelector(state => state.TrunksListReducer.detailsLoading);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const permissions = checkPermissions(rolesVision, 'Trunks');
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    useEffect(() => {
    }, []);

    const {
        update: hasDialerUpdatePermission,
    } = permissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fields = [
        {label: "ID", value: trunkData?.id},
        {label: "Стан", value: trunkData?.isActive ? 'Активна' : 'Не активна'},
        {label: "Опис", value: trunkData?.description || '- - -'},
    ];

    const openEditor = () => {
        dispatch(setSelectedTrunkIndex(trunkData.trunk?.id || props.selectedRowData));
        dispatch(setTrunkDialogVisible(true));
        dispatch(setTrunkErrorField(null));
        dispatch(getTrunkById(props.selectedRowData, jwtToken?.jwtToken));
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                        <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">ID: {trunkData?.id}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                {/*<p className="w-full">Оператори: {trunkData?.countOperators ? operatorsBodyTemplate() : '0 / 0'}</p>*/}
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">{trunkData?.name}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                {/*<p className="w-full">Стратегія операторів: {trunkData?.trunk.strategyOperators ? operatorsStrategyBodyTemplate() : '- - -'}</p>*/}
                            </div>
                        </div>
                        <div className="col-12 md:col-5 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                {/*<p className="w-full">Приоритет: {trunkData?.priority || '0'}</p>*/}
                            </div>
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{trunkData?.isActive ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                    openEditor();
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                                    'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            <p className="w-full">{trunkData.trunk?.name}</p>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && <>
                        {fields.map((field: any) => (
                            <React.Fragment key={field.label}>
                                <div className="field col-4 md:col-6 flex align-items-start m-0">
                                    <p>{field.label}</p>
                                </div>
                                <div className="field col-8 md:col-6 flex align-items-start m-0">
                                    <p>{field.value}</p>
                                </div>
                            </React.Fragment>
                        ))}
                        {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                            <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Список номерів:</label></div></div>
                            <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            <div className="field col-12 md:col-12 flex m-0 flex-column px-0">{trunkData?.phones?.length ? trunkData?.phones?.map((phone: { phoneNo: string}, index: number) => {
                                return <InputText key={index} disabled className="max-w-20rem mb-2 opacity-100 bg-transparent pl-0" value={phone?.phoneNo}/>
                            }) : '- - -'}</div>
                        </div>}
                    </>}
                    {windowWidth > 1248 &&
                        <React.Fragment>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex align-items-center m-0">

                                <div className="field col-12 md:col-6 flex align-items-center m-0 p-0">
                                    <p className="w-full">Список номерів</p>
                                </div>
                                {/*<div className="field col-12 md:col-3 flex align-items-center m-0 p-0">*/}
                                {/*    <p className="w-full">Бранч</p>*/}
                                {/*</div>*/}
                                <div className="field col-12 md:col-6 flex align-items-center m-0 p-0">
                                    <p className="w-full">Опис</p>
                                </div>
                            </div>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex m-0 scroll-detail-items">
                                {/*<div className="field col-12 md:col-3 flex m-0 p-0">*/}

                                {/*</div>*/}
                                <div className="field col-12 md:col-6 flex m-0 p-0 flex-column">
                                    {trunkData?.phones?.length ? trunkData?.phones?.map((phone: { phoneNo: string}, index: number) => {
                                        return <InputText key={index} disabled className="max-w-20rem mb-2 opacity-100 bg-transparent pl-0" value={phone?.phoneNo}/>
                                    }) : '- - -'}
                                </div>
                                <div className="field col-12 md:col-6 flex m-0 p-0">
                                    {trunkData?.description}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default TrunkListDetails;
