import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import AudioPlayerComponent from 'react-h5-audio-player';
import {Button} from "primereact/button";
import { Dialog } from 'primereact/dialog';
import axios from "axios";
import {setAudioField} from "../../../../redux/actions/actionsAudio";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

export default function TemplateDemo(props: any) {
    const dispatch = useAppDispatch();
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);
    const [file, setFile] = useState(null);
    const [audioSrc, setAudioSrc] = useState<any>(null);
    const audioPlayerRef = useRef(null);
    const fileInputRef = useRef<FileUpload>(null);
    const [playerKey, setPlayerKey] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const errorFields = useAppSelector(state => state.AudioListReducer.errorFields);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const rolePermissions = checkPermissions(rolesVision, 'Audio');
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle)

    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission
    } = rolePermissions;


    useEffect(() => {
        if (audioData?.audioTempUrl) {
            setAudioSrc(audioData?.audioTempUrl);
            setFile(audioData?.audioTempUrl);
        }
    }, []);

    useEffect(() => {
        if (file && audioPlayerRef.current) {
            // @ts-ignore
            audioPlayerRef.current.audio.current.pause();
            // @ts-ignore
            audioPlayerRef.current.audio.current.src = audioSrc;
            // @ts-ignore
            audioPlayerRef.current.audio.current.load();
        }
    }, [file]);

    const onFileClear = () => {
        setFile(null);
        setAudioSrc(null);
        setPlayerKey(prevKey => prevKey + 1);
        if (audioPlayerRef.current) {
            // @ts-ignore
            audioPlayerRef.current.audio.current.pause();
            // @ts-ignore
            audioPlayerRef.current.audio.current.currentTime = 0;
            // @ts-ignore
            audioPlayerRef.current.audio.current.src = '';
        }
        if (fileInputRef.current) {
            fileInputRef.current.clear();
            dispatch(setAudioField({ section: 'audioData', fieldName: 'audioFileName', value: '' }));
        } else {
            dispatch(setAudioField({ section: 'audioData', fieldName: 'audioFileName', value: '' }));
        }
    };

    const onFileSelect = (e: { files: string | any[]; }) => {
        onFileClear();
        if (e.files && e.files.length > 0) {
            const selectedFile = e.files[0];
            const newFile = audioData?.sss
                ? new File([selectedFile], audioData.audioName, { type: selectedFile.type })
                : selectedFile;

            setFile(newFile);
            // dispatch(setAudioIds(newFile));
            props.setAudioSrc(newFile)
            setAudioSrc(URL.createObjectURL(newFile));
            dispatch(setAudioField({ section: 'audioData', fieldName: 'audioFileName', value: newFile.name }));
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        dispatch(setAudioField({ section: 'audioData', fieldName: 'audioFileName', value: newName }));

        if (file) {
            // @ts-ignore
            const newFile = new File([file], newName, { type: file.type });
            // @ts-ignore
            setFile(newFile);
            setAudioSrc(URL.createObjectURL(newFile));
        }
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file-plus mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.8em', color: 'white' }} className="my-3 font-bold">
                    Завантажити файл
                </span>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className=" font-bold">
                    Перетягніть або виберіть файл
                </span>
            </div>
        );
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        onFileClear()
        callback();
    };


    const itemTemplate = (inFile: object, props: any) => {
        const file = inFile as File;
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '80%' }}>
                    <span className="flex flex-column text-left ml-3" style={{wordBreak: 'break-all'}}>
                        {file.name}
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const onFileUploadByLink = async () => {
        const responseUrl = await axios.get(`${audioSrc}`, {
            responseType: 'blob',
            headers: {
                cache: 'no-store',
                'Cache-Control': 'no-cache'
            }
        });

        const blob = new Blob([responseUrl.data], { type: responseUrl.headers['content-type'] });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${audioData?.audioName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    return (
        <div className="grid p-fluid">
            <div className="field col-10 md:col-4">
                <InputText
                    id="name"
                    disabled
                    className={classNames('opacity-100', {'p-invalid': errorFields?.audioFileName && !audioData?.audioFileName})}
                    value={!visible && audioData?.audioFileName && audioSrc ? audioData?.audioFileName : ''}
                    maxLength={150}
                    onChange={e => {
                        dispatch(setAudioField({ section: 'audioData', fieldName: 'audioName', value: e.target.value }));
                        handleNameChange(e);
                    }}
                />
                {errorFields?.audioFileName && !audioData?.audioFileName && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.audioFileName?.audioFileName}*</span></div>}
            </div>
            {!!file && <div className="field col-2 md:col-2">
                <Button icon="pi pi-times" disabled={!editType || !archiveTableToggle || audioData?.inSystem || !file} className="p-button-secondary" onClick={onFileClear}/>
            </div>}
            {!file && <div className="field col-2 md:col-2">
                <Button label="Завантажити файл" disabled={!editType || !archiveTableToggle || audioData?.inSystem} icon="pi pi-upload" onClick={() => setVisible(true)} />
            </div>}
            <div className="field col-12 md:col-4"></div>
            {!!file && !visible && <div className="field col-12 md:col-4 save-mp3 relative absolute mt-2">
                {hasRoleUpdatePermission && <Button
                    icon="pi pi-download"
                    onClick={onFileUploadByLink}
                    className={`absolute outline-none border-none shadow-none border-circle save-mp3 ml-4 text-lg ${audioPlayerRef ? 'opacity-100' : 'opacity-50'}`}
                />}
                <AudioPlayerComponent
                    key={playerKey}
                    ref={audioPlayerRef}
                    className="audio-player border-round-sm border-gray-600"
                    autoPlay={false}
                    src={audioSrc || null}
                    style={{border: '1px solid red', backgroundColor: 'rgba(255, 255, 255, 0.05)'}}
                />
            </div>}
                <Dialog header="Виберіть файл" visible={visible} style={{ width: '30vw' }} onHide={() => {if (!visible) return; setVisible(false); onFileClear()}}>
                    <FileUpload name="demo[]" url="/api/upload"
                                accept="audio/mpeg"
                                customUpload
                                className="shadow-none outline-none"
                                emptyTemplate={emptyTemplate}
                                chooseLabel="Завантажити файл"
                                auto
                                disabled={!editType || !archiveTableToggle || audioData?.inSystem}
                                maxFileSize={10485760}
                                onClear={onFileClear}
                                itemTemplate={itemTemplate}
                                uploadHandler={onFileSelect} />
                    <Button label="Зберегти та закрити" disabled={!file} onClick={() => {if (!visible) return; setVisible(false); }} className=" mt-4 ml-auto block"/>
                </Dialog>
        </div>
    );
}
