import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import AudioDialogInfo from "../AudioDialogInfo";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {setTrunkIds} from "../../../../redux/actions/actionsTrunk";
import AudioDialogManagement from "../DialogManagement";
import AudioDialogGeneralSettings from "../AudioDialogGeneralSettings";
import {setAudioDialogVisible, setAudioErrorField} from "../../../../redux/actions/actionsAudio";
import {archiveAudio, createAudio, updateAudio} from "../../../../redux/api/apiAudio";
import {validateAudioForm} from "../../../../redux/validator/validator";
import {ProgressBar} from "primereact/progressbar";
import {getItem} from "../../../../redux/cache/cacheService";
import {codes} from "../../../../redux/notificationCodes";
import {getNotificationMessage} from "../../../../redux/api/apiNotifications";

const AudioDialog = (props: any) => {
    const dispatch = useAppDispatch()
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);
    const dialogVisible = useAppSelector(state => state.AudioListReducer.audioDialogVisible);
    const createLoader = useAppSelector(state => state.AudioListReducer.createLoader);
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const dialerPermissions = checkPermissions(rolesVision, 'Audio');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [audioSrc, setAudioSrc] = useState<any>(null);
    const audioDeleteMessage = useAppSelector(state => state.NotificationsReducer.audioDeleteMessage);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const [visibleDeletedMessage, setVisibleDeletedMessage] = useState<any>(false);

    const {
        create: hasCreatePermission,
        update: hasUpdatePermission,
        delete: hasDeletePermission,
    } = dialerPermissions;

    let audio_delete_questions = getItem(codes.audio_delete_questions);

    const deletedAudioInfo = {
        audioId: audioData?.audioId
    };

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const audioDeleteQuestions = <div>{audioDeleteMessage ? audioDeleteMessage.text : audio_delete_questions ? audio_delete_questions : progressBar}</div>

    useEffect(() => {
        setActiveIndex(0);
    }, [dialogVisible]);

    useEffect(() => {
        setAudioSrc(null);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

    }, [dialogVisible]);

    useEffect(() => {
        if (audioData?.phones?.length) {
            dispatch(setTrunkIds(audioData?.phones));
        } else {
            dispatch(setTrunkIds(null));
        }
    }, [dialogVisible]);

    useEffect(() => {
        if (errorFields && errorFields?.nameUniq) {
            setActiveIndex(0);
        }
    }, [errorFields])

    const closeDialogVisible = () => {
        dispatch(setAudioDialogVisible(false));
        dispatch(setAudioErrorField(null));
    }

    const updateAudioFile = (type: string) => {
        const formData = new FormData();
        formData.append('AudioName', audioData?.audioName);
        formData.append('IsActive', audioData?.isActive);
        formData.append('Description', audioData?.description || '');
        formData.append('Audio', audioSrc || null);
        formData.append('AudioId', audioData?.audioId);
        formData.append('IsHold', audioData?.isHold);
        formData.append('IsRingback', audioData?.isRingback);
        const validationErrors = validateAudioForm(audioData);

        if (!validationErrors) {
            dispatch(updateAudio(formData, jwtToken?.jwtToken));
            setAudioSrc(null);

            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setAudioErrorField(validationErrors));
            if (validationErrors.audioName || validationErrors.audioFileName || validationErrors?.description) {
                setActiveIndex(0);
            }
        }
    };

    const createAudioFile = (type: string) => {
        const formData = new FormData();
        formData.append('AudioName', audioData?.audioName);
        formData.append('IsActive', audioData?.isActive);
        formData.append('Description', audioData?.description || '');
        formData.append('Audio', audioSrc);
        formData.append('IsHold', audioData?.isHold);
        formData.append('IsRingback', audioData?.isRingback);

        const validationErrors = validateAudioForm(audioData);

        if (!validationErrors) {
            dispatch(createAudio(formData, jwtToken?.jwtToken));
            setAudioSrc(null);
            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setAudioErrorField(validationErrors));
            if (validationErrors.audioName || validationErrors.audioFileName || validationErrors?.description) {
                setActiveIndex(0);
            }
        }
    };

    let audioDeletedMessage = getItem(codes.audio_delete_questions);
    const showDeletionNotification = () => {
        if (!audioDeletedMessage && jwtToken?.jwtToken) {
            dispatch(getNotificationMessage(user?.userId, 219, user));
        }
        setShowDeleteNotification(true);
    }

    const showSaveChangesNotification = () => {
        dispatch(setAudioDialogVisible(false));
    }

    const setActiveTabIndex = (e: any) => {
        setActiveIndex(e.index);
    }

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                {hasDeletePermission && audioData?.audioId && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                       className="p-button-outlined"
                                                                       label="Видалити"
                                                                       onClick={showDeletionNotification}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        loading={createLoader}
                        onClick={() => {
                            showSaveChangesNotification()
                        }}
                />
                {hasCreatePermission && !audioData?.audioId && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                             className=""
                                                                             label="Створити та зберегти"
                                                                             loading={createLoader}
                                                                             onClick={() => {
                                                                                 createAudioFile('')
                                                                             }}
                />}
                {hasUpdatePermission && audioData?.audioId && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                            label="Зберегти"
                                                                            loading={createLoader}
                                                                            onClick={() => {
                                                                                updateAudioFile('')
                                                                            }}
                />}
            </div>
        </div>
    );

    return (
        <Dialog
            header="Налаштування аудіо"
            footer={archiveTableToggle ? footer : ''}
            visible={dialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            className='dialogInfo'
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh", zIndex: '1101px !important'}}
            onHide={() => showSaveChangesNotification()}
        >
            {audioData && <div className="company-info-popup">
                <AudioDialogInfo/>
                <Divider style={{margin: '0 0 20px 0'}}/>
                {audioData?.audioId &&
                    <>
                        <AudioDialogManagement/>
                        <Divider style={{margin: '0 0 20px 0'}}/>
                    </>
                }
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveTabIndex(e)}>
                    <TabPanel header="Загальні налаштування">
                        {audioData && <AudioDialogGeneralSettings audioSrc={audioSrc} setAudioSrc={setAudioSrc}/>}
                    </TabPanel>
                </TabView>
            </div>}
            <ConfirmDialog visible={showDeleteNotification} onHide={() => setShowDeleteNotification(false)} message={audioDeleteQuestions}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                dispatch(archiveAudio(deletedAudioInfo.audioId, jwtToken?.jwtToken));
                if (deletedAudioInfo.audioId === props.selectedRowData) {
                    props.setSelectedRowData(null);
                }
                props.setSelectedRowData(null);
                dispatch(setAudioDialogVisible(false));
            }
            }/>
        </Dialog>
    )
}

export default AudioDialog
