import React from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Button} from "primereact/button";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {setArchiveTrunkTableToggle, setTrunkClearFilters} from "../../../../redux/actions/actionsTrunk";

const TrunkListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const trunkPermissions = checkPermissions(rolesVision, 'Trunks');

    const {
        // create: hasCreatePermission,
    } = trunkPermissions;

    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => {
                    dispatch(setArchiveTrunkTableToggle(true))
                }}
                        label="Транки"
                        className={archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                {/*<Button onClick={() => {*/}
                {/*    dispatch(setArchiveTrunkTableToggle(false))*/}
                {/*}}*/}
                {/*        label="Архів"*/}
                {/*        className={archiveTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}*/}
                {/*/>*/}
            </div>
            <div className="desktop">
                {/*{hasCreatePermission && (<Button label="Створити транк"*/}
                {/*                                       icon="pi pi-plus"*/}
                {/*                                       className="mr-2 mb-2"*/}
                {/*                                       onClick={() => {*/}
                {/*                                           dispatch(initialTrunkData());*/}
                {/*                                           dispatch(setTrunkDialogVisible(true));*/}
                {/*                                           dispatch(setTrunkErrorField(null));*/}
                {/*                                           props.setSelectedRowData(null);*/}
                {/*                                           dispatch(setArchiveTrunkTableToggle(true));*/}
                {/*                                           if (hasCreatePermission) {*/}
                {/*                                               dispatch(setEditType(true));*/}
                {/*                                           } else {*/}
                {/*                                               dispatch(setEditType(false));*/}
                {/*                                           }*/}
                {/*                                       }}*/}
                {/*/>)}*/}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    dispatch(setTrunkClearFilters());
                    props.handleReset()
                }}/>
            </div>
            <div className="mobile">
                {/*{hasCreatePermission && (*/}
                {/*    <Button icon="pi pi-plus"*/}
                {/*            className="mr-2 mb-2"*/}
                {/*            onClick={() => {*/}
                {/*                dispatch(initialTrunkData());*/}
                {/*                dispatch(setTrunkDialogVisible(true));*/}
                {/*                dispatch(setTrunkErrorField(null));*/}
                {/*                props.setSelectedRowData(null);*/}
                {/*                dispatch(setArchiveTrunkTableToggle(true));*/}
                {/*                if (hasCreatePermission) {*/}
                {/*                    dispatch(setEditType(true));*/}
                {/*                } else {*/}
                {/*                    dispatch(setEditType(false));*/}
                {/*                }*/}
                {/*            }}*/}
                {/*    />)}*/}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setTrunkClearFilters())}/>
            </div>
        </div>
    )
}

export default TrunkListNav
