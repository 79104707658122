import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";
import TemplateDemo from "../UploadAudio";
import {setAudioField} from "../../../../redux/actions/actionsAudio";
import {Checkbox} from "primereact/checkbox";

const AudioDialogGeneralSettings = (props: any) => {
    const dispatch = useAppDispatch();
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);
    const errorFields = useAppSelector(state => state.AudioListReducer.errorFields);
    const nameField = useRef(null);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle)
    const [RingbackChanged, setRingbackChanged] = useState(false);
    const [holdChanged, setHoldChanged] = useState(false);
    const createLoader = useAppSelector(state => state.AudioListReducer.createLoader);

    useEffect(() => {
    }, []);

    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!audioData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (errorFields?.nameUniq) {
            scrollIntoNameView();
        }
    }, [errorFields, audioData]);

    useEffect(() => {
        if (!audioData.isActive) {
            if (holdChanged) {
                dispatch(setAudioField({section: 'audioData', fieldName: 'isHold', value: false}));
            }
            if (RingbackChanged) {
                dispatch(setAudioField({section: 'audioData', fieldName: 'isRingback', value: false}));
            }
        }
    }, [audioData.isActive]);

    useEffect(() => {
        if (holdChanged) {
            setHoldChanged(false);
        }
        if (RingbackChanged) {
            setRingbackChanged(false);
        }
    }, [createLoader]);

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6 mb-0" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.audioName || errorFields?.nameUniq})}>Назва аудіо *</label>
                    <InputText
                        id="name"
                        value={audioData?.audioName || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.audioName || errorFields?.nameUniq})}
                        onChange={e => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'audioName', value: e.target.value}));
                        }}
                        disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                    />
                    {errorFields?.nameUniq && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.nameUniq}*</span></div>}
                </div>
                <div className="field col-12 md:col-1 m-0 p-0"></div>
                <div className="field col-12 md:col-2 m-0 p-0 pl-2">
                    <label className="">По замовчуванню для:</label>
                    <div className="flex justify-content-between col-12 md:col-12 p-0">
                        <p className="mb-1">HOLD</p>
                        <Checkbox disabled={!archiveTableToggle || !editType || (audioData?.isHold && !holdChanged) || !audioData?.isActive} checked={audioData?.isHold} onChange={e => {
                            setHoldChanged(true)
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isHold', value: e.checked}));
                        }}/>
                    </div>
                    <div className="flex justify-content-between col-12 md:col-12 p-0">
                        <p className="mb-0">RINGBACK</p>
                        <Checkbox disabled={!archiveTableToggle || !editType || !audioData?.isActive || (audioData?.isRingback && !RingbackChanged)} checked={audioData?.isRingback} onChange={e => {
                            setRingbackChanged(true);
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isRingback', value: e.checked}));
                        }}/>
                    </div>
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description" className={classNames('', {'p-error': errorFields?.description})}>Коментар*</label>
                    <InputTextarea
                        id="description"
                        value={audioData?.description || ''}
                        onChange={e => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'description', value: e.target.value}));
                        }}
                        className={classNames('', {'p-invalid': errorFields?.description})}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                    />
                </div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-12 mb-0">
                    <TemplateDemo audioSrc={props.audioSrc} setAudioSrc={props.setAudioSrc}/>
                </div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-12">
                    <label htmlFor="name" className="pl-2">Стан</label>
                    <div className="field-radiobutton my-4 pl-1">
                        <label htmlFor="name" className="mr-2 w-6rem">Не активна</label>
                        <InputSwitch
                            disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                            className="mr-3"
                            checked={audioData.isActive} onChange={(e) => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isActive', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AudioDialogGeneralSettings;
