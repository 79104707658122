import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {useNavigate} from "react-router-dom";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {setUserErrorFields, setUserField} from "../../redux/actions/actionsUsersList";
import {Button} from "primereact/button";
import {validateRecoveryEmail} from "../../redux/validator/validator";
import {sendRecoveryPassword} from "../../redux/api/apiUsersList";

const RecoveryPassEmailPage = () => {
    const dispatch = useAppDispatch();
    const rolesError = useAppSelector<any>(state => state.RolesReducer.error);
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const [error, setError] = useState<any>(null);
    // @ts-ignore
    const changePasswordLoading = useAppSelector(state => state.UsersListReducer.changePasswordLoading);
    // @ts-ignore
    const changePasswordSuccess = useAppSelector(state => state.UsersListReducer.changePasswordSuccess);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    let userReducer = useAppSelector(state => state.UsersReducer);

    useEffect(() => {
        dispatch(setUserField({section: 'password', fieldName: 'confirmationPassword', value: ''}));
        dispatch(setUserField({section: 'password', fieldName: 'newPassword', value: ''}));
        dispatch(setUserErrorFields(null));
    }, []);

    useEffect(() => {
        if (changePasswordSuccess) {
            navigate('/login');
        }
    }, [changePasswordSuccess]);

    useEffect(() => {
        if (rolesError) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }
    }, [rolesError]);

    const [userRecoveryData, setRecoveryData] = useState<any>('');

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let validateError = validateRecoveryEmail(userRecoveryData);
        setError(validateError);
        if (!validateError?.name ) {
            dispatch(sendRecoveryPassword(userRecoveryData));
            setError(null);
            dispatch(setUserErrorFields(null));
        }
    };

    return (
        <div className="login-body">
            <Toast position="bottom-right" ref={toast}/>
            <div className="login-image">
                <img src={`assets/layout/images/pages/newpassword-ondark.png`} alt="atlantis"/>
            </div>

            <div className="login-panel p-fluid flex-column justify-content-center align-items-start">
                <div className="flex flex-column max-w-18rem">
                    <h3 className="mb-2">Забули пароль ?</h3>
                    <p className="text-sm">Введіть свою електронну адресу або ім'я користувача, щоб скинути пароль*</p>
                    <form className="field col-12 md:col-12 flex flex-column px-0 max-w-18rem" autoComplete="off">
                        <span className="p-input-icon-left">
                            <i className="pi pi-envelope"></i>
                            <InputText
                                value={userRecoveryData}
                                name="userName"
                                onChange={e => setRecoveryData(e.target.value)}
                                type="text" placeholder="username"
                                className={classNames('', {'p-invalid': (userReducer.statusCode && userReducer.statusCode !== 200) || error?.name || errorFields})}
                            />
                        </span>
                        {error && error?.name && <label htmlFor="address" className={classNames('mt-2 mb-0 text-xs inline', {'p-error': error?.name})}>{error?.name?.name}</label>}
                        {errorFields && errorFields?.name && errorFields?.name?.name && <label htmlFor="address" className={classNames('mt-3 mb-0 text-xs inline', {'p-error': errorFields?.name?.name})}>{errorFields?.name?.name}</label>}
                        <div className="field col-12 md:col-12 flex justify-content-between mt-4 px-0">
                            <Button loading={changePasswordLoading} icon="pi pi-ban" label="Відміна" onClick={() => navigate('/login')} className="p-button-outlined mr-2"/>
                            <Button loading={changePasswordLoading} icon="pi pi-save" label="Відправити" className="ml-2" onClick={handleSubmit}/>
                        </div>
                    </form>
                </div>
                <div className="login-footer flex align-items-center">
                    <span>Copyright {new Date().getFullYear()}</span>
                </div>
                </div>
            </div>
    );
};

export default RecoveryPassEmailPage;
