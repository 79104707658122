import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../../redux/hooks";

const AudioDialogInfo = () => {
    const audioData = useAppSelector(state => state.AudioListReducer.audioData)

    const nameBodyTemplate = (rowData: any) => {
        let name = rowData?.audioName;
        const truncateWithEllipses = (text: string, max: number) => {
            return text.substr(0,max-1)+(text.length>max?'...':'');
        }
        if (name) {name = truncateWithEllipses(name, 156)}
        return (
            <React.Fragment>
                <div className="campaignName">
                    <p>{name}</p>
                </div>
            </React.Fragment>
        );
    };

    return (
        <DataTable className="user-dialog-info" value={[audioData]}>
            <Column field="audioName" header="Назва" body={nameBodyTemplate} className="col-6"></Column>
            <Column field="campaignTypeId" header="Стан" body={rowData => rowData?.isActive ? 'Активна' : 'Не активна'} className="col-6"></Column>
        </DataTable>
    )
}

export default AudioDialogInfo;
