export const ARCHIVE_QUEUE_TABLE_TOGGLE = 'ARCHIVE_QUEUE_TABLE_TOGGLE'
export const QUEUE_DIALOG_VISIBLE = 'COMPANIES_LIST/DIALOG_VISIBLE'
export const QUEUE_CLEAR_FILTERS = 'QUEUE_CLEAR_FILTERS'
export const SELECTED_QUEUE_ID = 'SELECTED_QUEUE_ID'
export const QUEUE_FIELD = 'QUEUE_FIELD'
export const QUEUE_ACD_FIELD = 'QUEUE_ACD_FIELD'
export const SHOW_QUEUE_LOADER = 'SHOW_QUEUE_LOADER'
export const HIDE_QUEUE_LOADER = 'HIDE_QUEUE_LOADER'
export const INITIAL_QUEUE_DATA = 'INITIAL_QUEUE_DATA'
export const GET_QUEUES = 'GET_QUEUES'
export const GET_QUEUE = 'GET_QUEUE'
export const SET_QUEUE_ERROR_FIELDS = 'SET_QUEUE_ERROR_FIELDS';
export const GET_QUEUE_PAGINATION = 'GET_QUEUE_PAGINATION'
export const SET_QUEUE_CURRENT_PAGE = 'SET_QUEUE_CURRENT_PAGE'
export const SET_QUEUE_CURRENT_SIZE = 'SET_QUEUE_CURRENT_SIZE'
export const SET_QUEUE_LOADING = 'SET_QUEUE_LOADING'
export const SHOW_QUEUE_DETAIL_LOADING = 'SHOW_QUEUE_DETAIL_LOADING'
export const SET_QUEUE_TRANK_ID = 'SET_QUEUE_TRANK_ID'
export const ADD_QUEUE_USER = 'ADD_QUEUE_USER'
export const DELETE_QUEUE_USER = 'DELETE_QUEUE_USER'
export const UPDATE_SCHEDULER_TIME = 'UPDATE_SCHEDULER_TIME'
export const TOGGLE_DAY_ACTIVE = 'TOGGLE_DAY_ACTIVE'
export const CHANGE_QUEUE_USER_LEVEL = 'CHANGE_QUEUE_USER_LEVEL'
export const GET_QUEUE_DIRECTORIES_QUEUE_TYPE = 'GET_QUEUE_DIRECTORIES_QUEUE_TYPE'
export const GET_QUEUE_DIRECTORIES_QUEUE_OPERATORS_TYPE = 'GET_QUEUE_DIRECTORIES_QUEUE_OPERATORS_TYPE'
export const GET_QUEUE_DIRECTORIES_QUEUE_AUDIO_TYPE = 'GET_QUEUE_DIRECTORIES_QUEUE_AUDIO_TYPE'
export const GET_QUEUE_DIRECTORIES_QUEUE_DIRECTIONS_TYPE = 'GET_QUEUE_DIRECTORIES_QUEUE_DIRECTIONS_TYPE'
export const SET_TRANK_IDS = 'SET_TRANK_IDS'
export const GET_QUEUE_DIRECTORIES_TRANKS_PHONE = 'GET_QUEUE_DIRECTORIES_TRANKS_PHONE'
export const GET_ALL_QUEUES = 'GET_ALL_QUEUES'
export const CHANGE_QUEUES_SKILL_GROUP_LEVEL = 'CHANGE_QUEUES_SKILL_GROUP_LEVEL';
export const DELETE_QUEUES_SKILL_GROUP = 'DELETE_QUEUES_SKILL_GROUP';
export const ADD_QUEUES_SKILL_GROUP = 'ADD_QUEUES_SKILL_GROUP';
export const CHANGE_UTILIZATION_INDEX = 'CHANGE_UTILIZATION_INDEX';
export const DELETE_UTILIZATION_SKILL_GROUP = 'DELETE_UTILIZATION_SKILL_GROUP';
export const ADD_UTILIZATION_SKILL_GROUP = 'ADD_UTILIZATION_SKILL_GROUP'
export const DELETE_QUEUE_BRANCH = 'DELETE_QUEUE_BRANCH'
