import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Skeleton} from "primereact/skeleton";
import {addSkillGroup, addUser, changeCampaignUserLevel, changeSkillGroupLevel, deleteSkillGroup, deleteUser, setChangedCompaniesData, setCompanyField, setEditType} from "redux/actions/actions";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {getCampaignDirectoriesCampaignStrategy} from "redux/api/api";
import React, {useEffect, useRef, useState} from "react";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {getUserDetails} from "../../../redux/api/apiUsersList";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import UserListDialog from "../../Settings/UsersList/UserDialod";
import {getQualificationGroupDetails} from "../../../redux/api/apiQualificationGroup";
import QualificationGroupDialog from "../../QualificationGroupList/QualificationGroupDialog";

const CompaniesListDialogUsers = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData)
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    const skillGroupList = useAppSelector(state => state.QualificationGroupListReducer.skillGroupList)
    // @ts-ignore
    const companiesDirectoryCampaignStrategy = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryCampaignStrategy)
    // @ts-ignore
    const selectedCompanyId = useAppSelector(state => state.CompaniesListReducer.selectedCompanyId)
    // @ts-ignore
    const loadingStrategyDict = useAppSelector(state => state.CompaniesListReducer.loadingStrategyDict)
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle)
    const statusDone = companiesData?.campaign?.statusId === 6;
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const inputRef = useRef<any>(null);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const detailsLoading = useAppSelector(state => state.UsersListReducer.detailLoading);
    const skillGroupDetailsLoading = useAppSelector(state => state.QualificationGroupListReducer.detailsLoading);
    const skillGroupPermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    useEffect(() => {
        dispatch(getCampaignDirectoriesCampaignStrategy(jwtToken?.jwtToken));
    }, []);

    const {
        update: hasUsersManagerUpdatePermission,
        visible: hasUsersManagerVisiblePermission
    } = usersManagerPermissions;

    const {
        visible: hasRoleVisionPermission,
        update: hasRoleUpdatePermission,
    } = skillGroupPermissions;

    // @ts-ignore
    const skillGroupDataWithLevel = skillGroupList?.map(({isActive, ...data}) => {
        if (companiesData?.campaign?.campaignId) {
            return {
                ...data,
                skillGroupLevel: 0,
                skillGroupId: data.id,
                campaignId: companiesData?.campaignId?.campaignId
            };
        } else {
            return {
                ...data,
                skillGroupLevel: 0,
                skillGroupId: data.id,
            };
        }
    });

    const isSkillGroupIdPresent = companiesData?.skillGroups?.some((skillGroup: { skillGroupId: any; }) =>
        skillGroupDataWithLevel?.some((levelGroup: { id: any; }) => levelGroup.id === skillGroup.skillGroupId)
    );

    const skillGroupLevels: any = {};
    companiesData?.skillGroups?.forEach((user: { skillGroupId: string | number; skillGroupLevel: any; }) => {
        skillGroupLevels[user.skillGroupId] = user.skillGroupLevel;
    });

    skillGroupDataWithLevel.forEach((user: { skillGroupId: string | number; skillGroupLevel: any; }) => {
        if (skillGroupLevels[user.skillGroupId] !== undefined) {
            user.skillGroupLevel = skillGroupLevels[user.skillGroupId];
        }
    });

    const usersDataWithUserLevel = usersData?.map((userData: any) => {
        return {
            ...userData,
            userLevel: 0,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? ` ${userData.middleName}` : ''}`
        }
    });

    const userLevels: any = {};
    companiesData?.campaignUsers?.forEach((user: { userId: string | number; userLevel: any; }) => {
        userLevels[user.userId] = user.userLevel;
    });

    usersDataWithUserLevel?.forEach((user: { userId: string | number; userLevel: any; }) => {
        if (userLevels[user.userId] !== undefined) {
            user.userLevel = userLevels[user.userId];
        }
    });

    const filteredUsers = companiesData?.campaignUsers?.filter((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    ) || [];

    const isUsersIdPresent = companiesData?.campaignUsers?.some((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    );

    const userItems = companiesData?.campaignUsers?.map((companiesItem: any) => {
        return usersDataWithUserLevel.map((userItem: any, index: number) => {
            if (companiesItem.userId === userItem.userId) {
                const userLevelError = errorFields?.campaignUsers?.campaignUsersWithoutLevel?.find((user: { userId: any; }) => user.userId === companiesItem.userId);

                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasUsersManagerVisiblePermission && <Button
                            className="max-w-4rem p-button-secondary mr-3"
                            style={{minWidth: '40px'}}
                            loading={detailsLoading && currentUserId === userItem.userId}
                            disabled={!archiveTableToggle || detailsLoading}
                            icon={archiveTableToggle && hasUsersManagerUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasUsersManagerUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(userItem.userId);
                                dispatch(getUserDetails(userItem.userId, jwtToken?.jwtToken, true));
                                if (hasUsersManagerUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {userItem.lastName} {userItem.firstName} {userItem.middleName}
                        </div>
                        <InputNumber
                            ref={inputRef}
                            value={userItem?.userLevel}
                            className={classNames('', {'p-invalid': errorFields?.campaignUsers && userLevelError})}
                            disabled={!archiveTableToggle || statusDone || !editType}
                            min={0} max={1000}
                            useGrouping={false}
                            onChange={(e) => dispatch(changeCampaignUserLevel({campaignId: companiesData?.campaign?.campaignId, userId: userItem.userId, userLevel: e.value}))}
                        />
                        <Button name={userItem.userId}
                                onClick={() => {
                                    dispatch(deleteUser(userItem.userId));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                                disabled={!archiveTableToggle || statusDone || !editType}
                        />
                    </div>
                )
            }
        })
    });

    const skillGroupUsers = companiesData?.skillGroups?.map((companiesItem: any) => {
        const user = skillGroupList.find((userItem: any) => userItem.id === companiesItem.skillGroupId);
        const {...userWithoutCampaignId} = companiesItem;
        if (companiesData?.campaign?.campaignId) {
            return {
                ...userWithoutCampaignId,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name,
                campaignId: companiesData?.campaignId?.campaignId
            }
        } else {
            return {
                ...userWithoutCampaignId,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name
            }
        }
    })

    const filteredSkillGroups = skillGroupUsers?.filter((skillGroup: { skillGroupId: any; }) =>
        skillGroupDataWithLevel?.some((levelGroup: { id: any; }) => levelGroup.id === skillGroup.skillGroupId)
    ) || [];

    const skillGroupItems = filteredSkillGroups?.map((item: any) => {
        return skillGroupDataWithLevel.map((skillGroup: any, index: number) => {
            if (item.skillGroupId === skillGroup.skillGroupId) {
                const userLevelError = errorFields?.campaignUsers?.campaignUsersWithoutLevel?.find((user: { userId: any; }) => user.userId === item.userId);

                return (
                    <div key={index} className="flex align-items-center mb-2 p-0">
                        {hasRoleVisionPermission && <Button
                            className="max-w-4rem p-button-secondary mr-3"
                            style={{minWidth: '40px'}}
                            loading={skillGroupDetailsLoading && currentUserId === skillGroup.skillGroupId}
                            disabled={!archiveTableToggle || skillGroupDetailsLoading}
                            icon={archiveTableToggle && hasRoleUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasRoleUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(skillGroup.skillGroupId);
                                dispatch(getQualificationGroupDetails(skillGroup.skillGroupId, jwtToken?.jwtToken, true));
                                if (hasRoleUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {skillGroup.name}
                        </div>
                        <InputNumber
                            ref={inputRef}
                            value={skillGroup?.skillGroupLevel}
                            className={classNames('', {'p-invalid': errorFields?.campaignUsers && userLevelError})}
                            disabled={!archiveTableToggle || !editType}
                            min={0} max={1000}
                            useGrouping={false}
                            onChange={(e) => dispatch(changeSkillGroupLevel({campaignId: companiesData?.campaign?.campaignId, skillGroupId: skillGroup.skillGroupId, skillGroupLevel: e.value}))}
                        />
                        <Button name={skillGroup.skillGroupId}
                                onClick={() => {
                                    dispatch(deleteSkillGroup(skillGroup.skillGroupId));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                                disabled={!archiveTableToggle || !editType}
                        />
                    </div>
                )
            }
        })
    });

    function randomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const campaignStrategyItemTemplate = (option: any) => {
        let typeName = companiesDirectoryCampaignStrategy.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                {loadingStrategyDict && <Skeleton width={`${randomNumber(50, 85)}%`} height="1rem"/>}
                {!loadingStrategyDict && <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>}
            </div>
        );
    }

    const selectedCampaignStrategyTemplate = (option: any) => {
        let typeName = companiesDirectoryCampaignStrategy.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            );
        }

        return "Оберіть тип";
    }

    const usersDataItemTemplate = (option: any) => {
        return usersData.map((item: any) => {
            if (item.userId === option.userId) {
                return `${item.lastName} ${item.firstName} ${item?.middleName ? ` ${item.middleName}` : ''}`
            }
        })
    }

    const skillGroupDataItemTemplate = (option: any) => {
        return skillGroupList.map((item: any) => {
            if (item.id === option.skillGroupId) {
                return item.name
            }
        })
    }


    const campaignUsers = companiesData?.campaignUsers?.map((companiesItem: any) => {
        const user = usersData.find((userItem: any) => userItem.userId === companiesItem.userId);
        const {campaignId, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            lastName: user?.lastName,
            firstName: user?.firstName,
            middleName: user?.middleName,
            userName: user?.userName,
            fullName: `${user?.lastName} ${user?.firstName} ${user?.middleName ? ` ${user.middleName}` : ''}`,
            userId: user?.userId,
        }
    })

    const addSkillGroupData = (e: any) => {
        const filteredEValue = e.value?.filter((item: { skillGroupId: any; }) => item.skillGroupId);
        const existingIds = new Set(filteredEValue.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const skillGroupData = new Set(skillGroupDataWithLevel.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const newItems = companiesData?.skillGroups.filter((item: { skillGroupId: any; }) =>
            !existingIds.has(item.skillGroupId) && !skillGroupData.has(item.skillGroupId));
        const updatedEValue = [...filteredEValue, ...newItems];

        // @ts-ignore
        dispatch(addSkillGroup({campaignId: selectedCompanyId, skillGroupId: updatedEValue}));
    }

    const changeCampaignUsers = (e: any) => {
        const filteredEValue = e.value?.filter((item: { userId: any; }) => item.userId);
        const existingIds = new Set(filteredEValue.map((item: { userId: any; }) => item.userId));
        const skillGroupData = new Set(usersDataWithUserLevel.map((item: { userId: any; }) => item.userId));
        const newItems = companiesData?.campaignUsers?.filter((item: { userId: any; }) =>
            !existingIds.has(item.userId) && !skillGroupData.has(item.userId));
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addUser({campaignId: selectedCompanyId, userId: updatedEValue}))
    }

    return (
        <div className="users">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-5">
                    {/*<h6>Користувачі:</h6>*/}
                    <div className="field-radiobutton">
                        <h6 className="mr-2 mb-0">Оператори</h6>
                        <InputSwitch checked={companiesData.campaign.isSkillGroup}                             disabled={!archiveTableToggle || statusDone || !editType}
                                     onChange={(e) => {
                            dispatch(setCompanyField({section: 'campaign', fieldName: 'isSkillGroup', value: e.value}));
                        }}/>
                        <h6 className="my-0 ml-2">Група кваліфікацій</h6>
                    </div>
                    {!companiesData.campaign.isSkillGroup && <div>
                        <MultiSelect
                            appendTo="self"
                            value={campaignUsers}
                            options={usersDataWithUserLevel}
                            onChange={(e) => {
                                dispatch(setChangedCompaniesData(true));
                                changeCampaignUsers(e);                            }}
                            itemTemplate={usersDataItemTemplate}
                            placeholder="Оберіть користувачів"
                            className={classNames('field col-12 md:col-12 p-0', {'p-invalid': errorFields?.campaignUsers  && !campaignUsers?.length})}
                            filter
                            optionLabel="fullName"
                            selectedItemsLabel={isUsersIdPresent ? `Користувачив обрано: ${filteredUsers?.length}` : ""}
                            maxSelectedLabels={0}
                            scrollHeight="250px"
                            disabled={!archiveTableToggle || statusDone || !editType}
                        />
                        {
                            companiesData?.campaignUsers?.length && isUsersIdPresent ?
                                <div className="users-list">
                                    {userItems}
                                </div>
                                :
                                <span className="no-users-message">Користувачив не обрано</span>
                        }
                    </div>}
                    {companiesData.campaign.isSkillGroup && <div>
                        <MultiSelect
                            appendTo="self"
                            value={skillGroupUsers}
                            options={skillGroupDataWithLevel}
                            onChange={(e) => {
                                dispatch(setChangedCompaniesData(true));
                                addSkillGroupData(e)
                            }}
                            itemTemplate={skillGroupDataItemTemplate}
                            placeholder="Оберіть групу"
                            className={classNames('field col-12 md:col-12 p-0', {'p-invalid': errorFields?.campaignUsers  && !skillGroupUsers?.length})}
                            filter
                            optionLabel="name"
                            selectedItemsLabel={isSkillGroupIdPresent ? `Груп обрано: ${filteredSkillGroups?.length}` : ""}
                            maxSelectedLabels={0}
                            scrollHeight="250px"
                            disabled={!archiveTableToggle || statusDone || !editType}
                        />
                        {
                            companiesData?.skillGroups?.length && isSkillGroupIdPresent ?
                                <div className="users-list">
                                    {skillGroupItems}
                                </div>
                                :
                                <span className="no-users-message">Груп не обрано</span>
                        }
                    </div>}
                    <div className="grid col-12 md:col-12">
                        {errorFields?.campaignUsers && <div className="col-12 md:col-12 p-0 m-0 pt-2 mb-2 w-full"><span className="title p-error text-sm">{errorFields?.campaignUsers?.campaignUsers}</span></div>}
                    </div>
                </div>
                <div className="field col-12 md:col-3">
                    <h6>Стратегія оператор:</h6>
                    <Dropdown id="type"
                              appendTo="self"
                              value={companiesData.campaign.operatorStrategyTypeId}
                              options={companiesDirectoryCampaignStrategy.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                              onChange={e => {
                                  dispatch(setChangedCompaniesData(true));
                                  dispatch(setCompanyField({section: 'campaign', fieldName: 'operatorStrategyTypeId', value: e.target.value}))
                              }}
                              itemTemplate={campaignStrategyItemTemplate}
                              valueTemplate={selectedCampaignStrategyTemplate}
                              disabled={!archiveTableToggle || statusDone || !editType}
                              placeholder="Оберіть тип"
                    />
                </div>
            </div>
            <UserListDialog/>
            <QualificationGroupDialog/>
        </div>
    )
}

export default CompaniesListDialogUsers
