export const ARCHIVE_BRANCH_TABLE_TOGGLE = 'ARCHIVE_BRANCH_TABLE_TOGGLE';
export const BRANCH_DIALOG_VISIBLE = 'BRANCH_DIALOG_VISIBLE';
export const SET_BRANCHES_LOADING = 'SET_BRANCHES_LOADING';
export const SELECTED_BRANCH_ID = 'SELECTED_BRANCH_ID';
export const BRANCH_FIELD = 'BRANCH_FIELD';
export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_USER_BRANCHES_LIST = 'GET_USER_BRANCHES_LIST';
export const GET_BRANCH_PAGINATION = 'GET_BRANCH_PAGINATION';
export const SET_BRANCH_CURRENT_PAGE = 'SET_BRANCH_CURRENT_PAGE';
export const SET_BRANCH_CURRENT_SIZE = 'SET_BRANCH_CURRENT_SIZE';
export const GET_BRANCHES_ARCHIVED = 'GET_BRANCHES_ARCHIVED';
export const GET_BRANCH = 'GET_BRANCH';
export const SHOW_BRANCH_LOADER = 'SHOW_BRANCH_LOADER';
export const HIDE_BRANCH_LOADER = 'HIDE_BRANCH_LOADER';
export const SHOW_BRANCH_DETAILS_LOADER = 'SHOW_BRANCH_DETAILS_LOADER';
export const HIDE_BRANCH_DETAILS_LOADER = 'HIDE_BRANCH_DETAILS_LOADER';
export const INITIAL_BRANCHES_DATA = 'INITIAL_BRANCHES_DATA';
export const GET_BRANCHES_OWNERS = 'GET_BRANCHES_OWNERS';
export const GET_BRANCHES_ACD = 'GET_BRANCHES_ACD';
export const TOGGLE_USER_LIDER = 'TOGGLE_USER_LIDER';
export const SET_BRANCH_ERROR_FIELDS = 'SET_BRANCH_ERROR_FIELDS';
export const SHOW_BRANCH_DELETE_MESSAGE = 'SHOW_BRANCH_DELETE_MESSAGE';
export const SET_BRANCH_RESPONSE_MESSAGE = 'SET_BRANCH_RESPONSE_MESSAGE';
export const SHOW_BRANCH_CREATED_MESSAGE = 'SHOW_BRANCH_CREATED_MESSAGE';
export const SHOW_BRANCH_UPDATED_MESSAGE = 'SHOW_BRANCH_UPDATED_MESSAGE';
export const SHOW_BRANCH_ERROR_DELETE_MESSAGE = 'SHOW_BRANCH_ERROR_DELETE_MESSAGE';
export const SHOW_BRANCH_ERROR_CREATED_MESSAGE = 'SHOW_BRANCH_ERROR_CREATED_MESSAGE';
export const SHOW_BRANCH_ERROR_UPDATED_MESSAGE = 'SHOW_BRANCH_ERROR_UPDATED_MESSAGE';
export const ADD_SKILL_GROUP_FOR_BRANCH = 'ADD_SKILL_GROUP_FOR_BRANCH';
export const DELETE_SKILL_GROUP_FROM_BRANCH = 'DELETE_SKILL_GROUP_FROM_BRANCH';
export const ADD_ROLES_FOR_BRANCH = "ADD_ROLES_FOR_BRANCH";
export const DELETE_ROLE_FROM_BRANCH = "DELETE_ROLE_FROM_BRANCH"
