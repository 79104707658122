import {SET_STATE_DIALOG, SET_USER_FIELD} from "../types/typesUser";
import {SET_USER_STATUS} from "../types/typesUsersList";

export function setUserField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: SET_USER_FIELD,
        payload: state,
    }
}

export function setUserStatusField(status: any) {
    return {
        type: SET_USER_STATUS,
        payload: status,
    }
}

export function setDialogState(state: boolean) {
    return {
        type: SET_STATE_DIALOG,
        payload: state,
    }
}
