import React, {useEffect, useRef} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {classNames} from "primereact/utils";
import {setTrunkField} from "../../../../redux/actions/actionsTrunk";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";

const TrunkList = () => {
    const dispatch = useAppDispatch();
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);
    const errorFields = useAppSelector(state => state.TrunksListReducer.errorFields);
    const nameField = useRef(null);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle)

    useEffect(() => {
    }, []);

    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!trunkData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (errorFields?.nameUniq) {
            scrollIntoNameView();
        }
    }, [errorFields, trunkData]);

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name || errorFields?.nameUniq})}>Назва транка *</label>
                    <InputText
                        id="name"
                        value={trunkData?.name || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.name || errorFields?.nameUniq})}
                        onChange={e => {
                            dispatch(setTrunkField({section: 'trunk', fieldName: 'name', value: e.target.value}));
                        }}
                        disabled={!archiveTableToggle || !editType}
                    />
                    {errorFields?.nameUniq && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.nameUniq}*</span></div>}
                </div>
                <div className="field col-12 md:col-3 m-0 p-0">
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description">Коментар</label>
                    <InputTextarea
                        id="description"
                        value={trunkData?.description || ''}
                        onChange={e => {
                            dispatch(setTrunkField({section: 'trunk', fieldName: 'description', value: e.target.value}));
                        }}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || !editType}
                    />
                </div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-12">
                    <label htmlFor="name" className="pl-2">Стан</label>
                    <div className="field-radiobutton my-4 pl-1">
                        <label htmlFor="name" className="mr-2 w-6rem">Не активна</label>
                        <InputSwitch
                            disabled={!archiveTableToggle || !editType}
                            className="mr-3"
                            checked={trunkData.isActive} onChange={(e) => {
                            dispatch(setTrunkField({section: 'trunk', fieldName: 'isActive', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrunkList;
