import {
    ADD_TO_MONITORING_LIST,
    CLEAR_MONITORING_FOR_SIP,
    GET_AUDIO_URL,
    HIDE_LOADER,
    MONITORING_RELOAD_TOGGLE,
    SET_CURRENT_MONITORING_PAGE,
    SET_CURRENT_MONITORING_SIZE,
    SET_FIRST_PAGE,
    SET_MONITORING_FILTER,
    SHOW_EXPORT_LOADING,
    SHOW_LOADER,
    UPDATE_SORT_FIELDS
} from "../types/typesMonitoringList";
import {HIDE_ERROR} from "../types/types";

export function setCurrentPage(state: any) {
    return {
        type: SET_CURRENT_MONITORING_PAGE,
        payload: state
    }
}

export function setCurrentPageSize(state: any) {
    return {
        type: SET_CURRENT_MONITORING_SIZE,
        payload: state
    }
}

export function setPageForFirst(state: any) {
    return {
        type: SET_FIRST_PAGE,
        payload: state
    }
}

export function getAudioUrlForPlayer(state: any) {
    return {
        type: GET_AUDIO_URL,
        payload: state
    }
}

export function showExportLoader(state: boolean) {
    return {
        type: SHOW_EXPORT_LOADING,
        payload: state
    }
}

export function showLoader() {
    return {
        type: SHOW_LOADER,
    }
}

export function hideLoader() {
    return {
        type: HIDE_LOADER,
    }
}

export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function addToMonitoringList(item: any) {
    return {
        type: ADD_TO_MONITORING_LIST,
        payload: item
    };
}

export function clearMonitoringSipDetails() {
    return {
        type: CLEAR_MONITORING_FOR_SIP,
    };
}

export function autoReloadToggle(reload: boolean) {
    return {
        type: MONITORING_RELOAD_TOGGLE,
        payload: reload
    };
}

export function updateSortFields(updatedFields: any) {
    return {
        type: UPDATE_SORT_FIELDS,
        payload: updatedFields,
    };
}

export function setMonitoringFilter(payload: any) {
    return {
        type: SET_MONITORING_FILTER,
        payload: payload,
    };
}

