export const USERS_TABLE_TOGGLE = 'USERS_TABLE_TOGGLE'
export const USERS_DIALOG_VISIBLE = 'USERS_DIALOG_VISIBLE'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SELECTED_USER_ID = 'SELECTED_USER_ID'
export const USER_FIELD = 'USER_FIELD'
export const DELETE_USER = 'DELETE_USER'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_ERROR = 'SHOW_ERROR'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE'
export const HIDE_ERROR = 'HIDE_ERROR'
export const SHOW_USER_CREATE_MESSAGE = 'SHOW_USER_CREATE_MESSAGE'
export const SHOW_USER_SAVE_MESSAGE = 'SHOW_USER_SAVE_MESSAGE'
export const HIDE_USER_CREATE_MESSAGE = 'HIDE_USER_CREATE_MESSAGE'
export const HIDE_USER_SAVE_MESSAGE = 'HIDE_USER_SAVE_MESSAGE'
export const INITIAL_USER_DATA = 'INITIAL_USER_DATA'
export const USER_STATE_SAVE = 'USER_STATE_SAVE'
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const SHOW_USER_DETAIL_LOADING = 'SHOW_USER_DETAIL_LOADING'
export const GET_USER_ROLES = 'GET_USER_ROLES'
export const GET_USER_ALL_ROLES = 'GET_USER_ALL_ROLES'
export const GET_USER_BRANCHES = 'GET_USER_BRANCHES'
export const GET_USER_LANGUAGES = 'GET_USER__LANGUAGES'
export const GET_USER_PAGINATION = 'GET_USER_PAGINATION'
export const SHOW_USER_ERROR_FIELD = 'SHOW_USER_ERROR_FIELD'
export const GET_USER_COUNTRIES = 'GET_USER_COUNTRIES'
export const GET_USER_STATUSES = 'GET_USER_STATUSES'
export const SET_USER_ERROR_FIELDS = 'SET_USER_ERROR_FIELDS'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const SHOW_ERROR_FIELD = 'SHOW_ERROR_FIELD'
export const SHOW_UPDATED_ERROR = 'SHOW_UPDATED_ERROR'
export const SET_ERROR_FIELDS = 'SET_ERROR_FIELDS';
export const SET_USER_CURRENT_PAGE = 'SET_USER_CURRENT_PAGE'
export const SET_USER_CURRENT_SIZE = 'SET_USER_CURRENT_SIZE'
export const SHOW_USER_DELETE_MESSAGE = 'SHOW_COMPANY_DELETE_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
export const CHANGED_USER_DATA = 'CHANGED_USER_DATA'
export const SHOW_USER_ERROR = 'SHOW_USER_ERROR'
export const HIDE_USER_DELETE_MESSAGE = 'HIDE_USER_DELETE_MESSAGE'
export const SET_USER_DELETE_MESSAGE_SUCCESS = 'SET_USER_DELETE_MESSAGE_SUCCESS'
export const SET_USER_STATUS_SUCCESS = 'SET_USER_STATUS_SUCCESS'
export const SET_CHECK_USER_FOR_CALL = 'SET_CHECK_USER_FOR_CALL'
export const SET_CHECK_USER_FOR_BAN = 'SET_CHECK_USER_FOR_BAN'
export const SET_USER_STATUS_ERROR = 'SET_USER_STATUS_ERROR'
export const SET_USER_STATUS = 'SET_USER_STATUS'
export const SET_USER_LIST_DATA_SORT = 'SET_USER_LIST_DATA_SORT'
export const USERS_DIALOG_UNZIPPING = 'USERS_DIALOG_UNZIPPING'
export const SET_EDIT_TYPE = 'SET_EDIT_TYPE'
export const CHANGE_CAMPAIGN_USER_LEVEL = 'CHANGE_CAMPAIGN_USER_LEVEL'
export const GET_USERS_DIRECTORIES_TRUNKS_PHONE = 'GET_USERS_DIRECTORIES_TRUNKS_PHONE'
export const SET_USER_TRUNK_IDS = 'SET_USER_TRUNK_IDS'
export const GET_PHONES_DICTIONARY = 'GET_PHONES_DICTIONARY'
export const GET_USER_STATUSES_BY_ID = "GET_USER_STATUSES_BY_ID"
