import moment from "moment";

export const setItem = (key: any, value: any, ttl: number) => {

    const cachedDate = moment().add(ttl, 'm')

    const cacheItem = {
        "date": cachedDate,
        "value": value
    }

    localStorage.setItem(key, JSON.stringify(cacheItem));
}

export const getItem = (key: any) => {

    if (!localStorage.getItem(key)) {
        return null;
    }

    const item = JSON.parse(localStorage.getItem(key) ?? '');
    const now = moment().toDate();

    const cachedDate = moment(item.date).toDate();

    if (item && cachedDate < now) {
        localStorage.removeItem(key)
        return null;
    }

    return item.value;
}
