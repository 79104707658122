import {InputText} from 'primereact/inputtext';
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import React, {useEffect} from "react";
import {getBranchesACD} from "../../../redux/api/apiBranchList";
import {setBranchField} from "../../../redux/actions/actionsBranchList";
import {Button} from "primereact/button";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";

const BranchACDSettings = () => {
    const dispatch = useAppDispatch();
    const branchesACD = useAppSelector(state => state.BranchesListReducer.branchesACD);
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const errorFields = useAppSelector(state => state.BranchesListReducer.errorFields);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    useEffect(() => {
        dispatch(getBranchesACD(jwtToken?.jwtToken));
    }, []);

    const foundACD = branchesACD?.find((item: { id: any; }) => item.id === branchData?.integrationURLId);

    return (
        <div className="grid p-fluid">
            <div className="field col-12 md:col-12 mb-0">
                <label htmlFor="name" className="font-bold">Інтегратор</label>
                <div className="field-radiobutton my-3">
                    <label htmlFor="name" className="mr-2">Загальний інтегратор</label>
                    <InputSwitch
                        // disabled={branchData?.isArchived || !editType}
                        disabled
                        checked={!branchData.isMainIntegrationUrl} onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'isMainIntegrationUrl', value: !e.value}));
                        if (!e.value) {
                            dispatch(setBranchField({fieldName: 'integrationURLId', value: 0}));
                        }
                    }}/>
                    <label htmlFor="icon">Персональний інтегратор</label>
                </div>
            </div>
            <Divider/>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.parentName && !branchData?.parentName})}>Інтегратор</label>
                <Dropdown
                    value={branchData?.integrationURLId || ''}
                    onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'integrationURLId', value: e.target.value}))
                    }}
                    options={branchesACD}
                    optionLabel="urlName"
                    optionValue="id"
                    filter
                    placeholder="Виберіть:"
                    // disabled={branchData?.isArchived || !editType || branchData.isMainIntegrationUrl}
                    disabled
                />
            </div>
            <div className="field col-10 md:col-6">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name && !branchData?.name})}>Integration Url</label>
                <InputText
                    id="name"
                    value={foundACD?.integertionUrl || ''}
                    // className={`${branchData.isMainIntegrationUrl ? 'opacity-50' : 'opacity-100'}`}
                    disabled
                />
            </div>
            <div className="field col-2 md:col-2 align-self-end flex justify-content-end md:justify-content-start">
                {!!branchData?.integrationURLId &&
                    <Button
                        onClick={(e) => {
                            dispatch(setBranchField({fieldName: 'integrationURLId', value: 0}))
                        }}
                        icon="pi pi-times"
                        className="delete-user p-button-secondary p-button-text field col-12 md:col-2 m-0"
                        style={{backgroundColor: 'rgba(255, 255, 255, 0.05)'}}
                        disabled={branchData?.isArchived || !editType || branchData.isMainIntegrationUrl}
                    />}
            </div>
        </div>
    );
};

export default BranchACDSettings;
