import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ADD_STATUS_GROUP_BRANCH,
    ARCHIVE_STATUS_TABLE_TOGGLE, DELETE_STATUS_BRANCH,
    GET_STATUS_DETAILS,
    GET_STATUS_PAGINATION, GET_STATUSES_DICTIONARY,
    GET_STATUSES_LIST,
    HIDE_STATUS_DETAILS_LOADER,
    HIDE_STATUS_LOADER, INITIAL_STATUS_DATA,
    SELECTED_STATUS_ID, SET_STATUS_CURRENT_PAGE, SET_STATUS_CURRENT_SIZE,
    SET_STATUS_ERROR_FIELDS, SET_STATUSES_LIST_DATA_SORT,
    SHOW_STATUS_DETAILS_LOADER,
    SHOW_STATUSES_ERROR, SHOW_STATUSES_LIST_LOADER,
    STATUS_DIALOG_VISIBLE, STATUS_FIELD, UPDATE_STATUS_PERMISSIONS, UPDATE_STATUS_TIME
} from "../types/typesStatusesList";

const initialState = {
    archiveStatusTableToggle: true,
    statusesList: [],
    statusDialogVisible: false,
    statusData: {
        typeId: 302,
        externalName: '',
        statusName: null,
        active: true,
        displayOrder: 20,
        statusColor: "#0F8BFD",
        callInboundInternal: false,
        callInboundExternal: false,
        callOutboundInternal: false,
        callOutboundExternal: false,
        chatInboundInternal: false,
        chatInboundExternal: false,
        chatOutboundInternal: false,
        chatOutboundExternal: false,
        description: null,
        isAutoStatus: false,
        timeStatus: null,
        isCallAuto: false,
        isChatAuto: false
    },
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        statusName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        active: {value: null, matchMode: FilterMatchMode.IN},
        typeId: {value: null, matchMode: FilterMatchMode.IN},
    },
    selectedStatusId: null,
    statusesDictionary: [],
    error: false,
    loading: false,
    detailsLoading: false,
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    errorFields: null,
    dataSort: {
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: "Id",
            isDesc: false
        },
        filter: [],
        active: true,
        isArchived: false
    },
    statusTime: null
}

export const StatusesListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_STATUS_TABLE_TOGGLE:
            return {...state, archiveStatusTableToggle: action.payload}
        case GET_STATUSES_LIST:
            return {...state, statusesList: action.payload};
        case GET_STATUS_DETAILS:
            return {...state, statusData: action.payload};
        case SELECTED_STATUS_ID:
            return {...state, selectedStatusId: action.payload}
        case SHOW_STATUSES_ERROR:
            return {...state, error: action.payload};
        case STATUS_DIALOG_VISIBLE:
            return {...state, statusDialogVisible: action.payload};
        case SHOW_STATUS_DETAILS_LOADER:
            return {...state, detailsLoading: true};
        case HIDE_STATUS_LOADER:
            return {...state, loading: false};
        case SHOW_STATUSES_LIST_LOADER:
            return {...state, loading: true};
        case HIDE_STATUS_DETAILS_LOADER:
            return {...state, detailsLoading: false};
        case GET_STATUS_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}};
        case SET_STATUS_ERROR_FIELDS:
            return {...state, errorFields: action.payload};
        case GET_STATUSES_DICTIONARY:
            return {...state, statusesDictionary: action.payload};
        case INITIAL_STATUS_DATA:
            return {...state, statusData: initialState.statusData}
        case STATUS_FIELD:
            return {
                ...state, statusData:
                    {
                        ...state.statusData,
                        [action.payload.fieldName]: action.payload.value,
                    }
            }
        case SET_STATUS_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_STATUS_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case SET_STATUSES_LIST_DATA_SORT:
            return {...state, dataSort: action.payload}
        case UPDATE_STATUS_PERMISSIONS:
            return {
                ...state,
                rolesData: {
                    ...state.statusData,
                },
            };
        case UPDATE_STATUS_TIME:
            return {...state, statusTime: action.payload};
        case ADD_STATUS_GROUP_BRANCH:
            let branchesId = action.payload.id.map((item: any) => {
                return item
            })

            return {
                ...state, statusData:
                    {
                        ...state.statusData,
                        statusBranches: branchesId.map((item: { id: number;}) => {
                            return {id: item.id};
                        })
                    }
            };
        case DELETE_STATUS_BRANCH:
            return {
                ...state, statusData:
                    {
                        ...state.statusData,
                        // @ts-ignore
                        statusBranches: state.statusData.statusBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        default:
            return state;
    }
};
