export const STATISTICS_TABLE_TOGGLE = 'STATISTICS_TABLE_TOGGLE'
export const GET_STATISTICS_USER_SORT_FIELDS = 'GET_STATISTICS_USER_SORT_FIELDS'
export const CLEAR_STATISTICS_FILTERS = 'CLEAR_STATISTICS_FILTERS'
export const SELECTED_STATISTICS_ID = 'SELECTED_STATISTICS_ID'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const INITIAL_STATISTICS_DATA = 'INITIAL_STATISTICS_DATA'
export const GET_STATISTICS = 'GET_STATISTICS'
export const GET_STATISTIC = 'GET_STATISTIC'
export const GET_STATISTICS_PAGINATION = 'GET_STATISTICS_PAGINATION'
export const SET_CURRENT_STATISTICS_PAGE = 'SET_CURRENT_STATISTICS_PAGE'
export const SET_CURRENT_STATISTICS_SIZE = 'SET_CURRENT_STATISTICS_SIZE'
export const SET_STATISTICS_LOADING = 'SET_STATISTICS_LOADING'
export const SHOW_DETAIL_LOADING = 'SHOW_DETAIL_LOADING'
export const GET_AUDIO_URL = 'GET_AUDIO_URL'
export const SHOW_EXPORT_STATISTICS_LOADING = 'SHOW_EXPORT_STATISTICS_LOADING'
export const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
export const SET_STATISTICS_FILTER = 'SET_STATISTICS_FILTER';
export const GET_STATISTIC_SORT_FIELDS = 'GET_STATISTIC_SORT_FIELDS';
export const UPDATE_STATISTIC_SORT_FIELDS = 'UPDATE_STATISTIC_SORT_FIELDS';
