import React, {useState, useEffect, useRef} from 'react';
import {classNames} from 'primereact/utils';
import {Route, Routes, useLocation, Navigate, useNavigate} from 'react-router-dom';
import AppConfig from './AppConfig';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import CompaniesHeader from "./components/CompaniesHeader";
import {Login} from "./pages/Login";
import CompaniesList from "./components/Campaign/СompaniesList";
import NavigationMenu from "./components/Menu/NavigationMenu";
import Topbar from "./components/Menu/NavigationMenu/TopBar";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {menuConfig, WSS_URL} from "./redux/config";
import UsersList from "./components/Settings/UsersList";
import BranchList from "./components/Branch/BranchList";
import {getRoleVision} from "./redux/api/apiRoles";
import {NotFound} from "./pages/NotFound";
import {Error} from "./pages/Error";
import Notifications from "./components/Notifications";
import {HubConnectionBuilder} from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import RolesList from "./components/Settings/RolesList";
import {setUserStatusField} from "./redux/actions/actionsUser";
import {initialAccountData} from "./redux/actions/actions";
import {setRolesVisible} from "./redux/actions/actionsRoles";
import StatusesList from "./components/Settings/StatusesList";
import {ERROR_ACCOUNT_WEB_LOGIN} from "./redux/types/types";
import QueueList from "./components/Queue/QueueList";
import {changeSession, stopGlobalJsSIP} from "./components/JsSip/GlobalJsSIP";
import {getUserStatus} from "./redux/api/apiUsersList";
import {setActiveSession, setReconnectSession} from "./redux/actions/actionsWebRTC";
import QualificationGroupList from "./components/QualificationGroupList/QualificationGroupList/QualificationGroupList";
import {DecodedToken} from "./types/types";
import {jwtDecode} from "jwt-decode";
import MonitoringBranches from "./components/Monitoring/MonitoringBranches";
import TrunkList from "./components/Settings/TrunksList/TrunkList";
import AudioList from "./components/Settings/AudioList/AudioList";
import AuthGoogle from "./components/AuthGoogle";
import AuthError from "./components/AuthError";
import RecoveryPass from "./components/RecoveryPass";
import RecoveryPassEmailPage from "./components/RecoveryPassEmailPage";
import StatisticsList from "./components/Statistics/StatisticsList";
import CallsStatisticsList from "./components/Statistics/CallsStatisticsList";
import StatusStatisticsList from "./components/Statistics/StatusStatisticsList";

const App = (props: any) => {
    const dispatch = useAppDispatch();
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [profileMenuPin, setProfileMenuPin] = useState(false);
    const [headerTitle, setHeaderTitle] = useState<string>('Вихідні компанії');
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const statusCode = JSON.parse(localStorage.getItem('callcenter:accountWebLogin') || '{}');
    const rolesError = useAppSelector<any>(state => state.RolesReducer.error);
    const navigate = useNavigate();
    const reconnectSession = useAppSelector(state => state.WebRTCReducer.reconnectSession);
    const [status, setStatus] = useState(null) as any;
    const [logoutUserId, setLogoutUserId] = useState(null) as any;
    const [statusHubConnection, setStatusHubConnection] = useState(null) as any;
    const [authGoogleQuery, setAuthGoogleQuery] = useState<boolean>(true);
    const [authGoogleErrorQuery, setAuthGoogleErrorQuery] = useState<boolean>(true);
    const [recoveryPass, setRecoveryPass] = useState<boolean>(true);
    const [recoveryPassEmailPage, setRecoveryPassEmailPage] = useState<boolean>(true);
    const activeSession = useAppSelector(state => state.WebRTCReducer.activeSession);
    let decodedToken: DecodedToken;
    let decodedUserId: string | undefined;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
        decodedUserId = decodedToken?.[`x-access-userid`]
    }
    const [jwtTokenLocalStorage, setJwtTokenLocalStorage] = useState(localStorage.getItem('callcenter:user'));

    let hash = window.location.hash;

    useEffect(() => {
        if (hash?.includes("auth_google?id")) {
            setAuthGoogleQuery(true);
        } else {
            setAuthGoogleQuery(false);
        }
    }, [hash]);

    useEffect(() => {
        if (hash?.includes("auth_google?id")) {
            setAuthGoogleQuery(true);
        } else {
            setAuthGoogleQuery(false);
        }
    }, []);

    useEffect(() => {
        if (hash?.includes("authError/error?notFound") || hash?.includes("authError/error?exist")) {
            setAuthGoogleErrorQuery(true);
        } else {
            setAuthGoogleErrorQuery(false);
        }
    }, [hash]);

    useEffect(() => {
        if (hash?.includes("authError/error?notFound") || hash?.includes("authError/error?exist")) {
            setAuthGoogleErrorQuery(true);
        } else {
            setAuthGoogleErrorQuery(false);
        }
    }, []);

    useEffect(() => {
        if (hash?.includes("pass/recovery?id")) {
            setRecoveryPass(true);
        } else {
            setRecoveryPass(false);
        }
    }, [hash]);

    useEffect(() => {
        if (hash?.includes("pass/recovery?id")) {
            setRecoveryPass(true);
        } else {
            setRecoveryPass(false);
        }
    }, []);

    useEffect(() => {
        if (hash?.includes("auth/forgotpassword")) {
            setRecoveryPassEmailPage(true);
        } else {
            setRecoveryPassEmailPage(false);
        }
    }, [hash]);

    useEffect(() => {
        if (hash?.includes("auth/forgotpassword")) {
            setRecoveryPassEmailPage(true);
        } else {
            setRecoveryPassEmailPage(false);
        }
    }, []);

    useEffect(() => {
        if (!authGoogleQuery && !jwtToken?.jwtToken && !authGoogleErrorQuery && !recoveryPass && !recoveryPassEmailPage) {
            navigate('/login')
        }
    }, [authGoogleQuery, authGoogleErrorQuery, recoveryPass, recoveryPassEmailPage]);

    useEffect(() => {
        if (authGoogleQuery && jwtToken?.jwtToken) {
            if (statusCode === 300) {
                if (!rolesVision && rolesError === false && jwtToken?.jwtToken) {
                    dispatch(getRoleVision(jwtToken?.jwtToken));
                }

                if (rolesVision) {
                    const filteredMenuConfigWithRole = menuConfig.filter((item) =>
                        rolesVision.hasOwnProperty(item.name)
                    );
                    if (filteredMenuConfigWithRole.length) {
                        const order = rolesVision?.length ? Object?.keys(rolesVision) : [];

                        filteredMenuConfigWithRole?.sort((a, b) => {
                            const indexA = order?.indexOf(a.name);
                            const indexB = order?.indexOf(b.name);
                            return indexA - indexB;
                        });

                        if (filteredMenuConfigWithRole[0].name.toLowerCase() === 'dialer') {
                            navigate(`/`);
                            localStorage.setItem('callcenter:accountWebLogin', '');
                            window.location.reload();
                        } else {
                            navigate(`/${filteredMenuConfigWithRole[0].name.toLowerCase()}`);
                            localStorage.setItem('callcenter:accountWebLogin', '');
                            window.location.reload();
                        }
                    }
                }

            }
        }
    }, [rolesVision, statusCode, rolesError, jwtToken?.jwtToken]);

    useEffect(() => {
        if (!jwtTokenLocalStorage) {
            logout();
        }
    }, [jwtTokenLocalStorage]);

    function checkSession() {
        const storedExpirationTime = localStorage.getItem('expirationTime');
        if (!storedExpirationTime) {
            logout();
            return;
        }

        const currentTime = Date.now();
        if (currentTime > parseInt(storedExpirationTime, 10)) {
            logout();
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            logout();
            // @ts-ignore
        }, jwtToken?.expiresIn * 1000);

        return () => clearTimeout(timer);
    }, [jwtToken, jwtToken?.expiresIn]);

    const logout = () => {
        localStorage.removeItem('callcenter:user');
        stopGlobalJsSIP();
        if (!authGoogleQuery) {
            navigate('/login');
        }
        localStorage.clear();
        localStorage.removeItem('persist:callcenter:user');
        localStorage.removeItem('expirationTime');
        dispatch(initialAccountData());
        dispatch(setRolesVisible(null));
        dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}});
        setJwtTokenLocalStorage(null);
    }

    ////TEST

    // useEffect(() => {
    //     const handleVisibilityChange = () => {
    //         console.log(activeSession)
    //         if (document.visibilityState === 'visible') {
    //             if (!activeSession) {
    //                 dispatch(setReconnectSession(true));
    //             }
    //         } else {
    //             console.log('Вкладка неактивна');
    //             dispatch(setActiveSession(false));
    //             dispatch(setReconnectSession(false));
    //         }
    //     };
    //
    //     // Добавляем слушатель события
    //     document.addEventListener('visibilitychange', handleVisibilityChange);
    //
    //     // Удаляем слушатель при размонтировании компонента
    //     return () => {
    //         document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };
    // }, []);


    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'callcenter:user') {
                logout();
                setJwtTokenLocalStorage(localStorage.getItem('callcenter:user'));
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const token = localStorage.getItem('callcenter:user');
            checkSession();
            if (!token) {
                logout();
            } else {
                setJwtTokenLocalStorage(token);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    PrimeReact.ripple = true;

    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let pathname = location.pathname;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setMenuActive(false);
    }, [menuMode]);

    useEffect(() => {
        if (!jwtToken?.jwtToken && !authGoogleQuery && !authGoogleErrorQuery && !recoveryPass && !recoveryPassEmailPage) {
            navigate('/login')
        }
        if (!rolesVision && !rolesError && jwtToken?.jwtToken) {
            dispatch(getRoleVision(jwtToken?.jwtToken));
        }
    }, [jwtToken?.jwtToken, rolesVision, rolesError]);

    const order = rolesVision ? Object.keys(rolesVision) : [];

// Функция для фильтрации меню по ролям
    const filterMenuItemsByRole = (items: any[]) => {
        return items.reduce((acc, item) => {
            if (item.name === 'Settings' && item?.items?.length) {
                const filteredSubItems = filterMenuItemsByRole(item.items);
                if (filteredSubItems.length) {
                    acc.push({...item, items: filteredSubItems});
                }
            } else if (rolesVision?.hasOwnProperty(item.name)) {
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const filteredMenuConfigWithRole = filterMenuItemsByRole(menuConfig);

    let sortedMenuConfigWithRole = filteredMenuConfigWithRole?.slice().sort((a: {
        name: string;
    }, b: {
        name: string;
    }) => {
        const indexA = order.indexOf(a.name);
        const indexB = order.indexOf(b.name);
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });

    const settingsIndex = sortedMenuConfigWithRole?.findIndex((item: {
        name: string;
    }) => item.name === 'Settings');
    const statisticsIndex = sortedMenuConfigWithRole?.findIndex((item: {
        name: string;
    }) => item.name === 'Statistic');

    if (settingsIndex !== -1 && statisticsIndex !== -1 && statisticsIndex > settingsIndex) {
        const [statisticsItem] = sortedMenuConfigWithRole.splice(statisticsIndex, 1);
        sortedMenuConfigWithRole.splice(settingsIndex, 0, statisticsItem);
    }

    useEffect(() => {
        if (!rolesVision && statusCode === 200 && rolesError === false) {
            dispatch(getRoleVision(jwtToken?.jwtToken))
        }
    }, [rolesVision, rolesError]);

    const checkAccess = (routeName: string) => {
        return rolesVision?.hasOwnProperty(routeName);
    };

    const renderComponentOrRedirect = (routeName: string, component: any) => {
        return checkAccess(routeName) && jwtToken?.jwtToken ? component : <Navigate to="/access"/>;
    };

    useEffect(() => {
        switch (pathname) {
            case '/':
                setHeaderTitle('Вихідні кампанії');
                break;
            case '/monitoring':
                setHeaderTitle('Монiторинг Подiй');
                break;
            case '/branch':
                setHeaderTitle('Branch');
                break;
            case '/usersmanager':
                setHeaderTitle('Користувачі');
                break;
            case '/role':
                setHeaderTitle('Ролі користувачів');
                break;
            case '/status':
                setHeaderTitle('Статуси');
                break;
            case '/queue':
                setHeaderTitle('Вхідні черги');
                break;
            case '/skill_group':
                setHeaderTitle('Групи кваліфікацій');
                break;
            case '/trunks':
                setHeaderTitle('Транки');
                break;
            case '/audio':
                setHeaderTitle('Бібліотека аудіо');
                break;
            case '/statistics':
                setHeaderTitle('Дані дзвінків');
                break;
            case '/calls':
                setHeaderTitle('Дзвінки операторів');
                break;
            case '/statuses':
                setHeaderTitle('Статуси операторів');
                break;
            default:
                setHeaderTitle('callcenter')
        }
    }, [pathname]);

    const startConnection = () => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${WSS_URL}StatusHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => jwtToken?.jwtToken || ""
            })
            .withAutomaticReconnect([0, 3000, 5000, 10000, 20000, 30000, 50000, 60000])
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        connection.onreconnected(() => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket reconnected to ${WSS_URL}StatusHub`);
            connection.invoke("UserConnected", decodedToken[`x-access-userid`]).catch(err => console.error(err));
            dispatch(getUserStatus(jwtToken?.jwtToken));
        });

        connection.onclose(async () => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket disconnected. Attempting to reconnect to ${WSS_URL}StatusHub`);
        });

        connection.start()
            .then(() => {
                connection.on('UpdateStatus', updateStatus => {
                    // console.log(updateStatus)
                    setStatus(JSON.parse(updateStatus));
                });
                connection.on('Logout', logout => {
                    // console.log(logout)
                    setLogoutUserId(JSON.parse(logout));
                });
                connection.invoke("UserConnected", decodedToken[`x-access-userid`]);
                changeSession();
            })
            .catch((e) => {
                if (!authGoogleQuery && !authGoogleErrorQuery && !recoveryPass && !recoveryPassEmailPage) {
                    console.error("Connection failed: ", e);
                }
            });

        setStatusHubConnection(connection);

        return connection;
    };

    let connection: any;

    useEffect(() => {
        if (!reconnectSession && !authGoogleQuery && !authGoogleErrorQuery && !recoveryPass && !recoveryPassEmailPage) {
            connection = startConnection();
            return () => {
                connection.stop();
            };
        } else {
            if (statusHubConnection && statusHubConnection.state === 'Disconnecting') {
                statusHubConnection.stop();
                connection = startConnection();
            }
        }
    }, [reconnectSession, authGoogleQuery, authGoogleErrorQuery, recoveryPass, recoveryPassEmailPage]);


    useEffect(() => {
        if (status) {
            const outputObject: Record<string, any> = {};

            for (const key in status) {
                const newKey = key.charAt(0).toLowerCase() + key.slice(1);
                outputObject[newKey] = status[key];
                if (!outputObject.statusColor) {
                    outputObject.statusColor = '#616161'
                }
            }

            if (outputObject?.userId === decodedUserId) {
                dispatch(setUserStatusField(outputObject));
            }
        }
    }, [status]);

    useEffect(() => {
        if (logoutUserId) {
            const userLogout: Record<string, any> = {};

            for (const key in logoutUserId) {
                const newKey = key.charAt(0).toLowerCase() + key.slice(1);
                userLogout[newKey] = logoutUserId[key];
            }
            if (userLogout?.userId === decodedToken[`x-access-userid`]) {
                stopGlobalJsSIP();
                navigate('/login');
                localStorage.clear();
                localStorage.removeItem('persist:callcenter:user');
                dispatch(initialAccountData());
                dispatch(setRolesVisible(null));
                dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}})
            }
        }
    }, [logoutUserId]);


    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        menuClick = false;
        configClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };


    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };
    ////TEST

    window.addEventListener('storage', (event) => {
        if (event.key === 'callCentreActiveSession') {
            dispatch(setActiveSession(false));
            dispatch(setReconnectSession(false));
        }
    });

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const profileMenuPinChange = (value?: boolean) => {
        if (value) {
            setProfileMenuPin(value);
        } else {
            setProfileMenuPin((prevState) => !prevState);
        }
    };

    const profileMenuPinClose = (active: any) => {
        setProfileMenuPin(active);
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const [topbarMenuActive] = useState(false);

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onChangeActiveInlineMenu = (event: any) => {
        event.preventDefault();
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const closeMenu = () => {
        setStaticMenuMobileActive((prevState) => !prevState);
    }

    return (
        <div className={!recoveryPass && !recoveryPassEmailPage ? layoutClassName : ''} onClick={onDocumentClick}>
            <Notifications/>
            {jwtToken?.jwtToken && rolesVision && !authGoogleQuery && !authGoogleErrorQuery && !recoveryPass && !recoveryPassEmailPage ?
                <div className="layout-main">
                    <Topbar
                        items={sortedMenuConfigWithRole}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />
                    <CompaniesHeader isDesktop={isDesktop} model={filteredMenuConfigWithRole} title={headerTitle}
                                     profileMenuPinChange={profileMenuPinChange} profileMenuPin={profileMenuPin}
                                     profileMenuPinClose={profileMenuPinClose}/>
                    <div
                        className={classNames(`layout-main-content transition-duration-300`, {'layout-main-content-profile-menu-active': profileMenuPin, 'sidebar-active': pinActive, 'layout-main-content-menu-active': sidebarStatic, 'p-0': pathname === '/404'})}>
                        <Routes>
                            <Route path="/" element={renderComponentOrRedirect("Dialer", <CompaniesList/>)}/>
                            <Route path="/queue" element={renderComponentOrRedirect("Queues", <QueueList/>)}/>
                            <Route path="/role" element={renderComponentOrRedirect("Role", <RolesList/>)}/>
                            <Route path="/skill_group"
                                   element={renderComponentOrRedirect("SkillGroupManager", <QualificationGroupList/>)}/>
                            <Route path="/status" element={renderComponentOrRedirect("Status", <StatusesList/>)}/>
                            <Route path="/monitoring"
                                   element={renderComponentOrRedirect("Monitoring", <MonitoringBranches
                                       profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/branch" element={renderComponentOrRedirect("Branch", <BranchList
                                profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/usersmanager" element={renderComponentOrRedirect("UsersManager", <UsersList
                                profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/trunks" element={renderComponentOrRedirect("Trunks", <TrunkList/>)}/>
                            <Route path="/statistics"
                                   element={renderComponentOrRedirect("Statistic", <StatisticsList profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/statuses"
                                   element={renderComponentOrRedirect("Statistic", <StatusStatisticsList profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/calls"
                                   element={renderComponentOrRedirect("Statistic", <CallsStatisticsList profileMenuPin={profileMenuPin}/>)}/>
                            <Route path="/audio" element={<AudioList/>}/>
                            <Route path="/error" element={<Error colorScheme={props.colorScheme}/>}/>
                            <Route path="/notfound" element={<NotFound colorScheme={props.colorScheme}/>}/>
                            <Route path="/*" element={<Navigate to="/notfound"/>}/>
                        </Routes>
                    </div>
                </div>
                : authGoogleQuery ? <AuthGoogle/> : authGoogleErrorQuery ? <AuthError/> : recoveryPass ?
                    <RecoveryPass/> : recoveryPassEmailPage ? <RecoveryPassEmailPage/> : <Login/>}
            {jwtToken && jwtToken.jwtToken && !recoveryPass && !recoveryPassEmailPage && <NavigationMenu
                model={sortedMenuConfigWithRole}
                onRootMenuItemClick={onRootMenuItemClick}
                onMenuItemClick={onMenuItemClick}
                onToggleMenu={onToggleMenu}
                onMenuClick={onMenuClick}
                menuMode={menuMode}
                colorScheme={props.colorScheme}
                menuActive={menuActive}
                sidebarActive={sidebarActive}
                sidebarStatic={sidebarStatic}
                pinActive={pinActive}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onSidebarMouseOver={onSidebarMouseOver}
                onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                resetActiveIndex={resetActiveIndex}
                close={closeMenu}
            />}

            <AppConfig
                configActive={configActive}
                onConfigButtonClick={onConfigButtonClick}
                onConfigClick={onConfigClick}
                menuMode={menuMode}
                changeMenuMode={onMenuModeChange}
                colorScheme={props.colorScheme}
                changeColorScheme={props.onColorSchemeChange}
                theme={props.theme}
                changeTheme={props.onMenuThemeChange}
                componentTheme={props.componentTheme}
                changeComponentTheme={props.onComponentThemeChange}
                ripple={ripple}
                onRippleChange={onRippleChange}
            />
        </div>
    );
};

export default App;
