import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {getQueueDirectoriesGetTranksPhones, getQueueId} from "../../../../redux/api/apiQueue";
import {setQueueDialogVisible, setQueueErrorField, setSelectedQueueIndex} from "../../../../redux/actions/actionsQueue";
import {setEditType} from "../../../../redux/actions/actions";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";

const QueueListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    // @ts-ignore
    const loader = useAppSelector(state => state.QueueListReducer.detailsLoading);
    // @ts-ignore
    const queuesDirectoryQueueOperatorsType = useAppSelector(state => state.QueueListReducer.queuesDirectoryQueueOperatorsType);
    // @ts-ignore
    const queueDataScheduler = useAppSelector(state => state.QueueListReducer.queueData?.scheduler);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Queues');
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [updatedScheduler, setUpdatedScheduler] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const [selectedPairs, setSelectedPairs] = useState<any>([{selectedGateway: null, selectedPhone: null}]);
    // @ts-ignore
    const companiesDirectoryTranksPhones = useAppSelector(state => state.QueueListReducer.queuesDirectoryTrunksPhone);
    const gateways = Array.from(new Set(companiesDirectoryTranksPhones?.map((item: any) => item.gatewayName)));


    useEffect(() => {
        dispatch(getQueueDirectoriesGetTranksPhones(jwtToken?.jwtToken));
    }, []);


    useEffect(() => {
        if (queueData?.queueTrunks?.length) {
            setSelectedPairs([]);
        }
    }, [queueData?.queueTrunks, queueData?.id]);


    const addPair = (selectedGateway: null, selectedPhone: null) => {
        setSelectedPairs((prevPairs: any[]) => {
            if (selectedGateway != null && selectedPhone != null) {
                const pairExists = prevPairs.some(pair => pair.selectedGateway === selectedGateway && pair.selectedPhone === selectedPhone);
                if (pairExists) {
                    return prevPairs;
                }
            }
            return [...prevPairs, {selectedGateway, selectedPhone}];
        });
    };

    useEffect(() => {
        if (queueData?.queueTrunks) {
            queueData?.queueTrunks?.map((queueTrank: any) => {
                let foundObject = companiesDirectoryTranksPhones?.find((item: { id: any; }) => item.id === queueTrank?.trunkPhoneId);
                if (queueTrank?.trunkPhoneId === foundObject?.id) {
                    addPair(foundObject?.gatewayName, foundObject?.id);
                }
            });
        }
    }, [companiesDirectoryTranksPhones, queueData?.queueTrunks]);

    const {
        update: hasDialerUpdatePermission,
    } = dialerPermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (queueDataScheduler && queueDataScheduler.days) {
            const labelsMap = weekdayField.reduce((map, weekday) => {
                // @ts-ignore
                map[weekday.dayOfWeek] = weekday.label;
                return map;
            }, {});

            const updatedDays = queueDataScheduler.days.map((day: { dayOfWeek: string | number; label: any; }) => ({
                ...day,
                // @ts-ignore
                label: labelsMap[day.dayOfWeek] || day.label
            }));

            updatedDays.sort((a: { dayOfWeek: number; }, b: { dayOfWeek: number; }) => {
                const dayOfWeekA = a.dayOfWeek === 0 ? 7 : a.dayOfWeek;
                const dayOfWeekB = b.dayOfWeek === 0 ? 7 : b.dayOfWeek;
                return dayOfWeekA - dayOfWeekB;
            });
            setUpdatedScheduler({
                ...queueDataScheduler,
                days: updatedDays
            });
        } else {
            setUpdatedScheduler(queueDataScheduler)
        }
    }, [queueDataScheduler]);

    const operatorsBodyTemplate = () => {
        const activeOperators = queueData?.activeOperators;
        const countOperators = queueData?.countOperators;

        if (activeOperators !== undefined && countOperators !== undefined) {
            return `${activeOperators} / ${countOperators}`;
        }
    }

    const operatorsStrategyBodyTemplate = () => {
        const operatorsStrategy = queueData?.queue?.strategyOperators;
        const foundElement = queuesDirectoryQueueOperatorsType.find((item: { dictionaryId: number; }) => item.dictionaryId === operatorsStrategy);
        if (foundElement) {
            return `${foundElement?.name}`;
        }
    }

    const branchBodyTemplate = () => {
        let element = branches?.find((element: { id: number; }) => element?.id === queueData.queue?.branchId);

        return (
            <React.Fragment>
                <span style={{verticalAlign: 'middle'}} className="image-text">
                    {element?.id} {element?.name}
                </span>
            </React.Fragment>
        );
    };

    const fields = [
        {label: "ID", value: queueData?.queue?.id},
        {label: "Branch", value: queueData?.queue?.branchId ? branchBodyTemplate() : '- - -'},
        {label: "А номер", value: queueData?.queue?.isGeneralTrunk ? 'Транк Черги' : 'Транк Оператора'},
        {label: "Стан", value: queueData?.queue?.isActive ? 'Активна' : 'Не активна'},
        {label: "Приоритет", value: queueData?.queue?.priority || '0'},
        {label: "Оператори:", value: queueData?.countOperators ? operatorsBodyTemplate() : '0 / 0'},
        {label: "Стратегія операторів:", value: queueData?.queue?.strategyOperators ? operatorsStrategyBodyTemplate() : '- - -'},
    ];

    const weekdayBodyTemplate = (days: { [x: string]: any; }, day: number) => {
        const currentDay = days[day];
        const start = days[day]?.start;
        if (!currentDay) {
            return <div className="grid col-12 md:col-12 flex align-items-center" style={{minWidth: '12%'}}>
                - - -
            </div>;
        }
        return (
            <div className="grid flex align-items-center">
                {start ?<div className="col-12 md:col-12 flex align-items-center pl-0" style={{minWidth: '12%'}}>
                    <Calendar disabled className="opacity-100 text-white queue-time" value={new Date(currentDay.start)} timeOnly style={{minWidth: '60px'}}/>
                </div> :
                <div className="col-12 md:col-12 flex align-items-center pl-0">
                    <Calendar disabled className="opacity-100 text-white queue-time" value={new Date(currentDay.end)} timeOnly style={{minWidth: '60px'}}/>
                </div>}
            </div>
        );
    };

    const titleBodyTemplate = (days: { [x: string]: any; }) => {
        const start = days[0]?.start;
        return (
            <div className="grid flex align-items-center">
                {start ? <div className="col-6 md:col-6 flex align-items-center" style={{minWidth: '12%'}}>
                    <div className="mr-2">
                        <label>З</label>
                    </div>
                </div> :
                <div className="col-6 md:col-6 flex align-items-center">
                    <div className="mr-2">
                        <label>По</label>
                    </div>
                </div>}
            </div>
        );
    };


    const openEditor = () => {
        dispatch(setSelectedQueueIndex(queueData.queue?.id || props.selectedRowData));
        dispatch(setQueueDialogVisible(true));
        dispatch(setQueueErrorField(null));
        dispatch(getQueueId(props.selectedRowData, jwtToken?.jwtToken));
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const weekdayField = [
        {label: 'Пн', dayOfWeek: 1},
        {label: 'Вт', dayOfWeek: 2},
        {label: 'Ср', dayOfWeek: 3},
        {label: 'Чт', dayOfWeek: 4},
        {label: 'Пт', dayOfWeek: 5},
        {label: 'Сб', dayOfWeek: 6},
        {label: 'Нд', dayOfWeek: 0}
    ];

    let startDates: any = {};
    let endDates: any = {};

    updatedScheduler?.days?.forEach((day: { dayOfWeek: any; start: any; end: any; label: any; }) => {
        const { dayOfWeek, start, end, label } = day;
        startDates[dayOfWeek] = { dayOfWeek, start, label };
        endDates[dayOfWeek] = { dayOfWeek, end, label };
    });

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchItems = queueData?.queueBranches?.map((queueItem: any, index: number) => {
        return formattedBranches?.map((branchItem: any) => {
            if (queueItem.id === branchItem.id) {
                return (
                        <div className="field col-12 md:col-10 flex m-0 border-round-sm pl-0" key={index}>
                            {branchItem.label}
                        </div>
                )
            }
        })
    })

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                        <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">ID: {queueData.queue?.id}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                <p className="w-full">Оператори: {queueData?.countOperators ? operatorsBodyTemplate() : '0 / 0'}</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">{queueData.queue?.name}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                <p className="w-full">Стратегія операторів: {queueData?.queue?.strategyOperators ? operatorsStrategyBodyTemplate() : '- - -'}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-5 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">Приоритет: {queueData?.queue?.priority || '0'}</p>
                            </div>
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{queueData?.queue?.isActive ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                    openEditor();
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                                    'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            <p className="w-full">{queueData.queue?.name}</p>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && <>
                        {fields.map((field: any) => (
                            <React.Fragment key={field.label}>
                                <div className="field col-12 md:col-6 flex align-items-center m-0">
                                    <p>{field.label}</p>
                                </div>
                                <div className="field col-12 md:col-6 flex align-items-center m-0">
                                    <p>{field.value}</p>
                                </div>
                            </React.Fragment>
                        ))}
                        {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                            <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Бранч:</label></div></div>
                            <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            <div className="field col-12 md:col-12 flex m-0 flex-column px-0">{queueData?.queueBranches?.length ? branchItems : '- - -'}</div>
                        </div>}
                        {updatedScheduler?.days?.length && updatedScheduler?.days ? <div className="grid col-12 md:col-12 flex align-items-center m-0">
                            <div className="col-12 md:col-12 px-0">
                                <p>Розклад роботи:</p>
                            </div>
                            {windowWidth >= 1248 && <div className="col-4 md:col-4 px-0">
                                Графік:
                            </div>}
                            {windowWidth >= 1248 && <div className="col-1 md:col-1 px-0"></div>}
                            {windowWidth >= 1248 && <div className="col-7 md:col-7 pl-4">
                                Розклад дзвінків:
                            </div>}
                        </div> : ''}
                        {updatedScheduler?.days?.length ? updatedScheduler?.days?.map((field: any, index: number) => (
                            <div className="field col-12 md:col-12 mb-0 p-0" key={index}>
                                <div className="grid flex align-items-center">
                                    <div className="col-12 md:col-2">
                                        <InputText type="text" value={field.label} className="opacity-100 w-full text-center" style={{paddingLeft: '2px', paddingRight: '2px'}} placeholder={field.label} disabled/>
                                    </div>
                                    <div className="col-5 md:col-5 flex align-items-center">
                                        <div className="mr-2">
                                            <label>З</label>
                                        </div>
                                        <Calendar disabled className="opacity-100" value={new Date(field?.start)} timeOnly/>
                                    </div>
                                    <div className="col-5 md:col-5 flex align-items-center">
                                        <div className="mr-2">
                                            <label>По</label>
                                        </div>
                                        <Calendar disabled className="opacity-100" value={new Date(field?.end)} timeOnly/>
                                    </div>
                                </div>
                            </div>
                        )) : ''}
                    </>}
                    {windowWidth > 1248 &&
                        <React.Fragment>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex align-items-center m-0">

                                <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                    <p className="w-full">{queueData?.queue?.isGeneralTrunk ? 'Транк Черги' : 'Транк Оператора'}</p>
                                </div>
                                <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                    <p className="w-full">Бранч</p>
                                </div>
                                <div className="field col-12 md:col-6 flex align-items-center m-0 p-0">
                                    <p className="w-full">Розклад роботи</p>
                                </div>
                            </div>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex m-0 scroll-detail-items">
                                <div className="field col-12 md:col-3 flex flex-column m-0 p-0">
                                    {queueData?.queue?.isGeneralTrunk && selectedPairs?.map((pair: { selectedGateway: null; selectedPhone: any; }, index: number) => (
                                        <div className="grid col-12 md:col-12 p-0" key={index}>
                                            <div className="field col-12 md:col-5 mb-0 pl-0">
                                                <Dropdown
                                                    emptyMessage="Немає доступних"
                                                    value={pair.selectedGateway}
                                                    options={gateways}
                                                    className="opacity-100 mr-2 queue-phone w-full bg-transparent"
                                                    disabled={true}
                                                    placeholder="Оберіть транк"
                                                />
                                            </div>
                                            <div className="field col-12 md:col-7 mb-0">
                                                <Dropdown
                                                    value={pair.selectedPhone}
                                                    optionLabel="phoneNo"
                                                    className="opacity-100 queue-phone w-full bg-transparent"
                                                    emptyMessage="Немає доступних"
                                                    optionValue="id"
                                                    options={companiesDirectoryTranksPhones?.filter((item: { gatewayName: null; id: any; }) =>
                                                        item.gatewayName === pair.selectedGateway &&
                                                        (!selectedPairs.some((selectedPair: { selectedPhone: any; }, selectedIndex: number) =>
                                                                selectedIndex !== index && selectedPair.selectedPhone === item.id)
                                                            || pair.selectedPhone === item.id)
                                                    )}
                                                    disabled
                                                    placeholder="Оберіть номер"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="field col-12 md:col-3 flex m-0 p-0 flex-wrap">
                                    {branchItems || '- - -'}
                                </div>
                                <div className="field col-12 md:col-6 flex m-0 p-0">
                                    {updatedScheduler?.days?.length ?
                                        <DataTable className="col-12 md:col-12 p-0 py-0" value={[startDates, endDates]}>
                                            <Column
                                                header={''}
                                                className="justify-content-start"
                                                body={(data) => titleBodyTemplate(data)}
                                                style={{cursor: 'pointer', width: '5%'}}
                                            />
                                            <Column
                                                header={'Пн'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 1)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Вт'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 2)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Ср'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 3)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Чт'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 4)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Пт'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 5)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Сб'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 6)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                            <Column
                                                header={'Нд'}
                                                className="justify-content-start"
                                                body={(data) => weekdayBodyTemplate(data, 0)}
                                                style={{cursor: 'pointer', width: '12%'}}
                                            />
                                        </DataTable> : '---'}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default QueueListDetails
