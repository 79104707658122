import React, {useEffect} from 'react';
import {googleAuthLoginError} from "../../redux/actions/actions";
import {useAppDispatch} from "../../redux/hooks";
import {useNavigate} from "react-router-dom";
import {stopGlobalJsSIP} from "../JsSip/GlobalJsSIP";

const AuthError = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let hash = window.location.hash;
    const replacedValue = hash?.replace('#/authError/error?', '');

    useEffect(() => {
        redirectAction(replacedValue)
    }, []);

    useEffect(() => {
        stopGlobalJsSIP();
    }, []);
    const redirectAction = (action: string) => {
        switch (action) {
            case 'notFound':
                dispatch(googleAuthLoginError('Аккаунт не знайдений. Зареєструйтесь або використайте іншу пошту.' || true));
                navigate('/');
                break;
            case 'exist':
                dispatch(googleAuthLoginError('Ваша пошта вже зареєстрована. Увійдіть або використайте іншу пошту.' || true));
                navigate('/');
                break;
            default:
                dispatch(googleAuthLoginError(true));
                navigate('/');
        }
    };

    return (
        <div>

        </div>
    );
};

export default AuthError;
