import {
    ADD_ROLE_GROUP_BRANCH,
    ARCHIVE_ROLE_TABLE_TOGGLE, DELETE_ROLE_BRANCH,
    GET_ROLE_DETAILS,
    GET_ROLE_PAGINATION,
    GET_ROLES_DICTIONARY,
    GET_ROLES_LIST,
    GET_ROLES_VISION, HIDE_ROLE_ARCHIVED_MESSAGE,
    HIDE_ROLE_DETAILS_LOADER,
    HIDE_ROLE_LOADER,
    ROLE_DIALOG_VISIBLE,
    ROLE_FIELD, SET_ROLE_ARCHIVED_MESSAGE_SUCCESS,
    SET_ROLE_CURRENT_PAGE,
    SET_ROLE_CURRENT_SIZE,
    SET_ROLE_MODULES_FIELD,
    SET_ROLES_LIST_DATA_SORT, SET_STATUS_MATRIX,
    SHOW_ROLE_DETAILS_LOADER,
    SHOW_ROLES_LIST_LOADER,
    SHOW_ROLES_VISION_ERROR,
    UPDATE_PERMISSION_ACTIVE,
    UPDATE_PERMISSIONS
} from "../types/typesRoles";
import {SELECTED_COMPANY_ID, SET_ROLE_ERROR_FIELDS} from "../types/types";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {GET_USER_SORT_FIELDS, UPDATE_SORT_FIELDS} from "../types/typesMonitoringList";
import {
    GET_STATISTICS_USER_SORT_FIELDS,
    UPDATE_STATISTIC_SORT_FIELDS
} from "../types/typesStatisticsList";

const initialState = {
    archiveRolesTableToggle: true,
    rolesList: [],
    rolesDictionary: [],
    roleDialogVisible: false,
    userSortFields: [],
    userStatisticSortFields: [],
    rolesData: {
        roleModules: [],
        isEdit: true
    },
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        active: {value: null, matchMode: FilterMatchMode.IN},
    },
    rolesVision: null,
    selectedRoleId: null,
    error: false,
    loading: false,
    detailsLoading: false,
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    statusMatrix: null,
    paginationPage: 1,
    paginationSize: 10,
    errorFields: null,
    dataSort: {
        "isArchived": false,
        "pageNumber": 1,
        "pageSize": 10,
        "sort": {
            "orderBy": "Id",
            "isDesc": true
        },
        "filter": []
    },
    roleArchivedSuccess: null,
    roleArchivedMessage: false
}

export const RolesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_ROLE_TABLE_TOGGLE:
            return {...state, archiveRolesTableToggle: action.payload}
        case GET_ROLES_VISION:
            return {...state, rolesVision: action.payload};
        case GET_ROLES_LIST:
            return {...state, rolesList: action.payload};
        case GET_ROLE_DETAILS:
            return {...state, rolesData: action.payload};
        case GET_ROLES_DICTIONARY:
            return {...state, rolesDictionary: action.payload};
        case SELECTED_COMPANY_ID:
            return {...state, selectedRoleId: action.payload}
        case SHOW_ROLES_VISION_ERROR:
            return {...state, error: action.payload};
        case ROLE_DIALOG_VISIBLE:
            return {...state, roleDialogVisible: action.payload};
        case SHOW_ROLES_LIST_LOADER:
            return {...state, loading: true};
        case HIDE_ROLE_LOADER:
            return {...state, loading: false};
        case SET_STATUS_MATRIX:
            return {...state, statusMatrix: action.payload};
        case SHOW_ROLE_DETAILS_LOADER:
            return {...state, detailsLoading: true};
        case HIDE_ROLE_DETAILS_LOADER:
            return {...state, detailsLoading: false};
        case GET_ROLE_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}};
        case SET_ROLE_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case GET_USER_SORT_FIELDS:
            return {...state, userSortFields: action.payload}
        case GET_STATISTICS_USER_SORT_FIELDS:
            return {...state, userStatisticSortFields: action.payload}
        case UPDATE_SORT_FIELDS:
            return {
                ...state,
                userSortFields: {
                    ...state.userSortFields,
                    fields: action.payload
                },
            };
        case UPDATE_STATISTIC_SORT_FIELDS:
            return {
                ...state,
                userStatisticSortFields: {
                    ...state.userStatisticSortFields,
                    fields: action.payload
                },
            };
        case ROLE_FIELD:
            return {
                ...state, rolesData:
                    {
                        ...state.rolesData,
                        [action.payload.fieldName]: action.payload.value,
                    }
            }
        case SET_ROLE_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_ROLE_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case SET_ROLES_LIST_DATA_SORT:
            return {...state, dataSort: action.payload};
        case SET_ROLE_ARCHIVED_MESSAGE_SUCCESS:
            return {...state, roleArchivedSuccess: action.payload}
        case HIDE_ROLE_ARCHIVED_MESSAGE:
            return {...state, roleArchivedMessage: false}
        case SET_ROLE_MODULES_FIELD:
            const moduleIndex = state.rolesData.roleModules.findIndex((module: any) => module.name === action.payload.moduleName);
            if (moduleIndex !== -1) {
                // @ts-ignore
                const updatedModule = {...state.rolesData.roleModules[moduleIndex],
                    permissionId: action.payload.value,
                };
                const updatedRoleModules = [...state.rolesData.roleModules];
                // @ts-ignore
                updatedRoleModules[moduleIndex] = updatedModule;
                return {
                    ...state,
                    rolesData: {
                        ...state.rolesData,
                        roleModules: updatedRoleModules,
                    },
                };
            }
            return state;
        case UPDATE_PERMISSIONS:
            return {
                ...state,
                rolesData: {
                    ...state.rolesData,
                    roleModules: state.rolesData.roleModules.map((module: any) => {
                        if (module.name === action.moduleName) {
                            return {...module,
                                permissions: action.updatedPermissions,
                            };
                        }
                        return module;
                    }),
                },
            };
        case ADD_ROLE_GROUP_BRANCH:
            let branchesId = action.payload.id.map((item: any) => {
                return item
            })

            return {
                ...state, rolesData:
                    {
                        ...state.rolesData,
                        roleBranches: branchesId.map((item: { id: number;}) => {
                            return {id: item.id};
                        })
                    }
            };
        case DELETE_ROLE_BRANCH:
            return {
                ...state, rolesData:
                    {
                        ...state.rolesData,
                        // @ts-ignore
                        roleBranches: state.rolesData.roleBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        case UPDATE_PERMISSION_ACTIVE:
            return {
                ...state,
                rolesData: {
                    ...state.rolesData,
                    roleModules: state.rolesData.roleModules.map((module) => {
                        // @ts-ignore
                        if (module.name === action.moduleName) {
                            // @ts-ignore
                            return {...module, permissions: module.permissions.map((permission: { id: any; }) => {
                                    if (permission.id === action.permissionId) {
                                        return {
                                            ...permission,
                                            isActive: action.newIsActive,
                                        };
                                    }
                                    return permission;
                                }),
                            };
                        }
                        return module;
                    }),
                },
            };
        default:
            return state;
    }
};
