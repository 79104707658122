import React, {useEffect, useState} from 'react';
import {OrganizationChart} from 'primereact/organizationchart';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {getUsersManagerBranchesList} from "../../../../redux/api/apiUsersList";

const UserBranchInfo = () => {
    const dispatch = useAppDispatch();
    const userBranchesList = useAppSelector(state => state.BranchesListReducer.userBranchesList);
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    const [branchVisible, setBranchVisible] = useState(false);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');

    const {
        visible: hasUsersManagerVisionPermission
    } = usersManagerPermissions;

    function reformatKeysWithExpanded(dataArray: any[], parentKey = "") {
        let index = 0;
        return dataArray?.map((item) => {
            index++;
            const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
            const newItem = {
                ...item,
                key: key,
                expanded: true,
                className: userData?.user?.branchId === item.data.id ? "active-branch text-white" : "bg-bluegray-400 text-white",
                label: item.data.name
            };

            if (item.children && item.children.length > 0) {
                newItem.children = reformatKeysWithExpanded(item.children, key);
            }
            return newItem;
        });
    }

    let newBranch = reformatKeysWithExpanded(userBranchesList);

    useEffect(() => {
        dispatch(getUsersManagerBranchesList(jwtToken?.jwtToken));
    }, []);

    const nodeTemplate = (node: any) => {
        return (
            <div className="flex flex-column">
                <div className="flex flex-column align-items-center">
                    <span className="font-bold mb-2">{node.label}</span>
                    <span className="text-xs">{node.data.ownerName}</span>
                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog header="Архітектура гілок" style={{minWidth: '80vw', height: '90vw', width: '98vw'}} visible={branchVisible} onHide={() => setBranchVisible(false)}>
                <OrganizationChart className="branch-organisation-chat" value={newBranch} nodeTemplate={nodeTemplate}/>
            </Dialog>
            <div className="overflow-x-auto">
                <div>
                    {(hasUsersManagerVisionPermission && <Button label="" icon="pi pi-external-link" onClick={() => setBranchVisible(true)}/>)}
                </div>
                {newBranch && newBranch?.length ? <OrganizationChart value={newBranch} className="branch-organizationchart-table branch-organizationchart-user-table" nodeTemplate={nodeTemplate}/> : ''}
            </div>
        </>
    )
};

export default UserBranchInfo;
