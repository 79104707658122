import {FilterMatchMode, FilterOperator} from "primereact/api";

import {
    ADD_QUEUE_USER, ADD_QUEUES_SKILL_GROUP, ADD_UTILIZATION_SKILL_GROUP,
    ARCHIVE_QUEUE_TABLE_TOGGLE,
    CHANGE_QUEUE_USER_LEVEL, CHANGE_QUEUES_SKILL_GROUP_LEVEL, CHANGE_UTILIZATION_INDEX, DELETE_QUEUE_BRANCH,
    DELETE_QUEUE_USER, DELETE_QUEUES_SKILL_GROUP, DELETE_UTILIZATION_SKILL_GROUP, GET_ALL_QUEUES,
    GET_QUEUE,
    GET_QUEUE_DIRECTORIES_QUEUE_AUDIO_TYPE,
    GET_QUEUE_DIRECTORIES_QUEUE_DIRECTIONS_TYPE,
    GET_QUEUE_DIRECTORIES_QUEUE_OPERATORS_TYPE,
    GET_QUEUE_DIRECTORIES_QUEUE_TYPE,
    GET_QUEUE_DIRECTORIES_TRANKS_PHONE,
    GET_QUEUE_PAGINATION,
    GET_QUEUES,
    INITIAL_QUEUE_DATA,
    QUEUE_ACD_FIELD,
    QUEUE_CLEAR_FILTERS,
    QUEUE_DIALOG_VISIBLE,
    QUEUE_FIELD,
    SELECTED_QUEUE_ID,
    SET_QUEUE_CURRENT_PAGE,
    SET_QUEUE_CURRENT_SIZE,
    SET_QUEUE_ERROR_FIELDS,
    SET_QUEUE_TRANK_ID,
    SET_TRANK_IDS,
    SHOW_QUEUE_DETAIL_LOADING,
    SHOW_QUEUE_LOADER,
    TOGGLE_DAY_ACTIVE,
    UPDATE_SCHEDULER_TIME
} from "../types/typesQueue";
import {ADD_QUALIFICATION_GROUP_BRANCH} from "../types/typesQualificationGroup";

const initialState = {
    archiveQueueTableToggle: true,
    queueDialogVisible: false,
    selectedQueueId: null,
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        branchId: {value: null, matchMode: FilterMatchMode.IN},
        isGeneralTrunk: {value: null, matchMode: FilterMatchMode.IN},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        priority: {value: null, matchMode: FilterMatchMode.IN},
        isActive: {value: null, matchMode: FilterMatchMode.IN},
    },
    queuesList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    queueData: {
        queue: {
            name: null,
            priority: 0,
            isActive: false,
            isGeneralTrunk: true,
            strategyOperators: 303,
            strategyOperatorsForGarbage: 303,
            description: null,
            branchId: null
        },
        queueACD: {
            isMWTNoAgentAudio: true,
            isSchedulerAudio:true,
            isMWTAudio: true
        },
        scheduler: {
            typeScheduler: 402,
            days: []
        },
        queueUsers: [],
        skillGroups: [],
        queueBranches: [],
        skillGroupsGarbage: [],
        queueTrunks: null
    },
    loading: false,
    queueTrankIds: null,
    detailsLoading: false,
    errorFields: null,
    queuesDirectoryQueueType: [],
    queuesDirectoryQueueOperatorsType: [],
    queuesDirectoryQueueAudioType: [],
    queuesDirectoryDirectionType: [],
    queuesDirectoryTrunksPhone: [],
    allQueues: []
}

export const QueueListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_QUEUE_TABLE_TOGGLE:
            return {...state, archiveQueueTableToggle: action.payload}
        case QUEUE_DIALOG_VISIBLE:
            return {...state, queueDialogVisible: action.payload}
        case SELECTED_QUEUE_ID:
            return {...state, selectedCompanyId: action.payload}
        case ADD_QUEUE_USER:
            let usersId = action.payload.userId.map((item: any) => {
                return item
            })
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        queueUsers: usersId.map((item: { userId: any; userLevel: any; userName: any; }) => {
                            return {userId: item.userId, userLevel: item.userLevel, userName: item.userName}
                        })
                    }
            }
        case CHANGE_QUEUE_USER_LEVEL:
            return {
                ...state,
                queueData: {
                    ...state.queueData,
                    queueUsers: state?.queueData?.queueUsers.map((user: any) =>
                        user.userId === action.payload.userId
                            ? {...user, userLevel: action.payload.userLevel}
                            : user
                    )
                }
            };
        case DELETE_QUEUE_USER:
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        // @ts-ignore
                        queueUsers: state.queueData.queueUsers.filter((item: any) => item.userId !== action.payload)
                    }
            }
        case DELETE_QUEUE_BRANCH:
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        // @ts-ignore
                        queueBranches: state.queueData.queueBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        case DELETE_QUEUES_SKILL_GROUP:
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        skillGroups: state.queueData.skillGroups.filter((item: any) => item.skillGroupId !== action.payload)
                    }
            }
        case ADD_QUEUES_SKILL_GROUP:
            let skillGroupId = action.payload.skillGroupId.map((item: any) => {
                return item
            })
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        skillGroups: skillGroupId.map((item: { skillGroupId: any; skillGroupLevel: any;}) => {
                            return {skillGroupId: item.skillGroupId, skillGroupLevel: item.skillGroupLevel}
                        })
                    }
            }
        case CHANGE_QUEUES_SKILL_GROUP_LEVEL:
            return {
                ...state,
                queueData: {
                    ...state.queueData,
                    skillGroups: state?.queueData?.skillGroups.map((user: any) =>
                        user.skillGroupId === action.payload.skillGroupId
                            ? {...user, skillGroupLevel: action.payload.skillGroupLevel}
                            : user
                    )
                }
            };
        case DELETE_UTILIZATION_SKILL_GROUP:
            const updatedSkillGroups = state.queueData.skillGroupsGarbage?.filter((item: any) => item.skillGroupId !== action.payload);

            const reorderedSkillGroups = updatedSkillGroups.map((item: any, index) => ({
                ...item,
                displayOrder: index + 1
            }));

            return {
                ...state, queueData: {
                    ...state.queueData,
                    skillGroupsGarbage: reorderedSkillGroups
                }
            }
        case ADD_UTILIZATION_SKILL_GROUP:
            let skillGroupIds = action.payload.skillGroupId.map((item: any) => {
                return item
            })
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        skillGroupsGarbage: skillGroupIds.map((item: { skillGroupId: any; utilizationGroupLevel: any;}, index: any) => {
                            return {skillGroupId: item.skillGroupId, displayOrder: index + 1}
                        })
                    }
            }
        case CHANGE_UTILIZATION_INDEX:
            return {...state, queueData: {...state.queueData, skillGroupsGarbage: action.payload}};
        case QUEUE_FIELD:
            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        [action.payload.section]: {
                            // @ts-ignore
                            ...state.queueData[action.payload.section],
                            [action.payload.fieldName]: action.payload.value,
                        }
                    }
            }
        case QUEUE_ACD_FIELD:
            return {
                ...state,
                queueData: {
                    ...state.queueData,
                    [action.payload.section]: {
                        // @ts-ignore
                        ...state.queueData[action.payload.section],
                        [action.payload.fieldName]: {
                            // @ts-ignore
                            ...state.queueData[action.payload.section][action.payload.fieldName],
                            [action.payload.acdFieldName]: action.payload.value,
                        }
                    }
                }
            }
        case QUEUE_CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    branchId: {value: null, matchMode: FilterMatchMode.IN},
                    isGeneralTrunk: {value: null, matchMode: FilterMatchMode.IN},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    priority: {value: null, matchMode: FilterMatchMode.IN},
                    isActive: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case SET_QUEUE_TRANK_ID:
            return {...state, queueData: {...state.queueData, trankId: action.payload}};
        case GET_QUEUES:
            return {...state, queuesList: action.payload}
        case GET_QUEUE_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_QUEUE_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_QUEUE_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_QUEUE:
            return {...state, queueData: action.payload}
        case SHOW_QUEUE_LOADER:
            return {...state, loading: action.payload}
        case SHOW_QUEUE_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case INITIAL_QUEUE_DATA:
            return {...state, queueData: initialState.queueData}
        case SET_QUEUE_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case TOGGLE_DAY_ACTIVE: {
            const {dayOfWeek, isActive} = action.payload;
            // @ts-ignore
            let updatedDays = [...state.queueData.scheduler.days];

            if (isActive) {
                // @ts-ignore
                const dayExists = updatedDays.find(day => day.dayOfWeek === dayOfWeek);
                if (!dayExists) {
                    // @ts-ignore
                    updatedDays.push({dayOfWeek: dayOfWeek});
                }
            } else {
                // @ts-ignore
                updatedDays = updatedDays.filter(day => day.dayOfWeek !== dayOfWeek);
            }
            return {
                ...state,
                queueData: {
                    ...state.queueData,
                    scheduler: {
                        // @ts-ignore
                        ...state.queueData.scheduler,
                        days: updatedDays,
                    },
                },
            };
        }
        case UPDATE_SCHEDULER_TIME: {
            const {dayOfWeek, newTime, type} = action.payload;
            // @ts-ignore
            let updatedDays = state.queueData.scheduler?.days?.map(day => {
                // @ts-ignore
                if (day.dayOfWeek === dayOfWeek) {return {...day, [type]: newTime};}
                return day;
            });

            return {
                ...state,
                queueData: {
                    ...state.queueData,
                    scheduler: {
                        // @ts-ignore
                        ...state.queueData.scheduler,
                        days: updatedDays,
                    },
                },
            };
        }
        case ADD_QUALIFICATION_GROUP_BRANCH:
            let branchesId = action.payload.id.map((item: any) => {
                return item
            })

            return {
                ...state, queueData:
                    {
                        ...state.queueData,
                        queueBranches: branchesId.map((item: { id: number;}) => {
                            return {id: item.id};
                        })
                    }
            };
        case GET_QUEUE_DIRECTORIES_QUEUE_TYPE:
            return {...state, queuesDirectoryQueueType: action.payload}
        case GET_QUEUE_DIRECTORIES_QUEUE_OPERATORS_TYPE:
            return {...state, queuesDirectoryQueueOperatorsType: action.payload}
        case GET_QUEUE_DIRECTORIES_QUEUE_AUDIO_TYPE:
            return {...state, queuesDirectoryQueueAudioType: action.payload}
        case GET_QUEUE_DIRECTORIES_QUEUE_DIRECTIONS_TYPE:
            return {...state, queuesDirectoryDirectionType: action.payload}
        case SET_TRANK_IDS:
            return {...state, queueTrunkIds: action.payload};
        case GET_QUEUE_DIRECTORIES_TRANKS_PHONE:
            return {...state, queuesDirectoryTrunksPhone: action.payload}
        case GET_ALL_QUEUES:
            return {...state, allQueues: action.payload}
        default:
            return state
    }
}
