import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {setRoleModuleField, updatePermissionActive, updatePermissions} from "../../../../redux/actions/actionsRoles";
import {menuRoleConfig} from "../../../../redux/config";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

const RoleModel = (props: any) => {
    const dispatch = useAppDispatch();
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData);
    const rolesDictionary = useAppSelector(state => state.RolesReducer.rolesDictionary);
    const newObject = {"dictionaryId": "nullId", "parentId": null, "name": "Hide", "description": null, "isActive": true, "orderBy": null};
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const errorFields = useAppSelector(state => state.RolesReducer.errorFields) as unknown as any;
    const rolePermissions = checkPermissions(rolesVision, 'Role');
    const archiveTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle)
    const editType = useAppSelector(state => state.UsersReducer.editType);

    const {
        // create: hasRoleCreatePermission,
        // update: hasRoleUpdatePermission
    } = rolePermissions;

    const updatedRolesDictionary = [...rolesDictionary, newObject];
    const updatedRoleModules = rolesData?.roleModules.map((module: any) => {
        if (module.name === 'Status' ||  module.name === 'Trunks' || module.name === 'UsersManager' || module.name === 'Role' || module.name === 'Audio') {
            if (module.permissionId === null) {
                return {
                    ...module,
                    permissionId: "nullId",
                    submenu: true
                };
            }
            return {
                ...module,
                submenu: true
            }
        } else {
            if (module.permissionId === null) {
                return {
                    ...module,
                    permissionId: "nullId",
                    submenu: false
                };
            }
            return {
                ...module,
                submenu: false
            };
        }
    });

    const findMenuItem = (items: any[], name: string): any | undefined => {
        for (const item of items) {
            if (item.name === name) {
                return item;
            } else if (item.items) {
                const found = findMenuItem(item.items, name);
                if (found) return found;
            }
        }
        return undefined;
    };

    const getLabelByName = (name: string) => {
        const menuItem = findMenuItem(menuRoleConfig, name);
        return menuItem ? menuItem.label : name;
    };

    const getIconByName = (name: string) => {
        const menuItem = findMenuItem(menuRoleConfig, name);
        return menuItem ? menuItem.icon : '';
    };

    const handleCheckboxChange = (moduleName: any, permissionId: any, newIsActive: any) => {
        dispatch(updatePermissionActive(moduleName, permissionId, newIsActive));
    };

    const handleDropdownChange = (data: any) => {
        const { value, moduleName } = data;

        const moduleToUpdate = rolesData.roleModules.find((module: any) => module.name === moduleName);
        if (moduleToUpdate) {
            // @ts-ignore
            if ((moduleToUpdate.permissionId === 'nullId' || !moduleToUpdate.permissionId) && value !== 'nullId') {
                const updatedPermissions: { id: number; isActive: boolean }[] = [
                    {
                        id: 101,
                        isActive: true,
                    },
                    {
                        id: 102,
                        isActive: false,
                    },
                    {
                        id: 103,
                        isActive: false,
                    },
                    {
                        id: 104,
                        isActive: false,
                    },
                ];

                dispatch(updatePermissions(moduleName, updatedPermissions));
            }
            // @ts-ignore
            if ((moduleToUpdate.permissionId !== 'nullId' || !moduleToUpdate.permissionId) && value === 'nullId') {
                const updatedPermissions: { id: number; isActive: boolean }[] = [];

                dispatch(updatePermissions(moduleName, updatedPermissions));
            }
            if (data.value === 'nullId') {
                data.value = null;
            }
            dispatch(setRoleModuleField(data));
        }
    };

    return (
        <div className="grid">
            <div className="flex col-12 md:col-12 py-0">
                <div className="col-12 md:col-3 flex align-items-center">Розділ меню</div>
                <div className="col-12 md:col-9 flex uppercase">
                    <div className="col-12 md:col-3">Доступ</div>
                    <div className="col-12 md:col-3">Створення</div>
                    <div className="col-12 md:col-3">Редагування</div>
                    <div className="col-12 md:col-3">Архівація</div>
                </div>
            </div>
            <div className="grid col-12 md:col-12 flex py-0">
                {rolesData && rolesData?.roleModules && updatedRoleModules.map((module: any) => {
                    return !module?.submenu && <React.Fragment key={module.name}>
                        <div className="col-12 md:col-3">
                            <React.Fragment key={module.name}>
                                <div className="field col-12 md:col-12 flex align-items-center m-0 w-full">
                                    <i className={getIconByName(module.name)}></i>
                                    <p className="ml-2">{getLabelByName(module.name)}</p>
                                </div>
                            </React.Fragment>
                        </div>
                        <div className="col-12 md:col-9">
                            <div className="col-12 md:col-12 flex py-0">
                                <div className="col-12 md:col-3 py-0">
                                    <Dropdown value={module.permissionId || ''} onChange={(e) => {
                                        handleDropdownChange({
                                            section: 'roleModules',
                                            fieldName: 'permissionId',
                                            value: e.value,
                                            moduleName: module?.name
                                        })
                                    }}
                                              options={updatedRolesDictionary}
                                              className={classNames('w-full p-0', {'p-invalid': errorFields?.permissionId})}
                                              optionLabel="name"
                                              disabled={module?.isDisabled || props.isDisabled || !archiveTableToggle || !rolesData?.isEdit || !editType}
                                              optionValue="dictionaryId"
                                              placeholder="Рівень доступу"
                                    />
                                </div>
                                {module.permissions && module.permissions
                                    .filter((permission: any) => permission.id !== 101).map((permission: any) => (
                                        <div className="col-12 md:col-3 pl-5" key={permission.id}>
                                            <Checkbox onChange={(e) =>
                                                handleCheckboxChange(
                                                    module.name,
                                                    permission.id,
                                                    e.checked
                                                )
                                            } checked={permission?.isActive} disabled={module?.isDisabled || props.isDisabled || !archiveTableToggle || !rolesData?.isEdit || !editType}></Checkbox>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </React.Fragment>
                })
                }
                 <React.Fragment>
                     <div className="col-12 md:col-3">
                         <React.Fragment>
                             <div className="field col-12 md:col-12 flex align-items-center m-0 w-full">
                                 <i className={getIconByName('Settings')}></i>
                                 <p className="ml-2">{getLabelByName('Settings')}</p>
                             </div>
                         </React.Fragment>
                     </div>
                     <div className="col-12 md:col-9">
                         <div className="col-12 md:col-12 flex py-0">
                             <div className="col-12 md:col-3 py-0">
                             </div>
                         </div>
                     </div>
                </React.Fragment>
                {rolesData && rolesData?.roleModules && updatedRoleModules.map((module: any) => {
                    return module?.submenu && <React.Fragment key={module.name}>
                        <div className="col-12 md:col-3">
                            <React.Fragment key={module.name}>
                                <div className="field col-12 md:col-12 flex align-items-center m-0 w-full pl-5">
                                    <i className={getIconByName(module.name)}></i>
                                    <p className="ml-2">{getLabelByName(module.name)}</p>
                                </div>
                            </React.Fragment>
                        </div>
                        <div className="col-12 md:col-9">
                            <div className="col-12 md:col-12 flex py-0">
                                <div className="col-12 md:col-3 py-0">
                                    <Dropdown value={module.permissionId || ''} onChange={(e) => {
                                        handleDropdownChange({
                                            section: 'roleModules',
                                            fieldName: 'permissionId',
                                            value: e.value,
                                            moduleName: module?.name
                                        })
                                    }}
                                              options={updatedRolesDictionary}
                                              className={classNames('w-full p-0', {'p-invalid': errorFields?.permissionId})}
                                              optionLabel="name"
                                              disabled={module?.isDisabled || props.isDisabled || !archiveTableToggle || !rolesData?.isEdit || !editType}
                                              optionValue="dictionaryId"
                                              placeholder="Рівень доступу"
                                    />
                                </div>
                                {module.permissions && module.permissions
                                    .filter((permission: any) => permission.id !== 101).map((permission: any) => (
                                        <div className="col-12 md:col-3 pl-5" key={permission.id}>
                                            <Checkbox onChange={(e) =>
                                                handleCheckboxChange(
                                                    module.name,
                                                    permission.id,
                                                    e.checked
                                                )
                                            } checked={permission?.isActive} disabled={module?.isDisabled || props.isDisabled || !archiveTableToggle || !rolesData?.isEdit || !editType}></Checkbox>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </React.Fragment>
                })
                }
            </div>
        </div>
    )
};

export default RoleModel;
