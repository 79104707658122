import {
    ADD_QUEUE_USER, ADD_QUEUES_SKILL_GROUP, ADD_UTILIZATION_SKILL_GROUP,
    ARCHIVE_QUEUE_TABLE_TOGGLE, CHANGE_QUEUE_USER_LEVEL, CHANGE_QUEUES_SKILL_GROUP_LEVEL, CHANGE_UTILIZATION_INDEX, DELETE_QUEUE_BRANCH, DELETE_QUEUE_USER, DELETE_QUEUES_SKILL_GROUP, DELETE_UTILIZATION_SKILL_GROUP,
    INITIAL_QUEUE_DATA, QUEUE_ACD_FIELD,
    QUEUE_CLEAR_FILTERS,
    QUEUE_DIALOG_VISIBLE,
    QUEUE_FIELD,
    SELECTED_QUEUE_ID,
    SET_QUEUE_CURRENT_PAGE,
    SET_QUEUE_CURRENT_SIZE,
    SET_QUEUE_ERROR_FIELDS,
    SET_TRANK_IDS,
    SHOW_QUEUE_LOADER,
    TOGGLE_DAY_ACTIVE,
    UPDATE_SCHEDULER_TIME
} from "../types/typesQueue";

export function setArchiveQueueTableToggle(state: boolean) {
    return {
        type: ARCHIVE_QUEUE_TABLE_TOGGLE,
        payload: state,
    }
}

export function setQueueDialogVisible(state: boolean) {
    return {
        type: QUEUE_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setSelectedQueueIndex(state: number) {
    return {
        type: SELECTED_QUEUE_ID,
        payload: state,
    }
}

export function setQueueField(state: { section: string, fieldName: string, value: any }) {
    return {
        type: QUEUE_FIELD,
        payload: state,
    }
}

export function setQueueACDField(state: { section: string, fieldName: string, acdFieldName: any, value: any }) {
    return {
        type: QUEUE_ACD_FIELD,
        payload: state,
    }
}

export function setQueueClearFilters() {
    return {
        type: QUEUE_CLEAR_FILTERS,
    }
}

export function showQueueLoader(state: boolean) {
    return {
        type: SHOW_QUEUE_LOADER,
        payload: state,
    }
}

export function initialQueueData() {
    return {
        type: INITIAL_QUEUE_DATA,
    }
}

export function setQueueErrorField(state: any) {
    return {
        type: SET_QUEUE_ERROR_FIELDS,
        payload: state,
    }
}


export function setQueueCurrentPage(state: any) {
    return {
        type: SET_QUEUE_CURRENT_PAGE,
        payload: state
    }
}

export function setQueueCurrentPageSize(state: any) {
    return {
        type: SET_QUEUE_CURRENT_SIZE,
        payload: state
    }
}

export function setQueueTrankIds(state: any) {
    return {
        type: SET_TRANK_IDS,
        payload: state,
    }
}

export function addQueueUser(state: {queueId:string, userId:any}) {
    return {
        type: ADD_QUEUE_USER,
        payload: state,
    }
}

export function changeQueueUserLevel(state: {queueId:string, userId: string, userLevel: any}) {
    return {
        type: CHANGE_QUEUE_USER_LEVEL,
        payload: state,
    }
}

export function deleteQueueUser(state: string) {
    return {
        type: DELETE_QUEUE_USER,
        payload: state,
    }
}

export function deleteQueueBranch(state: string) {
    return {
        type: DELETE_QUEUE_BRANCH,
        payload: state,
    }
}

export const updateDayTime = (dayOfWeek: any, newTime: any, type: any) => ({
        type: UPDATE_SCHEDULER_TIME,
        payload: { dayOfWeek, newTime, type },
});

export const toggleDayActive = (dayOfWeek: any, isActive: any) => ({
    type: TOGGLE_DAY_ACTIVE,
    payload: { dayOfWeek, isActive },
});


export function addQueueSkillGroup(state: {queueId:string, skillGroupId:any}) {
    return {
        type: ADD_QUEUES_SKILL_GROUP,
        payload: state,
    }
}

export function changeQueueSkillGroupLevel(state: {queueId:string, skillGroupId: string, skillGroupLevel: any}) {
    return {
        type: CHANGE_QUEUES_SKILL_GROUP_LEVEL,
        payload: state,
    }
}

export function deleteQueueSkillGroup(state: string) {
    return {
        type: DELETE_QUEUES_SKILL_GROUP,
        payload: state,
    }
}

export function addQueueUtilizationGroup(state: {queueId:string, skillGroupId:[]}) {
    return {
        type: ADD_UTILIZATION_SKILL_GROUP,
        payload: state,
    }
}

export function changeQueueUtilizationGroupIndex(state: any) {
    return {
        type: CHANGE_UTILIZATION_INDEX,
        payload: state,
    }
}

export function deleteQueueUtilizationGroup(state: string) {
    return {
        type: DELETE_UTILIZATION_SKILL_GROUP,
        payload: state,
    }
}
