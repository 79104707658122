import {
    GET_USER_BRANCHES, GET_USER_COUNTRIES, GET_USER_LANGUAGES,
    GET_USER_ROLES, HIDE_USER_CREATE_MESSAGE, INITIAL_USER_DATA, SET_USER_ERROR_FIELDS, SHOW_USER_CREATE_MESSAGE, SHOW_USER_DELETE_MESSAGE, SHOW_USER_SAVE_MESSAGE,
    USER_FIELD, USER_STATE_SAVE,
    USERS_DIALOG_VISIBLE,
    HIDE_USER_SAVE_MESSAGE, SHOW_USER_DETAIL_LOADING, SHOW_USER_ERROR, SET_USER_CURRENT_PAGE, SET_USER_CURRENT_SIZE, USERS_TABLE_TOGGLE, GET_USER_STATUSES, SET_USER_LIST_DATA_SORT, USERS_DIALOG_UNZIPPING, SET_USER_TRUNK_IDS, GET_USER_STATUSES_BY_ID
} from "../types/typesUsersList";

export function setUsersDialogVisible(state:boolean) {
    return {
        type: USERS_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setUserField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: USER_FIELD,
        payload: state,
    }
}
export function getUserRoles (state:any) {
    return {
        type: GET_USER_ROLES,
        payload: state,
    }
}

export function getUserBranches (state:any) {
    return {
        type: GET_USER_BRANCHES,
        payload: state,
    }
}

export function getUserLanguages (state:any) {
    return {
        type: GET_USER_LANGUAGES,
        payload: state,
    }
}

export function setUserStateSave(state: boolean) {
    return {
        type: USER_STATE_SAVE,
        payload: state,
    }
}

export function getUserCountries (state:any) {
    return {
        type: GET_USER_COUNTRIES,
        payload: state,
    }
}

export function getUserStatusesData (state:any) {
    return {
        type: GET_USER_STATUSES,
        payload: state,
    }
}

export function getUserStatusesById (state:any) {
    return {
        type: GET_USER_STATUSES_BY_ID,
        payload: state,
    }
}

export function initialUserData() {
    return {
        type: INITIAL_USER_DATA,
    }
}

export function setUserErrorFields(state:any) {
    return {
        type: SET_USER_ERROR_FIELDS,
        payload: state,
    }
}

export function showUserCreateMessage() {
    return {
        type: SHOW_USER_CREATE_MESSAGE,
    }
}

export function showUserSaveMessage() {
    return {
        type: SHOW_USER_SAVE_MESSAGE,
    }
}

export function showUserDeleteMessage(state: boolean) {
    return {
        type: SHOW_USER_DELETE_MESSAGE,
        payload: state,
    }
}

export function hideUserCreateMessage() {
    return {
        type: HIDE_USER_CREATE_MESSAGE,
    }
}

export function hideUserSaveMessage() {
    return {
        type: HIDE_USER_SAVE_MESSAGE,
    }
}

export function showUserDetailsLoading(state: boolean) {
    return {
        type: SHOW_USER_DETAIL_LOADING,
        payload: state,
    }
}

export function setUserError(state: boolean) {
    return {
        type: SHOW_USER_ERROR,
        payload: state
    }
}

export function setUsersActiveTableToggle(state: boolean) {
    return {
        type: USERS_TABLE_TOGGLE,
        payload: state
    }
}

export function setUserDialogUnzipping(state: boolean) {
    return {
        type: USERS_DIALOG_UNZIPPING,
        payload: state
    }
}

export function setCurrentPage(state: any) {
    return {
        type: SET_USER_CURRENT_PAGE,
        payload: state
    }
}

export function setCurrentPageSize(state: any) {
    return {
        type: SET_USER_CURRENT_SIZE,
        payload: state
    }
}

export function setUserListDataSort(state: any) {
    return {
        type: SET_USER_LIST_DATA_SORT,
        payload: state
    }
}

export function setUserTrunkIds(state: any) {
    return {
        type: SET_USER_TRUNK_IDS,
        payload: state,
    }
}
