import {hideError, showError} from "../actions/actions";
import axios from "axios";
import {API_URL} from "../config";
import {
    GET_NOTIFICATION_ERROR,
} from "../types/types";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";
import {getBranchDetailById} from "./apiBranchList";
import {GET_AUDIO, GET_AUDIO_PAGINATION, GET_AUDIOS, SHOW_AUDIO_CREATE_LOADER, SHOW_AUDIO_DETAIL_LOADING} from "../types/typesAudioList";
import {setAudioDialogVisible, setAudioErrorField, showAudioLoader} from "../actions/actionsAudio";
import {RoleDataSort} from "../../types/types";
import {setUserError} from "../actions/actionsUsersList";
import {setStatusArchiveTableToggle, setStatusDialogVisible} from "../actions/actionsStatuses";
import {getStatusesListWithPaginationFilterAndSorting} from "./apiStatusesList";


let dataSort = {
    pageNumber: 1,
    pageSize: 10,
    sort: {
        orderBy: "Id",
        isDesc: true
    }
}

export function getAllAudioWithPagination(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showAudioLoader(true))
        dispatch(hideError())
        axios.post(`${API_URL}Audio/GetAudioWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dataSort = data;
                dispatch({type: GET_AUDIOS, payload: response.data.data.items})
                dispatch({type: GET_AUDIO_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAudioLoader(false));
                dispatch(hideError());
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getAudioById(id: number, jwtToken: any, remote?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: SHOW_AUDIO_DETAIL_LOADING, payload: true});
        dispatch(hideError());
        axios.get(`${API_URL}Audio/GetAudioById?audioId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_AUDIO, payload: response.data.data})
                if (remote) {
                    dispatch(setAudioDialogVisible(true));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_AUDIO_DETAIL_LOADING, payload: false});
            })
    }
}

export function createAudio(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setAudioErrorField(null));
        dispatch(hideError());
        dispatch({type: SHOW_AUDIO_CREATE_LOADER, payload: true});
        axios.post(`${API_URL}Audio/CreateAudio`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_AUDIO, payload: response.data.data})
                    dispatch(getAllAudioWithPagination(dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
                if (response.data.statusCode === 400) {
                    let errorMessage = {"nameUniq": "Назва вже існує"};
                    dispatch(setAudioErrorField(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_AUDIO_CREATE_LOADER, payload: false});
            })
    }
}

export function updateAudio(data: any, jwtToken: any, branchId?: number | null) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setAudioErrorField(null));
        dispatch(hideError());
        dispatch({type: SHOW_AUDIO_CREATE_LOADER, payload: true});
        axios.post(`${API_URL}Audio/UpdateAudio`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_AUDIO, payload: response.data.data})
                    dispatch(getAllAudioWithPagination(dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    if (branchId) {
                        dispatch(getBranchDetailById(branchId, jwtToken));
                    }
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
                if (response.data.statusCode === 400 && response.data.message !== "Заборонено! Аудіо використовується в чергах.") {
                    let errorMessage = {"nameUniq": "Назва вже існує"};
                    dispatch(setAudioErrorField(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: SHOW_AUDIO_CREATE_LOADER, payload: false});
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function archiveAudio(id: number, jwtToken: any, sort? : any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}Audio/ArchiveUnArchiveAudio?audioId=${id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getAllAudioWithPagination(sort || dataSort, jwtToken));
                    dispatch(setAudioDialogVisible(false));
                } else {
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

