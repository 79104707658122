import {showStatusesErrorMessage} from "../actions/actionsStatuses";
import axios from "axios";
import {API_URL} from "../config";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {hideQualificationGroupDetailsLoader, hideQualificationGroupListLoader, setQualificationGroupArchiveTableToggle, setQualificationGroupDialogVisible, showQualificationGroupDetailsListLoader, showQualificationGroupListLoader} from "../actions/actionsQualificationGroup";
import {GET_QUALIFICATION_GROUP_DETAILS, GET_QUALIFICATION_GROUP_LIST, GET_QUALIFICATION_GROUP_PAGINATION, GET_SKILL_GROUP_LIST, GET_USERS} from "../types/typesQualificationGroup";
import {hideError} from "../actions/actionsMonitoringList";
import {GET_NOTIFICATION_ERROR} from "../types/types";
import {setUserError} from "../actions/actionsUsersList";
import {hideErrorMessage, showError} from "../actions/actions";
import {RoleDataSort} from "../../types/types";
import {SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";
import {getBranchDetailById} from "./apiBranchList";

const dataSort: RoleDataSort = {
    "isArchived": false,
    "pageNumber": 1,
    "pageSize": 10,
    "sort": {
        "orderBy": "Id",
        "isDesc": true
    },
    "filter": []
};

export function getQualificationGroupDetails(id: number, jwtToken: any, remote?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showQualificationGroupDetailsListLoader());
        axios.get(`${API_URL}SkillGroupManager/GetSkillGroupById?skillGroupId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_QUALIFICATION_GROUP_DETAILS, payload: response.data.data});
                        if (remote) {
                            dispatch(setQualificationGroupDialogVisible(true));
                        }
                    }
                    else {
                        return dispatch(showStatusesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideQualificationGroupDetailsLoader());
            })

    }
}

export function getQualificationGroupListWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showQualificationGroupListLoader());
        dispatch(hideError());
        axios.post(`${API_URL}SkillGroupManager/GetSkillGroupWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                dispatch({type: GET_QUALIFICATION_GROUP_LIST, payload: response.data.data.items})
                dispatch({type: GET_QUALIFICATION_GROUP_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideQualificationGroupListLoader());
                dispatch(hideError());
            })
    }
}

export function getQualificationGroupUsers(jwtToken: any, user: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}Users/AllSimpleUsers?isActive=true`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
                "x-access-userid": user.userId
            }
        })
            .then((response) => {
                dispatch({type: GET_USERS, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function updateQualificationGroup(data: any, jwtToken: any, sort? : RoleDataSort, branchId?:number) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.put(`${API_URL}SkillGroupManager/UpdateSkillGroup`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_QUALIFICATION_GROUP_DETAILS, payload: response.data.data})
                    // dispatch(showRoleSaveMessage());
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    if (branchId) {
                        dispatch(getBranchDetailById(branchId, jwtToken));
                    }
                } else {
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    if (response.data) {
                        dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                    }
                    else {
                        return dispatch(setUserError(true))
                    }
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}
export function createQualificationGroup(data: any, jwtToken: any, sort? : RoleDataSort) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}SkillGroupManager/CreateSkillGroup`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_QUALIFICATION_GROUP_DETAILS, payload: response.data.data})
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function archiveQualificationGroup(data: any, jwtToken: any, sort? : RoleDataSort) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}SkillGroupManager/ArchiveUnArchiveSkillGroup`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch(setQualificationGroupArchiveTableToggle(true))
                    dispatch(setQualificationGroupDialogVisible(false));
                } else {
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function copyQualificationGroup(id: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        axios.get(`${API_URL}SkillGroupManager/CopySkillGroup/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch({type: GET_QUALIFICATION_GROUP_DETAILS, payload: response.data.data});
                    dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch(setQualificationGroupArchiveTableToggle(true));

                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function getSkillGroupList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}SkillGroupManager/AllSimpleSkillGroups?isActive=true`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_SKILL_GROUP_LIST, payload: response.data.data});
                    }
                    else {
                        return dispatch(showStatusesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })

    }
}

