import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    CLEAR_STATUS_STATISTICS_FILTERS, GET_STATUS_STATISTIC,
    GET_STATUS_STATISTICS,
    GET_STATUS_STATISTICS_PAGINATION,
    HIDE_LOADER,
    INITIAL_STATUS_STATISTICS_DATA, SELECTED_STATUS_STATISTICS_ID, SET_CURRENT_STATUS_STATISTICS_PAGE,
    SET_CURRENT_STATUS_STATISTICS_SIZE,
    SET_STATUS_STATISTICS_FILTER,
    SET_STATUS_STATISTICS_LOADING,
    SHOW_DETAIL_LOADING,
    SHOW_EXPORT_STATUS_STATISTICS_LOADING
} from "../types/typesStatusStatisticsList";

const initialState = {
    statisticsStatusTableToggle: 'table1',
    selectedStatisticStatusId: null,
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        ownerCall: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        fullName: {value: null, matchMode: FilterMatchMode.IN},
        campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
        queueFullName: {value: null, matchMode: FilterMatchMode.IN},
        status: {value: null, matchMode: FilterMatchMode.IN},
        callResetName: {value: null, matchMode: FilterMatchMode.IN},
        amdHangup: {value: null, matchMode: FilterMatchMode.IN},
        typeChannel: {value: null, matchMode: FilterMatchMode.IN},
        direction: {value: null, matchMode: FilterMatchMode.IN},
        campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        start: {value: null, matchMode: FilterMatchMode.IN},
        fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
        duration: {value: null, matchMode: FilterMatchMode.IN},
        reactionClient: {value: null, matchMode: FilterMatchMode.IN},
        reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
        end: {value: null, matchMode: FilterMatchMode.IN},
    },
    statisticsStatusList: [],
    campaignListName: [],
    checkUserForBan: false,
    pagination: {
        hasNext: 0,
        pageNumber: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    statisticStatusData: {
        duration: null,
        phoneNo: null,
        contactName: null,
        description: null,
        url: null,
        end: null,
        id: null,
        campaignId: null,
        campaignName: null,
        nameFrom: null,
        nameTo: null,
        status: null,
        typeChannel: null,
        start: null,
        direction: null,
        isDeclined: false,
        username: null,
        hasDetails: false,
        sessionId: null,
        amdHangup: null,
        callReset: null
    },
    audioUrl: null,
    audioUrlLoading: false,
    audioUrlUploadLoading: false,
    loading: false,
    exportLoading: false,
    detailsLoading: false,
    error: false,
    errorUpdate: false,
    errorMessage: null,
    errorField: null,
    errorFields: null,
    pageChanged: false,
    statusDictionary: null,
    callResetDictionary: null,
    forcedClosingDictionary: null,
    statisticStatusFilterData: null
}

export const StatisticsStatusListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SELECTED_STATUS_STATISTICS_ID:
            return {...state, selectedStatisticStatusId: action.payload}
        case CLEAR_STATUS_STATISTICS_FILTERS:
            return {
                ...state, clearFilters: {
                    id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    ownerCall: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    fullName: {value: null, matchMode: FilterMatchMode.IN},
                    campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
                    queueFullName: {value: null, matchMode: FilterMatchMode.IN},
                    status: {value: null, matchMode: FilterMatchMode.IN},
                    callResetName: {value: null, matchMode: FilterMatchMode.IN},
                    amdHangup: {value: null, matchMode: FilterMatchMode.IN},
                    typeChannel: {value: null, matchMode: FilterMatchMode.IN},
                    direction: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    start: {value: null, matchMode: FilterMatchMode.IN},
                    fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
                    duration: {value: null, matchMode: FilterMatchMode.IN},
                    reactionClient: {value: null, matchMode: FilterMatchMode.IN},
                    reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
                    end: {value: null, matchMode: FilterMatchMode.IN}
                }
            }
        case GET_STATUS_STATISTICS:
            return {...state, statisticsStatusList: action.payload}
        case GET_STATUS_STATISTICS_PAGINATION:
            return {...state, pagination: {hasNext: action.payload.hasNext, pageNumber: action.payload.pageNumber}}
        case SET_CURRENT_STATUS_STATISTICS_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_CURRENT_STATUS_STATISTICS_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_STATUS_STATISTIC:
            return {...state, statisticStatusData: action.payload}
        case SET_STATUS_STATISTICS_LOADING:
            return {...state, loading: action.payload}
        case HIDE_LOADER:
            return {...state, loading: false}
        case INITIAL_STATUS_STATISTICS_DATA:
            return {...state, statisticStatusData: initialState.statisticStatusData}
        case SHOW_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case SHOW_EXPORT_STATUS_STATISTICS_LOADING:
            return {...state, exportLoading: action.payload}
        case SET_STATUS_STATISTICS_FILTER:
            return {...state, statisticStatusFilterData: action.payload}
        default:
            return state
    }
}
