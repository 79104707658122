import {InputText} from 'primereact/inputtext';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setUserField} from "../../../../redux/actions/actionsUsersList";
import {Dropdown} from "primereact/dropdown";
import moment from "moment/moment";
import {Calendar} from "primereact/calendar";
import {classNames} from "primereact/utils";
import React, {useEffect, useRef, useState} from "react";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import UserPhoneSettings from "../UserPhoneSettings";

const UsersListDialogPersonalInfo = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const days = Array.from({length: 31}, (_, index) => index + 1);
    moment.suppressDeprecationWarnings = true;
    const [day, setDay] = useState<any>('');
    const [month, setMonth] = useState<any>('');
    const [year, setYear] = useState<any>('');

    const [firstDay, setFirstDay] = useState<any>('');
    const [firstMonth, setFirstMonth] = useState<any>('');
    const [firstYear, setFirstYear] = useState<any>('');

    const [endDay, setEndDay] = useState<any>('');
    const [endMonth, setEndMonth] = useState<any>('');
    const [endYear, setEndYear] = useState<any>('');
    const emailField = useRef(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const usersDialogUnzipping = useAppSelector(state => state.UsersListReducer.usersDialogUnzipping);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const editType = useAppSelector(state => state.UsersReducer.editType);

    const {
        // create: hasUsersManagerCreatePermission,
        // update: hasUsersManagerUpdatePermission,
    } = usersManagerPermissions;
    // const scrollIntoEmailView = () => {
    //     // @ts-ignore
    //     emailField?.current?.scrollIntoView({behavior: 'smooth'});
    // }
    //
    // useEffect(() => {
    //     if (errorFields?.email) {
    //         scrollIntoEmailView();
    //     }
    // }, [errorFields, userData]);
    const getDay = (date: any) => {
        let d = new Date(date);
        d.getDay();
        return moment(d).format('DD');
    };

    const getMonth = (date: any) => {
        let d = new Date(date);
        d.getMonth();
        return moment(d).format('MM');
    };

    const getYear = (date: any) => {
        let d = new Date(date);
        d.getFullYear();
        return moment(d).format('YYYY');
    };

    const handleDayChange = (date: any) => {
        let d = new Date();
        d.setDate(date);
        return moment(d).format('DD');
    };

    const handleMonthChange = (newDate: any) => {
        let d = new Date();
        d.setMonth(newDate - 1);
        return moment(d).format('MM');
    };
    const handleYearChange = (date: any) => {
        return moment(date).format('YYYY');
    };

    useEffect(() => {
        if (day && month && year) {
            dispatch(setUserField({section: 'user', fieldName: 'birthDate', value: `${handleYearChange(year)}-${handleMonthChange(month)}-${handleDayChange(day)}`}));

        }
    }, [day, month, year]);

    useEffect(() => {
        if (firstYear && firstMonth && firstYear) {
            dispatch(setUserField({section: 'user', fieldName: 'recruitedDate', value: `${handleYearChange(firstYear)}-${handleMonthChange(firstMonth)}-${handleDayChange(firstDay)}`}));

        }
    }, [firstDay, firstMonth, firstYear]);

    useEffect(() => {
        if (endDay && endMonth && endYear) {
            dispatch(setUserField({section: 'user', fieldName: 'firedDate', value: `${handleYearChange(endYear)}-${handleMonthChange(endMonth)}-${handleDayChange(endDay)}`}));
        }
    }, [endDay, endMonth, endYear]);

    useEffect(() => {
        if (userData?.user?.birthDate) {
            let day = parseInt(getDay(userData.user.birthDate));
            let month = parseInt(getMonth(userData.user.birthDate));
            let year = getYear(userData.user.birthDate)
            setDay(day);
            setMonth(month);
            setYear(new Date(year));
        }
    }, []);


    useEffect(() => {
        if (userData?.user?.recruitedDate) {
            let day = parseInt(getDay(userData.user.recruitedDate));
            let month = parseInt(getMonth(userData.user.recruitedDate));
            let year = getYear(userData.user.recruitedDate)
            setFirstDay(day);
            setFirstMonth(month);
            setFirstYear(new Date(year));
        }
    }, []);

    useEffect(() => {
        if (userData?.user?.firedDate) {
            let day = parseInt(getDay(userData.user.firedDate));
            let month = parseInt(getMonth(userData.user.firedDate));
            let year = getYear(userData.user.firedDate)
            setEndDay(day);
            setEndMonth(month);
            setEndYear(new Date(year));
        }
    }, []);

    const months = [
        {label: '1', id: 1},
        {label: '2', id: 2},
        {label: '3', id: 3},
        {label: '4', id: 4},
        {label: '5', id: 5},
        {label: '6', id: 6},
        {label: '7', id: 7},
        {label: '8', id: 8},
        {label: '9', id: 9},
        {label: '10', id: 10},
        {label: '11', id: 11},
        {label: '12', id: 12},
    ];

    return (
        <div className="grid p-fluid">
            <div className="pl-2">
                <label htmlFor="name" className="font-bold text-base">ПiБ :</label>
            </div>
            <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left ml-2"></div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.lastName && !userData?.user?.lastName})}>Прізвище *</label>
                <InputText
                    id="name"
                    value={userData?.user?.lastName || ''}
                    maxLength={90}
                    className={classNames('', {'p-invalid': errorFields?.lastName && !userData?.user?.lastName})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'lastName', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                />
            </div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.firstName && !userData?.user?.firstName})}>Ім'я *</label>
                <InputText
                    id="name"
                    value={userData?.user?.firstName || ''}
                    maxLength={90}
                    className={classNames('', {'p-invalid': errorFields?.firstName && !userData?.user?.firstName})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'firstName', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                />
            </div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name">По батькові</label>
                <InputText
                    id="name"
                    value={userData?.user?.middleName || ''}
                    maxLength={90}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'middleName', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                />
            </div>
            <div className="field col-12 md:col-12 mb-0">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Дата народження :</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="field col-12 md:col-2">
                <div className="time-item">
                    <label htmlFor="address mb-1" className={classNames('', {'p-error': errorFields?.birthDate && !userData?.user?.birthDate && !day})}>День:</label>
                    <Dropdown
                        id="dayPicker"
                        options={days}
                        className={classNames('mt-2', {'p-invalid': errorFields?.birthDate && !userData?.user?.birthDate && !day})}
                        value={day ? day : ''}
                        onChange={e => setDay(e.target.value)}
                        placeholder="День"
                        disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    />
                </div>
            </div>
            <div className="field col-12 md:col-2">
                <div className="time-item">
                    <span className={classNames('title', {'p-error': errorFields?.birthDate && !userData?.user?.birthDate && !month})}>Місяць:</span>
                    <Dropdown
                        id="monthPicker"
                        options={months}
                        className={classNames('mt-2', {'p-invalid': errorFields?.birthDate && !userData?.user?.birthDate && !month})}
                        optionValue="id"
                        value={month ? month : ''}
                        onChange={e => setMonth(e.target.value)}
                        placeholder="Місяць"
                        disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    />
                </div>
            </div>
            <div className="field col-12 md:col-2">
                <div className="time-item">
                    <span className={classNames('title', {'p-error': errorFields?.birthDate && !userData?.user?.birthDate && !year})}>Року:</span>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className={classNames('mt-2', {'p-invalid': errorFields?.birthDate && !userData?.user?.birthDate && !year})}
                        value={year ? year : ''}
                        onChange={e => setYear(e.target.value)}
                        dateFormat="yy"
                        showIcon
                        view="year"
                        placeholder="Року"
                        disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    />
                </div>
            </div>
            <div className="field col-12 md:col-3"></div>
            <div className="field col-12 md:col-3"></div>
            <div className="grid col-12 md:col-12">
                <div className="col-12 md:col-12">
                    <div>
                        <label htmlFor="name" className="font-bold text-base">Стаж в компанii :</label>
                    </div>
                    <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                </div>
                <div className="grid col-12 md:col-6">
                    <div className="col-12 md:col-12">
                        <div>
                            <label htmlFor="name" className="text-base font-semibold">Прийнятий :</label>
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <label htmlFor="address" className={classNames('title', {'p-error': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstDay})}>День:</label>
                            <Dropdown
                                id="dayPicker"
                                options={days}
                                className={classNames('mt-2', {'p-invalid': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstDay})}
                                value={firstDay ? firstDay : ''}
                                onChange={e => setFirstDay(e.target.value)}
                                placeholder="День"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <span className={classNames('title', {'p-error': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstMonth})}>Місяць :</span>
                            <Dropdown
                                id="monthPicker"
                                className={classNames('mt-2', {'p-invalid': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstMonth})}
                                options={months}
                                optionValue="id"
                                value={firstMonth ? firstMonth : ''}
                                onChange={e => setFirstMonth(e.target.value)}
                                placeholder="Місяць"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <span className={classNames('title', {'p-error': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstMonth})}>Року :</span>
                            <Calendar
                                appendTo="self"
                                id="icon"
                                className={classNames('mt-2', {'p-invalid': errorFields?.recruitedDate && !userData?.user?.recruitedDate && !firstYear})}
                                value={firstYear ? firstYear : ''}
                                onChange={e => setFirstYear(e.target.value)}
                                dateFormat="yy"
                                showIcon
                                view="year"
                                placeholder="Року"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-12">
                        <div>
                            <label htmlFor="name" className="text-base font-semibold">Звiльнений :</label>
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <label htmlFor="address">День:</label>
                            <Dropdown
                                id="dayPicker"
                                options={days}
                                value={endDay ? endDay : ''}
                                className="mt-2"
                                onChange={e => setEndDay(e.target.value)}
                                placeholder="День"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <span className="title">Місяць :</span>
                            <Dropdown
                                id="monthPicker"
                                options={months}
                                className="mt-2"
                                optionValue="id"
                                value={endMonth ? endMonth : ''}
                                onChange={e => setEndMonth(e.target.value)}
                                placeholder="Місяць"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                    <div className="field col-12 md:col-4">
                        <div className="time-item">
                            <span className="title">Року :</span>
                            <Calendar
                                appendTo="self"
                                id="icon"
                                className="mt-2"
                                value={endYear ? endYear : ''}
                                onChange={e => setEndYear(e.target.value)}
                                dateFormat="yy"
                                showIcon
                                view="year"
                                placeholder="Року"
                                disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="field col-12 md:col-12 mb-0">*/}
            {/*    <div>*/}
            {/*        <label htmlFor="name" className="font-bold text-base mb-0">Контакти :</label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="field col-12 md:col-12 mb-0">*/}
            {/*    <UserPhoneSettings/>*/}
            {/*</div>*/}
            {/*<div className="field col-12 md:col-3" ref={emailField}>*/}
            {/*    <label htmlFor="address" className={classNames('', {'p-error': errorFields?.email})}>email:</label>*/}
            {/*    <InputText*/}
            {/*        id="address"*/}
            {/*        value={userData?.user?.email || ''}*/}
            {/*        className={classNames('', {'p-invalid': errorFields?.email})}*/}
            {/*        onChange={e => {*/}
            {/*            dispatch(setUserField({section: 'user', fieldName: 'email', value: e.target.value}));*/}
            {/*        }}*/}
            {/*        disabled={!usersDialogUnzipping && (!usersActiveTableToggle || (!editType))}*/}

            {/*    />*/}
            {/*    {errorFields && errorFields?.email && errorFields.title && <label htmlFor="address" className={classNames('mt-2 mt-2 text-xs inline', {'p-error': errorFields?.email})}>{errorFields?.title}*</label>}*/}
            {/*</div>*/}
            {/*<div className="field col-12 md:col-3"></div>*/}
        </div>
    );
};

export default UsersListDialogPersonalInfo;
