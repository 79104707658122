import {
    ADD_INBOUND_PHONE,
    ADD_OUTBOUND_PHONE,
    ARCHIVE_TRUNK_TABLE_TOGGLE,
    INITIAL_TRUNK_DATA, REMOVE_INBOUND_PHONE, REMOVE_OUTBOUND_PHONE,
    SELECTED_TRUNK_ID,
    SET_TRUNK_CURRENT_PAGE,
    SET_TRUNK_CURRENT_SIZE,
    SET_TRUNK_ERROR_FIELDS, SET_TRUNK_IDS,
    SHOW_TRUNK_LOADER,
    TRUNK_CLEAR_FILTERS,
    TRUNK_DIALOG_VISIBLE,
    TRUNK_FIELD, UPDATE_INBOUND_PHONE, UPDATE_OUTBOUND_PHONE
} from "../types/typesTrunkList";

export function setArchiveTrunkTableToggle(state: boolean) {
    return {
        type: ARCHIVE_TRUNK_TABLE_TOGGLE,
        payload: state,
    }
}

export function setTrunkDialogVisible(state: boolean) {
    return {
        type: TRUNK_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setSelectedTrunkIndex(state: number) {
    return {
        type: SELECTED_TRUNK_ID,
        payload: state,
    }
}

export function setTrunkField(state: { section: string, fieldName: string, value: any }) {
    return {
        type: TRUNK_FIELD,
        payload: state,
    }
}

export function setTrunkClearFilters() {
    return {
        type: TRUNK_CLEAR_FILTERS,
    }
}

export function showTrunkLoader(state: boolean) {
    return {
        type: SHOW_TRUNK_LOADER,
        payload: state,
    }
}

export function initialTrunkData() {
    return {
        type: INITIAL_TRUNK_DATA,
    }
}

export function setTrunkErrorField(state: any) {
    return {
        type: SET_TRUNK_ERROR_FIELDS,
        payload: state,
    }
}


export function setTrunkCurrentPage(state: any) {
    return {
        type: SET_TRUNK_CURRENT_PAGE,
        payload: state
    }
}

export function setTrunkCurrentPageSize(state: any) {
    return {
        type: SET_TRUNK_CURRENT_SIZE,
        payload: state
    }
}

export function setTrunkIds(state: any) {
    return {
        type: SET_TRUNK_IDS,
        payload: state,
    }
}

export function addOutboundPhone(state: string) {
    return {
        type: ADD_OUTBOUND_PHONE,
        payload: state,
    }
}

export function removeOutboundPhone (index: number) {
    return {
        type: REMOVE_OUTBOUND_PHONE,
        payload: index,
    }
}
export function updateOutboundPhone (index: number, newPhone: any) {
    return {
        type: UPDATE_OUTBOUND_PHONE,
        payload: { index, newPhone }
    }
}

export function addInboundPhone(state: string) {
    return {
        type: ADD_INBOUND_PHONE,
        payload: state,
    }
}

export function removeInboundPhone (index: number) {
    return {
        type: REMOVE_INBOUND_PHONE,
        payload: index,
    }
}
export function updateInboundPhone (index: number, newPhone: any) {
    return {
        type: UPDATE_INBOUND_PHONE,
        payload: { index, newPhone }
    }
}
