import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {Calendar} from "primereact/calendar";
import {setClearFilters} from "../../../../redux/actions/actions";
import {getStatusStatisticExcel} from "../../../../redux/api/apiStatusStatisticsList";
import {Checkbox} from "primereact/checkbox";

const StatusStatisticsListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const exportLoading = useAppSelector(state => state.StatisticsStatusListReducer.exportLoading);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const statisticPermissions = checkPermissions(rolesVision, 'Statistic');
    const statisticsStatusList = useAppSelector(state => state.StatisticsStatusListReducer?.statisticsStatusList);
    const [isListLength, setIsListLength] = useState(false);

    useEffect(() => {
        setIsListLength(!!statisticsStatusList?.users?.length);
    }, [statisticsStatusList]);

    const {
        update: hasUpdatePermission,
    } = statisticPermissions;

    let filteredObject = {
        "sort": props.sortData?.sort,
        "filter": props.sortData?.filter
    };

    const isDisabled = () => {
        return !props.endTime || !props.startTime || !props.filterDateTime || !props.filterEndDateTime;
    };

    return (
        <>
        <div className="table-buttons">
            <div className="text-normal-weight flex align-items-center">
                <Button
                    label="Мої активності"
                    className={props.activeTab === 'table1' ? 'mr-2 mb-2' : 'mr-2 mb-2'}
                />
                <div className="flex field align-items-center max-w-15rem ml-4 desktop-filter">
                    <span className="title pt-2" style={{width: '35px'}}>З*</span>
                    <Calendar disabled={props.allTimeChecked} className="mt-2" value={props.filterDateTime ? props.filterDateTime : null} onChange={(e: any) => {
                        // options.filterCallback(e.value, options.index);
                        props.setFilterDateTime(e.value)
                    }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2"
                        placeholder="00:00"
                        value={props.startTime ? props.startTime : null}
                        timeOnly
                        disabled={props.allTimeChecked}
                        onChange={(e) => props.setStartTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <div className="flex field align-items-center max-w-15rem ml-2 desktop-filter">
                    <span className="title pt-2" style={{width: '35px'}}>По*</span>
                    <Calendar className="mt-2" disabled={props.allTimeChecked} value={props.filterEndDateTime ? props.filterEndDateTime : null} onChange={(e: any) => {
                        props.setFilterEndDateTime(e.value)
                    }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2"
                        value={props.endTime ? props.endTime : null}
                        required
                        placeholder="00:00"
                        disabled={props.allTimeChecked}
                        timeOnly
                        onChange={(e) => props.setEndTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <div
                    className="flex align-items-center border-round-lg border-gray-600 border-1 p-2 pt-1 mb-2 ml-2 desktop-filter">
                    <Checkbox className="mr-2" onChange={e => props.setAllTimeChecked(e.checked)}
                              checked={props.allTimeChecked}/>
                    <label>За весь час</label>
                </div>
                <Button disabled={isDisabled()} onClick={() => {props.setHasStartFilter(!props.hasStartFilter);
                    if (props.allTimeChecked === null) {
                        props.setAllTimeChecked(false)
                    }
                }}
                        icon="pi pi-search" className="mb-2 ml-2 desktop-filter"/>
            </div>
            <div className="desktop">
                {hasUpdatePermission && <Button onClick={() => dispatch(getStatusStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                                label="Експорт таблиці"
                                                loading={exportLoading}
                                                disabled={!isListLength}
                                                icon="pi pi-file-excel"
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    props.resetSorting();

                }}/>
            </div>
            <div className="mobile">
                {hasUpdatePermission && <Button onClick={() => dispatch(getStatusStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                                icon="pi pi-file-excel"
                                                disabled={!isListLength}
                                                loading={exportLoading}
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())}/>
            </div>
        </div>
<div className="flex align-items-center flex-wrap">
            <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6' : `md:col-4`} sm:col-6 field align-items-center mobile-filter mb-1 px-0`}>
                <span className="title pt-2" style={{minWidth: '25px'}}>З*</span>
                <Calendar disabled={props.allTimeChecked} className="mt-2 w-full" value={props.filterDateTime ? props.filterDateTime : null} onChange={(e: any) => {
                    // options.filterCallback(e.value, options.index);
                    props.setFilterDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                <Calendar
                    appendTo="self"
                    id="icon"
                    className="mt-2 ml-2 w-full"
                    placeholder="00:00"
                    value={props.startTime ? props.startTime : null}
                    timeOnly
                    disabled={props.allTimeChecked}
                    onChange={(e) => props.setStartTime(e.value)}
                    hourFormat="24"
                />
            </div>
            <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6' : `md:col-4`} sm:col-6 field align-items-center mobile-filter mb-1 px-0`}>
                <span className="title pt-2" style={{minWidth: '25px'}}>По*</span>
                <Calendar className="mt-2 w-full" value={props.filterEndDateTime ? props.filterEndDateTime : null} onChange={(e: any) => {
                    props.setFilterEndDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" disabled={props.allTimeChecked} mask="99/99/9999"/>
                <Calendar
                    appendTo="self"
                    id="icon"
                    className="mt-2 ml-2 w-full"
                    value={props.endTime ? props.endTime : null}
                    required
                    placeholder="00:00"
                    disabled={props.allTimeChecked}
                    timeOnly
                    onChange={(e) => props.setEndTime(e.value)}
                    hourFormat="24"
                />
            </div>
    <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6 md:mb-2' : `md:col-4 md:mb-0`} sm:col-12 mb-2 sm:mb-0 p-0 sm:mb-2 justify-content-between flex sm:justify-content-between md:justify-content-start`}>
        <div
            className="align-items-center border-round-lg border-gray-600 border-1 p-2 pt-1 mx-0 md:mx-2 mobile-filter">
            <Checkbox className="mr-2" onChange={e => props.setAllTimeChecked(e.checked)}
                      checked={props.allTimeChecked}/>
            <label>За весь час</label>
        </div>
        <Button onClick={() => props.setHasStartFilter(!props.hasStartFilter)}
                disabled={isDisabled()}
                icon="pi pi-search" label="Пошук" className="mobile-filter mobile-filter-btn" style={{minWidth: '30px'}}/>
    </div>

</div>
            </>
    )
}

export default StatusStatisticsListNav;
