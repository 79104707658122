import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {setQueueField, toggleDayActive, updateDayTime} from "../../../redux/actions/actionsQueue";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {Calendar} from 'primereact/calendar';
import moment from "moment/moment";
import {classNames} from "primereact/utils";

interface SchedulerType {
    name: string;
    typeScheduler: number;
}

const QueueWorkSetting = () => {
    moment.suppressDeprecationWarnings = true;
    const dispatch = useAppDispatch();
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    const queueScheduler = queueData?.scheduler;
    // @ts-ignore
    const errorFields = useAppSelector(state => state.QueueListReducer.errorFields);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle);
    const [updatedScheduler, setUpdatedScheduler] = useState<any>({
        "typeScheduler": 402,
        "days": [
            {
                "label": "Понеділок",
                "dayOfWeek": 1
            },
            {
                "label": "Вівторок",
                "dayOfWeek": 2
            },
            {
                "label": "Середа",
                "dayOfWeek": 3
            },
            {
                "label": "Четвер",
                "dayOfWeek": 4
            },
            {
                "label": "П'ятниця",
                "dayOfWeek": 5
            },
            {
                "label": "Субота",
                "dayOfWeek": 6
            },
            {
                "label": "Неділя",
                "dayOfWeek": 0
            }
        ]
    });
    const schedulerTypes: SchedulerType[] = [
        {name: 'Дні тижня', typeScheduler: 402},
    ];

    const weekdayField = [
        {label: 'Понеділок', dayOfWeek: 1},
        {label: 'Вівторок', dayOfWeek: 2},
        {label: 'Середа', dayOfWeek: 3},
        {label: 'Четвер', dayOfWeek: 4},
        {label: 'П\'ятниця', dayOfWeek: 5},
        {label: 'Субота', dayOfWeek: 6},
        {label: 'Неділя', dayOfWeek: 0}
    ];

    useEffect(() => {
        if (queueScheduler && queueScheduler?.days) {
            const updatedDays = weekdayField.map(weekday => {
                const matchingDay = queueScheduler?.days?.find((day: { dayOfWeek: number; }) => day.dayOfWeek === weekday.dayOfWeek);

                if (matchingDay) {
                    return {...weekday, ...matchingDay};
                }

                return weekday;
            });

            setUpdatedScheduler({...queueScheduler, days: updatedDays});
        } else {
        }
    }, [queueScheduler]);

    const isValidDate = (value: any) => {
        const format = 'MM/DD/YYYY';
        return moment(value, format, true).isValid();
    };

    const handleDateChange = (date: any) => {
        return moment(date).format('MM/DD/YYYY HH:mm:ss');
    };

    const transformToDate = (dateString: any) => {
        return new Date(moment(dateString).format('MM/DD/YYYY HH:mm:ss'));
    }

    return (
        <div className="grid p-fluid">
            <div className="field col-12 md:col-6 mb-0">
                <Dropdown
                    value={queueData?.scheduler?.typeScheduler || 402}
                    onChange={(e) => {
                        dispatch(setQueueField({section: 'scheduler', fieldName: 'typeScheduler', value: e.value}));
                    }}
                    disabled={!archiveTableToggle || !editType}
                    options={schedulerTypes}
                    optionLabel="name"
                    optionValue="typeScheduler"
                    placeholder="Виберіть тип:" className="w-full py-2"
                />
            </div>
            <div className="field col-12 md:col-6"></div>
            <div className="field col-12 md:col-6">
                <label className={classNames('mb-3 font-bold', {'p-error': errorFields?.scheduler || errorFields?.scheduler})}>Розклад дзвінків</label>
                {updatedScheduler?.days?.map((field: any, index: number) => (
                    <div className="field col-12 md:col-12 mb-0 p-0" key={index}>
                        <div className="grid align-items-center">
                            <div className="col-12 md:col-4">
                                <InputText type="text" value={field.label} className="opacity-100 w-full" placeholder={field.label} disabled/>
                            </div>
                            <div className="col-2 md:col-2 flex justify-content-center">
                                <Checkbox
                                    disabled={!archiveTableToggle || !editType}
                                    className={classNames('opacity-100', {'p-invalid': errorFields?.scheduler && !updatedScheduler?.days})}
                                    checked={!!queueScheduler?.days?.find((day: { dayOfWeek: any; }) => day.dayOfWeek === field.dayOfWeek)}
                                    onChange={(e) => dispatch(toggleDayActive(field.dayOfWeek, e.checked))}
                                />
                            </div>
                            <div className="col-6 md:col-3 flex align-items-center">
                                <div className="mr-2">
                                    <label>З</label>
                                </div>
                                <Calendar
                                    className={classNames('opacity-100', {'p-invalid': errorFields?.scheduler && !updatedScheduler?.days})}
                                    disabled={!archiveTableToggle || !editType || !queueScheduler?.days?.find((day: { dayOfWeek: any; }) => day.dayOfWeek === field.dayOfWeek)}
                                    value={updatedScheduler?.days[index].start ? transformToDate(updatedScheduler?.days[index].start) : null}
                                    onChange={e => {
                                        if (isValidDate(e.target.value)) {
                                            const newTime = handleDateChange(e.target.value);
                                            dispatch(updateDayTime(field.dayOfWeek, newTime, 'start'));
                                        }
                                    }}
                                    placeholder="00:00"
                                    timeOnly
                                />
                            </div>
                            <div className="col-6 md:col-3 flex align-items-center">
                                <div className="mr-2">
                                    <label>По</label>
                                </div>
                                <Calendar
                                    disabled={!archiveTableToggle || !editType || !queueScheduler?.days?.find((day: { dayOfWeek: any; }) => day.dayOfWeek === field.dayOfWeek)}
                                    className={classNames('opacity-100', {'p-invalid': errorFields?.scheduler && !updatedScheduler?.days})}
                                    // value={updatedScheduler?.days[index].end ? transformToDate(updatedScheduler?.days[index].end) : transformToDate(new Date().setHours(0, 0, 0, 0))}
                                    value={updatedScheduler?.days[index].end ? transformToDate(updatedScheduler?.days[index].end) : null}
                                    onChange={e => {
                                        if (isValidDate(e.target.value)) {
                                            const newTime = handleDateChange(e.target.value);
                                            dispatch(updateDayTime(field.dayOfWeek, newTime, 'end'));
                                        }
                                    }}
                                    placeholder="00:00"
                                    timeOnly
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="grid col-12 md:col-12 pt-0">
                {errorFields?.scheduler && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2"><span className="title p-error text-sm">{errorFields?.scheduler?.scheduler}</span></div>}
            </div>
        </div>
    );
};

export default QueueWorkSetting;
