import {
    GET_NOTIFICATION_MESSAGE,
    GET_NOTIFICATION_MESSAGE_ERROR,
    SHOW_ACCESS_DENIED_NOTIFICATION,
    SHOW_NOT_FOUND_NOTIFICATION
} from "../types/typesNotification";

export function showAccessDeniedNotifications(state: any) {
    return {
        type: SHOW_ACCESS_DENIED_NOTIFICATION,
        payload: state
    }
}

export function showNotFoundNotifications(state: any) {
    return {
        type: SHOW_NOT_FOUND_NOTIFICATION,
        payload: state
    }
}

export function getNotificationsMessage(state: any) {
    return {
        type: GET_NOTIFICATION_MESSAGE,
        payload: state
    }
}

export function getNotificationMessageError(state: any) {
    return {
        type: GET_NOTIFICATION_MESSAGE_ERROR,
        payload: state
    }
}
