import React from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {setClearFilters, setArchiveTableToggle, setCompanyStateSave, setEditType} from "redux/actions/actions";
import {initialUserData, setUserDialogUnzipping, setUserErrorFields, setUsersActiveTableToggle, setUsersDialogVisible, setUserStateSave} from "../../../../redux/actions/actionsUsersList";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {getUserListExcel} from "../../../../redux/api/apiUsersList";

const UsersListNav = (props: any) => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const usersTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const exportLoading = useAppSelector(state => state.MonitoringListReducer.exportLoading);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');

    let filteredObject = {
        "sort": props.sortData?.sort,
        "filter": props.sortData?.filter
    };

    const {
        create: hasUsersManagerCreatePermission,
        update: hasUsersManagerUpdatePermission,
    } = usersManagerPermissions;

    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => {
                    dispatch(setUsersActiveTableToggle(true)); dispatch(setUserDialogUnzipping(true))}}
                        label="Користувачі"
                        className={usersTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => {dispatch(setUsersActiveTableToggle(false)); dispatch(setUserDialogUnzipping(false))}}
                        label="Архів"
                        className={!usersTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasUsersManagerUpdatePermission && <Button onClick={() => dispatch(getUserListExcel(props.sortData, jwtToken?.jwtToken))}
                                                label="Експорт таблиці"
                                                loading={exportLoading}
                                                icon="pi pi-file-excel"
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                {hasUsersManagerCreatePermission && (<Button label="Створити користувача"
                        icon="pi pi-plus"
                        className="mr-2 mb-2"
                        onClick={() => {
                            props.setSelectedRowData(null);
                            dispatch(initialUserData());
                            dispatch(setUsersDialogVisible(true));
                            dispatch(setUserStateSave(true));
                            dispatch(setUsersActiveTableToggle(true));
                            dispatch(setUserErrorFields(null));
                            if (hasUsersManagerCreatePermission) {
                                dispatch(setEditType(true));
                            } else {
                                dispatch(setEditType(false));
                            }
                        }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {props.resetSorting()}} />
            </div>
            <div className="mobile">
                {hasUsersManagerCreatePermission && (<Button icon="pi pi-plus"
                        className="mr-2 mb-2"
                        onClick={() => {
                            props.setSelectedRowData(null);
                            dispatch(initialUserData());
                            dispatch(setUsersDialogVisible(true));
                            dispatch(setUserErrorFields(null));
                            dispatch(setCompanyStateSave(true));
                            dispatch(setArchiveTableToggle(true));
                            if (hasUsersManagerCreatePermission) {
                                dispatch(setEditType(true));
                            } else {
                                dispatch(setEditType(false));
                            }
                        }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())} />
            </div>
        </div>
    )
}

export default UsersListNav
