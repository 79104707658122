import React from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Button} from "primereact/button";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {setEditType} from "../../../../redux/actions/actions";
import {initialAudioData, setArchiveAudioTableToggle, setAudioClearFilters, setAudioDialogVisible, setAudioErrorField} from "../../../../redux/actions/actionsAudio";

const AudioListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const trunkPermissions = checkPermissions(rolesVision, 'Audio');

    const {
        create: hasCreatePermission,
    } = trunkPermissions;

    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => {
                    dispatch(setArchiveAudioTableToggle(true))
                }}
                        label="Аудіо"
                        className={archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => {
                    dispatch(setArchiveAudioTableToggle(false))
                }}
                        label="Архів"
                        className={archiveTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasCreatePermission && (<Button label="Створити аудіо"
                                                       icon="pi pi-plus"
                                                       className="mr-2 mb-2"
                                                       onClick={() => {
                                                           dispatch(initialAudioData());
                                                           dispatch(setAudioDialogVisible(true));
                                                           dispatch(setAudioErrorField(null));
                                                           props.setSelectedRowData(null);
                                                           dispatch(setArchiveAudioTableToggle(true));
                                                           if (hasCreatePermission) {
                                                               dispatch(setEditType(true));
                                                           } else {
                                                               dispatch(setEditType(false));
                                                           }
                                                       }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    dispatch(setAudioClearFilters());
                    props.handleReset()
                }}/>
            </div>
            <div className="mobile">
                {hasCreatePermission && (
                    <Button icon="pi pi-plus"
                            className="mr-2 mb-2"
                            onClick={() => {
                                dispatch(initialAudioData());
                                dispatch(setAudioDialogVisible(true));
                                dispatch(setAudioErrorField(null));
                                props.setSelectedRowData(null);
                                dispatch(setArchiveAudioTableToggle(true));
                                if (hasCreatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}
                    />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setAudioClearFilters())}/>
            </div>
        </div>
    )
}

export default AudioListNav
