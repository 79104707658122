import React, {useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {setUserField} from "../../../../redux/actions/actionsUsersList";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {getUserCountriesData, getUserLanguagesData} from "../../../../redux/api/apiUsersList";
import {classNames} from "primereact/utils";
import {RadioButton} from "primereact/radiobutton";
import {InputNumber} from "primereact/inputnumber";

const UserDialogSettings = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const phonesDictionary = useAppSelector(state => state.UsersListReducer.phonesDictionary);
    // @ts-ignore
    const languages = useAppSelector(state => state.UsersListReducer.languages);
    // @ts-ignore
    const countries = useAppSelector(state => state.UsersListReducer.countries);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const editType = useAppSelector(state => state.UsersReducer.editType);

// @ts-ignore
    const usersDialogUnzipping = useAppSelector(state => state.UsersListReducer.usersDialogUnzipping);

    const themes = [
        {label: 'Light Theme', value: 'light'},
        {label: 'Dark Theme', value: 'dark'},
    ];

    useEffect(() => {
        dispatch(getUserCountriesData(jwtToken?.jwtToken));
        dispatch(getUserLanguagesData(jwtToken?.jwtToken));
    }, []);


    const handleThemeChange = (e: any) => {
        dispatch(setUserField({section: 'user', fieldName: 'theme', value: e.target.value}));
    };

    return (
        <div className="grid p-fluid">
            <div className="col-12 md:col-12">
                <div className="mb-4">
                    <label htmlFor="name" className="font-bold text-base">Опрацювання дзвінків :</label>
                </div>
                <div className="flex col-4 md:col-4 px-0">
                {phonesDictionary?.length > 0 && phonesDictionary.map((phone: any) => (
                    <div className="flex flex-column-reverse" style={{minWidth: '100px'}} key={phone.dictionaryId}>
                        <RadioButton disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))} inputId={phone.dictionaryId} name={phone.name} className="ml-2" value={phone.dictionaryId} onChange={e => {
                            dispatch(setUserField({section: 'user', fieldName: 'callTypeReceive', value: e.value}));
                        }} checked={userData?.user?.callTypeReceive === phone?.dictionaryId} />
                        <label htmlFor="ingredient2" className="ml-2 mb-2 mr-4">{phone.name}</label>
                    </div>
                ))}
                </div>
                <div className="grid">
                {userData?.user?.callTypeReceive === 403 && <div className="col-12 md:col-5 mt-2">
                    <div>
                        <InputNumber
                            maxLength={12}
                            id="phone"
                            value={userData?.user?.mobilePhone}
                            useGrouping={false}
                            className={classNames('mt-2 max-w-20rem mb-2', {'p-invalid p-error': errorFields?.mobilePhone})}
                            onChange={e => {
                                const inputValue = e.value;
                                if (inputValue && inputValue.toString().length <= 12) {
                                    dispatch(setUserField({section: 'user', fieldName: 'mobilePhone', value: `${e.value}`}));
                                } else if (!inputValue) {
                                    dispatch(setUserField({section: 'user', fieldName: 'mobilePhone', value: null}));
                                }
                            }}
                            placeholder="380_________"
                        />
                    </div>
                    {errorFields?.mobilePhone && <label htmlFor="name" className={classNames('p-error text-sm pt-4', {'p-error': errorFields?.mobilePhone})}>{errorFields?.mobilePhone?.name} *</label>}

                </div>}
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="col-12 md:col-12">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Локалiзация: :</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="field col-12 md:col-3">
                <label htmlFor="theme" className={classNames('', {'p-error': errorFields?.countryId && !userData?.user?.countryId})}>Країна: *</label>
                <Dropdown
                    id="theme"
                    value={userData?.user?.countryId || ''}
                    options={countries}
                    optionLabel="name"
                    optionValue="id"
                    className={classNames('mt-2', {'p-invalid': errorFields?.countryId && !userData?.user?.countryId})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'countryId', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    placeholder="Виберіть країну:"
                />
            </div>
            <div className="field col-12 md:col-3">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.utc && !userData?.user?.utc})}>UTC: *</label>
                <Dropdown
                    id="theme"
                    value={userData?.user?.utc || ''}
                    options={countries}
                    optionLabel="timeZone"
                    className={classNames('mt-2', {'p-invalid': errorFields?.utc && !userData?.user?.utc})}
                    optionValue="timeZone"
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'utc', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    placeholder="Виберіть UTC:"
                />
            </div>
            {/*<div className="col-12 md:col-9"></div>*/}

            {/*<div className="col-12 md:col-9"></div>*/}
            <div className="field col-12 md:col-3">
                <label htmlFor="theme" className={classNames('', {'p-error': errorFields?.languageId && !userData?.user?.languageId})}>Мова: *</label>
                <Dropdown
                    id="theme"
                    value={userData?.user?.languageId || ''}
                    options={languages}
                    optionLabel="uniqueSeoCode"
                    className={classNames('mt-2', {'p-invalid': errorFields?.languageId && !userData?.user?.languageId})}
                    optionValue="id"
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'languageId', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}
                    placeholder="Виберіть мову:"
                />
            </div>
            <div className="col-12 md:col-12 mb-0">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Тема:</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="theme">Тема:</label>
                <Dropdown
                    id="theme"
                    value={userData?.user?.theme || ''}
                    options={themes}
                    onChange={handleThemeChange}
                    placeholder="Виберіть тему:"
                    disabled
                />
            </div>
        </div>
    )
};

export default UserDialogSettings;
