import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../../redux/hooks";

const TrunkListDialogInfo = () => {
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData)

    const nameBodyTemplate = (rowData: any) => {
        let name = rowData?.name;
        const truncateWithEllipses = (text: string, max: number) => {
            return text.substr(0,max-1)+(text.length>max?'...':'');
        }
        if (name) {name = truncateWithEllipses(name, 156)}
        return (
            <React.Fragment>
                <div className="campaignName">
                    <p>{name}</p>
                </div>
            </React.Fragment>
        );
    };

    const operatorsBodyTemplate = () => {
        return (
            <div className="pl-1">
                {trunkData?.phonesCount || 0}
            </div>
        )
    }

    return (
        <DataTable className="user-dialog-info" value={[trunkData]}>
            <Column field="name" header="Назва" body={nameBodyTemplate} className="col-4"></Column>
            <Column header="Кількість номерів" body={operatorsBodyTemplate} className="col-4"></Column>
            <Column field="campaignTypeId" header="Стан" body={rowData => rowData?.isActive ? 'Активна' : 'Не активна'} className="col-4"></Column>
        </DataTable>
    )
}

export default TrunkListDialogInfo;
