import {
    CLEAR_FILTERS,
    SHOW_ERROR,
} from "../types/types";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ARCHIVE_BRANCH_TABLE_TOGGLE,
    BRANCH_DIALOG_VISIBLE,
    BRANCH_FIELD,
    GET_BRANCH,
    GET_BRANCH_PAGINATION,
    GET_BRANCHES,
    GET_BRANCHES_ARCHIVED,
    SELECTED_BRANCH_ID,
    SET_BRANCH_CURRENT_PAGE,
    SET_BRANCH_CURRENT_SIZE,
    SHOW_BRANCH_LOADER,
    HIDE_BRANCH_LOADER,
    INITIAL_BRANCHES_DATA,
    GET_BRANCHES_OWNERS,
    TOGGLE_USER_LIDER,
    SET_BRANCH_ERROR_FIELDS,
    GET_USER_BRANCHES_LIST,
    SHOW_BRANCH_DELETE_MESSAGE,
    SHOW_BRANCH_CREATED_MESSAGE,
    SHOW_BRANCH_UPDATED_MESSAGE,
    SHOW_BRANCH_ERROR_CREATED_MESSAGE,
    SHOW_BRANCH_ERROR_UPDATED_MESSAGE,
    SHOW_BRANCH_DETAILS_LOADER,
    HIDE_BRANCH_DETAILS_LOADER, SET_BRANCH_RESPONSE_MESSAGE, ADD_SKILL_GROUP_FOR_BRANCH, DELETE_SKILL_GROUP_FROM_BRANCH, ADD_ROLES_FOR_BRANCH, DELETE_ROLE_FROM_BRANCH, GET_BRANCHES_ACD
} from "../types/typesBranchesList";

const initialState = {
    archiveTableToggle: true,
    dialogVisible: false,
    selectedBranchId: null,
    clearFilters: {
        campaignTypeId: {value: null, matchMode: FilterMatchMode.IN},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        statusId: {value: null, matchMode: FilterMatchMode.IN},
        campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    },
    clearBranchesFilters: {
        phoneTypeId: {value: null, matchMode: FilterMatchMode.IN},
        contactName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        phoneNo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        description: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    },
    branchesList: [],
    userBranchesList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    branchesListArchived: [],
    branchData: {
        ownerName: null,
        parentName: null,
        parentId: 0,
        name: null,
        description: null,
        ownerId: null,
        isArchived: false,
        users: null,
        isMainIntegrationUrl: false,
        integrationURLId: 0
    },
    branchesOwners: null,
    branchesACD: null,
    branchDetailsLoader: false,
    loading: false,
    error: false,
    errorFields: null,
    successUpdateBranch: false,
    successCreateBranch: false,
    successDeletedBranch: false,
    errorUpdateBranch: false,
    errorCreateBranch: false,
    errorDeletedBranch: false,
    branchDeleteResponseMessage: null
}

export const BranchesListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_BRANCH_TABLE_TOGGLE:
            return {...state, archiveTableToggle: action.payload}
        case BRANCH_DIALOG_VISIBLE:
            return {...state, dialogVisible: action.payload}
        case SELECTED_BRANCH_ID:
            return {...state, selectedBranchId: action.payload}
        // case BRANCH_FIELD:
        //     return {
        //         ...state, branchData:
        //             {
        //                 ...state.branchData,
        //                 [action.payload.section]: {
        //                     // @ts-ignore
        //                     ...state.branchData[action.payload.section],
        //                     [action.payload.fieldName]: action.payload.value,
        //                 }
        //             }
        //     }
        case BRANCH_FIELD:
            return {
                ...state, branchData:
                    {
                        ...state.branchData,
                        [action.payload.fieldName]: action.payload.value,
                    }
            }
        case CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    campaignTypeId: {value: null, matchMode: FilterMatchMode.IN},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    statusId: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                }
            }
        case GET_BRANCHES:
            return {...state, branchesList: action.payload}
        case GET_USER_BRANCHES_LIST:
            return {...state, userBranchesList: action.payload}
        case GET_BRANCH_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_BRANCH_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_BRANCH_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_BRANCHES_ARCHIVED:
            return {...state, branchesListArchived: action.payload}
        case GET_BRANCH:
            return {...state, branchData: action.payload}
        case SHOW_BRANCH_LOADER:
            return {...state, loading: true}
        case HIDE_BRANCH_LOADER:
            return {...state, loading: false}
        case SHOW_ERROR:
            return {...state, error: true}
        case GET_BRANCHES_OWNERS:
            return {...state, branchesOwners: action.payload}
        case GET_BRANCHES_ACD:
            return {...state, branchesACD: action.payload}
        case INITIAL_BRANCHES_DATA:
            return {...state, branchData: initialState.branchData}
        case SET_BRANCH_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case SHOW_BRANCH_DELETE_MESSAGE:
            return {...state, companyDeleteMessage: action.payload}
        case SHOW_BRANCH_CREATED_MESSAGE:
            return {...state, successCreateBranch: action.payload}
        case SHOW_BRANCH_UPDATED_MESSAGE:
            return {...state, successUpdateBranch: action.payload}
        case SHOW_BRANCH_ERROR_CREATED_MESSAGE:
            return {...state, errorCreateBranch: action.payload}
        case SHOW_BRANCH_ERROR_UPDATED_MESSAGE:
            return {...state, errorUpdateBranch: action.payload}
        case SHOW_BRANCH_DETAILS_LOADER:
            return {...state, branchDetailsLoader: true}
        case SET_BRANCH_RESPONSE_MESSAGE:
            return {...state, branchDeleteResponseMessage: action.payload}
        case HIDE_BRANCH_DETAILS_LOADER:
            return {...state, branchDetailsLoader: false}
        case TOGGLE_USER_LIDER:
            return {
                ...state,
                branchData: {
                    ...state.branchData,
                    users: (state.branchData?.users as unknown as any[] | undefined)?.map((user: { userId: any; isLider: any }) => {
                        if (user.userId === action.payload) {
                            return {
                                ...user,
                                isLider: !user.isLider,
                            };
                        }
                        return user;
                    }) || []
                },
            };
        case ADD_SKILL_GROUP_FOR_BRANCH:
            let skillGroupIds = action.payload.id.map((item: any) => {
                return item
            })
            return {
                ...state, branchData:
                    {
                        ...state.branchData,
                        skillGroups: skillGroupIds.map((item: { id: number }) => {
                            return {id: item.id}
                        })
                    }
            }
        case DELETE_SKILL_GROUP_FROM_BRANCH:
            return {
                ...state, branchData:
                    {
                        ...state.branchData,
                        // @ts-ignore
                        skillGroups: state.branchData.skillGroups.filter((item: any) => item.id !== action.payload)
                    }
            }
        case ADD_ROLES_FOR_BRANCH:
            let roleIds = action.payload.id.map((item: any) => {
                return item
            })
            return {
                ...state, branchData:
                    {
                        ...state.branchData,
                        roles: roleIds.map((item: { id: number }) => {
                            return {id: item.id}
                        })
                    }
            }
        case DELETE_ROLE_FROM_BRANCH:
            return {
                ...state, branchData:
                    {
                        ...state.branchData,
                        // @ts-ignore
                        roles: state.branchData?.roles?.filter((item: any) => item.id !== action.payload)
                    }
            }
        default:
            return state
    }
}
