import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {classNames} from "primereact/utils";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {InputSwitch} from "primereact/inputswitch";
import {addSkillGroupBranch, deleteSkillGroupBranch, setQualificationGroupField} from "../../../redux/actions/actionsQualificationGroup";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {MultiSelect} from "primereact/multiselect";
import {getBranchDetailById} from "../../../redux/api/apiBranchList";
import {setEditType} from "../../../redux/actions/actions";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {setBranchDialogVisible} from "../../../redux/actions/actionsBranchList";

const QualificationGroupDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    const errorFields = useAppSelector(state => state.QualificationGroupListReducer.errorFields) as unknown as any;
    const descriptionField = useRef(null);
    const nameField = useRef(null);
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData) as unknown as any;
    const archiveQualificationGroupTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    let inSystem = qualificationData?.inSystem;
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        visible: hasBranchVisionPermission,
        update: hasBranchUpdatePermission
    } = branchPermissions;
    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        dispatch(setBranchDialogVisible(false));
    }, []);

    const scrollIntoDescriptionView = () => {
        // @ts-ignore
        descriptionField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!qualificationData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (!qualificationData.description && errorFields?.description) {
            scrollIntoDescriptionView();
        }
    }, [errorFields, qualificationData]);

    const onChangeNameField = (e: any) => {
        dispatch(setQualificationGroupField({section: 'qualificationData', fieldName: 'name', value: e.target.value}));
    };

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchesValue = qualificationData?.skillGroupBranches?.map((item: any) => {
        const branch = formattedBranches.find((branchItem: { id: number; }) => branchItem.id === item.id);
        const {skillGroup, ...branchWithoutId} = item;
        return {
            ...branchWithoutId,
            description: branch?.description,
            id: branch?.id,
            isArchived: branch?.isArchived,
            name: branch?.name,
            label: branch?.label,
            ownerId: branch?.ownerId,
            ownerName: branch?.ownerName,
            parentId: branch?.parentId,
            parentName: branch?.parentName,
            userCount: branch?.userCount,
            integrationURLId: branch?.integrationURLId,
            isMainIntegrationUrl: branch?.isMainIntegrationUrl,
            mainBranchId: branch?.mainBranchId
        }
    });


    const branchItems = qualificationData?.skillGroupBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasBranchVisionPermission && <Button
                            className="max-w-4rem p-button-secondary"
                            style={{minWidth: '40px'}}
                            loading={branchDetailsLoader && currentUserId === branchItem.id}
                            disabled={!archiveQualificationGroupTableToggle || branchDetailsLoader}
                            icon={archiveQualificationGroupTableToggle && hasBranchUpdatePermission ? 'pi pi-pencil' : !archiveQualificationGroupTableToggle || !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(branchItem.id);
                                dispatch(getBranchDetailById(branchItem.id, jwtToken?.jwtToken, true));
                                if (hasBranchUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-10 md:col-9 flex align-items-center m-0">
                            {branchItem.label}
                        </div>
                        <div className="field col-2 md:col-2 flex align-items-center justify-content-end m-0 p-0 pr-2">
                            <Button name={branchItem.id}
                                    onClick={() => {
                                        dispatch(deleteSkillGroupBranch(branchItem.id));
                                    }}
                                    icon="pi pi-times"
                                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 m-0"
                                    disabled={!archiveQualificationGroupTableToggle || !editType || inSystem}
                            />
                        </div>
                    </div>
                )
            }
        })
    });

    const changeSkillGroupBranches = (e: any) => {
        const filteredEValue = e.value?.filter((item: { id: number; }) => item.id);
        const existingIds = new Set(filteredEValue.map((item: { id: number; }) => item.id));
        const branchGroupData = new Set(formattedBranches.map((item: { id: number; }) => item.id));
        const newItems = qualificationData && qualificationData.skillGroupBranches
            ? qualificationData.skillGroupBranches.filter((item: { id: number; }) =>
                !existingIds.has(item.id) && !branchGroupData.has(item.id))
            : [];
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addSkillGroupBranch({ skillGroupId: qualificationData?.id, id: updatedEValue }));
    }

    return (
        <div className="general-settings">
            <div className="grid p-fluid px-2">
                <div className="field col-12 md:col-6 mb-0" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name})}>Назва групи *</label>
                    <InputText
                        id="name"
                        value={qualificationData?.name || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.name})}
                        onChange={e => {
                            onChangeNameField(e);
                        }}
                        disabled={!archiveQualificationGroupTableToggle || !editType || inSystem}
                    />
                    {errorFields?.name && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.name?.name}*</span></div>}               </div>
                <div className="field col-12 md:col-6"></div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description" className={classNames('', {'p-error': errorFields?.description})}>Коментар</label>
                    <InputTextarea
                        id="description"
                        value={qualificationData?.description || ''}
                        onChange={e => {
                            dispatch(setQualificationGroupField({section: 'qualificationData', fieldName: 'description', value: e.target.value}))
                        }}
                        className={classNames('', {'p-invalid': errorFields?.description})}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveQualificationGroupTableToggle || !editType || inSystem}
                    />
                </div>
                <div className="field col-12 md:col-6"></div>
            </div>
            <Divider className="mx-2"/>
            <div className="field col-12 md:col-4 flex flex-column">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId})}>Бранч *</label>
                <MultiSelect
                    appendTo="self"
                    value={branchesValue}
                    options={formattedBranches}
                    className={classNames('', {'p-invalid': errorFields?.branchId})}
                    onChange={(e) => {
                        changeSkillGroupBranches(e);
                    }}
                    placeholder="Оберіть бранч"
                    filter
                    optionLabel="label"
                    selectedItemsLabel={branchesValue ? `Обрано: ${branchesValue?.length}` : ""}
                    maxSelectedLabels={0}
                    scrollHeight="250px"
                    disabled={!archiveQualificationGroupTableToggle || !editType || inSystem}
                />
                {
                    branchesValue?.length ?
                        <div className="users-list mt-3">
                            {branchItems}
                        </div>
                        :
                        <>

                            {
                                errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.branchId})}>Нічого не вибрано</span> :
                                    <span className={classNames('no-users-message mt-4')}>Нічого не вибрано</span>
                            }
                        </>

                }
            </div>
            <div className="px-2">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Стан</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2">Неактивна</label>
                        <InputSwitch disabled={!archiveQualificationGroupTableToggle || !editType || inSystem} checked={qualificationData?.isActive} onChange={(e) => {
                            dispatch(setQualificationGroupField({section: 'qualificationData', fieldName: 'isActive', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QualificationGroupDialogGeneralSettings;
