import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import {ProgressBar} from "primereact/progressbar";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {codes} from "redux/notificationCodes";
import {getItem} from "redux/cache/cacheService";
import {setQueueDialogVisible, setQueueErrorField} from "../../../redux/actions/actionsQueue";
import QueuesDialogInfo from "../QueuesDialogInfo";
import QueuesListDialogManagement from "../DialogManagement";
import QueuesListDialogGeneralSettings from "../DialogGeneralSettings";
import QueueListDialogUsers from "../QueueDialogUsers";
import QueueWorkSetting from "../QueueWorkSetting";
import ACDSettingsTab from "../QueueDialogACD";
import {getUsers} from "../../../redux/api/api";
import {createQueue, getQueueDirectoriesGetTranksPhones, setArchiveQueueById, updateQueue} from "../../../redux/api/apiQueue";
import {validateQueueData} from "../../../redux/validator/validator";
import {getNotificationMessage} from "../../../redux/api/apiNotifications";
import {getSkillGroupList} from "../../../redux/api/apiQualificationGroup";

const QueuesListDialog = (props: any) => {
    const dispatch = useAppDispatch()
    const companies = useAppSelector(state => state.CompaniesListReducer);
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    // @ts-ignore
    const dialogVisible = useAppSelector(state => state.QueueListReducer.queueDialogVisible);
    // @ts-ignore
    const queueTrankIds = useAppSelector(state => state.QueueListReducer.queueTrunkIds);
    // @ts-ignore
    const companyStateSave = useAppSelector(state => state.CompaniesListReducer.companyStateSave);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle);
    // @ts-ignore
    const changeScheduler = useAppSelector(state => state.CompaniesListReducer.changeScheduler)
    const user = useAppSelector(state => state.UsersReducer.userData);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectIndex, setSelectIndex] = useState(0);
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false);
    const [showSaveChangeNotification, setShowSaveChangeNotification] = useState<boolean>(false);
    const [createdSchedule, setCreatedSchedule] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const saveChangesNotification = getItem(codes.save_changes);
    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const saveChangeMessage = <div>{companies && companies.notificationSaveChanges ? companies.notificationSaveChanges.text : saveChangesNotification ? saveChangesNotification : progressBar}</div>
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    let archiveQueueNotification = getItem(codes.archive_queue_question);
    const archiveQueueMessage = useAppSelector(state => state.NotificationsReducer.archiveQueueMessage);
    const deleteQueueQuestion = <div>{archiveQueueMessage && archiveQueueMessage?.text ? archiveQueueMessage?.text : archiveQueueNotification ? archiveQueueNotification : progressBar}</div>
    const dialerPermissions = checkPermissions(rolesVision, 'Queues');
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    const skillGroupList = useAppSelector(state => state.QualificationGroupListReducer.skillGroupList);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    useEffect(() => {
        setActiveIndex(0);
    }, [dialogVisible]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getQueueDirectoriesGetTranksPhones(jwtToken?.jwtToken));
        dispatch(getSkillGroupList(jwtToken?.jwtToken));
        dispatch(getUsers(user));
    }, [dialogVisible]);

    useEffect(() => {
        if (errorFields && errorFields?.nameUniq) {
            setActiveIndex(0);
        }
    }, [errorFields])

    const closeDialogVisible = () => {
        dispatch(setQueueDialogVisible(false));
        dispatch(setQueueErrorField(null));
    }

    const updateCampaign = (type: string) => {
        let queueCopy = {...queueData};
        const someNull = queueTrankIds?.some((item: { trunkPhoneId: null; }) => item.trunkPhoneId === null);

        if (someNull) {
            queueCopy.queueTrunks = null
        } else {
            queueCopy.queueTrunks = queueTrankIds;
        }

        queueCopy.skillGroups = queueCopy?.skillGroups?.filter((skillGroup: { skillGroupId: any; }) =>
            skillGroupList?.some((skillListItem: { id: any; }) => skillListItem.id === skillGroup.skillGroupId)
        );

        queueCopy.queueUsers = queueCopy?.queueUsers?.filter((skillGroup: { userId: any; }) =>
            usersData?.some((userItem: { userId: any; }) => userItem.userId === skillGroup.userId)
        );

        const validationErrors = validateQueueData(queueCopy);
        if (!validationErrors) {
            if (props?.branchId) {
                dispatch(updateQueue(queueCopy, jwtToken?.jwtToken, props?.branchId));
            } else {
                dispatch(updateQueue(queueCopy, jwtToken?.jwtToken));
            }

            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setQueueErrorField(validationErrors));
            if (validationErrors.name || validationErrors.queueTranks || validationErrors.branchId) {
                setActiveIndex(0);
            } else if (validationErrors.scheduler) {
                setActiveIndex(2);
            } else if (validationErrors.queueUsers) {
                setActiveIndex(1);
            } else if (validationErrors.mohAudioTime || validationErrors.mohAudioId || validationErrors.mwtNoAgentAudioTime || validationErrors.mwtAudioTime || validationErrors.schedulerAudioTime || validationErrors.schedulerStrategyAction || validationErrors.mwtStrategyAction || validationErrors.mwtNoAgentStrategyAction || validationErrors.mwtNoAgent || validationErrors.mwt || validationErrors.mwtStrategy || validationErrors.mwtNoAgentStrategy || validationErrors.schedulerStrategy) {
                setActiveIndex(3);
            }
        }
        setCreatedSchedule(false);
    };

    const createCampaign = (type: string) => {
        let queueCopy = {...queueData};

        const someNull = queueTrankIds?.some((item: { trunkPhoneId: null; }) => item.trunkPhoneId === null);

        if (someNull) {
            queueCopy.queueTrunks = null
        } else {
            queueCopy.queueTrunks = queueTrankIds;
        }

        const validationErrors = validateQueueData(queueCopy);

        if (!validationErrors) {
            dispatch(createQueue(queueCopy, jwtToken?.jwtToken))

            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setQueueErrorField(validationErrors));
            if (validationErrors.name || validationErrors.queueTranks || validationErrors?.branchId) {
                setActiveIndex(0);
            } else if (validationErrors?.scheduler) {
                setActiveIndex(2);
            } else if (validationErrors?.queueUsers) {
                setActiveIndex(1);
            } else if (validationErrors.mohAudioTime || validationErrors.mohAudioId || validationErrors.mwtNoAgentAudioTime || validationErrors.mwtAudioTime || validationErrors.schedulerAudioTime || validationErrors.schedulerStrategyAction || validationErrors.mwtStrategyAction || validationErrors.mwtNoAgentStrategyAction || validationErrors.mwtNoAgent || validationErrors.mwt || validationErrors.mwtStrategy || validationErrors.mwtNoAgentStrategy || validationErrors.schedulerStrategy) {
                setActiveIndex(3);
            }
        }
        setCreatedSchedule(false);
    };

    const deleteCampaign = () => {
        if (jwtToken?.jwtToken) {
            dispatch(setArchiveQueueById(queueData?.queue?.id, jwtToken?.jwtToken));
        }
        dispatch(setQueueDialogVisible(false));
    }

    const showDeletionNotification = () => {
        if (!archiveQueueNotification && jwtToken?.jwtToken) {
            dispatch(getNotificationMessage(user, 171, jwtToken?.jwtToken));
        }
        setShowDeleteNotification(true);
    }

    const showSaveChangesNotification = () => {
        dispatch(setQueueDialogVisible(false));
    }

    const setActiveTabIndex = (e: any) => {
        if ((createdSchedule || changeScheduler) && activeIndex === 2) {
            setVisible(true);
            setSelectIndex(e.index);
        } else {
            setActiveIndex(e.index);
        }
    }

    const closeNotification = () => {
        setVisible(false);
        setActiveIndex(selectIndex);
        setCreatedSchedule(false);
    }

    const saveNewScheduler = () => {
        closeNotification();
    }

    const cancelSaveScheduler = () => {

    }

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                {hasDialerDeletePermission && queueData?.queue?.id && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                              className="p-button-outlined"
                                                                              label="Видалити"
                                                                              onClick={showDeletionNotification}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            showSaveChangesNotification()
                        }}
                />
                {hasDialerCreatePermission && !queueData?.queue?.id && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                               className=""
                                                                               label="Створити та зберегти"
                                                                               onClick={() => {
                                                                                   createCampaign('')
                                                                               }}
                />}
                {hasDialerUpdatePermission && queueData?.queue?.id && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                              label="Зберегти"
                                                                              onClick={() => {
                                                                                  updateCampaign('')
                                                                              }}
                />}
            </div>
        </div>
    );

    return (
        <Dialog
            header="Налаштування черги"
            footer={archiveTableToggle ? footer : ''}
            visible={dialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            className='dialogInfo'
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh", zIndex: '1101px !important'}}
            onHide={() => showSaveChangesNotification()}
        >
            {queueData && <div className="company-info-popup">
                <ConfirmDialog visible={showDeleteNotification} onHide={() => setShowDeleteNotification(false)} message={deleteQueueQuestion}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
                {!companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}
                                                     icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {
                    updateCampaign('with_close')
                }}/>}
                {companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}
                                                    icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {
                    createCampaign('with_close')
                }}/>}
                <QueuesDialogInfo/>
                <Divider style={{margin: '0 0 20px 0'}}/>
                {queueData?.queue?.id &&
                    <>
                        <QueuesListDialogManagement/>
                        <Divider style={{margin: '0 0 20px 0'}}/>
                    </>
                }
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveTabIndex(e)}>
                    <TabPanel header="Загальні налаштування">
                        {queueData && <QueuesListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Додати операторів">
                        <QueueListDialogUsers/>
                    </TabPanel>
                    <TabPanel header="Розклад роботи">
                        <QueueWorkSetting/>
                    </TabPanel>
                    <TabPanel header="Налаштування ACD">
                        <ACDSettingsTab/>
                    </TabPanel>
                </TabView>
            </div>}
            <Dialog visible={visible} style={{width: '25vw'}} onHide={() => setVisible(false)}>
                <p className="m-0">
                    Застосувати розклад запуску та завершення кампанії?
                </p>
                <div className="flex justify-content-end mt-4">
                    <Button className="mr-2" onClick={() => saveNewScheduler()}>Так</Button>
                    <Button className="p-button-outlined mr-2" onClick={() => cancelSaveScheduler()}>Ні</Button>
                    <Button className="p-button-outlined" onClick={() => setVisible(false)}>Відміна</Button>
                </div>
            </Dialog>
        </Dialog>
    )
}

export default QueuesListDialog
