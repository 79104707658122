import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ADD_QUALIFICATION_GROUP_USER,
    ADD_SKILL_GROUP_BRANCH,
    ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE,
    CHANGE_QUALIFICATION_GROUP_USER_LEVEL,
    DELETE_QUALIFICATION_GROUP_USER,
    DELETE_SKILL_GROUP_BRANCH,
    GET_QUALIFICATION_GROUP_DETAILS,
    GET_QUALIFICATION_GROUP_LIST,
    GET_QUALIFICATION_GROUP_PAGINATION,
    GET_SKILL_GROUP_LIST, GET_USERS,
    HIDE_QUALIFICATION_GROUP_DETAILS_LOADER,
    HIDE_QUALIFICATION_GROUP_LOADER,
    INITIAL_QUALIFICATION_GROUP_DATA,
    QUALIFICATION_GROUP_CLEAR_FILTERS,
    QUALIFICATION_GROUP_DIALOG_VISIBLE,
    QUALIFICATION_GROUP_FIELD,
    SELECTED_QUALIFICATION_GROUP_ID,
    SET_QUALIFICATION_GROUP_CURRENT_PAGE,
    SET_QUALIFICATION_GROUP_CURRENT_SIZE,
    SET_QUALIFICATION_GROUP_ERROR_FIELDS,
    SET_QUALIFICATION_GROUP_LIST_DATA_SORT,
    SHOW_QUALIFICATION_GROUP_DETAILS_LOADER,
    SHOW_QUALIFICATION_GROUP_ERROR,
    SHOW_QUALIFICATION_GROUPS_LIST_LOADER
} from "../types/typesQualificationGroup";

const initialState = {
    archiveQualificationGroupTableToggle: true,
    qualificationGroupList: [],
    qualificationDialogVisible: false,
    qualificationData: {
        name: null,
        isActive: false,
        description: '',
        users: [],
    },
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        isActive: {value: null, matchMode: FilterMatchMode.IN},
    },
    selectedQualificationGroupId: null,
    usersData: [],
    error: false,
    loading: false,
    detailsLoading: false,
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    errorFields: null,
    skillGroupList: [],
    dataSort: {
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: "Id",
            isDesc: false
        },
        filter: [],
        active: true,
        isArchived: false
    }
}

export const QualificationGroupListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE:
            return {...state, archiveQualificationGroupTableToggle: action.payload}
        case GET_QUALIFICATION_GROUP_LIST:
            return {...state, qualificationGroupList: action.payload};
        case GET_SKILL_GROUP_LIST:
            return {...state, skillGroupList: action.payload};
        case GET_QUALIFICATION_GROUP_DETAILS:
            return {...state, qualificationData: action.payload};
        case SELECTED_QUALIFICATION_GROUP_ID:
            return {...state, selectedQualificationGroupId: action.payload}
        case SHOW_QUALIFICATION_GROUP_ERROR:
            return {...state, error: action.payload};
        case QUALIFICATION_GROUP_DIALOG_VISIBLE:
            return {...state, qualificationDialogVisible: action.payload};
        case SHOW_QUALIFICATION_GROUP_DETAILS_LOADER:
            return {...state, detailsLoading: true};
        case HIDE_QUALIFICATION_GROUP_LOADER:
            return {...state, loading: false};
        case SHOW_QUALIFICATION_GROUPS_LIST_LOADER:
            return {...state, loading: true};
        case HIDE_QUALIFICATION_GROUP_DETAILS_LOADER:
            return {...state, detailsLoading: false};
        case GET_QUALIFICATION_GROUP_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}};
        case SET_QUALIFICATION_GROUP_ERROR_FIELDS:
            return {...state, errorFields: action.payload};
        case INITIAL_QUALIFICATION_GROUP_DATA:
            return {...state, qualificationData: initialState.qualificationData}
        case QUALIFICATION_GROUP_FIELD:
            return {
                ...state, qualificationData:
                    {
                        ...state.qualificationData,
                        [action.payload.fieldName]: action.payload.value,
                    }
            }
        case QUALIFICATION_GROUP_CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    isActive: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case GET_USERS:
            return {...state, usersData: action.payload};
        case DELETE_QUALIFICATION_GROUP_USER:
            return {
                ...state, qualificationData:
                    {
                        ...state.qualificationData,
                        users: state.qualificationData.users.filter((item: any) => item.userId !== action.payload)
                    }
            };
        case ADD_QUALIFICATION_GROUP_USER:
            let usersId = action.payload.userId.map((item: any) => {
                return item
            })
            return {
                ...state, qualificationData:
                    {
                        ...state.qualificationData,
                        users: usersId.map((item: { userId: any; userLevel: any; userName: any; }) => {
                            return {userId: item.userId, userLevel: item.userLevel, userName: item.userName};
                        })
                    }
            };
        case CHANGE_QUALIFICATION_GROUP_USER_LEVEL:
            return {
                ...state,
                qualificationData: {
                    ...state.qualificationData,
                    users: state?.qualificationData?.users.map((user: any) =>
                        user.userId === action.payload.userId
                            ? {...user, userLevel: action.payload.userLevel}
                            : user
                    )
                }
            };
        case SET_QUALIFICATION_GROUP_CURRENT_PAGE:
            return {...state, paginationPage: action.payload};
        case SET_QUALIFICATION_GROUP_CURRENT_SIZE:
            return {...state, paginationSize: action.payload};
        case SET_QUALIFICATION_GROUP_LIST_DATA_SORT:
            return {...state, dataSort: action.payload};
        case ADD_SKILL_GROUP_BRANCH:
            let branchesId = action.payload.id.map((item: any) => {
                return item
            })

            return {
                ...state, qualificationData:
                    {
                        ...state.qualificationData,
                        skillGroupBranches: branchesId.map((item: { id: number;}) => {
                            return {id: item.id};
                        })
                    }
            };
        case DELETE_SKILL_GROUP_BRANCH:
            return {
                ...state, qualificationData:
                    {
                        ...state.qualificationData,
                        // @ts-ignore
                        skillGroupBranches: state.qualificationData.skillGroupBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        default:
            return state;
    }
};
