import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import React, {useRef, useState} from "react";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {addQualificationGroupUser, changeQualificationGroupUserLevel, deleteQualificationGroupUser} from "../../../redux/actions/actionsQualificationGroup";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getUserDetails} from "../../../redux/api/apiUsersList";
import {setEditType} from "../../../redux/actions/actions";
import UserListDialog from "../../Settings/UsersList/UserDialod";

const QualificationGroupDialogUsers = () => {
    const dispatch = useAppDispatch()
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData);
    const usersData = useAppSelector(state => state.QualificationGroupListReducer.usersData) as any;
    const selectedCompanyId = useAppSelector(state => state.QualificationGroupListReducer.selectedQualificationGroupId);
    const archiveTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const inputRef = useRef<any>(null);
    const errorFields = useAppSelector(state => state.QualificationGroupListReducer.errorFields);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const detailsLoading = useAppSelector(state => state.UsersListReducer.detailLoading);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const {
        update: hasUsersManagerUpdatePermission,
        visible: hasUsersManagerVisiblePermission
    } = usersManagerPermissions;

    const usersDataWithUserLevel = usersData?.map((userData: any) => {
        return {
            ...userData,
            userLevel: 0,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? ` ${userData.middleName}` : ''}`
        }
    });

    const userLevels: any = {};
    qualificationData?.users?.forEach((user: { userId: string | number; userLevel: any; }) => {
        userLevels[user.userId] = user.userLevel;
    });

    usersDataWithUserLevel?.forEach((user: { userId: string | number; userLevel: any; }) => {
        if (userLevels[user.userId] !== undefined) {
            user.userLevel = userLevels[user.userId];
        }
    });

    const isUsersIdPresent = qualificationData?.users?.some((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    );

    const filteredUsers = qualificationData?.users?.filter((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    ) || [];

    const userItems = qualificationData?.users?.map((companiesItem: any) => {
        return usersDataWithUserLevel.map((userItem: any, index: number) => {
            if (companiesItem.userId === userItem.userId) {
                const userLevelError = errorFields?.users?.campaignUsersWithoutLevel?.find((user: { userId: any; }) => user.userId === companiesItem.userId);
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasUsersManagerVisiblePermission && <Button
                            className="max-w-4rem p-button-secondary mr-3"
                            style={{minWidth: '40px'}}
                            loading={detailsLoading && currentUserId === userItem.userId}
                            disabled={!archiveTableToggle || detailsLoading}
                            icon={archiveTableToggle && hasUsersManagerUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasUsersManagerUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(userItem.userId);
                                dispatch(getUserDetails(userItem.userId, jwtToken?.jwtToken, true));
                                if (hasUsersManagerUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-8 flex align-items-center m-0">
                            {userItem.lastName} {userItem.firstName} {userItem.middleName}
                        </div>
                        <InputNumber
                            ref={inputRef}
                            value={userItem?.userLevel}
                            className={classNames('', {'p-invalid': errorFields?.users && userLevelError})}
                            disabled={!archiveTableToggle || !editType}
                            min={0} max={1000}
                            useGrouping={false}
                            onChange={(e) => dispatch(changeQualificationGroupUserLevel({campaignId: qualificationData?.id, userId: userItem.userId, userLevel: e.value}))}
                        />
                        <Button name={userItem.userId}
                                onClick={() => {
                                    dispatch(deleteQualificationGroupUser(userItem.userId));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                                disabled={!archiveTableToggle || !editType}
                        />
                    </div>
                )
            }
        })
    })

    const usersDataItemTemplate = (option: any) => {
        return usersData?.map((item: any) => {
            if (item.userId === option.userId) {
                return `${item.lastName} ${item.firstName} ${item?.middleName ? ` ${item.middleName}` : ''}`
            }
        })
    }

    const campaignUsers = qualificationData?.users?.map((companiesItem: any) => {
        const user = usersData?.find((userItem: any) => userItem.userId === companiesItem.userId);
        const {skillGroupId, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            lastName: user?.lastName,
            firstName: user?.firstName,
            middleName: user?.middleName,
            userName: user?.userName,
            fullName: `${user?.lastName} ${user?.firstName} ${user?.middleName ? ` ${user.middleName}` : ''}`,
            userId: user?.userId,
        }
    })

    const changeQualificationUsers = (e: any) => {
        const filteredEValue = e.value?.filter((item: { userId: any; }) => item.userId);
        const existingIds = new Set(filteredEValue.map((item: { userId: any; }) => item.userId));
        const skillGroupData = new Set(usersDataWithUserLevel.map((item: { userId: any; }) => item.userId));
        const newItems = qualificationData?.users?.filter((item: { userId: any; }) =>
            !existingIds.has(item.userId) && !skillGroupData.has(item.userId));
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addQualificationGroupUser({campaignId: selectedCompanyId, userId: updatedEValue}))
    }

    return (
        <div className="users">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-5">
                    <h6>Користувачі:</h6>

                    <MultiSelect
                        appendTo="self"
                        value={campaignUsers}
                        options={usersDataWithUserLevel}
                        className={classNames('', {'p-invalid': errorFields?.users})}
                        onChange={(e) => {
                            changeQualificationUsers(e);
                        }}
                        itemTemplate={usersDataItemTemplate}
                        placeholder="Оберіть користувачів"
                        filter
                        optionLabel="fullName"
                        selectedItemsLabel={isUsersIdPresent ? `Користувачив обрано: ${filteredUsers?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        disabled={!archiveTableToggle || !editType}
                    />
                    {
                        qualificationData?.users?.length ?
                            <div className="users-list">
                                {userItems}
                                <div className="grid col-12 md:col-12">
                                    {/*{errorFields?.users && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2 w-full"><span className="title p-error text-sm">{errorFields?.users?.users}</span></div>}*/}
                                </div>
                            </div>
                            :
                            <>

                                {
                                    errorFields?.users ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.users})}>Користувачив не обрано</span> :
                                        <span className={classNames('no-users-message')}>Користувачив не обрано</span>
                                }
                            </>

                    }
                </div>
            </div>
            <UserListDialog/>
        </div>
    )
}

export default QualificationGroupDialogUsers;
