export const GET_STATUSES_LIST = 'GET_STATUSES_LIST'
export const SHOW_STATUSES_ERROR = 'SHOW_STATUSES_ERROR'
export const SELECTED_STATUS_ID = 'SELECTED_STATUS_ID'
export const STATUS_DIALOG_VISIBLE = 'STATUS_DIALOG_VISIBLE'
export const STATUS_FIELD = 'STATUS_FIELD'
export const SET_STATUS_MODULE_FIELD = 'SET_STATUS_MODULE_FIELD'
export const UPDATE_STATUS_PERMISSION_ACTIVE = 'UPDATE_STATUS_PERMISSION_ACTIVE'
export const HIDE_STATUS_SAVE_MESSAGE = 'HIDE_STATUS_SAVE_MESSAGE'
export const SHOW_STATUS_SAVE_MESSAGE = 'SHOW_STATUS_SAVE_MESSAGE'
export const UPDATE_STATUS_PERMISSIONS = 'UPDATE_STATUS_PERMISSIONS'
export const ARCHIVE_STATUS_TABLE_TOGGLE = 'ARCHIVE_STATUS_TABLE_TOGGLE'
export const SHOW_STATUSES_LIST_LOADER = 'SHOW_STATUSES_LIST_LOADER'
export const SHOW_STATUS_DETAILS_LOADER = 'SHOW_STATUS_DETAILS_LOADER'
export const HIDE_STATUS_LOADER = 'HIDE_STATUS_LOADER'
export const HIDE_STATUS_DETAILS_LOADER = 'HIDE_STATUS_DETAILS_LOADER'
export const SET_STATUS_CURRENT_PAGE = 'SET_STATUS_CURRENT_PAGE'
export const SET_STATUS_CURRENT_SIZE = 'SET_STATUS_CURRENT_SIZE'
export const SET_STATUS_ERROR_FIELDS = 'SET_STATUS_ERROR_FIELDS'
export const SET_STATUSES_LIST_DATA_SORT = 'SET_STATUSES_LIST_DATA_SORT'
export const GET_STATUS_DETAILS = 'GET_STATUS_DETAILS'
export const GET_STATUS_PAGINATION = 'GET_STATUS_PAGINATION'
export const GET_STATUSES_DICTIONARY = 'GET_STATUSES_DICTIONARY'
export const INITIAL_STATUS_DATA = 'INITIAL_STATUS_DATA'
export const UPDATE_STATUS_TIME = 'UPDATE_STATUS_TIME'
export const ADD_STATUS_GROUP_BRANCH = "ADD_STATUS_GROUP_BRANCH"
export const DELETE_STATUS_BRANCH = "DELETE_STATUS_BRANCH"
