import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../redux/hooks";

const QueuesListDialogInfo = () => {
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData)

    const queueNameBodyTemplate = (rowData: any) => {
        let name = rowData?.name;
        const truncateWithEllipses = (text: string, max: number) => {
            return text.substr(0,max-1)+(text.length>max?'...':'');
        }
        if (name) {name = truncateWithEllipses(name, 156)}
        return (
            <React.Fragment>
                <div className="campaignName">
                    <p>{name}</p>
                </div>
            </React.Fragment>
        );
    };

    const operatorsBodyTemplate = () => {
        return (
            <div className="pl-1">
                {queueData?.activeOperators || 0} / {queueData?.countOperators || 0}
            </div>
        )
    }

    const isGeneralBodyTemplate = () => {
        return (
            <div className="pl-1">
                {queueData.queue.isGeneralTrunk ? 'Транк Черги' : 'Транк Оператора'}
            </div>
        )
    }

    return (
        <DataTable className="user-dialog-info" value={[queueData.queue]}>
            <Column field="name" header="Назва" body={queueNameBodyTemplate}></Column>
            <Column field="campaignTypeId" header="А номер" body={isGeneralBodyTemplate} style={{minWidth: '150px'}}></Column>
            <Column field="priority" header="Пріорітет" body={body => body?.priority || '0'}></Column>
            <Column field="campaignTypeId" header="Стан" body={rowData => rowData?.isActive ? 'Активна' : 'Не активна'}></Column>
            <Column field="activeOperators" header="Оператори" body={operatorsBodyTemplate}></Column>
        </DataTable>
    )
}

export default QueuesListDialogInfo
