import React, { useState, useEffect } from 'react';
import {changeInternetStatus} from "../../../redux/actions/actionsWebRTC";
import {useAppDispatch} from "../../../redux/hooks";

interface NetworkInformation extends EventTarget {
    downlink: number;
    effectiveType: string;
}

const InternetStatus = () => {
    const dispatch = useAppDispatch();
    const connection = (navigator as any).connection as NetworkInformation | undefined;
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const [networkInfo, setNetworkInfo] = useState({
        downlink: connection?.downlink ?? null,
        effectiveType: connection?.effectiveType ?? null
    });

    const getSignalStrength = (online: boolean, downlink: number | null, effectiveType: string | null): number => {
        if (!online) return 0;
        if (effectiveType === '3g') return 1;
        if (effectiveType === 'slow-4g' || effectiveType === '4g' && downlink && downlink < 2.5) return 2; // Slow 4G
        if (effectiveType === '4g' && downlink && downlink >= 2.5 && downlink && downlink <= 9) return 3; // Fast 4G
        if (effectiveType === 'lte' || effectiveType === '5g' || downlink && downlink >= 9) return 4; // No Throttling / 5G (assuming fast connection)

        return 1;
    };

    useEffect(() => {
        const updateNetworkInfo = () => {
            setIsOnline(navigator.onLine);
            setNetworkInfo({
                downlink: connection?.downlink ?? null,
                effectiveType: connection?.effectiveType ?? null
            });
        };

        window.addEventListener('online', updateNetworkInfo);
        window.addEventListener('offline', updateNetworkInfo);
        connection?.addEventListener('change', updateNetworkInfo);

        return () => {
            window.removeEventListener('online', updateNetworkInfo);
            window.removeEventListener('offline', updateNetworkInfo);
            connection?.removeEventListener('change', updateNetworkInfo);
        };
    }, [connection]);

    const signalStrength = getSignalStrength(isOnline, networkInfo.downlink, networkInfo.effectiveType);
    let strengthClass = '';
    let strengthRedClass = '';
    let borderColor:any = `rgb(20, 180, 9)`;

    switch (signalStrength) {
        case 0:
            strengthClass = 'signal-icon-offline';
            strengthRedClass = 'signal-strength-offline';
            borderColor = 'red';
            break;
        case 1:
            strengthClass = 'weak';
            strengthRedClass = '';
            borderColor = '#ff8900';
            break;
        case 2:
            strengthClass = 'weak';
            strengthRedClass = '';
            borderColor = '#ff8900';
            break;
        case 3:
            strengthClass = 'medium';
            strengthRedClass = '';
            borderColor = '#abdb06';
            break;
        case 4:
            strengthClass = 'strong';
            strengthRedClass = '';
            break;
        default:
            strengthClass = 'strong';
            strengthRedClass = '';
            break;
    }

    return (
        <div style={{borderColor: `${borderColor}`}} className={`routerContainer flex relative border-circle justify-content-center align-items-center ${strengthRedClass}`}>
            <div className={`signal-icon ${strengthClass}`}>
                <div className="signal-bar"></div>
                <div className="signal-bar"></div>
                <div className="signal-bar"></div>
                {strengthClass === 'signal-icon-offline' && <i className="offline-icon-router pi pi-times"></i>}
            </div>
        </div>
    );
};

export default InternetStatus;
