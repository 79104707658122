import React, {useEffect, useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {googleAccountWebLogin} from "../../redux/api/api";


const AuthGoogle = () => {
    const dispatch = useAppDispatch();
    const replacedValue = window.location.hash?.replace('#/auth_google?id=', '');
    const rolesError = useAppSelector<any>(state => state.RolesReducer.error);
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    // @ts-ignore
    const googleAuthError = useAppSelector(state => state.CompaniesListReducer.googleAuthError) as any;


    useEffect(() => {
        if (rolesError) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }
    }, [rolesError]);

    useEffect(() => {
        if (googleAuthError) {
            navigate('/login');
        }
    }, [googleAuthError]);

    useEffect(() => {
        if (replacedValue && replacedValue?.length) {
            dispatch(googleAccountWebLogin(replacedValue));
        } else {
            navigate('/login')
        }
    }, [replacedValue]);

    return (<>
            <Toast position="bottom-right" ref={toast}/>
            <div id="root">
                <div>
                    <i className="pi pi-spin pi-cog text-primary absolute" style={{ fontSize: '4rem', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                </div>
            </div>
        </>
    );
};

export default AuthGoogle;
