import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getItem} from "../../redux/cache/cacheService";
import {codes} from "../../redux/notificationCodes";
import React, {useEffect, useRef} from "react";
import {getNotificationMessage} from "../../redux/api/apiNotifications";
import {Toast} from "primereact/toast";
import {getNotification} from "../../redux/api/api";

const Notifications = () => {
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const getNotificationError = useAppSelector(state => state.CompaniesListReducer.getNotificationError);
    // @ts-ignore
    const getCompaniesIntervalError = useAppSelector(state => state.CompaniesListReducer.getCompaniesIntervalError);
    // @ts-ignore
    const getArchivedCompaniesError = useAppSelector(state => state.CompaniesListReducer.getArchiveCompaniesError);
    // @ts-ignore
    const getCampaignDirectoriesPhoneTypeError = useAppSelector(state => state.CompaniesListReducer.getCampaignDirectoriesPhoneTypeError);
    // @ts-ignore
    const getCampaignDirectoriesCampaignTypeError = useAppSelector(state => state.CompaniesListReducer.getCampaignDirectoriesCampaignTypeError);
    // @ts-ignore
    const getCampaignDirectoriesGetTranksPhonesError = useAppSelector(state => state.CompaniesListReducer.getCampaignDirectoriesGetTranksPhonesError);
    // @ts-ignore
    const getCampaignDirectoriesStrategyError = useAppSelector(state => state.CompaniesListReducer.getCampaignDirectoriesStrategyError);
    // @ts-ignore
    const errorUpdate = useAppSelector(state => state.CompaniesListReducer.errorUpdate);
    // @ts-ignore
    const errorMessage = useAppSelector(state => state.CompaniesListReducer.errorMessage);
    // @ts-ignore
    const companyCreateMessage = useAppSelector(state => state.CompaniesListReducer.companyCreateMessage);
    // @ts-ignore
    const companyDeleteMessage = useAppSelector(state => state.CompaniesListReducer.companyDeleteMessage);
    // @ts-ignore
    const companySaveMessage = useAppSelector(state => state.CompaniesListReducer.companySaveMessage);
    const accessDeniedError = useAppSelector(state => state.NotificationsReducer.accessDeniedError);
    const notFoundError = useAppSelector(state => state.NotificationsReducer.notFoundError);
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const notificationsError = useAppSelector(state => state.NotificationsReducer.error);
    const companies = useAppSelector(state => state.CompaniesListReducer);
    // @ts-ignore
    const error = useAppSelector(state => state.CompaniesListReducer.error);
    const branchDeleteResponseMessage = useAppSelector(state => state.BranchesListReducer.branchDeleteResponseMessage);
    // @ts-ignore
    const setUserStatus = useAppSelector(state => state.UsersListReducer.setUserStatus);
    // @ts-ignore
    const setUserStatusError = useAppSelector(state => state.UsersListReducer.setUserStatusError);

    let accessDeniedNotification = getItem(codes.user_access_denied_messages);
    let notFoundNotification = getItem(codes.not_found_message);
    let successNotification = getItem(codes.success);
    let updateNotification = getItem(codes.update);
    let errorNotification = getItem(codes.error);
    let errorUpdatedNotification = getItem(codes.error_updated);
    let user_deleted_messages = getItem(codes.user_deleted_messages);
    let user_delete_questions = getItem(codes.user_delete_questions);
    let archive_branch_question = getItem(codes.archive_branch_question);
    let archive_status_question = getItem(codes.archive_status_question);
    let archive_qualification_group_question = getItem(codes.archive_qualification_group_question);
    let copy_qualification_group_question = getItem(codes.copy_qualification_group_question);
    let un_archive_qualification_group_question = getItem(codes.un_archive_qualification_group_question);
    let copy_status = getItem(codes.copy_status);
    let copy_role = getItem(codes.copy_role);
    let audioDeletedMessage = getItem(codes.audio_delete_questions);
    let unzipNotification = getItem(codes.un_archive_audio_question);

    // @ts-ignore
    const errorNotificationMessage = <div>{companies && companies.notificationError ? companies.notificationError.text : errorNotification && errorNotification}</div>
    // @ts-ignore
    const successNotificationMessage = <div>{companies && companies.notificationSuccess ? companies.notificationSuccess.text : successNotification && successNotification}</div>
    // @ts-ignore
    const updatedNotificationMessage = <div>{companies && companies.notificationUpdated ? companies.notificationUpdated.text : updateNotification && updateNotification}</div>
    // @ts-ignore
    const errorUpdateNotificationMessage = <div>{companies && companies.notificationUpdatedError ? companies.notificationUpdatedError.text : errorUpdatedNotification && errorUpdatedNotification}</div>
    const accessDeniedNotificationMessage = <div>{notifications && notifications.accessDeniedError ? notifications.accessDeniedErrorMessage ? notifications.accessDeniedErrorMessage?.text : accessDeniedNotification : accessDeniedNotification}</div>
    const notFoundNotificationMessage = <div>{notifications && notifications.notFoundErrorMessage ? notifications.notFoundErrorMessage ? notifications.notFoundErrorMessage?.text : notFoundNotification : notFoundNotification}</div>

    useEffect(() => {
        if (user && user.userId) {
            if (!successNotification && !getNotificationError) {
                dispatch(getNotification(user.userId, 109, user));
            }
            if (!updateNotification && !getNotificationError) {
                dispatch(getNotification(user.userId, 110, user));
            }
            if (!errorNotification && !getNotificationError) {
                dispatch(getNotification(user.userId, 111, user));
            }
            if (!errorUpdatedNotification && !getNotificationError) {
                dispatch(getNotification(user.userId, 112, user));
            }
            if (!user_delete_questions && !getNotificationError) {
                dispatch(getNotification(user.userId, 130, user));
            }
            if (!user_deleted_messages && !getNotificationError) {
                dispatch(getNotification(user.userId, 126, user));
            }
            if (!audioDeletedMessage && !getNotificationError) {
                dispatch(getNotification(user.userId, 219, user));
            }
            if (!unzipNotification && !getNotificationError) {
                dispatch(getNotification(user.userId, 220, user));
            }
        }
    }, [getNotificationError]);

    useEffect(() => {
        if (user && user.userId) {
            if (!accessDeniedNotification && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 131, user));
            }
            if (!notFoundNotification && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 132, user));
            }
            if (!archive_branch_question && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 133, user));
            }
            if (!archive_status_question && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 153, user));
            }
            if (!archive_qualification_group_question && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 190, user));
            }
            if (!copy_status && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 199, user));
            }
            if (!copy_role && !notificationsError) {
                dispatch(getNotificationMessage(user.userId, 200, user));
            }
            if (!copy_qualification_group_question && !notificationsError) {
                // dispatch(getNotificationMessage(user.userId, 153, user));
            }
            if (!un_archive_qualification_group_question && !notificationsError) {
                // dispatch(getNotificationMessage(user.userId, 153, user));
            }
        }
    }, [notificationsError, user]);

    useEffect(() => {

        //исправить
        if (getNotificationError) {
            // toast?.current?.show({severity: 'error', summary: 'Помилка завантаження даних', detail: '', life: 3000});
        }

    }, [getNotificationError]);

    useEffect(() => {
        if (companyDeleteMessage) {
            toast?.current?.show({severity: 'success', summary: 'Компанія переміщена в архів.', detail: '', life: 3000});
        }

    }, [companyDeleteMessage]);

    useEffect(() => {
        if (!user?.isValidPassword) {
            toast?.current?.show({severity: 'error', summary: 'Будь ласка змініть тимчасовий пароль :)', detail: '', life: 3000});
        }

    }, [user?.isValidPassword]);

    useEffect(() => {
        if (error) {
            if (errorNotification) {
                toast?.current?.show({severity: 'error', summary: errorNotificationMessage, detail: '', life: 3000});
            } else {
                if (!getNotificationError && user.userId) {
                    dispatch(getNotification(user.userId, 109, function (message: string) {
                        toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [error, getNotificationError]);

    useEffect(() => {
        if (errorUpdate) {
            if (errorUpdatedNotification) {
                toast?.current?.show({severity: 'error', summary: errorUpdateNotificationMessage, detail: '', life: 3000});
            } else {
                if (!getNotificationError && user.userId) {
                    dispatch(getNotification(user.userId, 112, function (message: string) {
                        toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [errorUpdate, getNotificationError]);

    useEffect(() => {
        if (companyCreateMessage) {
            if (successNotification) {
                toast?.current?.show({severity: 'success', summary: successNotificationMessage, detail: '', life: 3000});
            } else {
                if (!getNotificationError && user.userId) {
                    dispatch(getNotification(user.userId, 109, function (message: string) {
                        toast?.current?.show({severity: 'success', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [companyCreateMessage, getNotificationError]);

    useEffect(() => {
        if (companySaveMessage) {
            if (updateNotification) {
                toast?.current?.show({severity: 'success', summary: updatedNotificationMessage, detail: '', life: 3000});
            } else {
                if (!getNotificationError && user.userId) {
                    dispatch(getNotification(user.userId, 110, function (message: string) {
                        toast?.current?.show({severity: 'success', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [companySaveMessage, getNotificationError]);

    useEffect(() => {
        if (errorMessage) {
            toast?.current?.show({severity: 'error', summary: errorMessage, detail: '', life: 4000});
        }
    }, [errorMessage]);

    useEffect(() => {
        if (getCompaniesIntervalError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження кампаній', detail: '', life: 4000});
        }
    }, [getCompaniesIntervalError]);

    useEffect(() => {
        if (getArchivedCompaniesError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження архівних кампаній', detail: '', life: 4000});
        }
    }, [getArchivedCompaniesError]);

    useEffect(() => {
        if (getCampaignDirectoriesPhoneTypeError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження типів телефонів', detail: '', life: 4000});
        }
    }, [getCampaignDirectoriesPhoneTypeError]);

    useEffect(() => {
        if (getCampaignDirectoriesCampaignTypeError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження типів кампаній', detail: '', life: 4000});
        }
    }, [getCampaignDirectoriesCampaignTypeError]);

    useEffect(() => {
        if (getCampaignDirectoriesGetTranksPhonesError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження транков кампаній', detail: '', life: 4000});
        }
    }, [getCampaignDirectoriesGetTranksPhonesError]);

    useEffect(() => {
        if (getCampaignDirectoriesStrategyError) {
            toast?.current?.show({severity: 'error', summary: 'Помилка завантаження стратегії операторів', detail: '', life: 4000});
        }
    }, [getCampaignDirectoriesStrategyError]);


    useEffect(() => {
        if (accessDeniedError) {
            if (accessDeniedNotification) {
                toast?.current?.show({severity: 'error', summary: accessDeniedNotificationMessage, detail: '', life: 3000});
            } else {
                if (!accessDeniedNotification && user.userId) {
                    dispatch(getNotificationMessage(user.userId, 131, function (message: string) {
                        toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [accessDeniedError, accessDeniedNotification]);

    useEffect(() => {
        if (notFoundError) {
            if (notFoundNotification) {
                toast?.current?.show({severity: 'error', summary: notFoundNotificationMessage, detail: '', life: 3000});
            } else {
                if (!notFoundNotification && user.userId) {
                    dispatch(getNotificationMessage(user.userId, 132, function (message: string) {
                        toast?.current?.show({severity: 'error', summary: message, detail: '', life: 3000});
                    }));
                }
            }
        }

    }, [notFoundError, notFoundNotification]);

    useEffect(() => {
        if (branchDeleteResponseMessage) {
            if (branchDeleteResponseMessage.statusCode === 200) {
                toast?.current?.show({severity: 'success', summary: branchDeleteResponseMessage?.message, detail: '', life: 3000});
            } else {
                toast?.current?.show({severity: 'error', summary: branchDeleteResponseMessage?.message, detail: '', life: 3000});
            }
        }

    }, [branchDeleteResponseMessage]);

    useEffect(() => {
        if (setUserStatus) {
            toast?.current?.show({severity: 'success', summary: setUserStatus?.message, detail: '', life: 3000});
        }
        if (setUserStatusError) {
            toast?.current?.show({severity: 'error', summary: setUserStatusError?.message, detail: '', life: 3000});
        }
    }, [setUserStatusError, setUserStatus]);

    return (
        <Toast style={{zIndex: 10000}} position="bottom-right" ref={toast}/>
    );
}

export default Notifications;
