import React, {useEffect, useRef, useState} from 'react';
import {TreeTable} from 'primereact/treetable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {OrganizationChart} from "primereact/organizationchart";
import {Divider} from "primereact/divider";
import {TabPanel, TabView} from "primereact/tabview";
import BranchDialogGeneralSettings from "../BranchDialogGeneralSettings";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {createBranch, deleteBranch, getAllBranchesListWithArchive, getBranchDetailById, getBranchesList, updateBranch} from "../../../redux/api/apiBranchList";
import {initialBranchesData, setBranchErrorFields, setBranchField} from "../../../redux/actions/actionsBranchList";
import {validateBranchForm} from "../../../redux/validator/validator";
import {Toast} from "primereact/toast";
import {ProgressBar} from "primereact/progressbar";
import BranchListNav from "../BranchListNav";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getNotification} from "../../../redux/api/api";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ConfirmDialog} from "primereact/confirmdialog";
import {setEditType} from "../../../redux/actions/actions";
import BranchListOfDependenciesRoles from "../BranchListOfDependenciesRoles";
import BranchListOfDependenciesQueues from "../BranchListOfDependenciesQueues";
import BranchListOfDependenciesStatuses from "../BranchListOfDependenciesStatuses";
import BranchListOfDependenciesSkillGroups from "../BranchListOfDependenciesSkillGroups";
import BranchACDSettings from "../BranchACDSettings";
import {getQualificationGroupListWithPaginationFilterAndSorting} from "../../../redux/api/apiQualificationGroup";
interface TreeNode {
    data: {
        keyName: string;
    };
    children: TreeNode[];
    key: any
}


export default function DynamicColumnsDemo(props: any) {
    const dispatch = useAppDispatch();
    const branchesList = useAppSelector(state => state.BranchesListReducer.branchesList);
    const successUpdateBranch = useAppSelector(state => state.BranchesListReducer.successUpdateBranch);
    const successCreateBranch = useAppSelector(state => state.BranchesListReducer.successCreateBranch);
    const errorUpdateBranch = useAppSelector(state => state.BranchesListReducer.errorUpdateBranch);
    const errorCreateBranch = useAppSelector(state => state.BranchesListReducer.errorCreateBranch);
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const loading = useAppSelector(state => state.BranchesListReducer.loading);
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const archiveTableToggle = useAppSelector(state => state.BranchesListReducer.archiveTableToggle);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [branchId, setBranchId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [branchVisible, setBranchVisible] = useState(false);
    const [editBranchVisible, setEditBranchVisible] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<Toast>(null);
    const scrollRef = useRef<any>(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const branchDeleteResponseMessage = useAppSelector(state => state.BranchesListReducer.branchDeleteResponseMessage);
    const activeClick = useAppSelector(state => state.UsersListReducer["activeClick"]);
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const archive_branch_question = getItem(codes.archive_branch_question);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        create: hasBranchCreatePermission,
        update: hasBranchUpdatePermission,
        delete: hasBranchDeletePermission,
        visible: hasBranchVisionPermission
    } = branchPermissions;

    useEffect(() => {
        if (!archiveTableToggle) {
            dispatch(getAllBranchesListWithArchive(jwtToken?.jwtToken));
        } else {
            dispatch(getBranchesList(jwtToken?.jwtToken, !archiveTableToggle));
        }
    }, [archiveTableToggle, jwtToken]);

    useEffect(() => {
        // @ts-ignore
        if (activeClick?.item === "/branch" && activeClick?.from ==='#/branch') {
            if (!archiveTableToggle) {
                dispatch(getAllBranchesListWithArchive(jwtToken?.jwtToken));
            } else {
                dispatch(getBranchesList(jwtToken?.jwtToken, !archiveTableToggle));
            }        }
    }, [activeClick]);

    useEffect(() => {
        dispatch(setBranchErrorFields(null));
        setActiveIndex(0);
    }, [editBranchVisible, dispatch]);

    useEffect(() => {
        if (successUpdateBranch) {
            toast?.current?.show({severity: 'success', summary: 'Гілка успішно оновлена.', detail: '', life: 3000});
        }

    }, [successUpdateBranch]);

    useEffect(() => {
        if (successCreateBranch) {
            toast?.current?.show({severity: 'success', summary: 'Гілка успішно збережена.', detail: '', life: 3000});
        }

    }, [successCreateBranch]);

    useEffect(() => {
        if (errorUpdateBranch || errorCreateBranch) {
            toast?.current?.show({severity: 'error', summary: 'Щось пішло не так.', detail: '', life: 3000});
        }

    }, [errorUpdateBranch, errorCreateBranch]);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const deleteBranchMessage = <div>{notifications && notifications?.archiveBranchMessage ? notifications?.archiveBranchMessage.text : archive_branch_question ? archive_branch_question : progressBar}</div>

    const deleteBranchById = () => {
        dispatch(deleteBranch(branchId, jwtToken?.jwtToken, !archiveTableToggle));
        if (branchDeleteResponseMessage && branchDeleteResponseMessage?.statusId === 200) {
            setSelectedRowData(null);
            setEditBranchVisible(false);
        }
    }

    const deletionBranchToastShow = () => {
        if (!archive_branch_question && jwtToken?.jwtToken) {
            dispatch(getNotification(jwtToken?.jwtToken, 133, jwtToken?.jwtToken));
        }
        setDeleteDialogVisible(true);
        setBranchId(branchData?.id);
    }
    const actionTemplate = (rowData: any) => {
        const deletionBranchToastShow = () => {
            if (!archive_branch_question && jwtToken?.jwtToken) {
                dispatch(getNotification(jwtToken?.jwtToken, 133, jwtToken?.jwtToken));
            }
            setDeleteDialogVisible(true);
            setBranchId(rowData?.data?.id);
            if (branchDeleteResponseMessage && branchDeleteResponseMessage?.statusId === 200) {
                setSelectedRowData(null);
            }
        }

        return (
            <div className="flex gap-2">
                {hasBranchCreatePermission && (
                    <Button type="button" className={classNames('p-button-text my-0', {'hidden': rowData?.data?.isArchived})} onClick={() => {
                        dispatch(initialBranchesData());
                        dispatch(setBranchField({fieldName: 'parentName', value: rowData?.data?.name}));
                        dispatch(setBranchField({fieldName: 'parentId', value: rowData?.data?.id}));
                        dispatch(setBranchField({fieldName: 'isArchived', value: false}));
                        dispatch(setBranchField({fieldName: 'description', value: 'test description'}));
                        setSelectedRowData(null);
                        setEditBranchVisible(true);
                        if (hasBranchCreatePermission) {
                            dispatch(setEditType(true));
                        } else {
                            dispatch(setEditType(false));
                        }
                    }} icon="pi pi-plus"></Button>
                )}
                {hasBranchDeletePermission && (
                    <Button
                        type="button"
                        onClick={() => deletionBranchToastShow()}
                        icon="pi pi-trash"
                        className={classNames('p-button-text my-0 text-white', {'hidden': rowData?.data?.isArchived})}
                    ></Button>
                )}
            </div>
        );
    };

    const branchTemplate = (node: any) => {
        return (
            <div className="flex flex-column">
                {node?.ref && <div ref={scrollRef}></div>}
                <div className="flex flex-column align-items-center">
                    <span className="font-bold mb-2">{node.label}</span>
                    <span className="text-xs">{node.data.ownerName}</span>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const footer = () => {
        const updatedUsers = branchData?.users?.map((user: any) => {
            if (!('isLider' in user)) {
                return {
                    ...user,
                    isLider: false,
                };
            }
            return user;
        });

        let newData = {
            parentId: branchData?.parentId,
            ownerId: branchData?.ownerId,
            users: updatedUsers ? updatedUsers : [],
            description: branchData?.description,
            name: branchData?.name,
            actionByUserId: branchData?.actionByUserId,
            isArchived: branchData?.isArchived,
            isMainIntegrationUrl: branchData?.isMainIntegrationUrl,
            integrationURLId: branchData?.integrationURLId
        };

        let updatedData = {
            id: branchData?.id,
            parentId: branchData?.parentId,
            ownerId: branchData?.ownerId,
            users: updatedUsers ? updatedUsers : [],
            description: branchData?.description,
            name: branchData?.name,
            actionByUserId: branchData?.actionByUserId,
            isArchived: branchData?.isArchived,
            skillGroups: branchData?.skillGroups,
            isMainIntegrationUrl: branchData?.isMainIntegrationUrl,
            integrationURLId: branchData?.integrationURLId
        };

        const createBranchData = (data: any) => {
            const validationErrors = validateBranchForm(branchData);
            dispatch(setBranchErrorFields(validationErrors));
            if (!validationErrors) {
                dispatch(createBranch(data, jwtToken?.jwtToken, !archiveTableToggle));
            } else {
                setActiveIndex(0);
            }
        };

        const updateBranchData = (data: any) => {
            const validationErrors = validateBranchForm(branchData);
            dispatch(setBranchErrorFields(validationErrors));
            if (!validationErrors) {
                dispatch(updateBranch(data, jwtToken?.jwtToken, !archiveTableToggle));
            } else {
                setActiveIndex(0);
            }
        };

        return <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                {branchData?.id && !branchData?.isArchived && hasBranchDeletePermission && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                                                   className="p-button-outlined"
                                                                                                   label="Видалити"
                                                                                                   onClick={deletionBranchToastShow}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => setEditBranchVisible(false)}
                />
                {!branchData?.id && hasBranchCreatePermission ? <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                        className=""
                                                                        label="Створити та зберегти"
                                                                        onClick={() => {
                                                                            createBranchData(newData);
                                                                        }}
                /> : ''}
                {branchData?.id && !branchData?.isArchived && hasBranchUpdatePermission ? <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                  label="Зберегти"
                                                                                                  onClick={() => {
                                                                                                      updateBranchData(updatedData);
                                                                                                  }}
                /> : ''}
            </div>
        </div>
    };

    const rolesItems = () => {
        return (
            <>
                {branchData?.roles?.map((item: any, index: number) => (
                    <div key={index} className="flex align-items-center mb-2 w-full">
                        <div className={`field col-12 md:col-12 flex align-items-center justify-content-between m-0 w-full p-0`}>
                            <p className="mb-0">{item.id} {item.name}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const statusesItems = () => {
        return (
            <>
                {branchData?.statuses?.map((item: any, index: number) => (
                    <div key={index} className="flex align-items-center mb-2 w-full px-2">
                        <div className={`field col-12 md:col-12 flex align-items-center justify-content-between m-0 w-full p-0`}>
                            <p className="mb-0">{item.id} {item.name}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const skillGroupItems = () => {
        return (
            <>
                {branchData?.skillGroups?.map((item: any, index: number) => (
                    <div key={index} className="flex align-items-center mb-2 w-full px-3">
                        <div className={`field col-12 md:col-12 flex align-items-center justify-content-between m-0 w-full p-0`}>
                            <p className="mb-0">{item.id} {item.name}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const queueItems = () => {
        return (
            <>
                {branchData?.queues?.map((item: any, index: number) => (
                    <div key={index} className="flex align-items-center mb-2 w-full px-2">
                        <div className={`field col-12 md:col-12 flex align-items-center justify-content-between m-0 w-full p-0`}>
                            <p className="mb-0">{item.id} {item.name}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const renderDetailPanel = (rowData: any, key: number) => {
        let ownerName = null;
        if (typeof branchData?.ownerName === 'object' && branchData?.ownerName !== null) {
            ownerName = branchData?.ownerName?.fullName
        } else if (typeof branchData?.ownerName === 'string') {
            ownerName = branchData?.ownerName
        }
        const fields = [
            {label: "ID", value: rowData.id ? rowData.id : '- - -'},
            {label: "Батьківський бранч", value: rowData.parentName ? rowData.parentName : '- - -'},
            {label: "Ім'я власника", value: rowData.ownerName ? ownerName : '- - -'},
            {label: "Кількість користувачів", value: rowData.userCount ? rowData.userCount : '- - -'},
            {label: "Порядковий номер", value: key},
        ];
        return (
            <div className="relative">
                {branchDetailsLoader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': branchDetailsLoader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                        <div className="field col-12 md:col-8 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full mb-2">ID: {rowData?.id}</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full">Порядковий номер: <span className="pl-2">{key}</span></p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full">Кількість користувачів: <span className="pl-2">{rowData.userCount ? rowData.userCount : '- - -'}</span></p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full font-bold">{rowData?.name}</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full">Ім'я власника: <span className="pl-2">{rowData.ownerName ? ownerName : '- - -'}</span></p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 py-0 pl-0">
                                <p className="w-full">Батьківський бранч: <span className="pl-2">{rowData.parentName ? rowData.parentName : '- - -'}</span></p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-2 flex align-items-center justify-content-end m-0 p-0">
                                {/*<p className="w-full">Приоритет: {queueData?.queue?.priority || '0'}</p>*/}
                            </div>
                            <div className="field col-12 md:col-7 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{hasBranchVisionPermission && (<Button label="Переглянути гілки" icon="pi pi-sitemap" onClick={() => setBranchVisible(true)}/>)}</p>
                            </div>
                            <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                <div className="field col-12 md:col-12 flex align-items-center justify-content-end m-0 p-0">
                                    <button className="close-button block cursor-pointer ml-0 mr-1" onClick={() => {
                                        setEditBranchVisible(true);
                                        if (hasBranchUpdatePermission) {
                                            dispatch(setEditType(true));
                                        } else {
                                            dispatch(setEditType(false));
                                        }
                                    }}><span className={classNames('', {
                                        'pi pi-pencil': !rowData.isArchived && hasBranchUpdatePermission,
                                        'pi pi-eye': rowData.isArchived || !hasBranchUpdatePermission
                                    })}></span></button>
                                    <button className="close-button block cursor-pointer ml-0" onClick={() => setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="field col-12 md:col-12 flex flex-wrap m-0 p-0">
                            <div className="field col-12 md:col-2 flex m-0 py-3">Список Lid branch</div>
                            <div className="field col-12 md:col-2 flex m-0 py-3">Список користувачів</div>
                            <div className="field col-12 md:col-2 flex m-0 py-3">Ролі</div>
                            <div className="field col-12 md:col-2 flex m-0 py-3">Статуси</div>
                            <div className="field col-12 md:col-2 flex m-0 py-3">Вхідні черги</div>
                            <div className="field col-12 md:col-2 flex m-0 py-3">Групи кваліфікацій</div>
                            <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            <div className="field col-12 md:col-12 flex flex-wrap scroll-detail-items mb-0">
                                <div className="field col-12 md:col-2 flex flex-wrap align-self-start m-0">{rowData?.users && rowData.users.length ? (
                                    (rowData.users.some((user: any) => user.isLider)) ? (
                                        rowData.users
                                            .filter((user: any) => user.isLider)
                                            .map((user: any, index: number) => (
                                                <React.Fragment key={index}>
                                                    <ul className="field col-12 md:col-8 flex align-items-center m-0 w-full">
                                                        <li className="w-full">{user.lastName} {user.firstName} {user.middleName ? user.middleName : ''}</li>
                                                    </ul>
                                                </React.Fragment>
                                            ))) : ('- - -')) : ('- - -')}</div>
                                <div className="field col-12 md:col-2 flex flex-wrap m-0 align-self-start">
                                    {rowData?.users && rowData?.users.length ? rowData?.users.map((user: any, index: number) => (
                                        <React.Fragment key={index}>
                                            <ul className="field col-12 md:col-8 flex w-full align-items-center m-0">
                                                <li className="w-full">{user?.lastName} {user?.firstName} {user?.middleName ? user.middleName : ''}</li>
                                            </ul>
                                        </React.Fragment>
                                    )) : '- - -'}
                                </div>
                                <div className="field col-12 md:col-2 flex m-0 flex-column py-3">{rolesItems()}</div>
                                <div className="field col-12 md:col-2 flex m-0 flex-column py-3">{statusesItems()}</div>
                                <div className="field col-12 md:col-2 flex m-0 flex-column py-3">{queueItems()}</div>
                                <div className="field col-12 md:col-2 flex m-0 flex-column py-3">{skillGroupItems()}</div>
                            </div>
                        </div>
                        <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                    </div>}
                    {windowWidth <= 1248 && <div className="flex col-12 md:col-12 mb-0 w-full">
                        <div className="field col-8 md:col-8 flex align-items-center m-0 px-0">
                            <p className="font-bold">{rowData.name}</p>
                        </div>
                    </div>}

                    {windowWidth <= 1248 && fields.map((field: any, index: number) => (
                        <React.Fragment key={`${field.label}_${index}`}>
                            <div className="field col-12 md:col-4 flex align-items-center m-0">
                                <p>{field.label}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0">
                                <p>{field.value}</p>
                            </div>
                        </React.Fragment>
                    ))}
                    {windowWidth <= 1248 && <>
                        <div className="field col-12 md:col-4 flex m-0">
                            <p>Список Lid branch</p>
                        </div>
                        <div className="grid field col-12 md:col-8 flex align-items-center m-0">
                            {rowData?.users && rowData.users.length ? (
                                (rowData.users.some((user: any) => user.isLider)) ? (
                                    rowData.users
                                        .filter((user: any) => user.isLider)
                                        .map((user: any, index: number) => (
                                            <React.Fragment key={index}>
                                                <ul className="field col-12 md:col-8 flex align-items-center m-0 w-full">
                                                    <li className="w-full">{user.lastName} {user.firstName} {user.middleName ? user.middleName : ''}</li>
                                                </ul>
                                            </React.Fragment>
                                        ))) : ('- - -')) : ('- - -')}
                        </div>
                    </>}
                    {windowWidth <= 1248 && <div className="field col-12 md:col-4 flex m-0">
                        <p>Список користувачів</p>
                    </div>}
                    {windowWidth <= 1248 &&<div className="grid field col-12 md:col-8 flex align-items-center m-0">
                        {rowData?.users && rowData?.users.length ? rowData?.users.map((user: any, index: number) => (
                            <React.Fragment key={index}>
                                <ul className="field col-12 md:col-8 flex w-full align-items-center m-0">
                                    <li className="w-full">{user?.lastName} {user?.firstName} {user?.middleName ? user.middleName : ''}</li>
                                </ul>
                            </React.Fragment>
                        )) : '- - -'}
                    </div>}
                    {windowWidth <= 1248 && <div className="flex overflow-x-auto mt-3 w-full justify-content-center branch-organisation-chat">
                        {hasBranchVisionPermission && (<Button label="Переглянути гілки" icon="pi pi-sitemap" onClick={() => setBranchVisible(true)}/>)}
                    </div>}
                </div>
            </div>)
    }

    function reformatKeys(dataArray: any[], parentKey = "") {
        let index = 0;
        return dataArray?.map((item) => {
            index++;
            const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
            const newItem = {
                ...item,
                key: key,
                data: {
                    ...item.data,
                    keyName: key + '\u00A0\u00A0\u00A0\u00A0' + `${item?.data?.name}`
                }
            };

            if (item.children !== null && item.children && item.children.length > 0) {
                newItem.children = reformatKeys(item.children, key);
            }
            return newItem;
        });
    }

    function reformatKeysWithExpanded(dataArray: any[], parentKey = "") {
        let index = 0;
        return dataArray?.map((item) => {
            index++;
            const key = parentKey === "" ? `${index}` : `${parentKey}.${index}`;
            const newItem = {
                ...item,
                key: key,
                expanded: true,
                className: branchData?.id === item.data.id ? "active-branch text-white" : "bg-bluegray-400 text-white",
                label: item.data.name,
                ref: branchData?.id === item.data.id
            };

            if (item.children !== null && item.children && item.children.length > 0) {
                newItem.children = reformatKeysWithExpanded(item.children, key);
            }
            return newItem;
        });
    }

    let newArray = reformatKeys(branchesList);
    let newBranch = reformatKeysWithExpanded(branchesList);

    function getAllKeys(treeNode: TreeNode, keysArray: any[] = []): string[] {
        keysArray.push(treeNode.key);
        if (treeNode.children && Array.isArray(treeNode.children)) {
            for (const childNode of treeNode.children) {
                getAllKeys(childNode, keysArray);
            }
        }
        return keysArray;
    }

    const convertToExpandedKeys = (dataArray: any) => {
        const expandedKeys: any = {};

        dataArray.forEach((key: any) => {
            let currentKey = "";
            key.split(".").forEach((part: any) => {
                currentKey += (currentKey === "" ? "" : ".") + part;
                expandedKeys[currentKey] = true;
            });
        });

        setExpandedKeys(expandedKeys);
        return expandedKeys;
    };

    useEffect(() => {
        let allKeys: string[] = [];

        if (newArray?.length && !expandedKeys) {
            allKeys = getAllKeys(newArray[0]);
            convertToExpandedKeys(allKeys);
        }
    }, [newArray, expandedKeys]);

    useEffect(() => {
        let allKeys: string[] = [];
        if (newArray?.length) {
            allKeys = getAllKeys(newArray[0]);
            convertToExpandedKeys(allKeys);
        }
    }, [archiveTableToggle]);

    const createdUserTime = () => {
        const time = new Date(branchData?.branchData?.createdDateTime || '').toLocaleString("uk-UA")
        if (branchData?.branchData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(branchData?.branchData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (branchData?.branchData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    const branchDialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    setEditBranchVisible(true);
                    setSelectedRowData(null);
                    if (hasBranchUpdatePermission) {
                        dispatch(setEditType(true));
                    } else {
                        dispatch(setEditType(false));
                    }
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': !branchData.isArchived && hasBranchUpdatePermission,
                        'pi pi-eye': branchData.isArchived || !hasBranchUpdatePermission
                    })}></span></Button>
            </div>
        </>
    }

    const rowClassName = (newArray: any) => {
        return { 'line-through text-gray-400': (newArray.data && newArray.data.isArchived) };
    }

    return (
        <div className="layout-dashboard">
            <div className="flex col-12 md:col-12 flex-wrap">
                <Toast ref={toast} position="bottom-right"/>
                <div className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table branch-table align-self-start', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                    <BranchListNav/>
                    <TreeTable
                        className="cursor-pointer"
                        value={newArray}
                        onToggle={(e) => setExpandedKeys(e.value)}
                        expandedKeys={expandedKeys}
                        loading={loading}
                        scrollHeight={(selectedRowData) && !isMobile() ? '33vh' : '75vh'}
                        rowClassName={rowClassName}
                        onRowClick={(e) => {
                            const target = e.originalEvent.target as HTMLElement;
                            if (!target.classList.contains('p-button') && !target.closest('.p-button')) {
                                dispatch(getBranchDetailById(e.node.data.id, jwtToken?.jwtToken));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.node);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.node);
                                    }
                                } else {
                                    setSelectedRowData(e.node);
                                    setVisible(true);
                                }
                            }
                        }}
                        scrollable>
                        {/*<Column field="keyName" header="Branch Name" body={keyNameTemplate} expander style={{ width: '500px' }}></Column>*/}
                        {/*<Column field="ownerName" header="Owner" body={ownerNameTemplate} style={{ width: '200px' }}></Column>*/}
                        {/*<Column field="userCount" header="CountUsers" body={countUsersTemplate} style={{ width: '200px' }}></Column>*/}
                        {/*<Column field="id" header="Action" style={{ width: '100px' }} body={actionTemplate}></Column>*/}
                        <Column field="keyName" header="Branch Name" expander style={{ width: '500px' }}></Column>
                        <Column field="id" header="ID" style={{ width: '100px' }}></Column>
                        <Column field="ownerName" header="Owner" style={{ width: '200px' }}></Column>
                        <Column field="userCount" header="CountUsers" style={{ width: '200px' }}></Column>
                        <Column field="action" header="Action" style={{ width: '100px' }} body={actionTemplate}></Column>
                    </TreeTable>
                </div>
                {selectedRowData && branchData && (
                    <>
                        {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                            {renderDetailPanel(branchData, selectedRowData.key)}
                        </div>}

                        {windowWidth <= 1248 && <div>
                            <Dialog header={branchDialogHeader} visible={visible} className="monitoring-info" onHide={() => setVisible(false)}>
                                {renderDetailPanel(branchData, selectedRowData.key)}
                            </Dialog>
                        </div>}
                    </>
                )}
            </div>

            <Dialog header="Архітектура гілок" style={{height: '100vh', width: '98vw'}} visible={branchVisible} className={classNames('', {'branch-info': isMobile()})} onHide={() => setBranchVisible(false)}>
                <OrganizationChart className="branch-organisation-chat" value={newBranch} nodeTemplate={branchTemplate}/>
            </Dialog>
            <Dialog
                header={branchData?.id ? "Редагувати гілку" : "Створити гілку"}
                footer={footer}
                dismissableMask={false}
                closeOnEscape={false}
                draggable={true}
                blockScroll={true}
                position="top"
                onHide={() => setEditBranchVisible(false)}
                style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
                visible={editBranchVisible} className='monitoring-info'>
                <Divider style={{margin: '0 0 20px 0'}}/>
                {!!branchData.id && <div className="grid align-items-center user-dialog-info">
                    <div className="field col-12 md:col-6 mb-0">
                        {!!branchData.id && <div className="item pl-2">
                            ID: {branchData?.id ? branchData.id : ''}
                        </div>}
                    </div>
                    <div className="field col-12 md:col-6 mb-0">
                        {!!branchData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                            {branchData?.branchData?.userCreate && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{branchData?.branchData?.userCreate ? branchData?.branchData?.userCreate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                            </div>}
                            {branchData?.branchData?.userUpdate && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{branchData?.branchData?.userUpdate ? branchData?.branchData?.userUpdate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                            </div>}
                        </div>}
                    </div>
                </div>}
                <TabView className="user-dialog-info branch-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Загальна iнформацiя">
                        <BranchDialogGeneralSettings/>
                    </TabPanel>
                    {branchData?.id && <TabPanel header="Ролі користувачів">
                        <BranchListOfDependenciesRoles/>
                    </TabPanel>}
                    {branchData?.id &&<TabPanel header="Статуси користувачів">
                        <BranchListOfDependenciesStatuses/>
                    </TabPanel>}
                    {branchData?.id &&<TabPanel header="Вхідні черги">
                        <BranchListOfDependenciesQueues/>
                    </TabPanel>}
                    {/*<TabPanel header="Вихідні кампанії">*/}
                    {/*    /!*<BranchDialogGeneralSettings/>*!/*/}
                    {/*</TabPanel>*/}
                    {branchData?.id &&<TabPanel header="Групи кваліфікацій">
                        <BranchListOfDependenciesSkillGroups/>
                    </TabPanel>}
                    {branchData?.id ? <TabPanel header="Архітектура гілки">
                        {hasBranchVisionPermission && (<div className="mb-1">
                            <Button label="" icon="pi pi-external-link" onClick={() => setBranchVisible(true)}/>
                        </div>)}
                        {windowWidth > 1248 && <OrganizationChart className="branch-organizationchart-table relative" value={newBranch} nodeTemplate={branchTemplate}/>}
                    </TabPanel> : ''}
                    <TabPanel header="Інтеграція">
                        <BranchACDSettings/>
                    </TabPanel>
                </TabView>
            </Dialog>
            <ConfirmDialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} message={deleteBranchMessage}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити"
                           accept={() => deleteBranchById()}
            />
        </div>
    );
}
