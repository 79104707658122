import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {HubConnectionBuilder} from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import {setCompanyField} from "../../../redux/actions/actions";
import {MVP, WSS_URL} from "../../../redux/config";
import {DecodedToken} from "../../../types/types";
import {jwtDecode} from "jwt-decode";

const CompaniesListDialogInfo = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData)
    // @ts-ignore
    const companiesDirectoryCampaignType = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryCampaignType)
    const [allCampaigns, setAllCampaigns] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    let decodedToken: DecodedToken;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
    }
    const statusesDict = [
        {name: 'DRAFT', code: 1},
        {name: 'IDLE', code: 2},
        {name: 'ACTIVE', code: 3},
        {name: 'PAUSE', code: 4},
        {name: 'CANCEL', code: 5},
        {name: 'DONE', code: 6},
    ];

    function startConnection() {
        const connection = new HubConnectionBuilder()
            .withUrl(`${WSS_URL}DialerHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => jwtToken?.jwtToken || ""
            })
            .withAutomaticReconnect()
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        connection.start()
            .then(() => {
                connection.on('StateCampaign', newData => {
                    setData(JSON.parse(newData));
                });
                connection.on('AllCampaigns', AllCampaigns => {
                    setAllCampaigns(JSON.parse(AllCampaigns));
                });
                connection.invoke("UserConnected", decodedToken[`x-access-userid`]);
            })
            .catch(() => {
                setTimeout(startConnection, 5000);
            });

        return connection;
    }


    useEffect(() => {
        const connection = startConnection();

        return () => {
            connection.stop();
        };
    }, []);

    useEffect(() => {
            if (companiesData?.campaign?.campaignId === data.CampaignId) {
                dispatch(setCompanyField({section: 'campaign', fieldName: 'statusId', value: data.StatusId}));
            }}, [data]);

    useEffect(() => {
        const matchingCampaign = allCampaigns?.find((campaign: any) => campaign.CampaignId === companiesData?.campaign?.campaignId);
        if (matchingCampaign) {
            const fieldsToUpdate = [
                'activeOperators', 'isArchived', 'countUsers', 'isDoneContactCount',
                'countContacts', 'progressContacts', 'progressCampaign', 'calledContactCount',
                'name', 'description', 'statusId', 'campaignTypeId', 'operatorStrategyTypeId',
                'priority', 'schedulerId', 'employmentOperators', 'wellComplet', 'firstCallOperator'
            ];

            const fieldsToSchedulerUpdate = [
                'typeEventStart',
                'typeScheduler',
                'startDate',
                'startTime',
                'endDate',
                'endTime'
            ];
            fieldsToUpdate.forEach(fieldName => {
                dispatch(setCompanyField({ section: 'campaign', fieldName, value: matchingCampaign[fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] }));
            });
            if (matchingCampaign?.TypeScheduler) {
                fieldsToSchedulerUpdate.forEach(fieldName => {
                    dispatch(setCompanyField({ section: 'scheduler', fieldName, value: matchingCampaign[fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] }));
                });
            }
        }}, [allCampaigns]);

    const statusBodyTemplate = (rowData: any) => {
        let statusName = statusesDict.map((item) => {
            if(item.code === rowData?.statusId) {
                return item.name
            }
        })
        return <span className={`customer-badge status-${rowData?.statusId}`} style={{borderRadius: `6px`}}>{statusName}</span>;
    };

    const campaignTypeBodyTemplate = (rowData: any) => {
        let typeName = companiesDirectoryCampaignType.map((item: any) => {
            if(item.dictionaryId === rowData?.campaignTypeId) {
                return item.name
            }
        })
        return (
            <React.Fragment>
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">
                    {typeName}
                </span>
            </React.Fragment>
        );
    };

    const campaignNameBodyTemplate = (rowData: any) => {
        let name = rowData?.name;
        const truncateWithEllipses = (text: string, max: number) => {
            return text.substr(0,max-1)+(text.length>max?'...':'');
        }
        if (name) {name = truncateWithEllipses(name, 156)}
        return (
            <React.Fragment>
                <div className="campaignName">
                    <p>{name}</p>
                </div>
            </React.Fragment>
        );
    };

    const operatorsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-1">
                {rowData?.activeOperators} / {rowData?.countUsers}
            </div>
        )
    }

    const countContactsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-1">
                {rowData?.calledContactCount} / {rowData?.countContacts}
            </div>
        )
    }

    const isDoneContactCount = (rowData: any) => {
        return (
            <div className="pl-1">
                {rowData?.isDoneContactCount}
            </div>
        )
    }

    const isGeneralBodyTemplate = () => {
        return (
            <div className="pl-1">
                {companiesData.campaign.isGeneralTrunk ? 'Транк Кампанії' : 'Транк Оператора'}
            </div>
        )
    }

    const isDoneContactCountPercent = (rowData: any) => {
        let percent = rowData?.calledContactCount*100/rowData?.countContacts;
        if (isNaN(percent)) {
            percent = 0;
        } else {
            percent = Math.round(percent);
        }
        return (
            <div className="flex justify-content-center align-items-center justify-content-between pl-1">
                <div>{percent}%</div>
            </div>
        )
    }


    return (
        <DataTable value={[companiesData.campaign]}>
            {!MVP && <Column field="priority" header="Пріорітет"></Column>}
            <Column field="campaignTypeId" header="Тип" body={campaignTypeBodyTemplate}></Column>
            <Column header="А номер" body={isGeneralBodyTemplate} style={{minWidth: '150px'}}></Column>
            <Column field="name" header="Назва" body={campaignNameBodyTemplate}></Column>
            <Column field="statusId" header="Статус" body={statusBodyTemplate}></Column>
            <Column field="activeOperators" header="Оператори" body={operatorsBodyTemplate}></Column>
            <Column field="calledContactCount" header="Опрацьовано контактів" body={countContactsBodyTemplate}></Column>
            <Column field="calledContactCount" header="Опрацьовано" body={isDoneContactCountPercent}></Column>
            <Column field="isDoneContactCount" header="Успішно" body={isDoneContactCount}></Column>
        </DataTable>
    )
}

export default CompaniesListDialogInfo
