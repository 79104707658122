import React, {useEffect, useRef, useState} from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {setUserDialogUnzipping, setUserErrorFields, setUserField, setUsersDialogVisible} from "../../../../redux/actions/actionsUsersList";
import UsersListDialogInfo from "../UserDialodInfo";
import UsersListDialogGeneralSettings from "../UsersListDialogGeneralSettings";
import UsersListDialogPersonalInfo from "../UserDialogPersonalInfo";
import UserBranchInfo from "../UserBranchInfo";
import UserDialogSettings from "../UserDialogSettings";
import UserDialogCampaign from "../UserDialogCampaign";
import {createUser, deleteUser, getPhoneDictionary, getUserDetails, getUsersManagerRolesList, updateUser} from "../../../../redux/api/apiUsersList";
import {Toast} from "primereact/toast";
import {validateUserForm} from "../../../../redux/validator/validator";
import {getNotification} from "../../../../redux/api/api";
import {getItem} from "../../../../redux/cache/cacheService";
import {codes} from "../../../../redux/notificationCodes";
import {ConfirmDialog} from "primereact/confirmdialog";
import {ProgressBar} from "primereact/progressbar";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import UserPhoneSettings from "../UserPhoneSettings";

const UserListDialog = (props: any) => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const userDialogVisible = useAppSelector(state => state.UsersListReducer.dialogVisible);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const userId = userData?.user?.id;
    const toast = useRef<Toast>(null);
    const toastBC = useRef<Toast>(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const [visibleDeletedMessage, setVisibleDeletedMessage] = useState<any>(false);
    // @ts-ignore
    const userDeleteQuestionsText = useAppSelector(state => state.CompaniesListReducer.userDeleteQuestionsText);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as any;
    // @ts-ignore
    const dataSort = useAppSelector(state => state.UsersListReducer.dataSort);
    // @ts-ignore
    const usersDialogUnzipping = useAppSelector(state => state.UsersListReducer.usersDialogUnzipping);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    // @ts-ignore
    const userTrunkIds = useAppSelector(state => state.UsersListReducer.usersTrunkIds);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {
        create: hasUsersManagerCreatePermission,
        update: hasUsersManagerUpdatePermission,
        delete: hasUsersManagerDeletePermission,
        visible: hasUsersManagerVisionPermission
    } = usersManagerPermissions;

    useEffect(() => {
        dispatch(getUsersManagerRolesList(jwtToken?.jwtToken));
        dispatch(getPhoneDictionary(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.branchClick) {
            setActiveIndex(4);
            props.setBranchClick(false);
        } else {
            setActiveIndex(0);
        }
        dispatch(setUserErrorFields(null));
        dispatch(setUserField({section: 'user', fieldName: 'actionByUserId', value: user.userId}));
    }, [userDialogVisible])

    useEffect(() => {
        if (errorFields !== null && 'userNameError' in errorFields) {
            setActiveIndex(0);
        } else if (errorFields !== null && 'mobilePhone' in errorFields) {
            setActiveIndex(3);
        } else if (errorFields !== null && 'emailError' in errorFields) {
            setActiveIndex(2);
        }
        if (errorFields && errorFields.apiError) {
            if (errorFields.message === 'EmailExist') {
                dispatch(setUserErrorFields({'email': true, 'title': errorFields.title}));
                setActiveIndex(1);
            }
            if (errorFields.message === 'userNameError') {
                dispatch(setUserErrorFields({'userName': true, 'title': errorFields.title}));
                setActiveIndex(0);
            }
        }
    }, [errorFields])

    useEffect(() => {
        dispatch(setUserField({section: 'user', fieldName: 'actionByUserId', value: user.userId}));
    }, [userData?.user?.actionByUserId])

    const showSaveChangesNotification = () => {
        dispatch(setUsersDialogVisible(false));
        // dispatch(setUserDialogUnzipping(false));
        if (userData?.user?.userId) {
            dispatch(getUserDetails(userData?.user?.userId, jwtToken?.jwtToken));
        }
    }


    const createUserData = () => {
        const hasValidTrunkPhoneId = userTrunkIds?.some((item: { trunkId: null; }) => item.trunkId != null);
        const updatedUserData = {
            ...userData,
            user: {
                ...userData.user,
                userTrunks: hasValidTrunkPhoneId ? userTrunkIds : []
            }
        };

        const validationErrors = validateUserForm(updatedUserData.user);
        dispatch(setUserErrorFields(validationErrors));
        if (!validationErrors) {
            dispatch(createUser(updatedUserData.user, jwtToken?.jwtToken, dataSort));
        } else {
            if (validationErrors.userName || validationErrors.password || validationErrors.roles || validationErrors.branchId) {
                setActiveIndex(0);
            } else if (validationErrors.firstName || validationErrors.lastName || validationErrors.birthDate || validationErrors.recruitedDate) {
                setActiveIndex(1);
            } else if (validationErrors.phoneNo || validationErrors.email || validationErrors.userTrunks || validationErrors.email) {
                setActiveIndex(2);
            } else if (validationErrors.utc || validationErrors.countryId || validationErrors.languageId || validationErrors.mobilePhone) {
                setActiveIndex(3);
            }
        }
    };

    const updateUserData = () => {
        const hasValidTrunkPhoneId = userTrunkIds?.some((item: { trunkId: null; }) => item.trunkId != null);
        const updatedUserData = {
            ...userData,
            user: {
                ...userData.user,
                userTrunks: hasValidTrunkPhoneId ? userTrunkIds : []
            }
        };
        const validationErrors = validateUserForm(updatedUserData.user);

        dispatch(setUserErrorFields(validationErrors));
        if ((!validationErrors || validationErrors.password) && !validationErrors?.roles && !validationErrors?.branchId && !validationErrors?.userTrunks && !validationErrors?.mobilePhone && !validationErrors?.email) {
            dispatch(updateUser(updatedUserData.user, jwtToken?.jwtToken, dataSort));
            dispatch(setUserErrorFields(null));
        } else {
            if (validationErrors.userName || validationErrors.roles || validationErrors.branchId) {
                setActiveIndex(0);
            } else if (validationErrors.firstName || validationErrors.lastName || validationErrors.birthDate || validationErrors.recruitedDate) {
                setActiveIndex(1);
            } else if (validationErrors.phoneNo || validationErrors.email || validationErrors.userTrunks || validationErrors.email) {
                setActiveIndex(2);
            } else if (validationErrors.utc || validationErrors.countryId || validationErrors.languageId || validationErrors.mobilePhone) {
                setActiveIndex(3);
            }
        }
    };

    let user_delete_questions = getItem(codes.user_delete_questions);

    const deletedUserInfo = {
        userId: userData?.user?.userId,
        isActive: false,
        actionByUserId: user?.userId,
        userName: user?.userName
    };

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const userDeletedQuestion = <div>{userDeleteQuestionsText ? userDeleteQuestionsText.text : user_delete_questions ? user_delete_questions : progressBar}</div>

    const deletionToastShow = () => {
        if (!user_delete_questions && user.userId) {
            dispatch(getNotification(user.userId, 130, jwtToken?.jwtToken));
        }
        setVisibleDeletedMessage(true);
    }

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <Toast ref={toast}/>
            <Toast ref={toastBC} position="center"/>
            <div className="buttons">
                {userId && usersActiveTableToggle && hasUsersManagerDeletePermission && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                                                className="p-button-outlined"
                                                                                                label="Видалити"
                                                                                                onClick={() => {
                                                                                                    deletionToastShow();
                                                                                                }}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            dispatch(setUsersDialogVisible(false));
                        }
                        }
                />
                {!userId && usersActiveTableToggle && hasUsersManagerCreatePermission && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                 className=""
                                                                                                 label="Створити та зберегти"
                                                                                                 onClick={() => createUserData()}
                />}
                {userId && (usersActiveTableToggle || usersDialogUnzipping) && hasUsersManagerUpdatePermission && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                                          label="Зберегти"
                                                                                                                          onClick={() => {
                                                                                                                              dispatch(updateUserData)
                                                                                                                          }
                                                                                                                          }
                />}
            </div>
        </div>
    );

    const bodyHeader = () => {
        return <UsersListDialogInfo/>;
    }

    const createdUserTime = () => {
        const time = new Date(userData?.user?.userData?.createdDateTime || '').toLocaleString("uk-UA")
        if (userData?.user?.userData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(userData?.user?.userData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (userData?.user?.userData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    return (
        <Dialog
            header={bodyHeader}
            footer={!hasUsersManagerVisionPermission ? null : footer}
            visible={userDialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
            onHide={() => showSaveChangesNotification()}
        >
            {userData && <div className="company-info-popup">
                <div className="grid align-items-center">
                    <div className="field col-12 md:col-6">
                        {!!userData.user.id && <div className="item">
                            ID: {userData?.user?.id ? userData.user.id : ''}
                        </div>}
                    </div>
                    <div className="field col-12 md:col-6">
                        {!!userData.user.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                            {userData?.user?.userData?.userCreate && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{userData?.user?.userData?.userCreate ? userData?.user?.userData?.userCreate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                            </div>}
                            {userData?.user?.userData?.updatedByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{userData?.user?.userData?.userUpdate ? userData?.user?.userData?.userUpdate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                            </div>}
                        </div>}
                    </div>
                </div>
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Загальна iнформацiя">
                        {userData && <UsersListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Дані користувача">
                        <UsersListDialogPersonalInfo/>
                    </TabPanel>
                    <TabPanel header="Контакти">
                        <UserPhoneSettings/>
                    </TabPanel>
                    <TabPanel header="Налаштування">
                        <UserDialogSettings/>
                    </TabPanel>
                    {!!userData?.user?.id && <TabPanel header="Черги/Кампанii">
                        <UserDialogCampaign/>
                    </TabPanel>}
                    {userData?.user?.branchId ? <TabPanel header="Пiдпорядкування">
                        <UserBranchInfo/>
                    </TabPanel> : ''}
                </TabView>
                <ConfirmDialog visible={visibleDeletedMessage} onHide={() => setVisibleDeletedMessage(false)} message={userDeletedQuestion}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                    dispatch(deleteUser(deletedUserInfo, jwtToken?.jwtToken));
                    // @ts-ignore
                    if (deletedUserInfo.userId === props.selectedRowData?.userId) {
                        props.setSelectedRowData(null);
                    }
                    dispatch(setUsersDialogVisible(false));
                }
                }/>
            </div>}
        </Dialog>
    )
}

export default UserListDialog
