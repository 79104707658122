import React from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Button} from "primereact/button";
import {setEditType} from "redux/actions/actions";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {
    initialQueueData,
    setArchiveQueueTableToggle,
    setQueueClearFilters,
    setQueueDialogVisible,
    setQueueErrorField
} from "../../../redux/actions/actionsQueue";

const CompaniesListNav = (props: any) => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Queues');

    const {
        create: hasDialerCreatePermission,
    } = dialerPermissions;
    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => {
                    dispatch(setArchiveQueueTableToggle(true))
                }}
                        label="Черги"
                        className={archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => {
                    dispatch(setArchiveQueueTableToggle(false))
                }}
                        label="Архів"
                        className={archiveTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasDialerCreatePermission && (<Button label="Створити чергу"
                                                       icon="pi pi-plus"
                                                       className="mr-2 mb-2"
                                                       onClick={() => {
                                                           dispatch(initialQueueData());
                                                           dispatch(setQueueDialogVisible(true));
                                                           dispatch(setQueueErrorField(null));
                                                           props.setSelectedRowData(null);
                                                           dispatch(setArchiveQueueTableToggle(true));
                                                           if (hasDialerCreatePermission) {
                                                               dispatch(setEditType(true));
                                                           } else {
                                                               dispatch(setEditType(false));
                                                           }
                                                       }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    dispatch(setQueueClearFilters());
                    props.handleReset()
                }}/>
            </div>
            <div className="mobile">
                {hasDialerCreatePermission && (
                    <Button icon="pi pi-plus"
                            className="mr-2 mb-2"
                            onClick={() => {
                                dispatch(setQueueErrorField(null));
                                dispatch(initialQueueData());
                                dispatch(setQueueDialogVisible(true));
                                props.setSelectedRowData(null);
                                dispatch(setArchiveQueueTableToggle(true));
                                if (hasDialerCreatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}
                    />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setQueueClearFilters())}/>
            </div>
        </div>
    )
}

export default CompaniesListNav
