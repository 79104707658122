import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getTempUrl} from "../../../redux/api/apiMonitoringList";
import AudioPlayerComponent from 'react-h5-audio-player';
import {ProgressBar} from "primereact/progressbar";

const AudioPlayer = (props: any) => {
    const dispatch = useAppDispatch();
    const audioUrl = useAppSelector(state => state.MonitoringListReducer.audioUrl);
    const monitoringData = useAppSelector(state => state.MonitoringListReducer?.monitoringData);
    const audioPlayer2 = useRef<any>(null);
    const [key, setKey] = useState(1);
    const [currentTime, setCurrentTime] = useState(3);
    const [isLoading, setIsLoading] = useState(false);
    const notFoundError = useAppSelector(state => state.NotificationsReducer.notFoundError);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    useEffect(() => {
        if (props.timeStamp && audioUrl && !isLoading) {
            audioPlayer2.current.audio.current.currentTime = props.timeStamp / 1000;
        }
    }, [audioUrl]);

    const handlePlayError = (e: any) => {
        if (!notFoundError && jwtToken?.jwtToken && monitoringData?.sessionId) {
            props.setTimeStamp(e.target.currentTime * 1000);
            dispatch(getTempUrl(jwtToken?.jwtToken, monitoringData?.sessionId, monitoringData?.id));
            setKey(prevKey => prevKey + 1);
        }
    };

    const handleTimeUpdate = (e: any) => {
        setCurrentTime(e.target.currentTime * 1000);
    };

    const handleWaiting = () => {
        setIsLoading(true);
    };

    const handlePlaying = () => {
        setIsLoading(false);
    };

    return (
        <>
            {isLoading && <ProgressBar mode="indeterminate" style={{height: '3px'}}></ProgressBar>}
            <AudioPlayerComponent
                ref={audioPlayer2}
                key={key}
                className={`${isLoading && 'opacity-50'}`}
                autoPlay={true}
                onWaiting={handleWaiting}
                onError={handlePlayError}
                autoPlayAfterSrcChange
                onPlaying={handlePlaying}
                onListen={handleTimeUpdate}
                src={audioUrl ? audioUrl : null}
            />
        </>
    );
};

export default AudioPlayer;
