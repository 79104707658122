import React, {useEffect, useState} from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {addInboundPhone, addOutboundPhone, removeInboundPhone, removeOutboundPhone, setTrunkField, updateInboundPhone, updateOutboundPhone} from "../../../../redux/actions/actionsTrunk";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {InputNumber} from "primereact/inputnumber";

const BlackList = () => {
    const dispatch = useAppDispatch();
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);
    const errorFields = useAppSelector(state => state.TrunksListReducer.errorFields);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle);
    const listInboundPhones = useAppSelector(state => state.TrunksListReducer.trunkData?.listInboundPhones);
    const listOutboundPhones = useAppSelector(state => state.TrunksListReducer.trunkData?.listOutboundPhones);
    const [editedPhones, setEditedPhones] = useState<number[]>([...listOutboundPhones]);
    const [editedInboundPhones, setEditedInboundPhones] = useState<number[]>([...listInboundPhones]);
    const handleAddPhone = (value: any) => {
        dispatch(addOutboundPhone(value));
    };

    const handleUpdatePhone = (index: number, newPhoneValue: any) => {
        if (String(newPhoneValue).length > 12) return;

        setEditedPhones((prev: any) => {
            const updatedPhones = [...prev];
            updatedPhones[index] = newPhoneValue != null ? String(newPhoneValue) : '';
            return updatedPhones;
        });

        dispatch(updateOutboundPhone(index, newPhoneValue != null ? String(newPhoneValue) : ''));
    };

    const handleAddInboundPhone = (value: any) => {
        dispatch(addInboundPhone(value));
    };

    const handleUpdateInboundPhone = (index: number, newPhoneValue: any) => {
        if (String(newPhoneValue).length > 12) return;

        setEditedInboundPhones((prev: any) => {
            const updatedPhones = [...prev];
            updatedPhones[index] = newPhoneValue != null ? String(newPhoneValue) : '';
            return updatedPhones;
        });

        dispatch(updateInboundPhone(index, newPhoneValue != null ? String(newPhoneValue) : ''));
    };

    useEffect(() => {
        setEditedPhones(listOutboundPhones);
    }, [listOutboundPhones]);

    useEffect(() => {
        setEditedInboundPhones(listInboundPhones);
    }, [listInboundPhones]);

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="grid field col-12 md:col-12">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="description" className="font-bold pb-2">Вхідні дзвінки</label>
                        {listInboundPhones && editedInboundPhones?.map((item: any, index: number) => {
                            return <div key={index} className="flex mb-2">
                                <InputNumber
                                    disabled={!archiveTableToggle || !editType} className={classNames('w-18rem mr-2', {'p-invalid': errorFields?.listInboundPhones &&  (!item?.length || item?.length < 12)})}
                                    placeholder="380*********" useGrouping={false} maxLength={12} onChange={(e) => handleUpdateInboundPhone(index, e.value)} value={item}/>
                                <Button style={{minHeight: '37px', minWidth: '37px'}} icon="pi pi-times" disabled={!archiveTableToggle || !editType} onClick={() => dispatch(removeInboundPhone(index))} className={classNames('p-button-secondary cursor-card-item mr-2 px-2 flex cursor-pointer align-items-center justify-content-center')}></Button>
                            </div>
                        })}
                        {errorFields?.listInboundPhones && <div className="col-12 md:col-12 pb-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.listInboundPhones?.listInboundPhones}</span></div>}
                        {editType && <Button className="w-full w-19rem mt-2" disabled={!archiveTableToggle || !editType} icon="pi pi-plus" onClick={() => handleAddInboundPhone(null)}><label className="pl-2">Додати номер</label></Button>}
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="description" className="font-bold pb-2">Вихідні дзвінки</label>
                        {listOutboundPhones && editedPhones?.map((item: any, index: number) => {
                            return <div key={index} className="flex mb-2">
                                <InputNumber
                                    disabled={!archiveTableToggle || !editType} className={classNames('w-18rem mr-2', {'p-invalid': errorFields?.listOutboundPhones && (!item?.length || item?.length < 12)})}
                                    placeholder="380*********" useGrouping={false} maxLength={12} onChange={(e) => handleUpdatePhone(index, e.value)} value={item}/>
                                <Button style={{minHeight: '37px', minWidth: '37px'}} icon="pi pi-times" onClick={() => dispatch(removeOutboundPhone(index))} disabled={!archiveTableToggle || !editType} className={classNames('p-button-secondary cursor-card-item mr-2 px-2 flex cursor-pointer align-items-center justify-content-center')}></Button>
                            </div>
                        })}
                        {errorFields?.listOutboundPhones && <div className="col-12 md:col-12 pb-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.listOutboundPhones?.listOutboundPhones}</span></div>}
                        {editType && <Button className="w-full w-19rem mt-2" disabled={!archiveTableToggle || !editType} icon="pi pi-plus" onClick={() => handleAddPhone(null)}><label className="pl-2">Додати номер</label></Button>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="description" className="font-bold pb-2">Вихідні дзвінки</label>
                        <InputTextarea
                            id="description"
                            value={trunkData?.blackListRegex || ''}
                            onChange={e => {
                                dispatch(setTrunkField({section: 'trunk', fieldName: 'blackListRegex', value: e.target.value}));
                            }}
                            autoResize
                            rows={3}
                            disabled={!archiveTableToggle || !editType}
                        /></div>
                </div>
            </div>
        </div>
    )
}

export default BlackList;
