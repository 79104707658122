import React from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Button} from "primereact/button";
import {setEditType} from "redux/actions/actions";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {initialQualificationGroupData, setClearFilters, setQualificationGroupArchiveTableToggle, setQualificationGroupDialogVisible, setQualificationGroupErrorFields} from "../../../redux/actions/actionsQualificationGroup";

const QualificationGroupListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const archiveStatusTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);

    const {
        create: hasCreatePermission,
    } = dialerPermissions;
    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => {
                    dispatch(setQualificationGroupArchiveTableToggle(true))
                }}
                        label="Групи"
                        className={archiveStatusTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => {
                    dispatch(setQualificationGroupArchiveTableToggle(false))
                }}
                        label="Архів"
                        className={archiveStatusTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasCreatePermission && (<Button label="Створити групу"
                                                 icon="pi pi-plus"
                                                 className="mr-2 mb-2"
                                                 disabled={!(hasCreatePermission)}
                                                 onClick={() => {
                                                     dispatch(initialQualificationGroupData());
                                                     dispatch(setQualificationGroupDialogVisible(true));
                                                     dispatch(() => props.setSelectedRowData(null));
                                                     dispatch(setQualificationGroupErrorFields(null));
                                                     dispatch(setQualificationGroupArchiveTableToggle(true));
                                                     if (hasCreatePermission) {
                                                         dispatch(setEditType(true));
                                                     } else {
                                                         dispatch(setEditType(false));
                                                     }
                                                 }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    dispatch(setClearFilters());
                    props.handleReset()
                }}/>
            </div>
            <div className="mobile">
                {hasCreatePermission && (<Button icon="pi pi-plus"
                                                 className="mr-2 mb-2"
                                                 disabled={!(hasCreatePermission)}
                                                 onClick={() => {
                                                     dispatch(initialQualificationGroupData());
                                                     dispatch(setQualificationGroupDialogVisible(true));
                                                     dispatch(() => props.setSelectedRowData(null));
                                                     dispatch(setQualificationGroupErrorFields(null));
                                                     dispatch(setQualificationGroupArchiveTableToggle(true));
                                                     if (hasCreatePermission) {
                                                         dispatch(setEditType(true));
                                                     } else {
                                                         dispatch(setEditType(false));
                                                     }
                                                 }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())}/>
            </div>
        </div>
    )
}

export default QualificationGroupListNav;
