import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {Divider} from "primereact/divider";
import {checkPermissions} from "../../../../../redux/permissions/permissionsUtils";
import {setEditType} from "../../../../../redux/actions/actions";
import {setAudioDialogVisible, setAudioErrorField, setAudioField, setSelectedAudioIndex} from "../../../../../redux/actions/actionsAudio";
import {getAudioById} from "../../../../../redux/api/apiAudio";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import AudioPlayerComponent from "react-h5-audio-player";
import axios from "axios";

const AudioListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);
    const loader = useAppSelector(state => state.AudioListReducer.detailsLoading);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const permissions = checkPermissions(rolesVision, 'Audio');
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [visible, setVisible] = useState(false);
    const audioPlayerRef = useRef<any>(null);
    const [playerKey] = useState<number>(0);

    const {
        visible: hasDialerVisiblePermission,
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
    } = permissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAudioReady = () => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.audio.current.pause();
        }
    };

    useEffect(() => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.audio.current.pause();
        }
    }, [audioData?.audioTempUrl])


    const audioHeaderBody = () => {
        return <p>{audioData?.audioName}</p>
    }

    const onFileUploadByLink = async () => {
        const responseUrl = await axios.get(`${audioData?.audioTempUrl}`, {
            responseType: 'blob',
            headers: {
                cache: 'no-store',
                'Cache-Control': 'no-cache'
            }
        });

        const blob = new Blob([responseUrl.data], {type: responseUrl.headers['content-type']});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${audioData?.audioName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    const audioBodyTemplate = () => {
        return hasDialerCreatePermission ? <>{(
            <div className="field col-12 md:col-12 flex align-items-center mb-0 px-0">
                <Button icon="pi pi-caret-right" onClick={() => {
                    // handlePlay();
                    setVisible(true);
                }} className="bg-primary" label="Прослухати"/>
            </div>)}
            <Dialog header={audioHeaderBody} visible={visible} style={{width: '25vw'}} className="pb-0 audio-dialog" onHide={() => setVisible(false)}>
                {audioData?.audioTempUrl && <div className="field col-12 md:col-12 save-mp3 relative absolute mt-2">
                    {hasDialerUpdatePermission && <Button
                        icon="pi pi-download"
                        onClick={onFileUploadByLink}
                        className={`absolute outline-none border-none shadow-none border-circle save-mp3 ml-4 text-lg ${audioPlayerRef ? 'opacity-100' : 'opacity-50'}`}
                    />}
                    <AudioPlayerComponent
                        key={playerKey}
                        ref={audioPlayerRef}
                        className="audio-player border-round-sm border-gray-600 mt-3"
                        autoPlay={false}
                        onCanPlay={handleAudioReady}
                        src={audioData?.audioTempUrl || null}
                        style={{border: '1px solid red', backgroundColor: 'rgba(255, 255, 255, 0.05)'}}
                    />
                </div>}
            </Dialog>
        </> : ''
    }

    const fields = [
        {label: "ID", value: audioData?.audioId},
        {label: "Стан", value: audioData?.isActive ? 'Активна' : 'Не активна'},
        {label: "Назва аудіо:", value: audioData?.audioFileName || '- - -'},
        {label: "Опис", value: audioData?.description || '- - -'},
    ];

    const openEditor = () => {
        dispatch(setSelectedAudioIndex(audioData?.audioId || props.selectedRowData));
        dispatch(setAudioDialogVisible(true));
        dispatch(setAudioErrorField(null));
        dispatch(getAudioById(props.selectedRowData, jwtToken?.jwtToken));
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                        <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">ID: {audioData?.audioId}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                {(audioData?.isHold || audioData?.isRingback) && <p className="w-full">По замовчуванню для:</p>}
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full">{audioData?.audioName}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                <div className="flex flex-column">
                                    {audioData?.isHold && <p className="mb-0">HOLD</p>}
                                    {audioData?.isRingback && <p>RINGBACK</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-5 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                {/*<p className="w-full">Приоритет: {audio?.priority || '0'}</p>*/}
                            </div>
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{audioData?.isActive ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                    openEditor();
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                                    'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => {
                                    props.setSelectedRowData(null);
                                    dispatch(setAudioField({section: 'audioData', fieldName: 'audioTempUrl', value: null}));
                                }}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            <p className="w-full">{audioData?.audioName}</p>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && <>
                        {fields.map((field: any) => (
                            <React.Fragment key={field.label}>
                                <div className="field col-4 md:col-6 flex align-items-start m-0">
                                    <p>{field.label}</p>
                                </div>
                                <div className="field col-8 md:col-6 flex align-items-start m-0">
                                    <p>{field.value}</p>
                                </div>
                            </React.Fragment>
                        ))}
                        {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                            {audioData?.audioTempUrl && <div className="field col-12 md:col-12 save-mp3 relative absolute mt-2 px-0">
                                {hasDialerUpdatePermission && <Button
                                    icon="pi pi-download"
                                    onClick={onFileUploadByLink}
                                    className={`absolute outline-none border-none shadow-none border-circle save-mp3 ml-4 text-lg ${audioPlayerRef ? 'opacity-100' : 'opacity-50'}`}
                                />}
                                {audioData?.audioTempUrl &&<AudioPlayerComponent
                                    key={playerKey}
                                    ref={audioPlayerRef}
                                    className="audio-player border-round-sm border-gray-600 mt-3"
                                    autoPlay={false}
                                    src={audioData?.audioTempUrl || null}
                                    style={{border: '1px solid red', backgroundColor: 'rgba(255, 255, 255, 0.05)'}}
                                />}
                            </div>}
                        </div>}
                    </>}
                    {windowWidth > 1248 &&
                        <React.Fragment>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex align-items-center m-0">

                                <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                    <p className="w-full">Назва аудіо:</p>
                                </div>
                                <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                    <p className="w-full">Опис</p>
                                </div>
                                {hasDialerVisiblePermission && <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                    <p className="w-full">Аудіо</p>
                                </div>}
                            </div>
                            <Divider className="my-0"/>
                            <div className="field col-12 md:col-12 flex m-0 scroll-detail-items">
                                <div className="field col-12 md:col-4 flex m-0 p-0">
                                    {audioData?.audioFileName || '- - -'}
                                </div>
                                <div className="field col-12 md:col-4 flex m-0 p-0">
                                    {audioData?.description || '- - -'}
                                </div>
                                {hasDialerVisiblePermission && <div className="field col-12 md:col-4 flex m-0 p-0 flex-wrap flex-column">
                                    {audioBodyTemplate()}
                                </div>}
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default AudioListDetails;
