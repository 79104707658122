import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {InputText} from "primereact/inputtext";
import {setStatusField, setStatusTime} from "../../../../redux/actions/actionsStatuses";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {classNames} from "primereact/utils";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

const StatusParams = () => {
    const dispatch = useAppDispatch();
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;
    const statusesDictionary = useAppSelector(state => state.StatusesListReducer.statusesDictionary);
    const errorFields = useAppSelector(state => state.StatusesListReducer.errorFields) as unknown as any;
    const [timeInSeconds, setTimeInSeconds] = useState<any>(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const rolePermissions = checkPermissions(rolesVision, 'Status');
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveStatusTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);
    const {
        // create: hasRoleCreatePermission,
        // update: hasRoleUpdatePermission
    } = rolePermissions;
    const [timeInHoursMinutesSeconds, setTimeInHoursMinutesSeconds] = useState({
        hours: '00',
        minutes: '00',
        remainingSecondsFinal: '00'
    });

    let inSystem = statusData?.inSystem;

    const convertToTimeInSeconds = (time: any) => {
        const { hours, minutes, remainingSecondsFinal } = time;
        return (parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(remainingSecondsFinal));
    };

    const convertToHoursMinutesSeconds = (seconds: any) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const remainingSecondsFinal = remainingSeconds % 60;

        return {
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            remainingSecondsFinal: remainingSecondsFinal.toString().padStart(2, '0')
        };
    };

    useEffect(() => {
        if (statusData?.timeStatus !== undefined) {
            const timeInSeconds = statusData.timeStatus;
            setTimeInSeconds(timeInSeconds);

            const timeInHoursMinutesSeconds = convertToHoursMinutesSeconds(timeInSeconds);
            setTimeInHoursMinutesSeconds(timeInHoursMinutesSeconds);
        }
    }, [statusData?.timeStatus]);


    useEffect(() => {
        if (timeInSeconds) {
            dispatch(setStatusTime(timeInSeconds));
        }
    }, [timeInSeconds]);

    return (
        <div className="grid p-fluid">
            <div className="field col-12 md:col-12">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Час статуса:</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="field col-12 md:col-1">
                <label>Годин</label>
                <InputText
                    id="name"
                    keyfilter="num"
                    maxLength={2}
                    disabled={!archiveStatusTableToggle || !editType || inSystem}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            setTimeInHoursMinutesSeconds((prevState) => ({
                                ...prevState,
                                hours: '00'
                            }));
                            const newTimeInSeconds = convertToTimeInSeconds({
                                ...timeInHoursMinutesSeconds,
                                hours: '00'
                            });
                            setTimeInSeconds(newTimeInSeconds);
                        }
                    }}
                    value={timeInHoursMinutesSeconds.hours || ''}
                    className={classNames('', {'p-invalid': errorFields?.timeStatus})}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setTimeInHoursMinutesSeconds((prevState) => ({
                            ...prevState,
                            hours: newValue
                        }));
                        const newTimeInSeconds = convertToTimeInSeconds({
                            ...timeInHoursMinutesSeconds,
                            hours: newValue
                        });
                        setTimeInSeconds(newTimeInSeconds);
                    }}
                />
            </div>
            <div className="field col-12 md:col-1">
                <label>Хвилин</label>
                <InputText
                    id="name"
                    keyfilter="num"
                    maxLength={2}
                    className={classNames('', {'p-invalid': errorFields?.timeStatus})}
                    disabled={!archiveStatusTableToggle || !editType || inSystem}
                    value={timeInHoursMinutesSeconds.minutes}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            setTimeInHoursMinutesSeconds((prevState) => ({
                                ...prevState,
                                minutes: '00'
                            }));
                            const newTimeInSeconds = convertToTimeInSeconds({
                                ...timeInHoursMinutesSeconds,
                                minutes: '00'
                            });
                            setTimeInSeconds(newTimeInSeconds);
                        }
                    }}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setTimeInHoursMinutesSeconds((prevState) => ({
                            ...prevState,
                            minutes: newValue
                        }));
                        const newTimeInSeconds = convertToTimeInSeconds({
                            ...timeInHoursMinutesSeconds,
                            minutes: newValue
                        });
                        setTimeInSeconds(newTimeInSeconds);
                    }}
                />
            </div>
            <div className="field col-12 md:col-1">
                <label>Секунд</label>
                <InputText
                    id="name"
                    value={timeInHoursMinutesSeconds.remainingSecondsFinal}
                    keyfilter="num"
                    maxLength={2}
                    disabled={!archiveStatusTableToggle || !editType || inSystem}
                    className={classNames('', {'p-invalid': errorFields?.timeStatus})}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            setTimeInHoursMinutesSeconds((prevState) => ({
                                ...prevState,
                                remainingSecondsFinal: '00'
                            }));
                            const newTimeInSeconds = convertToTimeInSeconds({
                                ...timeInHoursMinutesSeconds,
                                remainingSecondsFinal: '00'
                            });
                            setTimeInSeconds(newTimeInSeconds);
                        }
                    }}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setTimeInHoursMinutesSeconds((prevState) => ({
                            ...prevState,
                            remainingSecondsFinal: newValue
                        }));
                        const newTimeInSeconds = convertToTimeInSeconds({
                            ...timeInHoursMinutesSeconds,
                            remainingSecondsFinal: newValue
                        });
                        setTimeInSeconds(newTimeInSeconds);
                    }}
                />
            </div>
            <div className="field col-12 md:col-12">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Тип статуса:</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <div className="field-radiobutton my-4 pl-2">
                        <Dropdown value={statusData?.typeId} onChange={(e) => {
                            dispatch(setStatusField({section: 'statusData', fieldName: 'typeId', value: e.target.value}));
                            if (e.target.value === 301) {
                                dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundExternal', value: false}));
                                dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundExternal', value: false}));
                                dispatch(setStatusField({section: 'statusData', fieldName: 'isCallAuto', value: false}))
                            }
                        }} options={statusesDictionary} optionLabel="description"
                                  placeholder="Виберіть тип:"
                                  disabled={!archiveStatusTableToggle || !editType || inSystem}
                                  optionValue="dictionaryId" className="w-full md:w-14rem"/>
                    </div>
                </div>
            </div>
            <div className="field col-12 md:col-12 mb-0">
                <div>
                    <label htmlFor="name" className="font-bold text-base">Дозволи статуса:</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            </div>
            <div className="field col-12 md:col-3 flex-column mb-0">
                <label htmlFor="name" className="mb-2 font-bold">Дзвiнки:</label>
                <div className="flex align-items-center mb-2">
                    <Checkbox
                        name="category"
                        disabled={!archiveStatusTableToggle || statusData?.typeId !== 302 || !editType || inSystem}
                        checked={statusData?.callInboundExternal}
                        onChange={() => {
                            dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundExternal', value: !statusData?.callInboundExternal}));
                            if (!!statusData?.callInboundExternal) {
                                dispatch(setStatusField({section: 'statusData', fieldName: 'isCallAuto', value: false}));
                            }
                        }}
                    />
                    <label htmlFor='Вхiднi зовн.' className="ml-2">
                        Вхiднi зовнiшнi
                    </label>
                </div>
                <div className="flex align-items-center mb-2">
                    <Checkbox
                        name="callOutboundInternal"
                        checked={statusData?.callOutboundExternal}
                        onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundExternal', value: !statusData?.callOutboundExternal}))}
                        disabled={!archiveStatusTableToggle || statusData?.typeId !== 302 || !editType || inSystem}
                    />
                    <label htmlFor='Вихiднi зовн.' className="ml-2">
                        Вихiднi зовнiшнi
                    </label>
                </div>
                <div className="flex align-items-center mb-2">
                    <Checkbox
                        name="category"
                        checked={statusData?.callInboundInternal}
                        onChange={() => {
                            dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundInternal', value: !statusData?.callInboundInternal}));
                        }}
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                    <label htmlFor='Вхiднi внутр' className="ml-2">
                        Вхiднi внутрішні
                    </label>
                </div>
                <div className="flex align-items-center mb-2">
                    <Checkbox
                        name="callOutboundInternal"
                        checked={statusData?.callOutboundInternal}
                        onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundInternal', value: !statusData?.callOutboundInternal}))}
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                    <label htmlFor='Вихiднi внутр.' className="ml-2">
                        Вихiднi внутрішні
                    </label>
                </div>
                <div className="flex align-items-center mb-2">
                    <Checkbox
                        name="callDialer"
                        checked={statusData?.callDialer}
                        onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callDialer', value: !statusData?.callDialer}))}
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                    <label htmlFor='Вихiднi внутр.' className="ml-2">
                        Дайлер
                    </label>
                </div>
            </div>
            {/*<div className="field col-12 md:col-3 flex-column mb-0">*/}
            {/*    <label htmlFor="name" className="mb-2"></label>*/}
            {/*    <div className="flex align-items-center mt-2">*/}
            {/*        <Checkbox disabled={!statusData?.callInboundExternal || !archiveStatusTableToggle || statusData?.typeId !== 302 || !editType || inSystem}*/}
            {/*                  onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'isCallAuto', value: !statusData?.isCallAuto}))}*/}
            {/*                  checked={statusData?.isCallAuto} />*/}
            {/*        <label htmlFor="ingredient1" className="ml-2">Автопризначення</label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="field col-12 md:col-3 flex-column mb-0">*/}
            {/*    <label htmlFor="name" className="mb-2 font-bold">Чати:</label>*/}
            {/*    <div className="flex align-items-center mb-2">*/}
            {/*        <Checkbox*/}
            {/*            name="category"*/}
            {/*            onChange={() => {*/}
            {/*                dispatch(setStatusField({section: 'statusData', fieldName: 'chatInboundInternal', value: !statusData?.chatInboundInternal}));*/}
            {/*                if (!!statusData?.callInboundInternal) {*/}
            {/*                    dispatch(setStatusField({section: 'statusData', fieldName: 'isChatAuto', value: false}));*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            checked={statusData?.chatInboundInternal}*/}
            {/*            disabled={false}*/}
            {/*        />*/}
            {/*        <label htmlFor='Вхiднi внутр' className="ml-2">*/}
            {/*            Вхідні внутрішні*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="flex align-items-center mb-2">*/}
            {/*        <Checkbox*/}
            {/*            name="category"*/}
            {/*            checked={statusData?.chatInboundExternal}*/}
            {/*            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'chatInboundExternal', value: !statusData?.chatInboundExternal}))}*/}
            {/*            disabled={false}*/}
            {/*        />*/}
            {/*        <label htmlFor='Вхiднi зовн.' className="ml-2">*/}
            {/*            Вхiднi зовн.*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="flex align-items-center mb-2">*/}
            {/*        <Checkbox*/}
            {/*            name="callOutboundInternal"*/}
            {/*            checked={statusData?.chatOutboundInternal}*/}
            {/*            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'chatOutboundInternal', value: !statusData?.chatOutboundInternal}))}*/}
            {/*            disabled={false}*/}
            {/*        />*/}
            {/*        <label htmlFor='Вихiднi внутр.' className="ml-2">*/}
            {/*            Вихiднi внутр.*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="flex align-items-center mb-2">*/}
            {/*        <Checkbox*/}
            {/*            name="callOutboundInternal"*/}
            {/*            checked={statusData?.chatOutboundExternal}*/}
            {/*            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'chatOutboundExternal', value: !statusData?.chatOutboundExternal}))}*/}
            {/*            disabled={false}*/}
            {/*        />*/}
            {/*        <label htmlFor='Вихiднi зовн.' className="ml-2">*/}
            {/*            Вихiднi зовн.*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="field col-12 md:col-3 flex-column mb-0">*/}
            {/*    <label htmlFor="name" className="mb-2"></label>*/}
            {/*    <div className="flex align-items-center mt-2">*/}
            {/*        <Checkbox disabled={!statusData?.chatInboundInternal}*/}
            {/*                  onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'isChatAuto', value: !statusData?.isChatAuto}))}*/}
            {/*                  checked={statusData?.isChatAuto} />*/}
            {/*        <label htmlFor="ingredient1" className="ml-2">Автопризначення</label>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    );
};

export default StatusParams;
