import {RadioButton} from "primereact/radiobutton";
import {Calendar} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {setChangedScheduler, setCompanyField} from "redux/actions/actions";
import {Dropdown} from "primereact/dropdown";
import ScheduleTitle from "../ScheduleComponents/ScheduleTitle";
import TimeFromTo from "../ScheduleComponents/TimeFromTo";
import LaunchDate from "../ScheduleComponents/LaunchDate";
import {InputSwitch} from "primereact/inputswitch";
import {Checkbox} from "primereact/checkbox";
import {MultiSelect} from "primereact/multiselect";
import moment from "moment";
import {classNames} from "primereact/utils";
import {MVP} from "../../../redux/config";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getCampaignDirectoriesSchedulerType} from "../../../redux/api/api";

const QueueListDialogStartEndFinish = (props: any) => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle);
    // @ts-ignore
    const schedulerType = useAppSelector(state => state.CompaniesListReducer.companiesDirectorySchedulerType);
    const [individualSwitchValue, setIndividualSwitchValue] = useState<boolean>(false);
    const [selectedDaysWeek, setSelectedDaysWeek] = useState<any>([]);
    const [selectedIndividualDaysWeek, setSelectedIndividualDaysWeek] = useState<any>([]);
    const [genericMonthlyCheckboxValue, setGenericMonthlyCheckboxValue] = useState<any>([]);
    const [IndividualMonthlyCheckboxValue, setIndividualMonthlyCheckboxValue] = useState<any>([]);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    const [selectedSchedulerType, setSelectedSchedulerType] = useState( {
        "dictionaryId": 401,
        "parentId": 400,
        "name": "Once",
        "description": "Кампанія запускається один раз у вказану дату.",
        "isActive": true,
        "orderBy": 1
    });
    const [dates, setDates] = useState<any>(null);
    const [value1, setValue1] = useState<any>(null);
    const [value2, setValue2] = useState<any>(null);
    const [value3, setValue3] = useState<any>(null);
    const [value4, setValue4] = useState<any>(null);
    const [value5, setValue5] = useState<any>(null);
    const [value6, setValue6] = useState<any>(null);
    const [value7, setValue7] = useState<any>(null);
    const statusDone = companiesData?.campaign?.statusId === 6;
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        // create: hasDialerCreatePermission,
        // update: hasDialerUpdatePermission,
    } = dialerPermissions;
    moment.suppressDeprecationWarnings = true;

    useEffect(() => {
        dispatch(getCampaignDirectoriesSchedulerType(jwtToken?.jwtToken));
    }, [])

    const isValidDate = (value: any) => {
        const format = 'MM/DD/YYYY';
        return moment(value, format, true).isValid();
    };

    const handleDateChange = (date: any) => {
        return moment(date).format('MM/DD/YYYY HH:mm:ss');
    };

    const transformToDate = (dateString: any) => {
        return new Date(moment(dateString).format('MM/DD/YYYY HH:mm:ss'));
    }

    let startDateValue = new Date();
    startDateValue.setHours(0, 0, 0);

    let endDateValue = new Date();
    endDateValue.setHours(0, 0, 0);

    let startTimeValue = new Date();
    startTimeValue.setHours(0, 0, 0);

    let endTimeValue = new Date();
    endTimeValue.setHours(0, 0, 0);

    const [startTime] = useState<any>(startTimeValue);
    const [endTime] = useState<any>(endTimeValue);
    const [startDate] = useState<any>(startDateValue);
    const [endDate] = useState<any>(endDateValue);

    const scheduler = {
        schedulerId: null,
        startTime: (companiesData.scheduler && companiesData.scheduler.startTime) ? companiesData.scheduler.startTime : handleDateChange(startTime),
        endTime: (companiesData.scheduler && companiesData.scheduler.endTime) ? companiesData.scheduler.endTime : handleDateChange(endTime),
        startDate: (companiesData.scheduler && companiesData.scheduler.startDate) ? companiesData.scheduler.startDate : handleDateChange(startDate),
        endDate: (companiesData.scheduler && companiesData.scheduler.endDate) ? companiesData.scheduler.endDate : handleDateChange(endDate),
        typeScheduler: selectedSchedulerType.dictionaryId,
        typeEventStart: true,
        typeEventEnd: true,
        typeValue: 1,
        daysNumberOfWeek: null,
        monthNumber: null,
        delay: 0,
        lastDayOfMonth: null,
        dayNumber: null,
        orderWeekday: null
    }

    const changeTypeScheduler = (e: any) => {
        dispatch(setCompanyField({section: 'scheduler', fieldName: 'typeScheduler', value: e.value.type}));
        setSelectedSchedulerType(e.value);
    };

    schedulerType.map((item: any) => {
        if (companiesData?.scheduler?.typeScheduler !== selectedSchedulerType.dictionaryId && item?.dictionaryId === companiesData?.scheduler?.typeScheduler) {
            setSelectedSchedulerType(item);
        }
    });

    const onWeekdayCheckboxChange = (e: any, type: any) => {
        let selectedValue: any[];
        switch (type) {
            case 1:
                selectedValue = [...selectedDaysWeek];
                if (e.checked) selectedValue.push(e.value);
                else selectedValue.splice(selectedValue.indexOf(e.value), 1);
                setSelectedDaysWeek(selectedValue);
                break;
            case 2:
                selectedValue = [...selectedIndividualDaysWeek];
                if (e.checked) selectedValue.push(e.value);
                else selectedValue.splice(selectedValue.indexOf(e.value), 1);
                setSelectedIndividualDaysWeek(selectedValue);
                break;
            case 3:
                selectedValue = [...IndividualMonthlyCheckboxValue];
                if (e.checked) selectedValue.push(e.value);
                else selectedValue.splice(selectedValue.indexOf(e.value), 1);
                setIndividualMonthlyCheckboxValue(selectedValue);
                break;
            case 4:
                selectedValue = [...genericMonthlyCheckboxValue];
                if (e.checked) selectedValue.push(e.value);
                else selectedValue.splice(selectedValue.indexOf(e.value), 1);
                setGenericMonthlyCheckboxValue(selectedValue);
                break;
        }
    };

    const onMonthlyWeekdayCheckboxChange = (e: any, type: any) => {
        switch (type) {
            case 1:
                setValue1(e.value);
                break;
            case 2:
                setValue2(e.value);
                break;
            case 3:
                setValue3(e.value);
                break;
            case 4:
                setValue4(e.value);
                break;
            case 5:
                setValue5(e.value);
                break;
            case 6:
                setValue6(e.value);
                break;
            case 7:
                setValue7(e.value);
                break;
        }
    };

    const setHoursDate = () => {
        let newDate = new Date();
        newDate.setHours(0, 0, 0);
        return newDate
    }

    const weekdayTime = {
        startDateTimeMonday: setHoursDate(),
        endDateTimeMonday: setHoursDate(),
        startDateTimeTuesday: setHoursDate(),
        endDateTimeTuesday: setHoursDate(),
        startDateTimeWednesday: setHoursDate(),
        endDateTimeWednesday: setHoursDate(),
        startDateTimeThursday: setHoursDate(),
        endDateTimeThursday: setHoursDate(),
        startDateTimeFriday: setHoursDate(),
        endDateTimeFriday: setHoursDate(),
        startDateTimeSaturday: setHoursDate(),
        endDateTimeSaturday: setHoursDate(),
        startDateTimeSunday: setHoursDate(),
        endDateTimeSunday: setHoursDate()
    }

    const daysWeek = [
        {name: '1'},
        {name: '2'},
        {name: '3'},
        {name: '4'},
    ];
    const weekdayField = [
        {value: 'Monday', label: 'Понеділок', startDateTimeValue: 'startDateTimeMonday', endDateTimeValue: 'endDateTimeMonday', days: value1, type: 1},
        {value: 'Tuesday', label: 'Вівторок', startDateTimeValue: 'startDateTimeTuesday', endDateTimeValue: 'endDateTimeTuesday', days: value2, type: 2},
        {value: 'Wednesday', label: 'Середа', startDateTimeValue: 'startDateTimeWednesday', endDateTimeValue: 'endDateTimeWednesday', days: value3, type: 3},
        {value: 'Thursday', label: 'Четвер', startDateTimeValue: 'startDateTimeThursday', endDateTimeValue: 'endDateTimeThursday', days: value4, type: 4},
        {value: 'Friday', label: 'П\'ятниця', startDateTimeValue: 'startDateTimeFriday', endDateTimeValue: 'endDateTimeFriday', days: value5, type: 5},
        {value: 'Saturday', label: 'Субота', startDateTimeValue: 'startDateTimeSaturday', endDateTimeValue: 'endDateTimeSaturday', days: value6, type: 6},
        {value: 'Sunday', label: 'Неділя', startDateTimeValue: 'startDateTimeSunday', endDateTimeValue: 'endDateTimeSunday', days: value7, type: 7}
    ];

    useEffect(() => {
        // if (!companiesData.campaign.campaignId) {
        //     Object.entries(scheduler).map(([key, value]) => dispatch(setCompanyField({section: 'scheduler', fieldName: key, value: value})));
        //     Object.entries(weekdayTime).map(([key, value]) => dispatch(setCompanyField({section: 'weekdayTime', fieldName: key, value: value})));
        //     Object.entries(genericTime).map(([key, value]) => dispatch(setCompanyField({section: 'genericTime', fieldName: key, value: value})));
        // }
        if (!companiesData.scheduler && props.createdSchedule) {
            Object.entries(scheduler).map(([key, value]) => dispatch(setCompanyField({section: 'scheduler', fieldName: key, value: value})));
        }
        if (companiesData.campaign.campaignId) {
            Object.entries(weekdayTime).map(([key, value]) => dispatch(setCompanyField({section: 'weekdayTime', fieldName: key, value: value})));
        }
    }, []);

    return (
        <div className="start-end-finish">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-12">
                    <h6>Умови запуску</h6>

                    <div className="grid p-fluid">
                        {companiesData?.scheduler?.typeEventStart && <>
                            <div className="col-12 md:col-6">
                                <Dropdown className="field col-12 md:col-12" value={selectedSchedulerType} onChange={(e) => {
                                    dispatch(setChangedScheduler(true));
                                    changeTypeScheduler(e)
                                }} options={schedulerType}
                                          disabled={!archiveTableToggle || statusDone || !editType}
                                          optionLabel="name"
                                          placeholder="Виберіть тип:"/>
                            </div>
                            <div className="col-12 md:col-12">
                                {scheduler.typeScheduler === 401 && <>
                                    <div className="col-12 md:col-6">
                                        <ScheduleTitle/>
                                        <TimeFromTo archiveTableToggle={archiveTableToggle} startTime={scheduler.startTime} endTime={scheduler.endTime}/>
                                        <LaunchDate archiveTableToggle={archiveTableToggle} startDate={scheduler.startDate} companiesData={companiesData}/>
                                    </div>
                                </>}

                                {companiesData?.scheduler?.typeScheduler === 402 && <>
                                    <div className="col-12 md:col-6">
                                        <ScheduleTitle/>
                                        <TimeFromTo archiveTableToggle={archiveTableToggle} startTime={scheduler.startTime} endTime={scheduler.endTime}/></div>
                                </>}

                                {companiesData?.scheduler?.typeScheduler === 403 && <>
                                    <div className="field col-12 md:col-12">
                                        <ScheduleTitle/>
                                        <div className="field-radiobutton pl-3">
                                            <label htmlFor="icon" className="mr-2">Загальний</label>
                                            <InputSwitch checked={individualSwitchValue} onChange={() => setIndividualSwitchValue((prevState) => !prevState)}/>
                                            <label htmlFor="icon">Індивідуальний</label>
                                        </div>
                                        {!individualSwitchValue && <div className="field col-12 md:col-6 pl-4">
                                            <TimeFromTo archiveTableToggle={archiveTableToggle} startTime={scheduler.startTime} endTime={scheduler.endTime}/> {weekdayField.map(({value, label}, index) => {
                                            return <div className="col-12 md:col-12" key={index}>
                                                <div className="field-checkbox mb-0">
                                                    <Checkbox inputId="checkOption3" name="option" value={value} checked={selectedDaysWeek.indexOf(value) !== -1} onChange={(e) => onWeekdayCheckboxChange(e, 1)}/>
                                                    <label htmlFor="checkOption3">{label}</label>
                                                </div>
                                            </div>
                                        })}
                                        </div>}

                                        {individualSwitchValue && <div className="field col-12 md:col-6">
                                            {weekdayField.map(({value, label, startDateTimeValue, endDateTimeValue}, index) => {
                                                return <div className="grid pl-3" key={index}>
                                                    <div className="field-checkbox col-12 md:col-4 mb-0">
                                                        <Checkbox inputId="checkOption3" name="option" value={value} checked={selectedIndividualDaysWeek.indexOf(value) !== -1} onChange={(e) => onWeekdayCheckboxChange(e, 2)}/>
                                                        <label htmlFor="checkOption3">{label}</label>
                                                    </div>
                                                    <div className="field col-12 md:col-4 mb-0">
                                                        <div className="time-item">
                                                            <span className="title">З</span>
                                                            <Calendar
                                                                appendTo="self"
                                                                id="icon"
                                                                value={companiesData?.weekdayTime ? companiesData?.weekdayTime?.[startDateTimeValue] : ''}
                                                                onChange={e => {
                                                                    dispatch(setChangedScheduler(true));
                                                                    dispatch(setCompanyField({section: 'weekdayTime', fieldName: `startDateTime${value}`, value: e.target.value}))
                                                                }}
                                                                timeOnly
                                                                hourFormat="24"
                                                                disabled={selectedIndividualDaysWeek.indexOf(value) === -1 || statusDone || !editType}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field col-12 md:col-4 mb-0">
                                                        <div className="time-item">
                                                            <span className="title pr-1">По</span>
                                                            <Calendar
                                                                appendTo="self"
                                                                id="icon"
                                                                value={companiesData?.weekdayTime ? companiesData?.weekdayTime?.[endDateTimeValue] : ''}
                                                                onChange={e => {
                                                                    dispatch(setChangedScheduler(true));
                                                                    dispatch(setCompanyField({section: 'weekdayTime', fieldName: `endDateTime${value}`, value: e.target.value}))
                                                                }}
                                                                timeOnly
                                                                hourFormat="24"
                                                                disabled={selectedIndividualDaysWeek.indexOf(value) === -1 || statusDone || !editType}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>}
                                    </div>
                                </>}

                                {companiesData?.scheduler?.typeScheduler === 404 && <div className="field col-12 md:col-6 m-0">
                                    <div className="field m-0">
                                        <label htmlFor="icon">Вибрані дні:</label>
                                    </div>
                                    <TimeFromTo archiveTableToggle={archiveTableToggle} startTime={scheduler.startTime} endTime={scheduler.endTime}/>
                                    <Calendar placeholder={'Виберіть дні'} className="pt-2" showIcon value={dates} onChange={(e) => setDates(e.value)} selectionMode="multiple" readOnlyInput/>
                                </div>}

                                {companiesData?.scheduler?.typeScheduler === 405 && <>
                                    <div className="field col-12 md:col-12">
                                        <ScheduleTitle/>
                                        <div className="field-radiobutton pl-3">
                                            <label htmlFor="icon" className="mr-2">Загальний</label>
                                            <InputSwitch checked={individualSwitchValue} onChange={() => setIndividualSwitchValue((prevState) => !prevState)}/>
                                            <label htmlFor="icon">Індивідуальний</label>
                                        </div>
                                        {!individualSwitchValue && <div className="field col-12 md:col-6 pl-4">
                                            <TimeFromTo archiveTableToggle={archiveTableToggle} startDateTime={scheduler.startTime} endDateTime={scheduler.endTime}/> {weekdayField.map(({value, label, days, type}, index) => {
                                            return <div className="col-12 md:col-6 p-0 pr-0" key={index}>
                                                <div className="field-checkbox mb-0">
                                                    <div className="field-checkbox mb-0 col-12 md:col-6">
                                                        <Checkbox inputId="checkOption3" name="option" value={value} checked={genericMonthlyCheckboxValue.indexOf(value) !== -1} onChange={(e) => onWeekdayCheckboxChange(e, 4)}/>
                                                        <label htmlFor="checkOption3">{label}</label>
                                                    </div>
                                                    <div className="col-12 md:col-6 pl-0">
                                                        <MultiSelect disabled={genericMonthlyCheckboxValue.indexOf(value) === -1 || statusDone || !editType} id="multiselect" options={daysWeek} value={days} onChange={(e) => onMonthlyWeekdayCheckboxChange(e, type)} optionLabel="name"></MultiSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        </div>
                                        }
                                        {individualSwitchValue && <>
                                            {weekdayField.map(({value, label, startDateTimeValue, endDateTimeValue, days, type}, index) => {
                                                return <div className="grid pl-4" key={index}>
                                                    <div className="field-checkbox col-12 md:col-2  mb-0">
                                                        <Checkbox inputId="checkOption3" name="option" value={value} checked={IndividualMonthlyCheckboxValue.indexOf(value) !== -1} onChange={(e) => onWeekdayCheckboxChange(e, 3)}/>
                                                        <label htmlFor="checkOption3">{label}</label>
                                                    </div>
                                                    <div className="col-12 md:col-2">
                                                        <MultiSelect disabled={IndividualMonthlyCheckboxValue.indexOf(value) === -1 || statusDone || !editType} id="multiselect" options={daysWeek} value={days} onChange={(e) => onMonthlyWeekdayCheckboxChange(e, type)} optionLabel="name"></MultiSelect>
                                                    </div>
                                                    <div className="field col-12 md:col-2 mb-0">
                                                        <div className="time-item">
                                                            <span className="title">З</span>
                                                            <Calendar
                                                                appendTo="self"
                                                                id="icon"
                                                                value={companiesData?.weekdayTime ? companiesData?.weekdayTime?.[startDateTimeValue] : ''}
                                                                onChange={e => {
                                                                    dispatch(setChangedScheduler(true));
                                                                    dispatch(setCompanyField({section: 'weekdayTime', fieldName: `startDateTime${value}`, value: e.target.value}))
                                                                }}
                                                                timeOnly
                                                                hourFormat="24"
                                                                disabled={IndividualMonthlyCheckboxValue.indexOf(value) === -1 || statusDone || !editType}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="field col-12 md:col-2 mb-0">
                                                        <div className="time-item">
                                                            <span className="title pr-1">По</span>
                                                            <Calendar
                                                                appendTo="self"
                                                                id="icon"
                                                                value={companiesData?.weekdayTime ? companiesData?.weekdayTime?.[endDateTimeValue] : new Date()}
                                                                onChange={e => {
                                                                    dispatch(setChangedScheduler(true));
                                                                    dispatch(setCompanyField({section: 'weekdayTime', fieldName: `endDateTime${value}`, value: e.target.value}))
                                                                }}
                                                                timeOnly
                                                                hourFormat="24"
                                                                disabled={IndividualMonthlyCheckboxValue.indexOf(value) === -1 || statusDone || !editType}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </>}
                                    </div>
                                </>}
                            </div>
                        </>}
                        <div className="field col-12 md:col-12 mt-3">
                            <h6>Умови завершення</h6>
                            <div className="grid p-fluid">
                                {!MVP && <div className="field col-12 md:col-4">
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="finishingСonditions1"
                                            name="finishingСonditions"
                                            value={true}
                                            onChange={(e) => {
                                                dispatch(setChangedScheduler(true));
                                                dispatch(setCompanyField({section: 'scheduler', fieldName: 'typeEventEnd', value: e.value}))
                                            }}
                                            checked={companiesData?.scheduler?.typeEventEnd}
                                            disabled={!archiveTableToggle || statusDone}
                                        />
                                        <label htmlFor="finishingСonditions1">За розкладом</label>
                                    </div>
                                </div>}
                                {!MVP && <div className="field col-12 md:col-4">
                                    <div className="field-radiobutton">
                                        <RadioButton
                                            inputId="launchFinishing2"
                                            name="launchFinishing"
                                            value={false}
                                            onChange={(e) => {
                                                dispatch(setChangedScheduler(true));
                                                dispatch(setCompanyField({section: 'scheduler', fieldName: 'typeEventEnd', value: e.value}))
                                            }}
                                            checked={!companiesData?.scheduler?.typeEventEnd}
                                            disabled={!archiveTableToggle || statusDone || MVP}
                                        />
                                        <label htmlFor="launchConditions2" className={classNames('', {'text-500': MVP})}>За подією</label>
                                    </div>
                                </div>}

                                {companiesData?.scheduler?.typeEventEnd && <>
                                    <div className="field col-12 md:col-6">
                                        <label htmlFor="icon">Дата завершення</label>
                                        <Calendar
                                            appendTo="self"
                                            id="icon"
                                            className={classNames('', {'p-invalid': errorFields?.endDate})}
                                            dateFormat="dd-mm-yy"
                                            minDate={new Date()}
                                            value={companiesData?.scheduler.endDate ? transformToDate(companiesData?.scheduler?.endDate) : transformToDate(scheduler.endDate)}
                                            onChange={e => {
                                                if (isValidDate(e.target.value)) {
                                                    dispatch(setChangedScheduler(true));
                                                    dispatch(setCompanyField({section: 'scheduler', fieldName: 'endDate', value: handleDateChange(e.target.value)}))
                                                }
                                            }}
                                            showIcon
                                            disabled={!archiveTableToggle || statusDone || !editType}
                                        />
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueueListDialogStartEndFinish
