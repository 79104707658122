import {HIDE_ERROR} from "../types/types";
import {
    GET_STATUS_STATISTIC,
    SET_CURRENT_STATUS_STATISTICS_PAGE,
    SET_CURRENT_STATUS_STATISTICS_SIZE,
    SET_FIRST_PAGE,
    SET_STATUS_STATISTICS_FILTER, SET_STATUS_STATISTICS_LOADING,
    SHOW_EXPORT_STATUS_STATISTICS_LOADING
} from "../types/typesStatusStatisticsList";


export function setStatusStatisticsCurrentPage(state: any) {
    return {
        type: SET_CURRENT_STATUS_STATISTICS_PAGE,
        payload: state
    }
}

export function setStatusStatisticsCurrentPageSize(state: any) {
    return {
        type: SET_CURRENT_STATUS_STATISTICS_SIZE,
        payload: state
    }
}

export function setPageForFirst(state: any) {
    return {
        type: SET_FIRST_PAGE,
        payload: state
    }
}

export function showStatusStatisticsExportLoader(state: boolean) {
    return {
        type: SHOW_EXPORT_STATUS_STATISTICS_LOADING,
        payload: state
    }
}


export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function setStatusStatisticsFilter(payload: any) {
    return {
        type: SET_STATUS_STATISTICS_FILTER,
        payload: payload,
    };
}

export function showStatusStatisticsLoader(payload: any) {
    return {
        type: SET_STATUS_STATISTICS_LOADING,
        payload: payload,
    };
}

// export function updateStatusStatisticsSortFields(updatedFields: any) {
//     return {
//         type: UPDATE_STATISTIC_SORT_FIELDS,
//         payload: updatedFields,
//     };
// }

export function getUserStatusDetailsInfo(data: any) {
    return {
        type: GET_STATUS_STATISTIC,
        payload: data,
    };
}


