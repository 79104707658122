import axios from "axios";
import {API_URL} from "../config";
import {setUserError} from "../actions/actionsUsersList";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {GET_NOTIFICATION_ERROR} from "../types/types";
import {hideError} from "../actions/actionsMonitoringList";
import {RoleDataSort} from "../../types/types";
import {hideStatusDetailsLoader, hideStatusesListLoader, setStatusArchiveTableToggle, setStatusDialogVisible, showStatusDetailsListLoader, showStatusesErrorMessage, showStatusesListLoader} from "../actions/actionsStatuses";
import {GET_STATUS_DETAILS, GET_STATUS_PAGINATION, GET_STATUSES_DICTIONARY, GET_STATUSES_LIST} from "../types/typesStatusesList";
import {SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";
import {getBranchDetailById} from "./apiBranchList";

export function getStatusesListWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showStatusesListLoader());
        dispatch(hideError());
        axios.post(`${API_URL}Status/GetStatusesWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_STATUSES_LIST, payload: response.data.data.items})
                dispatch({type: GET_STATUS_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideStatusesListLoader());
                dispatch(hideError());
            })
    }
}

export function getStatusDetails(id: number, jwtToken: any, copy?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showStatusDetailsListLoader());
        axios.get(`${API_URL}Status/GetStatusById?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        if (copy) {
                            response.data.data.statusName = '';
                            response.data.data.description = '';
                            response.data.data.id = null;
                            dispatch({type: GET_STATUS_DETAILS, payload: response.data.data});
                        } else {
                            dispatch({type: GET_STATUS_DETAILS, payload: response.data.data});
                        }
                    }
                    else {
                        return dispatch(showStatusesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .finally(() => {
            dispatch(showAccessDeniedNotifications(false));
            dispatch(hideStatusDetailsLoader());
        })

    }
}

export function getStatusesDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}IdentityDictionary/GetDictionary?isActive=true`, [300], {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_STATUSES_DICTIONARY, payload: response.data.data});
                    }
                    else {
                        return dispatch(showStatusesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showStatusesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showStatusesErrorMessage(true))
            })

    }
}

const dataSort: RoleDataSort = {
    "isArchived": false,
    "pageNumber": 1,
    "pageSize": 10,
    "sort": {
        "orderBy": "Id",
        "isDesc": true
    },
    "filter": []
}
export function updateStatus(data: any, jwtToken: any, sort? : RoleDataSort, branchId?: number) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}Status/UpdateStatus`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_STATUS_DETAILS, payload: response.data.data})
                    // dispatch(showRoleSaveMessage());
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    if (branchId) {
                        dispatch(getBranchDetailById(branchId, jwtToken));
                    }
                } else {
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    if (response.data) {
                        dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                    }
                    else {
                        return dispatch(setUserError(true))
                    }
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}
export function createStatus(data: any, jwtToken: any, sort? : RoleDataSort) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}Status/CreateStatus`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_STATUS_DETAILS, payload: response.data.data})
                    // dispatch(showRoleSaveMessage());
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function archiveStatus(data: any, jwtToken: any, sort? : RoleDataSort) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}Status/ChangeArchiveStatus`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch(setStatusArchiveTableToggle(true))
                    dispatch(setStatusDialogVisible(false));
                } else {
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function copyStatuses(id: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Status/CopyStatus/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_STATUS_DETAILS, payload: response.data.data});
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(getStatusesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: GET_NOTIFICATION_ERROR, payload: false});
                return dispatch(setUserError(false))
            })
    }
}
