import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ADD_INBOUND_PHONE,
    ADD_OUTBOUND_PHONE,
    ADD_TRUNK_USER,
    ARCHIVE_TRUNK_TABLE_TOGGLE,
    DELETE_TRUNK_BRANCH,
    DELETE_TRUNK_USER, GET_TRUNK,
    GET_TRUNK_PAGINATION,
    GET_TRUNKS, INITIAL_TRUNK_DATA, REMOVE_INBOUND_PHONE, REMOVE_OUTBOUND_PHONE,
    SELECTED_TRUNK_ID,
    SET_TRUNK_CURRENT_PAGE,
    SET_TRUNK_CURRENT_SIZE, SET_TRUNK_ERROR_FIELDS, SET_TRUNK_IDS, SHOW_TRUNK_DETAIL_LOADING, SHOW_TRUNK_LOADER,
    TRUNK_CLEAR_FILTERS,
    TRUNK_DIALOG_VISIBLE,
    TRUNK_FIELD, UPDATE_INBOUND_PHONE, UPDATE_OUTBOUND_PHONE
} from "../types/typesTrunkList";

const initialState = {
    archiveTrunkTableToggle: true,
    trunkDialogVisible: false,
    selectedTrunkId: null,
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        phonesCount: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        isActive: {value: null, matchMode: FilterMatchMode.IN},
    },
    trunksList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    trunkData: {
        description: null,
        name: null,
        isActive: false,
        listOutboundPhones: [],
        listInboundPhones: []
    },
    trunkIds: null,
    loading: false,
    detailsLoading: false,
    errorFields: null,
}

export const TrunksListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_TRUNK_TABLE_TOGGLE:
            return {...state, archiveTrunkTableToggle: action.payload}
        case TRUNK_DIALOG_VISIBLE:
            return {...state, trunkDialogVisible: action.payload}
        case SELECTED_TRUNK_ID:
            return {...state, selectedTrunkId: action.payload}
        case ADD_TRUNK_USER:
            let usersId = action.payload.userId.map((item: any) => {
                return item
            })
            return {
                ...state, trunkData:
                    {
                        ...state.trunkData,
                        trunkUsers: usersId.map((item: { userId: any; userLevel: any; userName: any; }) => {
                            return {userId: item.userId, userLevel: item.userLevel, userName: item.userName}
                        })
                    }
            }
        case DELETE_TRUNK_USER:
            return {
                ...state, trunkData:
                    {
                        ...state.trunkData,
                        // @ts-ignore
                        trunkUsers: state.trunkData.trunkUsers.filter((item: any) => item.userId !== action.payload)
                    }
            }
        case DELETE_TRUNK_BRANCH:
            return {
                ...state, trunkData:
                    {
                        ...state.trunkData,
                        // @ts-ignore
                        trunkBranches: state.trunkData.trunkBranches.filter((item: any) => item.id !== action.payload)
                    }
            }
        case TRUNK_FIELD:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    [action.payload.fieldName]: action.payload.value,
                }
            }
        case TRUNK_CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    branchId: {value: null, matchMode: FilterMatchMode.IN},
                    phonesCount: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    isActive: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case GET_TRUNKS:
            return {...state, trunksList: action.payload}
        case GET_TRUNK_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_TRUNK_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_TRUNK_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_TRUNK:
            return {...state, trunkData: action.payload}
        case SHOW_TRUNK_LOADER:
            return {...state, loading: action.payload}
        case SHOW_TRUNK_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case INITIAL_TRUNK_DATA:
            return {...state, trunkData: initialState.trunkData}
        case SET_TRUNK_IDS:
            return {...state, trunkIds: action.payload};
        case SET_TRUNK_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case ADD_OUTBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listOutboundPhones: [
                        ...state.trunkData.listOutboundPhones,
                        action.payload,
                    ],
                },
            };
        case REMOVE_OUTBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listOutboundPhones: state.trunkData.listOutboundPhones.filter(
                        (phone: string, i: number) => i !== action.payload
                    ),
                },
            };
        case UPDATE_OUTBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listOutboundPhones: state.trunkData.listOutboundPhones.map(
                        (phone: string, i: number) => i === action.payload.index ? action.payload.newPhone : phone
                    ),
                },
            };
        case ADD_INBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listInboundPhones: [
                        ...state.trunkData.listInboundPhones,
                        action.payload,
                    ],
                },
            };
        case REMOVE_INBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listInboundPhones: state.trunkData.listInboundPhones.filter(
                        (phone: string, i: number) => i !== action.payload
                    ),
                },
            };
        case UPDATE_INBOUND_PHONE:
            return {
                ...state,
                trunkData: {
                    ...state.trunkData,
                    listInboundPhones: state.trunkData.listInboundPhones.map(
                        (phone: string, i: number) => i === action.payload.index ? action.payload.newPhone : phone
                    ),
                },
            };
        default:
            return state
    }
}
