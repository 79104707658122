import {
    ARCHIVE_TABLE_TOGGLE,
    CLEAR_FILTERS,
    COMPANY_FIELD,
    DIALOG_VISIBLE,
    GET_COMPANIES,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE,
    GET_COMPANIES_DIRECTORIES_PHONE_TYPE,
    SELECTED_COMPANY_ID,
    SHOW_LOADER,
    HIDE_LOADER,
    SHOW_ERROR,
    HIDE_ERROR,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY,
    SHOW_LOADER_STRATEGY_DICT,
    HIDE_LOADER_STRATEGY_DICT,
    GET_COMPANY,
    GET_USERS,
    DELETE_USER,
    ADD_USER,
    INITIAL_COMPANIES_DATA,
    COMPANY_STATE_SAVE,
    GET_COMPANIES_ARCHIVED,
    SHOW_COMPANY_CREATE_MESSAGE,
    SHOW_COMPANY_SAVE_MESSAGE,
    HIDE_COMPANY_CREATE_MESSAGE,
    HIDE_COMPANY_SAVE_MESSAGE,
    SHOW_ERROR_MESSAGE,
    HIDE_ERROR_MESSAGE,
    SHOW_ERROR_FIELD,
    GET_NOTIFICATION,
    CHANGED_SCHEDULER,
    RESET_SCHEDULER,
    SET_CALL_CYCLES,
    SHOW_UPDATED_ERROR,
    CHANGED_COMPANIES_DATA,
    SET_ERROR_FIELDS,
    GET_COMPANIES_DIRECTORIES_TRANKS_PHONE,
    SET_TRANK_ID,
    SET_INTERVAL_ID,
    SHOW_GET_COMPANIES_INTERVAL_ERROR,
    SHOW_GET_ARCHIVE_COMPANIES_ERROR,
    SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR,
    SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE,
    SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR,
    GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR,
    GET_PAGINATION,
    SET_CURRENT_PAGE,
    SET_CURRENT_SIZE,
    GET_CAMPAIGN_CONTACTS,
    SET_CAMPAIGN_CONTACTS_PAGE_SIZE,
    CLEAR_CAMPAIGN_CONTACTS,
    SET_CONTACTS_LOADER,
    GET_NOTIFICATION_ERROR,
    CLEAR_LEADS_FILTERS,
    SHOW_COMPANY_DELETE_MESSAGE, SET_LOADING, SHOW_CAMPAIGN_DETAIL_LOADING, GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE, DELETE_SKILL_GROUP, CHANGE_SKILL_GROUP_LEVEL, ADD_SKILL_GROUP, GOOGLE_AUTH_SHOW_ERROR
} from "../types/types";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {RelationCampaignUsers} from "types/types";
import {CHANGE_CAMPAIGN_USER_LEVEL} from "../types/typesUsersList";

const initialState = {
    archiveTableToggle: true,
    dialogVisible: false,
    selectedCompanyId: null,
    clearFilters: {
        campaignTypeId: {value: null, matchMode: FilterMatchMode.IN},
        branchId: {value: null, matchMode: FilterMatchMode.IN},
        name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        statusId: {value: null, matchMode: FilterMatchMode.IN},
        campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        isGeneralTrunk: {value: null, matchMode: FilterMatchMode.IN},
    },
    clearLeadsFilters: {
        phoneTypeId: {value: null, matchMode: FilterMatchMode.IN},
        contactName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        phoneNo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        description: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        result: {value: null, matchMode: FilterMatchMode.IN},
    },
    companiesList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    companiesListArchived: [],
    companiesData: {
        campaign: {
            isSkillGroup: false,
            campaignId: null,
            name: null,
            description: null,
            isArchived: false,
            statusId: 0,
            campaignTypeId: 201,
            operatorStrategyTypeId: 308,
            countUsers: 0,
            countContacts: 0,
            priority: 0,
            schedulerId: 0,
            progressContacts: null,
            progressCampaign: 0,
            employmentOperators: 0,
            wellComplet: 0,
            firstCallOperator: true,
            isGeneralTrunk: true,
            isDoneContactCount: 0,
            branchId: null
        },
        campaignData: {
            createdByUserId: null,
            updatedByUserId: null,
            createdDateTime: null,
            updatedDateTime: null,
        },
        campaignUsers: [],
        relationCampaignContacts: [],
        callCycles: [
            {typeCall: 101, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 1},
            {typeCall: 102, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 2},
            {typeCall: 103, countCalls: 1, timeBetweenCalls: 0, priorityCallType: 3}
        ],
        skillGroups: [],
        scheduler: null,
        trunkId: null
    },
    usersData: [],
    campaignContacts: {
        countPage: 1,
        totalItems: 10,
        items: [],
    },
    campaignContactsPageSize: {
        page: 1,
        size: 10
    },
    contactsLoader: false,
    companiesDirectoryCampaignType: [],
    campaignDirectoriesPhoneType: [],
    companiesDirectoryCampaignStrategy: [],
    companiesDirectoryTranksPhones: [],
    companiesDirectorySchedulerType: [],
    loading: false,
    detailsLoading: false,
    loadingStrategyDict: false,
    error: false,
    errorUpdate: false,
    errorMessage: null,
    companyCreateMessage: false,
    companySaveMessage: false,
    companyDeleteMessage: false,
    companyStateSave: false,
    changeScheduler: false,
    changedCompaniesData: false,
    errorField: null,
    errorFields: null,
    notificationCopy: null,
    notificationLaunch: null,
    notificationCancel: null,
    notificationSuspend: null,
    notificationSuccess: null,
    notificationError: null,
    notificationUpdated: null,
    notificationUpdatedError: null,
    notificationSaveChanges: null,
    intervalId: null,
    getCompaniesIntervalError: false,
    getArchiveCompaniesError: false,
    getCampaignDirectoriesPhoneTypeError: false,
    getCampaignDirectoriesCampaignTypeError: false,
    getCampaignDirectoriesGetTranksPhonesError: false,
    getCampaignDirectoriesStrategyError: false,
    getNotificationError: false,
    userLoading: false,
    googleAuthError: false,
    userDeleteQuestionsText: null,
    userDeleteMessageText: null
}

export const CompaniesListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ARCHIVE_TABLE_TOGGLE:
            return {...state, archiveTableToggle: action.payload}
        case DIALOG_VISIBLE:
            return {...state, dialogVisible: action.payload}
        case SELECTED_COMPANY_ID:
            return {...state, selectedCompanyId: action.payload}
        case COMPANY_FIELD:
            return {
                ...state, companiesData:
                    {
                        ...state.companiesData,
                        [action.payload.section]: {
                            // @ts-ignore
                            ...state.companiesData[action.payload.section],
                            [action.payload.fieldName]: action.payload.value,
                        }
                    }
            }
        case CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    campaignTypeId: {value: null, matchMode: FilterMatchMode.IN},
                    branchId: {value: null, matchMode: FilterMatchMode.IN},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    statusId: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    isGeneralTrunk: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case CLEAR_LEADS_FILTERS:
            return {
                ...state, clearFilters: {
                    campaignTypeId: {value: null, matchMode: FilterMatchMode.IN},
                    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    statusId: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                }
            }
        case GET_COMPANIES:
            return {...state, companiesList: action.payload}
        case GET_CAMPAIGN_CONTACTS:
             return {...state, campaignContacts: {totalItems: action.payload.totalItems, countPage: action.payload.countPage, items: action.payload.items}}
        case CLEAR_CAMPAIGN_CONTACTS:
            return {...state, campaignContacts: {totalItems: 0, countPage: 0, items: []}}
        case SET_CAMPAIGN_CONTACTS_PAGE_SIZE:
            return {...state, campaignContactsPageSize: {page: action.payload.page, size: action.payload.size}}
        case GET_PAGINATION:
            return {...state, pagination:{countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_CURRENT_PAGE:
            return {...state, paginationPage:action.payload}
        case SET_CURRENT_SIZE:
            return {...state, paginationSize:action.payload}
        case GET_COMPANIES_ARCHIVED:
            return {...state, companiesListArchived: action.payload}
        case GET_COMPANY:
            return {...state, companiesData: action.payload}
        case GET_USERS:
            return {...state, usersData: action.payload}
        case GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE:
            return {...state, companiesDirectoryCampaignType: action.payload}
        case GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE:
            return {...state, companiesDirectorySchedulerType: action.payload}
        case GET_COMPANIES_DIRECTORIES_PHONE_TYPE:
            return {...state, campaignDirectoriesPhoneType: action.payload}
        case GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY:
            return {...state, companiesDirectoryCampaignStrategy: action.payload}
        case GET_COMPANIES_DIRECTORIES_TRANKS_PHONE:
            return {...state, companiesDirectoryTranksPhones: action.payload}
        case SHOW_LOADER:
            return {...state, loading: true}
        case SHOW_CAMPAIGN_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case HIDE_LOADER:
            return {...state, loading: false}
        case SHOW_LOADER_STRATEGY_DICT:
            return {...state, loadingStrategyDict: true}
        case HIDE_LOADER_STRATEGY_DICT:
            return {...state, loadingStrategyDict: false}
        case SHOW_ERROR:
            return {...state, error: true}
        case SHOW_UPDATED_ERROR:
            return {...state, errorUpdate: true}
        case SHOW_COMPANY_CREATE_MESSAGE:
            return {...state, companyCreateMessage: true}
        case SHOW_COMPANY_SAVE_MESSAGE:
            return {...state, companySaveMessage: true}
        case SHOW_COMPANY_DELETE_MESSAGE:
            return {...state, companyDeleteMessage: action.payload}
        case HIDE_COMPANY_CREATE_MESSAGE:
            return {...state, companyCreateMessage: false}
        case HIDE_COMPANY_SAVE_MESSAGE:
            return {...state, companySaveMessage: false}
        case HIDE_ERROR:
            return {...state, error: false, errorUpdate: false}
        case COMPANY_STATE_SAVE:
            return {...state, companyStateSave: action.payload}
        case INITIAL_COMPANIES_DATA:
            return {...state, companiesData: initialState.companiesData}
        case DELETE_USER:
            return {
                ...state, companiesData:
                    {
                        ...state.companiesData,
                        campaignUsers: state.companiesData.campaignUsers.filter((item: RelationCampaignUsers) => item.userId !== action.payload)
                    }
            }
        case ADD_USER:
            let usersId = action.payload.userId.map((item: any) => {
                return item
            })
            return {
                ...state, companiesData:
                    {
                        ...state.companiesData,
                        campaignUsers: usersId.map((item: { userId: any; userLevel: any; userName: any;}) => {
                            return {userId: item.userId, userLevel: item.userLevel, userName: item.userName}
                        })
                    }
            }
        case CHANGE_CAMPAIGN_USER_LEVEL:
            return {
                ...state,
                companiesData: {
                    ...state.companiesData,
                    campaignUsers: state?.companiesData?.campaignUsers.map((user: any) =>
                        user.userId === action.payload.userId
                            ? {...user, userLevel: action.payload.userLevel}
                            : user
                    )
                }
            };
        case DELETE_SKILL_GROUP:
            return {
                ...state, companiesData:
                    {
                        ...state.companiesData,
                        skillGroups: state.companiesData.skillGroups.filter((item: any) => item.skillGroupId !== action.payload)
                    }
            }
        case ADD_SKILL_GROUP:
            let skillGroupId = action.payload.skillGroupId.map((item: any) => {
                return item
            })
            return {
                ...state, companiesData:
                    {
                        ...state.companiesData,
                        skillGroups: skillGroupId.map((item: { skillGroupId: any; skillGroupLevel: any;}) => {
                            return {skillGroupId: item.skillGroupId, skillGroupLevel: item.skillGroupLevel}
                        })
                    }
            }
        case CHANGE_SKILL_GROUP_LEVEL:
            return {
                ...state,
                companiesData: {
                    ...state.companiesData,
                    skillGroups: state?.companiesData?.skillGroups.map((user: any) =>
                        user.skillGroupId === action.payload.skillGroupId
                            ? {...user, skillGroupLevel: action.payload.skillGroupLevel}
                            : user
                    )
                }
            };
        case SHOW_ERROR_MESSAGE:
            return {...state, errorMessage: action.payload}
        case SHOW_ERROR_FIELD:
            return {...state, errorField: action.payload}
        case SET_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case HIDE_ERROR_MESSAGE:
            return {...state, errorMessage: null}
        case CHANGED_SCHEDULER:
            return {...state, changeScheduler: action.payload, changedCompaniesData: action.payload}
        case CHANGED_COMPANIES_DATA:
            return {...state, changedCompaniesData: action.payload}
        case RESET_SCHEDULER:
            return {...state, companiesData: {...state.companiesData, scheduler: null}}
        case SET_CALL_CYCLES:
            return {...state, companiesData: {...state.companiesData, callCycles: action.payload}};
        case SET_TRANK_ID:
            return {...state, companiesData: {...state.companiesData, trunkId: action.payload}};
        case SET_INTERVAL_ID:
            return {...state, intervalId: action.payload};
        case SHOW_GET_COMPANIES_INTERVAL_ERROR:
            return {...state, getCompaniesIntervalError: action.payload};
        case SHOW_GET_ARCHIVE_COMPANIES_ERROR:
            return {...state, getArchiveCompaniesError: action.payload};
        case SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR:
            return {...state, getCampaignDirectoriesPhoneTypeError: action.payload};
        case SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE:
            return {...state, getCampaignDirectoriesCampaignTypeError: action.payload};
        case SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR:
            return {...state, getCampaignDirectoriesGetTranksPhonesError: action.payload};
        case GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR:
            return {...state, getCampaignDirectoriesStrategyError: action.payload};
        case SET_CONTACTS_LOADER:
            return {...state, contactsLoader: action.payload}
        case SET_LOADING:
            return {...state, userLoading: action.payload};
        case GOOGLE_AUTH_SHOW_ERROR:
            return {...state, googleAuthError: action.payload}
        case GET_NOTIFICATION:
            switch (action.payload.code) {
                case 105:
                    return {...state, notificationLaunch: action.payload}
                case 106:
                    return {...state, notificationSuspend: action.payload}
                case 107:
                    return {...state, notificationCancel: action.payload}
                case 108:
                    return {...state, notificationCopy: action.payload}
                case 109:
                    return {...state, notificationSuccess: action.payload}
                case 110:
                    return {...state, notificationUpdated: action.payload}
                case 111:
                    return {...state, notificationError: action.payload}
                case 112:
                    return {...state, notificationUpdatedError: action.payload}
                case 113:
                    return {...state, notificationSaveChanges: action.payload}
                case 126:
                    return {...state, userDeleteMessageText: action.payload}
                case 130:
                    return {...state, userDeleteQuestionsText: action.payload}
                default:
                    return state
            }
        case GET_NOTIFICATION_ERROR:
            return {...state, getNotificationError: action.payload}
        default:
            return state
    }
}
