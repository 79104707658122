import {
    ARCHIVE_TABLE_TOGGLE,
    CLEAR_FILTERS,
    COMPANY_FIELD,
    DIALOG_VISIBLE,
    SELECTED_COMPANY_ID,
    SHOW_LOADER,
    HIDE_LOADER,
    HIDE_ERROR,
    SHOW_ERROR,
    SHOW_LOADER_STRATEGY_DICT,
    HIDE_LOADER_STRATEGY_DICT,
    DELETE_USER,
    ADD_USER,
    INITIAL_COMPANIES_DATA,
    COMPANY_STATE_SAVE,
    SHOW_COMPANY_CREATE_MESSAGE,
    HIDE_COMPANY_CREATE_MESSAGE,
    SHOW_COMPANY_SAVE_MESSAGE,
    HIDE_COMPANY_SAVE_MESSAGE,
    SHOW_ERROR_MESSAGE,
    HIDE_ERROR_MESSAGE,
    SHOW_ERROR_FIELD,
    CHANGED_SCHEDULER,
    RESET_SCHEDULER,
    SET_CALL_CYCLES,
    SHOW_UPDATED_ERROR,
    CHANGED_COMPANIES_DATA,
    SET_ERROR_FIELDS,
    SET_TRANK_ID,
    SET_INTERVAL_ID,
    SHOW_GET_COMPANIES_INTERVAL_ERROR,
    SHOW_GET_ARCHIVE_COMPANIES_ERROR,
    SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR,
    SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE,
    GET_COMPANIES,
    SET_CURRENT_PAGE,
    SET_CURRENT_SIZE,
    SET_CAMPAIGN_CONTACTS_PAGE_SIZE,
    CLEAR_CAMPAIGN_CONTACTS,
    SET_CONTACTS_LOADER,
    SHOW_COMPANY_DELETE_MESSAGE, SET_USER_CONNECTED, INITIAL_ACCOUNT_DATA, SET_ACTIVE_CLICK, CHANGE_SKILL_GROUP_LEVEL, DELETE_SKILL_GROUP, ADD_SKILL_GROUP, GOOGLE_AUTH_SHOW_ERROR,
} from "../types/types";
import {CHANGE_CAMPAIGN_USER_LEVEL, SET_EDIT_TYPE} from "../types/typesUsersList";

export function setArchiveTableToggle(state:boolean) {
    return {
        type: ARCHIVE_TABLE_TOGGLE,
        payload: state,
    }
}

export function setDialogVisible(state:boolean) {
    return {
        type: DIALOG_VISIBLE,
        payload: state,
    }
}

export function setSelectedCompanyIndex(state:number) {
    return {
        type: SELECTED_COMPANY_ID,
        payload: state,
    }
}

export function setCompanyField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: COMPANY_FIELD,
        payload: state,
    }
}

export function setClearFilters() {
    return {
        type: CLEAR_FILTERS,
    }
}

export function showLoader() {
    return {
        type: SHOW_LOADER,
    }
}

export function hideLoader() {
    return {
        type: HIDE_LOADER,
    }
}

export function showLoaderStrategyDict() {
    return {
        type: SHOW_LOADER_STRATEGY_DICT,
    }
}

export function hideLoaderStrategyDict() {
    return {
        type: HIDE_LOADER_STRATEGY_DICT,
    }
}

export function showError() {
    return {
        type: SHOW_ERROR,
    }
}

export function showUpdatedError() {
    return {
        type: SHOW_UPDATED_ERROR,
    }
}

export function showGetCompaniesIntervalError(state: boolean) {
    return {
        type: SHOW_GET_COMPANIES_INTERVAL_ERROR,
        payload: state
    }
}

export function addUser(state: {campaignId:string, userId:any}) {
    return {
        type: ADD_USER,
        payload: state,
    }
}

export function deleteUser(state: string) {
    return {
        type: DELETE_USER,
        payload: state,
    }
}
export function addSkillGroup(state: {campaignId:string, skillGroupId:[]}) {
    return {
        type: ADD_SKILL_GROUP,
        payload: state,
    }
}

export function deleteSkillGroup(state: string) {
    return {
        type: DELETE_SKILL_GROUP,
        payload: state,
    }
}

export function showCompanyCreateMessage() {
    return {
        type: SHOW_COMPANY_CREATE_MESSAGE,
    }
}

export function showCompanySaveMessage() {
    return {
        type: SHOW_COMPANY_SAVE_MESSAGE,
    }
}

export function showCompanyDeleteMessage(state: boolean) {
    return {
        type: SHOW_COMPANY_DELETE_MESSAGE,
        payload: state,
    }
}

export function hideCompanyCreateMessage() {
    return {
        type: HIDE_COMPANY_CREATE_MESSAGE,
    }
}

export function hideCompanySaveMessage() {
    return {
        type: HIDE_COMPANY_SAVE_MESSAGE,
    }
}

export function hideError() {
    return {
        type: HIDE_ERROR,
    }
}

export function initialCompaniesData() {
    return {
        type: INITIAL_COMPANIES_DATA,
    }
}

export function setCompanyStateSave(state: boolean) {
    return {
        type: COMPANY_STATE_SAVE,
        payload: state,
    }
}

export function hideErrorMessage() {
    return {
        type: HIDE_ERROR_MESSAGE,
    }
}

export function showErrorMessage(message: string) {
    return {
        type: SHOW_ERROR_MESSAGE,
        payload: message
    }
}

export function setErrorField(state:any) {
    return {
        type: SHOW_ERROR_FIELD,
        payload: state,
    }
}

export function setChangedScheduler(state:boolean) {
    return {
        type: CHANGED_SCHEDULER,
        payload: state,
    }
}

export function setChangedCompaniesData(state:boolean) {
    return {
        type: CHANGED_COMPANIES_DATA,
        payload: state,
    }
}

export function resetScheduler() {
    return {
        type: RESET_SCHEDULER,
    }
}

export function setCallCycles(state: any) {
    return {
        type: SET_CALL_CYCLES,
        payload: state,
    }
}

export function setTrankId(state: number) {
    return {
        type: SET_TRANK_ID,
        payload: state,
    }
}

export function setErrorFields(state:any) {
    return {
        type: SET_ERROR_FIELDS,
        payload: state,
    }
}
export const setIntervalId = (state: any) => ({
    type: SET_INTERVAL_ID,
    payload: state,
});

export function showGetArchiveCompaniesError(state: boolean) {
    return {
        type: SHOW_GET_ARCHIVE_COMPANIES_ERROR,
        payload: state
    }
}

export function showGetCampaignDirectoriesPhoneTypeError(state: boolean) {
    return {
        type: SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR,
        payload: state
    }
}

export function getCampaignDirectoriesCampaignTypeError(state: boolean) {
    return {
        type: SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE,
        payload: state
    }
}

export function updateStateCampaignSignal(state: any) {
    return {
        type: GET_COMPANIES,
        payload: state
    }
}

export function setCurrentPage(state: any) {
    return {
        type: SET_CURRENT_PAGE,
        payload: state
    }
}

export function setCurrentPageSize(state: any) {
    return {
        type: SET_CURRENT_SIZE,
        payload: state
    }
}

export function setCampaignContactsPageSize(state: {page: number, size: number}) {
    return {
        type: SET_CAMPAIGN_CONTACTS_PAGE_SIZE,
        payload: state
    }
}

export function clearCampaignContacts() {
    return {
        type: CLEAR_CAMPAIGN_CONTACTS,
    }
}

export function setContactsLoader(state: boolean) {
    return {
        type: SET_CONTACTS_LOADER,
        payload: state
    }
}

export function setConnectUser(state: boolean) {
    return {
        type: SET_USER_CONNECTED,
        payload: state
    }
}

export function initialAccountData() {
    return {
        type: INITIAL_ACCOUNT_DATA,
    }
}

export function setActiveClick(state: any) {
    return {
        type: SET_ACTIVE_CLICK,
        payload: state
    }
}

export function setEditType(state: boolean) {
    return {
        type: SET_EDIT_TYPE,
        payload: state
    }
}

export function googleAuthLoginError(state: any) {
    return {
        type: GOOGLE_AUTH_SHOW_ERROR,
        payload: state
    }
}

export function changeCampaignUserLevel(state: {campaignId:string, userId: string, userLevel: any}) {
    return {
        type: CHANGE_CAMPAIGN_USER_LEVEL,
        payload: state,
    }
}

export function changeSkillGroupLevel(state: {campaignId:string, skillGroupId: string, skillGroupLevel: any}) {
    return {
        type: CHANGE_SKILL_GROUP_LEVEL,
        payload: state,
    }
}
