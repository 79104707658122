import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom';
import AppWrapper from './AppWrapper';
import {Provider} from 'react-redux';
import store from './redux/store';
import {persistor} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <HashRouter>
                    <AppWrapper></AppWrapper>
            </HashRouter>
        </PersistGate>
    </Provider>
);

