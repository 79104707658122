import React, {useEffect, useRef, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {deleteQueueBranch, setQueueField, setQueueTrankIds} from "../../../redux/actions/actionsQueue";
import {Button} from "primereact/button";
import {PROD} from "../../../redux/config";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";
import {MultiSelect} from "primereact/multiselect";
import {addQualificationGroupBranch} from "../../../redux/actions/actionsQualificationGroup";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getBranchDetailById} from "../../../redux/api/apiBranchList";
import {setEditType} from "../../../redux/actions/actions";
import {setBranchDialogVisible} from "../../../redux/actions/actionsBranchList";

const QueuesListDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.QueueListReducer.errorFields);
    // @ts-ignore
    const companiesDirectoryTranksPhones = useAppSelector(state => state.QueueListReducer.queuesDirectoryTrunksPhone);
    const phoneField = useRef(null);
    const nameField = useRef(null);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle)
    const [selectedPairs, setSelectedPairs] = useState<any>([{selectedGateway: null, selectedPhone: null}]);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const selectedCompanyId = useAppSelector(state => state.QualificationGroupListReducer.selectedQualificationGroupId);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const [currentUserId, setCurrentUserId] = useState<any>(null);

    const {
        visible: hasBranchVisionPermission,
        update: hasBranchUpdatePermission
    } = branchPermissions;

    const addPair = (selectedGateway: null, selectedPhone: null) => {
        setSelectedPairs((prevPairs: any[]) => {
            if (selectedGateway != null && selectedPhone != null) {
                const pairExists = prevPairs.some(pair => pair.selectedGateway === selectedGateway && pair.selectedPhone === selectedPhone);
                if (pairExists) {
                    return prevPairs;
                }
            }
            return [...prevPairs, {selectedGateway, selectedPhone}];
        });
    };

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(setBranchDialogVisible(false));
    }, []);


    useEffect(() => {
        // @ts-ignore
        const updatedTrunks = selectedPairs.map(({ selectedGateway, ...rest }) => rest);
        const updatedArray = updatedTrunks.map((item: { selectedPhone: any; }) => {
            return {
                ...item,
                trunkPhoneId: item.selectedPhone,
            };
        });
        // @ts-ignore
        const cleanedArray = updatedArray.map(({ selectedPhone, ...rest }) => rest);
        dispatch(setQueueTrankIds(cleanedArray));
    }, [selectedPairs]);

    const removePair = (indexToRemove: number) => {
        setSelectedPairs((currentPairs: any[]) =>
            currentPairs.filter((_, index) => index !== indexToRemove)
        );
    };

    const changeSelectedGateway = (value: any, index: number) => {
        setSelectedPairs((prevPairs: any[]) =>
            prevPairs.map((pair, idx) =>
                idx === index ? {...pair, selectedGateway: value, selectedPhone: null} : pair
            )
        );
    };

    const changeSelectedPhone = (value: null, index: any) => {
        setSelectedPairs((prevPairs: any[]) =>
            prevPairs.map((pair, idx) =>
                idx === index ? { ...pair, selectedPhone: value } : pair
            )
        );
    };
    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    const scrollIntoTrankView = () => {
        // @ts-ignore
        phoneField?.current?.scrollIntoView({behavior: 'smooth'});
    }
    const priorityOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        if (!queueData.queue.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (errorFields?.nameUniq) {
            scrollIntoNameView();
        }
        if (errorFields?.trankId && queueData.trankId) {
            scrollIntoTrankView();
        }
    }, [errorFields, queueData]);

    useEffect(() => {
        if (queueData?.queueTrunks?.length) {
            setSelectedPairs([]);
        }
    }, [queueData?.queueTrunks, queueData?.id]);

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    useEffect(() => {
        if (queueData?.queueTrunks) {
            queueData?.queueTrunks?.map((queueTrank: any) => {
                let foundObject = companiesDirectoryTranksPhones?.find((item: { id: any; }) => item.id === queueTrank?.trunkPhoneId);
                if (queueTrank?.trunkPhoneId === foundObject?.id) {
                    addPair(foundObject?.gatewayName, foundObject?.id);
                }
            });
        }
    }, [companiesDirectoryTranksPhones, queueData?.queueTrunks]);

    const gateways = Array.from(new Set(companiesDirectoryTranksPhones?.map((item: any) => item.gatewayName)));

    const onChangeNameField = (e: any) => {
        dispatch(setQueueField({section: 'queue', fieldName: 'name', value: e.target.value}));
    }

    const changeQueueBranches = (e: any) => {
        const filteredEValue = e.value?.filter((item: { id: number; }) => item.id);
        const existingIds = new Set(filteredEValue.map((item: { id: number; }) => item.id));
        const skillGroupData = new Set(formattedBranches.map((item: { id: number; }) => item.id));
        const newItems = queueData && queueData.queueBranches
            ? queueData.queueBranches.filter((item: { id: number; }) =>
                !existingIds.has(item.id) && !skillGroupData.has(item.id))
            : [];
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addQualificationGroupBranch({ campaignId: selectedCompanyId, id: updatedEValue }));
    }

    const branchesValue = queueData?.queueBranches?.map((companiesItem: any) => {
        const user = formattedBranches.find((userItem: any) => userItem.id === companiesItem.id);
        const {queueId, isMainIntegrationUrl, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            description: user?.description,
            id: user?.id,
            isArchived: user?.isArchived,
            name: user?.name,
            label: user?.label,
            ownerId: user?.ownerId,
            ownerName: user?.ownerName,
            parentId: user?.parentId,
            parentName: user?.parentName,
            userCount: user?.userCount,
            integrationURLId: user?.integrationURLId,
            isMainIntegrationUrl: user?.isMainIntegrationUrl,
            mainBranchId: user?.mainBranchId
        }
    });

    const branchItems = queueData?.queueBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        <div className="field col-10 md:col-10 flex align-items-center m-0">
                            {hasBranchVisionPermission && <Button
                                className="max-w-4rem p-button-secondary mr-3"
                                style={{minWidth: '40px'}}
                                loading={branchDetailsLoader && currentUserId === branchItem.id}
                                disabled={!archiveTableToggle || branchDetailsLoader}
                                icon={archiveTableToggle && hasBranchUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                                onClick={() => {
                                    setCurrentUserId(branchItem.id);
                                    dispatch(getBranchDetailById(branchItem.id, jwtToken?.jwtToken, true));
                                    if (hasBranchUpdatePermission) {
                                        dispatch(setEditType(true));
                                    } else {
                                        dispatch(setEditType(false));
                                    }
                                }}/>}
                            {branchItem.label}
                        </div>
                        <div className="field col-2 md:col-2 flex align-items-center justify-content-end m-0 p-0">
                            <Button name={branchItem.id}
                                    onClick={() => {
                                        dispatch(deleteQueueBranch(branchItem.id));
                                    }}
                                    icon="pi pi-times"
                                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 m-0"
                                    disabled={!archiveTableToggle || !editType}
                            />
                        </div>
                    </div>
                )
            }
        })
    })

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name || errorFields?.nameUniq})}>Назва черги *</label>
                    <InputText
                        id="name"
                        value={queueData?.queue?.name || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.name || errorFields?.nameUniq})}
                        onChange={e => {
                            onChangeNameField(e);
                        }}
                        disabled={!archiveTableToggle || !editType}
                    />
                    {errorFields?.nameUniq && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.nameUniq}*</span></div>}
                </div>
                <div className="field col-12 md:col-3">
                    <label htmlFor="priority">Пріоритет</label>
                    <Dropdown id="priority"
                              appendTo="self"
                              value={queueData.queue.priority ?? 0}
                              options={priorityOptions}
                              onChange={e => {
                                  dispatch(setQueueField({section: 'queue', fieldName: 'priority', value: e.value}));
                              }}
                              disabled={!archiveTableToggle || !editType}
                              placeholder="Оберіть пріоритет"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description">Коментар</label>
                    <InputTextarea
                        id="description"
                        value={queueData?.queue?.description || ''}
                        onChange={e => {
                            dispatch(setQueueField({section: 'queue', fieldName: 'description', value: e.target.value}));
                        }}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || !editType}
                    />
                </div>
                <div className="field col-12 md:col-6"></div>
            </div>
            <div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !queueData?.queue?.branchId})}>Бранч *</label>
                    <MultiSelect
                        appendTo="self"
                        value={branchesValue}
                        options={formattedBranches}
                        className={classNames('', {'p-invalid': errorFields?.branchId})}
                        onChange={(e) => {
                            changeQueueBranches(e);
                        }}
                        placeholder="Оберіть бранч"
                        filter
                        optionLabel="label"
                        selectedItemsLabel={branchesValue ? `Обрано: ${branchesValue?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        disabled={!archiveTableToggle || !editType}
                    />
                    {
                        branchesValue?.length ?
                            <div className="users-list mt-3">
                                {/*{branchDetailsLoader && <div className="bg-loading-animation"><i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i></div>}*/}
                                {branchItems}
                            </div>
                            :
                            <>

                                {
                                    errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.branchId})}>Нічого не вибрано</span> :
                                        <span className={classNames('no-users-message mt-4')}>Нічого не вибрано</span>
                                }
                            </>

                    }
                </div>
                <div>
                    {!PROD && <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>}
                    <label htmlFor="name">Стан</label>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2 w-6rem">Не активна</label>
                        <InputSwitch
                            disabled={!archiveTableToggle || !editType}
                            className="mr-3"
                            checked={queueData.queue.isActive} onChange={(e) => {
                            dispatch(setQueueField({section: 'queue', fieldName: 'isActive', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                    <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                    {
                        // !PROD &&
                        <h6>А номер</h6>}
                    {
                        // !PROD &&
                        <div className="field-radiobutton my-4 pl-2">
                            <label htmlFor="name" className="mr-2">Транк Оператора</label>
                            <InputSwitch
                                disabled={!archiveTableToggle || !editType}
                                className="mx-3"
                                value={queueData.queue.isGeneralTrunk}
                                checked={queueData.queue.isGeneralTrunk}
                                onChange={(e) => {
                                    if (e.value) {
                                        dispatch(setQueueField({section: 'queue', fieldName: 'isGeneralTrunk', value: e.value}));
                                    } else {
                                        dispatch(setQueueField({section: 'queue', fieldName: 'isGeneralTrunk', value: e.value}));
                                    }
                            }}/>
                            <label htmlFor="icon">Загальний Транк Черги</label>
                        </div>}
                    <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>

                    <div className="grid p-fluid">
                        <div className="grid col-12 md:col-6">
                            <div className="field col-12 md:col-5 mb-0">
                                <label className={classNames('', {'p-error': errorFields?.queueTranks || errorFields?.queueTranks})}>Транки*</label>
                            </div>
                            <div className="field col-12 md:col-5 mb-0">
                                <label className={classNames('', {'p-error': errorFields?.queueTranks || errorFields?.queueTranks})}>Номер*</label>
                            </div>
                            {selectedPairs?.map((pair: { selectedGateway: null; selectedPhone: any; }, index: number) => (
                                <div className="grid col-12 md:col-12" key={index}>
                                    <div className="field col-12 md:col-5 mb-0">
                                        <Dropdown
                                            emptyMessage="Немає доступних"
                                            value={pair.selectedGateway}
                                            options={gateways}
                                            className={classNames('', {'p-invalid': errorFields?.queueTranks})}
                                            onChange={(e) => changeSelectedGateway(e.value, index)}
                                            disabled={!archiveTableToggle || !editType || !queueData?.queue?.isGeneralTrunk}
                                            placeholder="Оберіть транк"
                                        />
                                    </div>
                                    <div className="field col-12 md:col-5 mb-0">
                                        <Dropdown
                                            value={pair.selectedPhone}
                                            optionLabel="phoneNo"
                                            emptyMessage="Немає доступних"
                                            className={classNames('', {'p-invalid': errorFields?.queueTranks})}
                                            optionValue="id"
                                            options={companiesDirectoryTranksPhones?.filter((item: { gatewayName: null; id: any; }) =>
                                                item.gatewayName === pair.selectedGateway &&
                                                (!selectedPairs.some((selectedPair: { selectedPhone: any; }, selectedIndex: number) =>
                                                        selectedIndex !== index && selectedPair.selectedPhone === item.id)
                                                    || pair.selectedPhone === item.id)
                                            )}
                                            onChange={(e) => changeSelectedPhone(e.value, index)}
                                            disabled={!pair.selectedGateway || !archiveTableToggle || !editType || !queueData?.queue?.isGeneralTrunk}
                                            placeholder="Оберіть номер"
                                        />
                                    </div>
                                    <div className="field col-12 md:col-1">
                                        {selectedPairs.length > 1 && <Button icon="pi pi-times" className="p-button-secondary"
                                                disabled={!archiveTableToggle || !editType || selectedPairs.length < 1 || !queueData?.queue?.isGeneralTrunk}
                                                onClick={() => removePair(index)}/>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="grid col-12 md:col-6"></div>
                        <div className="grid col-12 md:col-6 pt-0">
                            {errorFields?.queueTranks && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2"><span className="title p-error text-sm">{errorFields?.queueTranks?.queueTranks}*</span></div>}
                        </div>
                    </div>
                    <Button disabled={!archiveTableToggle || !editType || !queueData?.queue?.isGeneralTrunk} icon="pi pi-plus" onClick={() => addPair(null, null)}><label className="pl-2">Додати транк</label></Button>
                </div>
            </div>
        </div>
    )
}

export default QueuesListDialogGeneralSettings
