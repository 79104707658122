import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../../redux/hooks";
import React from "react";
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";
import {Divider} from "primereact/divider";

const UsersListDialogInfo = () => {
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);

    const roleBodyTemplate = (rowData: any) => {
        if (rowData?.roles && Array.isArray(rowData.roles)) {
            return (
                <ul className="m-0 p-0">
                    {rowData?.roles.map((item: any, index: number) => (
                        <li className="pb-1" key={index}>{item.name || '-'}</li>
                    ))}
                </ul>
            );
        }
        if (rowData?.roles && !Array.isArray(rowData.roles)) {
            return (
                <ul className="m-0 p-0">
                        <li className="pb-1">{rowData?.roles.name || '-'}</li>
                </ul>
            );
        }else {
           return <p className="pb-1">-</p>
        }
    }

    const branchBodyTemplate = (rowData: any) => {
        if (rowData?.branchId && branches) {
            const foundBranch = branches.find((branch: any) => branch.id === rowData.branchId);
            return (
                <>
                        <p className="pb-1">{foundBranch?.name || '-'}</p>
                </>
            );
        } else {
            return <p className="pb-1">-</p>

        }
    }

    const ownerBodyTemplate = (rowData: any) => {
        if (rowData?.branchId && branches) {
            const foundBranch = branches.find((branch: any) => branch.id === rowData.branchId);
            return (
                <>
                    <p className="pb-1">{foundBranch?.ownerName || '-'}</p>
                </>
            );
        } else {
            return <p className="pb-1">-</p>

        }
    }

    const loginBodyTemplate = (rowData: any) => {
            return (
                <>
                    <p className="pb-1">{rowData?.userName || '-'}</p>
                </>
            );
    }

    const renderStatusColumn = (rowData: any) => {
        const statusClass = classNames({
            'p-badge status-unsuccessful': rowData.status === 'Unsuccess',
            'p-badge status-success': !rowData.status
        });
        return (
            <Badge value={rowData.status ? rowData.status : 'active'} className={`${statusClass} text-white customer-badge w-6 text-xs p-0 mr-4`} style={{borderRadius: '6px', backgroundColor: rowData?.statusColor, minWidth: '124px'}}/>
        );
    };

    return (
        <>
            <div id="pr_id_3_header" className="p-dialog-title mb-3">Налаштування користувача</div>
            <DataTable value={[userData.user]}>
                <Column field="userName" body={loginBodyTemplate} header="Логін"></Column>
                <Column field="roles" body={roleBodyTemplate} header="Роль"></Column>
                <Column field="status" body={renderStatusColumn} header="Статус" style={{cursor: 'pointer', width: '8%'}}></Column>
                <Column field="branchName" body={branchBodyTemplate} header="Branch"></Column>
                <Column field="ownerName" body={ownerBodyTemplate} header="Керiвник"></Column>
            </DataTable>
            <Divider style={{margin: '0 0 20px 0'}}/>
        </>

    )
}

export default UsersListDialogInfo
