import {
    ADD_QUALIFICATION_GROUP_BRANCH,
    ADD_QUALIFICATION_GROUP_USER, ADD_SKILL_GROUP_BRANCH,
    ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE, CHANGE_QUALIFICATION_GROUP_USER_LEVEL, DELETE_QUALIFICATION_GROUP_USER, DELETE_SKILL_GROUP_BRANCH,
    HIDE_QUALIFICATION_GROUP_DETAILS_LOADER, HIDE_QUALIFICATION_GROUP_LOADER, INITIAL_QUALIFICATION_GROUP_DATA, QUALIFICATION_GROUP_CLEAR_FILTERS,
    QUALIFICATION_GROUP_DIALOG_VISIBLE, QUALIFICATION_GROUP_FIELD,
    SELECTED_QUALIFICATION_GROUP_ID,
    SET_QUALIFICATION_GROUP_CURRENT_PAGE,
    SET_QUALIFICATION_GROUP_CURRENT_SIZE,
    SET_QUALIFICATION_GROUP_ERROR_FIELDS, SET_QUALIFICATION_GROUP_LIST_DATA_SORT,
    SHOW_QUALIFICATION_GROUP_DETAILS_LOADER, SHOW_QUALIFICATION_GROUPS_LIST_LOADER,

} from "../types/typesQualificationGroup";
import {ADD_STATUS_GROUP_BRANCH, DELETE_STATUS_BRANCH} from "../types/typesStatusesList";

export function setCurrentQualificationGroupPage(state: any) {
    return {
        type: SET_QUALIFICATION_GROUP_CURRENT_PAGE,
        payload: state
    }
}

export function setQualificationGroupField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: QUALIFICATION_GROUP_FIELD,
        payload: state,
    }
}

export function setCurrentQualificationGroupPageSize(state: any) {
    return {
        type: SET_QUALIFICATION_GROUP_CURRENT_SIZE,
        payload: state
    }
}

export function setQualificationGroupArchiveTableToggle(state:boolean) {
    return {
        type: ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE,
        payload: state,
    }
}

export function setSelectedQualificationGroupIndex(state:number) {
    return {
        type: SELECTED_QUALIFICATION_GROUP_ID,
        payload: state,
    }
}

export function setQualificationGroupDialogVisible(state:boolean) {
    return {
        type: QUALIFICATION_GROUP_DIALOG_VISIBLE,
        payload: state,
    }
}

export function showQualificationGroupDetailsListLoader() {
    return {
        type: SHOW_QUALIFICATION_GROUP_DETAILS_LOADER,
    }
}

export function showQualificationGroupListLoader() {
    return {
        type: SHOW_QUALIFICATION_GROUPS_LIST_LOADER,
    }
}

export function hideQualificationGroupDetailsLoader() {
    return {
        type: HIDE_QUALIFICATION_GROUP_DETAILS_LOADER,
    }
}

export function setQualificationGroupErrorFields(state:any) {
    return {
        type: SET_QUALIFICATION_GROUP_ERROR_FIELDS,
        payload: state,
    }
}

export function setQualificationGroupListDataSort(state: any) {
    return {
        type: SET_QUALIFICATION_GROUP_LIST_DATA_SORT,
        payload: state
    }
}

export function initialQualificationGroupData() {
    return {
        type: INITIAL_QUALIFICATION_GROUP_DATA,
    }
}

export function hideQualificationGroupListLoader() {
    return {
        type: HIDE_QUALIFICATION_GROUP_LOADER,
    }
}

export function setClearFilters() {
    return {
        type: QUALIFICATION_GROUP_CLEAR_FILTERS,
    }
}

export function addQualificationGroupUser(state: {campaignId:string, userId:any}) {
    return {
        type: ADD_QUALIFICATION_GROUP_USER,
        payload: state,
    }
}

export function addQualificationGroupBranch(state: {campaignId:string, id:any}) {
    return {
        type: ADD_QUALIFICATION_GROUP_BRANCH,
        payload: state,
    }
}

export function deleteQualificationGroupUser(state: string) {
    return {
        type: DELETE_QUALIFICATION_GROUP_USER,
        payload: state,
    }
}

export function changeQualificationGroupUserLevel(state: {campaignId:string, userId: string, userLevel: any}) {
    return {
        type: CHANGE_QUALIFICATION_GROUP_USER_LEVEL,
        payload: state,
    }
}

export function addSkillGroupBranch(state: {skillGroupId :number, id:any}) {
    return {
        type: ADD_SKILL_GROUP_BRANCH,
        payload: state,
    }
}

export function deleteSkillGroupBranch(state: string) {
    return {
        type: DELETE_SKILL_GROUP_BRANCH,
        payload: state,
    }
}
