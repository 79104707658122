import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'primereact/button';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {initialAccountData, setConnectUser} from "../redux/actions/actions";
import {setRolesVisible} from "../redux/actions/actionsRoles";
import {menuConfig} from "../redux/config";
import {stopGlobalJsSIP} from "../components/JsSip/GlobalJsSIP";

export const Access = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;

    const goDashboard = () => {
            const filteredMenuConfigWithRole = menuConfig.filter((item) =>
                rolesVision.hasOwnProperty(item.name)
            );
            if (filteredMenuConfigWithRole.length > 0) {
                navigate(`/${filteredMenuConfigWithRole[0].name.toLowerCase()}`);
            }
    };

    // useEffect(() => {
    //         if (rolesVision && rolesVision.length) {
    //             const filteredMenuConfigWithRole = menuConfig?.filter((item) =>
    //                 rolesVision?.includes(item.name)
    //             );
    //             const targetName = "Dialer";
    //             const index = rolesVision.indexOf(targetName);
    //             if (index !== -1) {
    //                 navigate(`/`);
    //             } else {
    //                 navigate(`/${filteredMenuConfigWithRole[0].name.toLowerCase()}`);
    //             }
    //         }
    // }, [rolesVision]);

    // useEffect(() => {
    //     if (!user?.userId || !rolesVision) {
    //         navigate(`/login`);
    //     }
    // }, [user, rolesVision]);

    const logoutUser = () => {
        stopGlobalJsSIP();
        navigate('/login');
        localStorage.clear();
        dispatch(setConnectUser(false));
        dispatch(initialAccountData());
        dispatch(setRolesVisible(null));
    };

    return (
        <div className="exception-body accessdenied">
            <div className="exception-panel">
                <h1>ACCESS</h1>
                <h3 className="mt-0">denied</h3>
                <p>You are not allowed to view this page.</p>
                <div className="flex align-items-center">
                    {rolesVision ? <Button className="mr-2" type="button" label="Go to available pages" onClick={goDashboard}></Button> : ''}
                    {rolesVision ? <p className="m-0 mt-auto text-lg">or</p>: ''}
                    <Button className="ml-2" type="button" label="Logout" onClick={logoutUser}></Button>
                </div>
            </div>
            <div className="exception-footer">
                <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo" alt="exception-logo"/>
                <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="exception-appname"/>
            </div>
        </div>
    );
};
