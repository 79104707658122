import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {getTrunksDictionary} from "../../../../redux/api/apiRoles";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {setUserField, setUserTrunkIds} from "../../../../redux/actions/actionsUsersList";
import {classNames} from "primereact/utils";
import {getUsersDirectoriesGetTrunksPhones} from "../../../../redux/api/apiUsersList";

const UserPhoneSettings = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const companiesDirectoryTranksPhones = useAppSelector(state => state.UsersListReducer.usersDirectoryTrunksPhone);
    // @ts-ignore
    const trunksDictionary = useAppSelector(state => state.UsersListReducer.userTrunksDictionary);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    // @ts-ignore
    const usersActiveTableToggle = useAppSelector(state => state.UsersListReducer.usersActiveTableToggle);
    // @ts-ignore
    const usersDialogUnzipping = useAppSelector(state => state.UsersListReducer.usersDialogUnzipping);
    const [selectedPairs, setSelectedPairs] = useState<any>([{selectedGateway: null, selectedPhone: null, trunkOrder: 1, trunkType: 501}]);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const gateways = Array.from(new Set(companiesDirectoryTranksPhones?.map((item: any) => item.gatewayName)));
    const emailField = useRef(null);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.UsersListReducer.errorFields);

    useEffect(() => {
        dispatch(getTrunksDictionary(jwtToken?.jwtToken));
        dispatch(getUsersDirectoriesGetTrunksPhones(jwtToken?.jwtToken));
    }, []);

    const displayOrder = (rowData: any) => {
        return <div>{rowData?.trunkOrder}</div>
    }

    const scrollIntoEmailView = () => {
        // @ts-ignore
        emailField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (errorFields?.email) {
            scrollIntoEmailView();
        }
    }, [errorFields, userData]);

    const updateTrunkType = (trunkOrder: any, newTrunkType: any) => {
        setSelectedPairs((currentPairs: any[]) =>
            currentPairs.map(pair =>
                pair.trunkOrder === trunkOrder ? { ...pair, trunkType: newTrunkType } : pair
            )
        );
        updateTrunkName(trunkOrder, null);
        updateTrunkNumber(trunkOrder, null);
    };

    const updateTrunkName = (trunkOrder: any, newTrunkType: any) => {
        setSelectedPairs((currentPairs: any[]) =>
            currentPairs.map(pair =>
                pair.trunkOrder === trunkOrder ? { ...pair, selectedGateway: newTrunkType } : pair
            )
        );
        updateTrunkNumber(trunkOrder, null);
    };

    const updateTrunkNumber = (trunkOrder: any, newTrunkType: any) => {
        setSelectedPairs((currentPairs: any[]) =>
            currentPairs.map(pair =>
                pair.trunkOrder === trunkOrder ? { ...pair, selectedPhone: newTrunkType } : pair
            )
        );
    };

    const trunkType = (rowData: any) => {
        const foundItem = trunksDictionary?.find((item: { dictionaryId: number; }) => item.dictionaryId === rowData?.trunkType);
        return rowData?.saved ? <InputText className="opacity-100 w-full" disabled value={foundItem?.name || ''}/> : <Dropdown disabled={!usersDialogUnzipping || (!usersActiveTableToggle || !editType)} value={rowData?.trunkType} options={trunksDictionary} onChange={(e) => updateTrunkType(rowData?.trunkOrder, e.value)} optionLabel="name" emptyMessage="нічого не знайдено" optionValue="dictionaryId" className="w-full"/>    }

    const trunkName = (rowData: any) => {
        return rowData?.saved ? <InputText className="opacity-100 w-full" disabled value={rowData?.selectedGateway}/> : <Dropdown disabled={!usersDialogUnzipping || (!usersActiveTableToggle || !editType)} value={rowData?.selectedGateway} options={gateways} className={classNames('w-full', {'p-invalid': errorFields?.userTrunks && !rowData?.selectedGateway})} onChange={(e) => updateTrunkName(rowData?.trunkOrder, e.value)} emptyMessage="Немає доступних"/>
    }

    const trunkNumber = (rowData: any) => {
        const selectedPhoneIds = selectedPairs
            ?.map((pair: { selectedPhone: any; }) => pair.selectedPhone)
            ?.filter((phoneId: any) => phoneId !== rowData.selectedPhone);

        const filteredCompanies = companiesDirectoryTranksPhones.filter((company: { id: any; gatewayName: any; isReserved: any; }) =>
            !selectedPhoneIds.includes(company.id) &&
            company.gatewayName === rowData.selectedGateway &&
            (rowData.trunkType !== 502 || !company.isReserved)
        );
        const foundItem = companiesDirectoryTranksPhones?.find((item: { id: number; }) => item.id === rowData?.selectedPhone);
        return rowData?.saved ? <InputText className="opacity-100 w-full" disabled value={foundItem?.phoneNo}/> : <Dropdown  value={rowData?.selectedPhone} options={filteredCompanies} optionValue="id" optionLabel="phoneNo" onChange={(e) => updateTrunkNumber(rowData?.trunkOrder, e.value)}
        className={classNames('w-full', {'p-invalid': errorFields?.userTrunks && !rowData?.selectedPhone})} emptyMessage="Немає доступних" disabled={!usersDialogUnzipping || (!rowData.selectedGateway || !usersActiveTableToggle || !editType)}/>
    }

    const deleteTrunk = (rowData: any) => {
        return <div>
            {selectedPairs?.length > 1 && <div>
                <Button icon="pi pi-times" className="p-button-secondary"
                        disabled={!usersDialogUnzipping || (!usersActiveTableToggle || !editType)}
                        onClick={() => removePair(rowData.trunkOrder)}/>

            </div>}
        </div>
    }

    useEffect(() => {
        if (userData?.user?.userTrunks?.length) {
            setSelectedPairs([]);
        }
    }, [userData?.user?.userTrunks, userData?.user?.id]);

    useEffect(() => {
        if (userData?.user?.userTrunks) {
            userData?.user?.userTrunks?.map((queueTrank: any) => {
                let foundObject = companiesDirectoryTranksPhones?.find((item: { id: any; }) => item.id === queueTrank?.trunkId);
                if (queueTrank?.trunkId === foundObject?.id) {
                    addPair(foundObject?.gatewayName, foundObject?.id, queueTrank?.trunkType, queueTrank?.trunkOrder, true);
                }
            });
        }
    }, [companiesDirectoryTranksPhones, userData?.user?.userTrunks]);

    useEffect(() => {
        // @ts-ignore
        const updatedTrunks = selectedPairs.map(({selectedGateway, ...rest}) => rest);
        const updatedArray = updatedTrunks.map((item: { selectedPhone: any; trunkType: any; trunkOrder: any; }) => {
            return {
                ...item,
                trunkId: item.selectedPhone,
                trunkType: item.trunkType,
                trunkOrder: item.trunkOrder
            };
        });
        // @ts-ignore
        const cleanedArray = updatedArray.map(({selectedPhone, ...rest}) => rest);
        dispatch(setUserTrunkIds(cleanedArray));
    }, [selectedPairs]);

    const addPair = (selectedGateway: null, selectedPhone: null, trunkType: number, order?: null, saved = false) => {
        setSelectedPairs((prevPairs: any[]) => {
            if (selectedGateway != null && selectedPhone != null) {
                const pairExists = prevPairs.some(pair => pair.selectedGateway === selectedGateway && pair.selectedPhone === selectedPhone);
                if (pairExists) {
                    return prevPairs;
                }
            }

            const newPair = { selectedGateway, selectedPhone, trunkOrder: prevPairs.length + 1, trunkType, saved };
            const newPairs = [...prevPairs, newPair];

            return newPairs.map((pair, index) => ({
                ...pair,
                trunkOrder: index + 1 // Установить trunkOrder как index + 1
            }));
        });
    };

    const removePair = (trunkOrder: any) => {
        setSelectedPairs((prevPairs: any[]) =>
            prevPairs
                .filter((pair: { trunkOrder: any; }) => pair.trunkOrder !== trunkOrder) // Удаление элемента
                .map((pair: any, index: number) => ({ ...pair, trunkOrder: index + 1 })) // Обновление trunkOrder
        );
    };

    const onRowReorder = (e: { dragIndex: number; dropIndex: number; }) => {
        let updatedRows = [...selectedPairs];
        const draggedRow = updatedRows[e.dragIndex];

        updatedRows.splice(e.dragIndex, 1);

        updatedRows.splice(e.dropIndex, 0, draggedRow);

        updatedRows = updatedRows.map((row, index) => ({
            ...row,
            trunkOrder: index+1
        }));
        setSelectedPairs(updatedRows)
    };

    return (
        <div>
            <div className="field col-12 md:col-12 mb-0">
                <div>
                    <label htmlFor="name" className="font-bold text-base mb-0">Контакти :</label>
                </div>
            </div>
            <DataTable value={selectedPairs} reorderableColumns reorderableRows onRowReorder={onRowReorder} className="w-full">
                <Column rowReorder style={{width: '3rem'}}/>
                <Column body={displayOrder} style={{width: '3rem'}}/>
                <Column body={trunkType} style={{width: '15rem'}}/>
                <Column body={trunkName} style={{width: '15rem'}}/>
                <Column body={trunkNumber} style={{width: '15rem'}}/>
                <Column body={deleteTrunk} style={{width: '15rem'}}/>
            </DataTable>
            <div className="field col-12 md:col-4" style={{maxWidth: '150px'}}>
                <Button disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))} icon="pi pi-plus" onClick={() => addPair(null, null, 501, null)}><label className="pl-2">Додати транк</label></Button>
            </div>
            <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            <div className="field col-12 md:col-3 flex flex-column " ref={emailField}>
                <label htmlFor="address" className={classNames('', {'p-error': errorFields?.email})}>Email*</label>
                <InputText
                    id="address"
                    value={userData?.user?.email || ''}
                    className={classNames('', {'p-invalid': errorFields?.email})}
                    onChange={e => {
                        dispatch(setUserField({section: 'user', fieldName: 'email', value: e.target.value}));
                    }}
                    disabled={!usersDialogUnzipping || (!usersActiveTableToggle || (!editType))}

                />
                {errorFields && errorFields?.email &&  <label htmlFor="address" className={classNames('mt-2 mt-2 text-xs inline', {'p-error': errorFields?.email})}>{errorFields?.email?.email}</label>}
            </div>
        </div>
    );
};

export default UserPhoneSettings;
