import {classNames} from "primereact/utils";
import {ProgressBar} from "primereact/progressbar";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../redux/hooks";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {Divider} from "primereact/divider";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const CallsStatisticsDetails = (props: any) => {
    const statisticCallsData = useAppSelector(state => state.StatisticsCallsListReducer.statisticCallsData);
    const detailsLoading = useAppSelector(state => state.StatisticsListReducer.detailsLoading);

    const convertMillisecondsToTime = (totalMilliseconds: number): string => {
        const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        const remainingMillisecondsForSeconds = totalMilliseconds % (1000 * 60);
        const seconds = remainingMillisecondsForSeconds >= 500
            ? Math.ceil(remainingMillisecondsForSeconds / 1000)
            : Math.floor(remainingMillisecondsForSeconds / 1000);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const durationBodyTemplate = (count: any, duration: any, AVG: any) => {
        return <div className="flex flex-wrap col-12 px-0">
            <div className="field col-4 md:col-4  px-0 flex">Кількість</div>
            <div className="field col-4 md:col-4 px-0 flex ">Тривалість</div>
            <div className="field col-4 md:col-4 px-0 flex ">Середня</div>

            <div className="field col-4 md:col-4 px-0 flex ">{count}</div>
            <div className="field col-4 md:col-4 px-0 flex ">{duration}</div>
            <div className="field col-4 md:col-4 px-0 flex ">{AVG}</div>

            <Divider className="m-0"/>
        </div>
    }

    const fields = [
        {label: "Всього дзвінків", value: null},
        {label: null, value: durationBodyTemplate(statisticCallsData?.allCountUserCalls || '0',statisticCallsData?.allUserFullduration ? convertMillisecondsToTime(statisticCallsData?.allUserFullduration) : '00:00:00',statisticCallsData?.allUserAVGduration ? convertMillisecondsToTime(statisticCallsData?.allUserAVGduration) : '00:00:00')},

        {label: "Вхідні успішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.internalSuccessCount || '0',statisticCallsData?.internalSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},


        {label: "Вхідні неуспішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.internalUnsuccessCount || '0',statisticCallsData?.internalUnsuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.internalUnsuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},


        {label: "Вихідні успішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.externalSuccessCount || '0',statisticCallsData?.externalSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.externalSuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},

        {label: "Вихідні неуспішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.externalUnsuccessCount || '0',statisticCallsData?.externalUnsuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.externalUnsuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},

        {label: "Кампанії успішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.campaignSuccessCount || '0',statisticCallsData?.campaignSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.campaignSuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},

        {label: "Кампанії неуспішні", value: ''},
        {label: null, value: durationBodyTemplate(statisticCallsData?.campaignUnsuccessCount || '0',statisticCallsData?.campaignUnsuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.campaignUnsuccessFullduration) : '00:00:00',statisticCallsData?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '00:00:00')},

    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headerTitleDurationBlock = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>Тривалість</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>Середня</div>
        </div>
    }

    const headerTitleAllUser = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.allUserFullduration ? convertMillisecondsToTime(statisticCallsData?.allUserFullduration) : '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.allUserAVGduration ? convertMillisecondsToTime(statisticCallsData?.allUserAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleAllInternal = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.internalSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.internalSuccessFullduration) : '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.internalSuccessAVGduration ?convertMillisecondsToTime(statisticCallsData?.internalSuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleAllInternalUnsuccess = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.internalUnsuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.internalUnsuccessFullduration) : '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.internalUnsuccessAVGduration ?convertMillisecondsToTime(statisticCallsData?.internalUnsuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleExternalSuccess = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.externalSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.externalSuccessFullduration) : '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.externalSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.externalSuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleExternalUnsuccess = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.externalUnsuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.externalUnsuccessFullduration) : '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.externalUnsuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.externalUnsuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleCampaignSuccess = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.campaignSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.campaignSuccessFullduration): '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.campaignSuccessAVGduration ? convertMillisecondsToTime(statisticCallsData?.campaignSuccessAVGduration): '- - -'}</div>
        </div>
    }

    const headerTitleCampaignUnsuccess = () => {
        return <div className="flex">
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.campaignSuccessFullduration ? convertMillisecondsToTime(statisticCallsData?.campaignSuccessFullduration): '- - -'}</div>
            <div style={{ width: '100px', minWidth: '100px', maxWidth: '100px'}}>{statisticCallsData?.campaignUnsuccessAVGduration ?convertMillisecondsToTime(statisticCallsData?.campaignUnsuccessAVGduration): '- - -'}</div>
        </div>
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Всього дзвінків" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>

                <Column header="Вхідні успішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
                <Column header="Вхідні неуспішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
                <Column header="Вихідні успішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
                <Column header="Вихідні неуспішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
                <Column header="Кампанії успішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
                <Column header="Кампанії неуспішні" headerStyle={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}/>
            </Row>
            <Row>
                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

                <Column header={headerTitleDurationBlock}/>

            </Row>
            <Row>
                <Column header={headerTitleAllUser}/>
                <Column header={headerTitleAllInternal}/>
                <Column header={headerTitleAllInternalUnsuccess}/>
                <Column header={headerTitleExternalSuccess}/>
                <Column header={headerTitleExternalUnsuccess}/>
                <Column header={headerTitleCampaignSuccess}/>
                <Column header={headerTitleCampaignUnsuccess}/>
            </Row>
        </ColumnGroup>
    );

    return (
        <div className="relative">
            {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
            <div className={classNames('grid', {'opacity-50': detailsLoading})}
                 style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                {windowWidth > 1248 &&
                    <div className="grid col-12 md:col-12 mb-0 px-0 w-full justify-content-between">
                        <div className="absolute top-0 right-0 pr-2">
                            <button className="close-button block cursor-pointer ml-auto"
                                    onClick={() => {
                                        props.setSelectedRowData(null);
                                        props.setSelectedRowExpansion(null);
                                    }
                                    }><span className="pi pi-times"></span>
                            </button>
                        </div>
                        <div className="field col-12 md:col-10 flex align-items-start m-0 pb-3 pl-0">
                            <div className="field col-12 md:col-12 flex m-0 py-0 flex-column">
                                {statisticCallsData.pathSegment ?
                                    <a target="_blank" className="mb-2" href={`${statisticCallsData.pathSegment}`}>{statisticCallsData.contactName}</a> :
                                    <p className="m-0 mb-2">{statisticCallsData.contactName}</p>}
                                <div className="flex align-items-center pl-2">
                                    <div className="mr-2">{statisticCallsData?.fullName}</div>
                                </div>
                            </div>
                        </div>
                        <Divider className="m-0 ml-2"/>
                        <div className="field col-12 flex flex-wrap mb-0">
                            <div style={{ width: '215px', minWidth: '215px', maxWidth: '215px' }} className="field px-2 flex align-items-center m-0 pb-3">Всього дзвінків : {statisticCallsData?.allCountUserCalls|| '0'}</div>
                            <div style={{ width: '215px', minWidth: '215px', maxWidth: '215px' }} className="field px-2 flex align-items-center m-0 pb-3">Вхідні успішні: {statisticCallsData?.internalSuccessCount || '0'}</div>
                            <div style={{ width: '215px', minWidth: '215px', maxWidth: '215px' }} className="field px-2 flex align-items-center m-0 pb-3">Вхідні неуспішні: {statisticCallsData?.internalUnsuccessCount || '0'}</div>
                            <div style={{ width: '215px', minWidth: '215px', maxWidth: '215px' }} className="field px-2 flex align-items-center m-0 pb-3">Вихідні успішні: {statisticCallsData?.externalSuccessCount || '0'}</div>
                            <div style={{ width: '215px', minWidth: '215px', maxWidth: '215px' }} className="field px-2 flex align-items-center m-0 pb-3">Вихідні неуспішні: {statisticCallsData?.externalUnsuccessCount || '0'}</div>
                            <div style={{ width: '225px', minWidth: '225px', maxWidth: '225px' }} className="field px-2 flex align-items-center m-0 pb-3">Кампанії успішні: {statisticCallsData?.campaignSuccessCount || '0'}</div>
                            <div style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }} className="field pr-2 flex align-items-center m-0 pb-3">Кампанії неуспішні: {statisticCallsData?.campaignUnsuccessCount || '0'}</div>
                        </div>

                        <Divider className="m-0 ml-2"/>
                    </div>
                }
                {windowWidth <= 1248 && (statisticCallsData.contactName || statisticCallsData.pathSegment) &&
                    <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            {statisticCallsData.pathSegment ?
                                <a target="_blank" className="mb-2" href={`${statisticCallsData.pathSegment}`}>{statisticCallsData.contactName}</a> :
                                <p className="m-0 mb-2">{statisticCallsData.contactName}</p>}
                        </div>
                        {(statisticCallsData.contactName || statisticCallsData.pathSegment) && (
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                {windowWidth > 1248 && <React.Fragment>
                    <DataTable
                        headerColumnGroup={headerGroup}
                        className="statisticksDetailTable col-12 md:col-12 pl-0 py-0" value={[statisticCallsData]}
                        scrollable
                    >
                    </DataTable>
                </React.Fragment>}
                {windowWidth <= 1248 && (
                    <React.Fragment>
                        {fields.map((field: any) => (
                            <React.Fragment key={field.label}>
                                <div className={`field col-12 md:col-12 align-items-center m-0 ${!field.label && 'hidden'}`}>
                                    <p>{field.label}</p>
                                </div>
                                <div className={`field col-12 md:col-12 align-items-center m-0 ${!field.value && 'hidden'}`}>
                                    {field.value}
                                </div>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default CallsStatisticsDetails;
