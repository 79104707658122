import {hideError, showError} from "../actions/actions";
import axios from "axios";
import {API_URL} from "../config";
import {
    GET_NOTIFICATION_ERROR,
} from "../types/types";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";
import {GET_TRUNK, GET_TRUNK_PAGINATION, GET_TRUNKS, SHOW_TRUNK_DETAIL_LOADING} from "../types/typesTrunkList";
import {setTrunkErrorField, showTrunkLoader} from "../actions/actionsTrunk";
import {getBranchDetailById} from "./apiBranchList";


let dataSort = {
    isActive: true,
    pageNumber: 1,
    pageSize: 10,
    sort: {
        orderBy: "Id",
        isDesc: true
    }
}

export function getAllTrunksWithPagination(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showTrunkLoader(true))
        dispatch(hideError())
        axios.post(`${API_URL}Trunks/GetTrunksWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dataSort = data;
                if (data.isArchived === false) {
                    dispatch({type: GET_TRUNKS, payload: response.data.data.items})
                } else {
                    dispatch({type: GET_TRUNKS, payload: response.data.data.items})
                }
                dispatch({type: GET_TRUNK_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showTrunkLoader(false));
                dispatch(hideError());
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getTrunkById(id: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: SHOW_TRUNK_DETAIL_LOADING, payload: true});
        dispatch(hideError());
        axios.get(`${API_URL}Trunks/GetTrunkById?trunkId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_TRUNK, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SHOW_TRUNK_DETAIL_LOADING, payload: false});
            })
    }
}

export function createTrunk(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setTrunkErrorField(null));
        dispatch(hideError());
        axios.post(`${API_URL}Trunks/CreateTrunk`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_TRUNK, payload: response.data.data})
                    dispatch(getAllTrunksWithPagination(dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
                if (response.data.statusCode === 400) {
                    let errorMessage = {"nameUniq": "Назва транка вже існує"};
                    dispatch(setTrunkErrorField(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function updateTrunk(data: any, jwtToken: any, branchId?: number | null) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setTrunkErrorField(null));
        dispatch(hideError());
        axios.post(`${API_URL}Trunks/UpdateTrunk`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_TRUNK, payload: response.data.data})
                    dispatch(getAllTrunksWithPagination(dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    if (branchId) {
                        dispatch(getBranchDetailById(branchId, jwtToken));
                    }
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
                if (response.data.statusCode === 400) {
                    let errorMessage = {"nameUniq": "Назва транка вже існує"};
                    dispatch(setTrunkErrorField(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}
//
// export function getQueueDirectoriesQueueType(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError());
//         axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=200`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_QUEUE_DIRECTORIES_QUEUE_TYPE, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError());
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }
//
// export function getQueueDirectoriesQueueOperatorsType(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError());
//         axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=300`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_QUEUE_DIRECTORIES_QUEUE_OPERATORS_TYPE, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError())
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }
//
// export function setArchiveQueueById(id: number, jwtToken: any, sort?: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(setUserError(false));
//         axios.post(`${API_URL}Queues/ArchiveOrUnArchiveQueue?queueId=${id}`, {}, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`,
//             }
//         })
//             .then((response) => {
//                 if (response.data.statusCode === 200) {
//                     dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
//                     dispatch(getAllQueuesWithPagination(sort || dataSort, jwtToken));
//                     dispatch(setStatusDialogVisible(false));
//                 } else {
//                     // dispatch(getAllQueuesWithPagination(sort || dataSort, user));
//                     dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
//                 }
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
//                     return dispatch(setUserError(true))
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//                 dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
//                 dispatch({type: SET_USER_STATUS_ERROR, payload: null});
//             })
//     }
// }

export function deleteTrunkPhoneById(id: number, phoneId: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.delete(`${API_URL}Trunks/DeleteTrunkPhoneById?trunkId=${phoneId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getTrunkById(id, jwtToken));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function setStateTrunkById(id: number, phoneId: number, isActive: boolean, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}Trunks/ArchiveOrUnArchiveQueue?queueId=${id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getTrunkById(id, jwtToken));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}


//
// export function getQueueDirectoriesQueueAudioType(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError());
//         axios.get(`${API_URL}Queues/GetAllAudio?isActive=true`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_QUEUE_DIRECTORIES_QUEUE_AUDIO_TYPE, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError())
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }
// export function getQueueDirectoriesQueueDirectionsType(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError());
//         axios.get(`${API_URL}VoipDictionary/GetDictionary/true?parentId=500`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_QUEUE_DIRECTORIES_QUEUE_DIRECTIONS_TYPE, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError())
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }
//
// export function copyQueueById(queueId: number, jwtToken: any, sort?: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError());
//         axios.get(`${API_URL}Queues/CopyQueue/${queueId}`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 if (response.data.statusCode === 200) {
//                     dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
//                     dispatch(getAllQueuesWithPagination(sort || dataSort, jwtToken));
//                 } else {
//                     dispatch(getAllQueuesWithPagination(sort || dataSort, jwtToken));
//                     dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
//                 }
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError())
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//                 dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
//                 dispatch({type: SET_USER_STATUS_ERROR, payload: null});
//             })
//     }
// }
//
// export function getQueueDirectoriesGetTranksPhones(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError())
//         dispatch(hideErrorMessage())
//         dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: false})
//         axios.get(`${API_URL}VoipDictionary/GetTrunksPhones`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_QUEUE_DIRECTORIES_TRANKS_PHONE, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     return dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: true})
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }
//
// export function getAllQueues(jwtToken: any) {
//     return (dispatch: (arg0: any) => void) => {
//         dispatch(hideError())
//         dispatch(hideErrorMessage())
//         axios.get(`${API_URL}Queues/GetAllQueues?isArchived=false&isActive=true`, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${jwtToken}`
//             }
//         })
//             .then((response) => {
//                 dispatch({type: GET_ALL_QUEUES, payload: response.data.data})
//             })
//             .catch((e) => {
//                 if (e?.response?.status === 401) {
//                     return dispatch(showAccessDeniedNotifications(true));
//                 } else {
//                     return dispatch(showError());
//                 }
//             })
//             .finally(() => {
//                 dispatch(showAccessDeniedNotifications(false));
//             })
//     }
// }


