export const GET_CALLS_STATISTICS_USER_SORT_FIELDS = 'GET_CALLS_STATISTICS_USER_SORT_FIELDS'
export const CLEAR_CALLS_STATISTICS_FILTERS = 'CLEAR_CALLS_STATISTICS_FILTERS'
export const SELECTED_CALLS_STATISTICS_ID = 'SELECTED_CALLS_STATISTICS_ID'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const INITIAL_CALLS_STATISTICS_DATA = 'INITIAL_CALLS_STATISTICS_DATA'
export const GET_CALLS_STATISTICS = 'GET_CALLS_STATISTICS'
export const GET_CALL_STATISTIC = 'GET_CALL_STATISTIC'
export const GET_CALLS_STATISTICS_PAGINATION = 'GET_CALLS_STATISTICS_PAGINATION'
export const SET_CURRENT_CALLS_STATISTICS_PAGE = 'SET_CURRENT_CALLS_STATISTICS_PAGE'
export const SET_CURRENT_CALLS_STATISTICS_SIZE = 'SET_CURRENT_CALLS_STATISTICS_SIZE'
export const SET_CALLS_STATISTICS_LOADING = 'SET_CALLS_STATISTICS_LOADING'
export const SHOW_DETAIL_LOADING = 'SHOW_DETAIL_LOADING'
export const SHOW_EXPORT_CALLS_STATISTICS_LOADING = 'SHOW_EXPORT_CALLS_STATISTICS_LOADING'
export const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
export const SET_CALLS_STATISTICS_FILTER = 'SET_CALLS_STATISTICS_FILTER';
export const GET_STATISTIC_SORT_FIELDS = 'GET_STATISTIC_SORT_FIELDS';
export const UPDATE_STATISTIC_SORT_FIELDS = 'UPDATE_STATISTIC_SORT_FIELDS';
