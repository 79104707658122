import {classNames} from "primereact/utils";
import {ProgressBar} from "primereact/progressbar";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../redux/hooks";
import {Divider} from "primereact/divider";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const StatusStatisticsDetails = (props: any) => {
    const statisticStatusData = useAppSelector(state => state.StatisticsStatusListReducer.statisticStatusData);
    const detailsLoading = useAppSelector(state => state.StatisticsListReducer.detailsLoading);

    const convertSecondsToTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        const remainingSeconds = totalSeconds % 60;
        const seconds = remainingSeconds >= 0.5 ? Math.ceil(remainingSeconds) : Math.floor(remainingSeconds);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const durationBodyTemplate = (count: any, duration: any, AVG: any) => {
        return <div className="flex flex-wrap col-12 px-0">
            <div className="field col-4 md:col-4  px-0 flex">Кількість</div>
            <div className="field col-4 md:col-4 px-0 flex ">Тривалість</div>
            <div className="field col-4 md:col-4 px-0 flex ">Середня</div>

            <div className="field col-4 md:col-4 px-0 flex ">{count}</div>
            <div className="field col-4 md:col-4 px-0 flex ">{duration}</div>
            <div className="field col-4 md:col-4 px-0 flex ">{AVG}</div>

            <Divider className="m-0"/>
        </div>
    }


    const mapFields = statisticStatusData?.statuses?.length
        ? statisticStatusData.statuses.flatMap((item: any) =>
            item.statusCount
                ? [
                    {
                        label: item.statusName,
                        value: ''
                    },
                    {
                        label: null,
                        value: durationBodyTemplate(
                            item.statusCount,
                            item.timeInStatus ? convertSecondsToTime(item.timeInStatus) : '00:00:00',
                            item.avgTimePerStatus ? convertSecondsToTime(item.avgTimePerStatus) : '00:00:00'
                        )
                    },
                ]
                : []
        )
        : [];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headerTitleStatuses = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>Тривалість
            </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>Середня
            </div>
        </div>
    }

    const headerStatusesValue = (statusCount: any, timeInStatus: any, avgTimePerStatus: any) => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{timeInStatus ? convertSecondsToTime(timeInStatus) : '00:00:00'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{avgTimePerStatus ? convertSecondsToTime(avgTimePerStatus) : '00:00:00'}</div>
        </div>
    }

    const bodyStatusesValue = (statusName: any, statusCount: any, index: number) => {
        return <div className="flex" key={index}>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statusName} :
            </div>
            <div
                style={{width: '60px', minWidth: '60px', maxWidth: '60px'}}>{statusCount || '0'}</div>
        </div>
    }

    const headerGroup = () => {
        return (<ColumnGroup>
                <Row>
                    {statisticStatusData?.statuses?.length && statisticStatusData?.statuses?.map((item: any, index: number) => {
                        if (item?.statusCount) {
                            return (
                                <Column key={index} header={item.statusName}
                                        headerStyle={{width: '240px', minWidth: '240px', maxWidth: '240px'}}/>
                            )
                        }
                    })}
                </Row>
                <Row>
                    {statisticStatusData?.statuses?.length && statisticStatusData?.statuses?.map((item: any, index: number) => {
                        if (item?.statusCount) {
                            return (
                                <Column key={index}
                                        header={headerTitleStatuses}/>
                            )
                        }
                    })}
                </Row>
                <Row>
                    {/*<Column className="column-border-none" header="" style={{borderRight: '1px solid grey'}}/>*/}
                    {statisticStatusData?.statuses?.length && statisticStatusData?.statuses?.map((item: any, index: number) => {
                        if (item?.statusCount) {
                            return (
                                <Column key={index}
                                        header={headerStatusesValue(item?.statusCount, item?.timeInStatus, item?.avgTimePerStatus)}/>
                            )
                        }
                    })}
                </Row>
            </ColumnGroup>
        )
    };

    return (
        <div className="relative">
            {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
            <div className={classNames('grid', {'opacity-50': detailsLoading})}
                 style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                {windowWidth > 1248 &&
                    <div className="grid col-12 md:col-12 mb-0 px-0 w-full justify-content-between">
                        <div className="absolute top-0 right-0 pr-2">
                            <button className="close-button block cursor-pointer ml-auto"
                                    onClick={() => {
                                        props.setSelectedRowData(null);
                                        props.setSelectedRowExpansion(null);
                                    }
                                    }><span className="pi pi-times"></span>
                            </button>
                        </div>
                        <div className="field col-12 md:col-10 flex align-items-start m-0 pb-3 pl-0">
                            <div className="field col-12 md:col-12 flex m-0 py-0 flex-column">
                                {statisticStatusData.pathSegment ?
                                    <a target="_blank" className="mb-2"
                                       href={`${statisticStatusData.pathSegment}`}>{statisticStatusData.contactName}</a> :
                                    <p className="m-0 mb-2">{statisticStatusData.contactName}</p>}
                                <div className="flex align-items-center pl-2">
                                    <div className="mr-2">{statisticStatusData?.fullName}</div>

                                </div>
                            </div>
                        </div>
                        <Divider className="mt-0 ml-2 mb-1"/>
                        <div className="field col-12 md:col-10 flex align-items-start m-0 pb-3 pl-0">
                            <div className="field col-12 md:col-12 flex m-0 py-0 pl-3">
                                <div style={{width: '140px', minWidth: '140px', maxWidth: '140px'}}>Поточний статус:
                                </div>
                                <div
                                    style={{width: '140px', minWidth: '140px', maxWidth: '140px'}}>{statisticStatusData?.now?.statusName}</div>
                                <div
                                    style={{width: '140px', minWidth: '140px', maxWidth: '140px'}}>{statisticStatusData?.now?.timeInStatus ? convertSecondsToTime(statisticStatusData?.now?.timeInStatus) : '00:00:00'}</div>
                            </div>
                        </div>
                        <Divider className="m-0 ml-2"/>
                        <p className="pl-3 mt-2">Кількість переходів в статуси:</p>
                        <div className="field col-12 flex flex-wrap mb-0 pl-3">
                            {statisticStatusData?.statuses?.length && statisticStatusData?.statuses?.map((item: any, index: number) => {
                                if (item?.statusCount) {
                                    return (
                                        bodyStatusesValue(item?.statusName, item?.statusCount, index)
                                    )
                                }
                            })}
                        </div>

                        <Divider className="m-0 ml-2"/>
                    </div>
                }
                {windowWidth <= 1248 && (statisticStatusData.contactName || statisticStatusData.pathSegment) &&
                    <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            {statisticStatusData.pathSegment ?
                                <a target="_blank" className="mb-2"
                                   href={`${statisticStatusData.pathSegment}`}>{statisticStatusData.contactName}</a> :
                                <p className="m-0 mb-2">{statisticStatusData.contactName}</p>}
                        </div>
                        {(statisticStatusData.contactName || statisticStatusData.pathSegment) && (
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                {windowWidth > 1248 && <React.Fragment>
                    <DataTable
                        headerColumnGroup={headerGroup()}
                        className="statisticksDetailTable col-12 md:col-12 pl-0 py-0" value={[statisticStatusData]}
                        scrollable
                    >
                    </DataTable>
                </React.Fragment>}
                {windowWidth <= 1248 && (
                    <React.Fragment>
                        <div className={`field flex col-12 md:col-12 align-items-center m-0`}>
                            <div className={`field flex col-6 md:col-6 align-items-center m-0 p-0`}>
                                <p className="m-0">Поточний статус:</p>
                            </div>
                            <p className="m-0">{statisticStatusData?.now?.statusName}</p>
                        </div>

                        <div className="field flex flex-wrap col-12 md:col-12 align-items-center m-0">
                            <div className="field col-6 md:col-6 px-0 flex mb-0">Тривалість</div>
                            <div className="field col-6 md:col-6 px-0 flex mb-0">Середня</div>
                        </div>
                        <div className="field flex flex-wrap col-12 md:col-12 align-items-center m-0">
                            <div className="field col-6 md:col-6 px-0 flex mb-0">{statisticStatusData?.now?.timeInStatus ? convertSecondsToTime(statisticStatusData?.now?.timeInStatus) : '00:00:00'}</div>
                            <div className="field col-6 md:col-6 px-0 flex mb-0">{statisticStatusData?.now?.avgTimePerStatus ? convertSecondsToTime(statisticStatusData?.now?.avgTimePerStatus) : '00:00:00'}</div>
                        </div>
                        <Divider/>
                    </React.Fragment>
                )}
                {windowWidth <= 1248 && (
                    <React.Fragment>
                        {mapFields?.map((field: any, index: number) => (
                            <React.Fragment key={index}>
                                <div
                                    className={`field col-12 md:col-12 align-items-center m-0 ${!field.label && 'hidden'}`}>
                                    <p>{field.label}</p>
                                </div>
                                <div
                                    className={`field col-12 md:col-12 align-items-center m-0 ${!field.value && 'hidden'}`}>
                                    {field.value}
                                </div>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default StatusStatisticsDetails;
