import axios from "axios";
import {API_URL} from "../config";
import {getItem, setItem} from "../cache/cacheService";
import {getNotificationMessageError, getNotificationsMessage} from "../actions/actionsNotifications";

export function getNotificationMessage(userId: any, code: number, user: any, callback?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(getNotificationMessageError(false));
        axios.get(`${API_URL}Notification/LangNotification/${userId}/${code}`, {
            headers: {
                'Content-Type': 'application/json',
                "x-access-userid": user.userId
            }
        })
            .then((response) => {
                setItem(`notification_code:${response.data.code}`, response.data.text, 5)
                dispatch(getNotificationsMessage(response.data));
            })
            .catch(() => {
                dispatch(getNotificationMessageError(true));
            }).finally(() => {
            if (callback) {
                callback(getItem(`notification_code:${code}`));
            }
        });
    }
}
