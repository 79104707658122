export const SET_QUALIFICATION_GROUP_CURRENT_PAGE = 'SET_STATUS_CURRENT_PAGE';
export const SET_QUALIFICATION_GROUP_CURRENT_SIZE = 'SET_STATUS_CURRENT_SIZE';
export const ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE = 'ARCHIVE_QUALIFICATION_GROUP_TABLE_TOGGLE';
export const SET_QUALIFICATION_GROUP_LIST_DATA_SORT = 'SET_QUALIFICATION_GROUP_LIST_DATA_SORT';
export const QUALIFICATION_GROUP_FIELD = 'QUALIFICATION_GROUP_FIELD';
export const INITIAL_QUALIFICATION_GROUP_DATA = 'INITIAL_QUALIFICATION_GROUP_DATA';
export const SET_QUALIFICATION_GROUP_ERROR_FIELDS = 'SET_QUALIFICATION_GROUP_ERROR_FIELDS';
export const GET_QUALIFICATION_GROUP_PAGINATION = 'GET_QUALIFICATION_GROUP_PAGINATION';
export const HIDE_QUALIFICATION_GROUP_DETAILS_LOADER = 'HIDE_QUALIFICATION_GROUP_DETAILS_LOADER';
export const SHOW_QUALIFICATION_GROUPS_LIST_LOADER = 'SHOW_QUALIFICATION_GROUPS_LIST_LOADER';
export const HIDE_QUALIFICATION_GROUP_LOADER = 'HIDE_QUALIFICATION_GROUP_LOADER';
export const SHOW_QUALIFICATION_GROUP_DETAILS_LOADER = 'SHOW_QUALIFICATION_GROUP_DETAILS_LOADER';
export const QUALIFICATION_GROUP_DIALOG_VISIBLE = 'QUALIFICATION_GROUP_DIALOG_VISIBLE';
export const SHOW_QUALIFICATION_GROUP_ERROR = 'SHOW_QUALIFICATION_GROUP_ERROR';
export const SELECTED_QUALIFICATION_GROUP_ID = 'SELECTED_QUALIFICATION_GROUP_ID';
export const GET_QUALIFICATION_GROUP_DETAILS = 'GET_QUALIFICATION_GROUP_DETAILS';
export const GET_QUALIFICATION_GROUP_LIST = 'GET_QUALIFICATION_GROUP_LIST';
export const QUALIFICATION_GROUP_CLEAR_FILTERS = 'QUALIFICATION_GROUP_CLEAR_FILTERS';
export const GET_USERS = 'GET_USERS';
export const ADD_QUALIFICATION_GROUP_USER = 'ADD_QUALIFICATION_GROUP_USER';
export const DELETE_QUALIFICATION_GROUP_USER = 'DELETE_QUALIFICATION_GROUP_USER';
export const CHANGE_QUALIFICATION_GROUP_USER_LEVEL = 'CHANGE_QUALIFICATION_GROUP_USER_LEVEL';
export const GET_SKILL_GROUP_LIST = 'GET_SKILL_GROUP_LIST';
export const ADD_QUALIFICATION_GROUP_BRANCH = 'ADD_QUALIFICATION_GROUP_BRANCH';
export const ADD_SKILL_GROUP_BRANCH = "ADD_SKILL_GROUP_BRANCH";
export const DELETE_SKILL_GROUP_BRANCH = "DELETE_SKILL_GROUP_BRANCH";
