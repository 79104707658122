import JsSIP from "jssip";
import { UAConfiguration } from "jssip/lib/UA";

let globalUA: JsSIP.UA | any = null;

export const initGlobalJsSIP = (config: UAConfiguration) => {
    if (!globalUA) {
        globalUA = new JsSIP.UA(config);
        globalUA.start();
    }
};

export const getGlobalJsSIP = () => globalUA;

export const stopGlobalJsSIP = () => {
    if (globalUA) {
        globalUA.stop();
        globalUA.removeAllListeners();
        globalUA = null;
    }
};

export  const changeSession = () => {
    localStorage.setItem('callCentreActiveSession', `${new Date()}`);
};
