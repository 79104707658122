export const GET_STATUS_STATISTICS_USER_SORT_FIELDS = 'GET_STATUS_STATISTICS_USER_SORT_FIELDS'
export const CLEAR_STATUS_STATISTICS_FILTERS = 'CLEAR_STATUS_STATISTICS_FILTERS'
export const SELECTED_STATUS_STATISTICS_ID = 'SELECTED_STATUS_STATISTICS_ID'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const INITIAL_STATUS_STATISTICS_DATA = 'INITIAL_STATUS_STATISTICS_DATA'
export const GET_STATUS_STATISTICS = 'GET_STATUS_STATISTICS'
export const GET_STATUS_STATISTIC = 'GET_CALL_STATISTIC'
export const GET_STATUS_STATISTICS_PAGINATION = 'GET_STATUS_STATISTICS_PAGINATION'
export const SET_CURRENT_STATUS_STATISTICS_PAGE = 'SET_CURRENT_STATUS_STATISTICS_PAGE'
export const SET_CURRENT_STATUS_STATISTICS_SIZE = 'SET_CURRENT_STATUS_STATISTICS_SIZE'
export const SET_STATUS_STATISTICS_LOADING = 'SET_STATUS_STATISTICS_LOADING'
export const SHOW_DETAIL_LOADING = 'SHOW_DETAIL_LOADING'
export const SHOW_EXPORT_STATUS_STATISTICS_LOADING = 'SHOW_EXPORT_STATUS_STATISTICS_LOADING'
export const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
export const SET_STATUS_STATISTICS_FILTER = 'SET_STATUS_STATISTICS_FILTER';
export const GET_STATISTIC_SORT_FIELDS = 'GET_STATISTIC_SORT_FIELDS';
export const UPDATE_STATISTIC_SORT_FIELDS = 'UPDATE_STATISTIC_SORT_FIELDS';
