import {FilterMatchMode, FilterOperator} from "primereact/api";
import {
    ADD_MONITORING_OBJECT,
    ADD_TO_MONITORING_LIST,
    CHANGED_MONITORING_DATA,
    CLEAR_FILTERS,
    CLEAR_MONITORING_FOR_SIP,
    GET_AUDIO_URL,
    GET_CALL_RESET_DICTIONARY,
    GET_CAMPAIGN_NAME_LIST,
    GET_FORCED_CLOSING_DICTIONARY,
    GET_MONITORING,
    GET_MONITORING_FOR_SIP,
    GET_MONITORING_PAGINATION,
    GET_MONITORINGS,
    GET_STATUS_DICTIONARY,
    HIDE_ERROR,
    HIDE_ERROR_MESSAGE,
    HIDE_LOADER,
    HIDE_MONITORING_CREATE_MESSAGE,
    HIDE_MONITORING_SAVE_MESSAGE,
    INITIAL_MONITORING_DATA,
    MONITORING_DIALOG_VISIBLE,
    MONITORING_FIELD,
    MONITORING_RELOAD_TOGGLE,
    MONITORING_STATE_SAVE,
    MONITORING_TABLE_TOGGLE,
    SELECTED_MONITORING_ID,
    SET_CURRENT_MONITORING_PAGE,
    SET_CURRENT_MONITORING_SIZE,
    SET_ERROR_FIELDS,
    SET_FIRST_PAGE, SET_MONITORING_FILTER,
    SHOW_DETAIL_LOADING,
    SHOW_ERROR,
    SHOW_ERROR_FIELD,
    SHOW_ERROR_MESSAGE,
    SHOW_EXPORT_LOADING,
    SHOW_LOADER,
    SHOW_MONITORING_CREATE_MESSAGE,
    SHOW_MONITORING_DELETE_MESSAGE,
    SHOW_MONITORING_SAVE_MESSAGE,
    SHOW_SIP_DETAILS_LOADING,
    SHOW_UPDATED_ERROR
} from "../types/typesMonitoringList";
import {AUDIO_LOADING, UPLOAD_AUDIO_LOADING} from "../types/types";
import {SET_CHECK_USER_FOR_BAN} from "../types/typesUsersList";

const initialState = {
    monitoringTableToggle: 'table1',
    dialogVisible: false,
    autoReload: false,
    selectedMonitoringId: null,
    clearFilters: {
        nameFrom: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        ownerCall: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        fullName: {value: null, matchMode: FilterMatchMode.IN},
        campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
        queueFullName: {value: null, matchMode: FilterMatchMode.IN},
        status: {value: null, matchMode: FilterMatchMode.IN},
        callResetName: {value: null, matchMode: FilterMatchMode.IN},
        amdHangup: {value: null, matchMode: FilterMatchMode.IN},
        typeChannel: {value: null, matchMode: FilterMatchMode.IN},
        direction: {value: null, matchMode: FilterMatchMode.IN},
        campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        start: {value: null, matchMode: FilterMatchMode.IN},
        fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
        duration: {value: null, matchMode: FilterMatchMode.IN},
        reactionClient: {value: null, matchMode: FilterMatchMode.IN},
        reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
        end: {value: null, matchMode: FilterMatchMode.IN},
    },
    monitoringList: [],
    campaignListName: [],
    checkUserForBan: false,
    pagination: {
        hasNext: 0,
        pageNumber: 0,
    },
    paginationPage: 1,
    paginationSize: 10,
    monitoringData: {
        duration: null,
        phoneNo: null,
        contactName: null,
        description: null,
        url: null,
        end: null,
        id: null,
        campaignId: null,
        campaignName: null,
        nameFrom: null,
        nameTo: null,
        status: null,
        typeChannel: null,
        start: null,
        direction: null,
        isDeclined: false,
        username: null,
        hasDetails: false,
        sessionId: null,
        amdHangup: null,
        callReset: null
    },
    monitoringSipData: {
        duration: null,
        phoneNo: null,
        contactName: null,
        description: null,
        url: null,
        end: null,
        id: null,
        campaignId: null,
        campaignName: null,
        nameFrom: null,
        nameTo: null,
        status: null,
        typeChannel: null,
        start: null,
        direction: null,
        isDeclined: false,
        username: null,
        hasDetails: false,
        sessionId: null,
        amdHangup: null,
        callReset: null
    },
    audioUrl: null,
    audioUrlLoading: false,
    audioUrlUploadLoading: false,
    loading: false,
    exportLoading: false,
    detailsLoading: false,
    sipDetailsLoading: false,
    error: false,
    errorUpdate: false,
    errorMessage: null,
    monitoringCreateMessage: false,
    monitoringSaveMessage: false,
    monitoringDeleteMessage: false,
    monitoringStateSave: false,
    errorField: null,
    errorFields: null,
    pageChanged: false,
    statusDictionary: null,
    callResetDictionary: null,
    forcedClosingDictionary: null,
    monitoringFilterData: null
}

export const MonitoringListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MONITORING_TABLE_TOGGLE:
            return {...state, monitoringTableToggle: action.payload}
        case MONITORING_DIALOG_VISIBLE:
            return {...state, dialogVisible: action.payload}
        case SELECTED_MONITORING_ID:
            return {...state, selectedMonitoringId: action.payload}
        case MONITORING_FIELD:
            return {
                ...state, monitoringData:
                    {
                        ...state.monitoringData,
                        [action.payload.section]: {
                            // @ts-ignore
                            ...state.monitoringData[action.payload.section],
                            [action.payload.fieldName]: action.payload.value,
                        }
                    }
            }
        case CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    nameFrom: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    ownerCall: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    nameTo: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    fullName: {value: null, matchMode: FilterMatchMode.IN},
                    campaignFullName: {value: null, matchMode: FilterMatchMode.IN},
                    queueFullName: {value: null, matchMode: FilterMatchMode.IN},
                    status: {value: null, matchMode: FilterMatchMode.IN},
                    callResetName: {value: null, matchMode: FilterMatchMode.IN},
                    amdHangup: {value: null, matchMode: FilterMatchMode.IN},
                    typeChannel: {value: null, matchMode: FilterMatchMode.IN},
                    direction: {value: null, matchMode: FilterMatchMode.IN},
                    campaignId: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    start: {value: null, matchMode: FilterMatchMode.IN},
                    fullCallDuration: {value: null, matchMode: FilterMatchMode.IN},
                    duration: {value: null, matchMode: FilterMatchMode.IN},
                    reactionClient: {value: null, matchMode: FilterMatchMode.IN},
                    reactionOperator: {value: null, matchMode: FilterMatchMode.IN},
                    end: {value: null, matchMode: FilterMatchMode.IN}
                }
            }
        case GET_MONITORINGS:
            return {...state, monitoringList: action.payload}
        case GET_CAMPAIGN_NAME_LIST:
            return {...state, campaignListName: action.payload}
        case GET_MONITORING_PAGINATION:
            return {...state, pagination: {hasNext: action.payload.hasNext, pageNumber: action.payload.pageNumber}}
        case SET_CURRENT_MONITORING_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_CURRENT_MONITORING_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_MONITORING:
            return {...state, monitoringData: action.payload}
        case GET_MONITORING_FOR_SIP:
            return {...state, monitoringSipData: action.payload}
        case CLEAR_MONITORING_FOR_SIP:
            return {...state, monitoringSipData: initialState.monitoringSipData}
        case SHOW_LOADER:
            return {...state, loading: true}
        case HIDE_LOADER:
            return {...state, loading: false}
        case SHOW_ERROR:
            return {...state, error: true}
        case SHOW_UPDATED_ERROR:
            return {...state, errorUpdate: true}
        case SHOW_MONITORING_CREATE_MESSAGE:
            return {...state, monitoringCreateMessage: true}
        case SHOW_MONITORING_SAVE_MESSAGE:
            return {...state, monitoringSaveMessage: true}
        case SHOW_MONITORING_DELETE_MESSAGE:
            return {...state, monitoringDeleteMessage: action.payload}
        case HIDE_MONITORING_CREATE_MESSAGE:
            return {...state, monitoringCreateMessage: false}
        case HIDE_MONITORING_SAVE_MESSAGE:
            return {...state, monitoringSaveMessage: false}
        case HIDE_ERROR:
            return {...state, error: false, errorUpdate: false}
        case MONITORING_STATE_SAVE:
            return {...state, monitoringStateSave: action.payload}
        case INITIAL_MONITORING_DATA:
            return {...state, monitoringData: initialState.monitoringData}
        case SHOW_ERROR_MESSAGE:
            return {...state, errorMessage: action.payload}
        case SHOW_DETAIL_LOADING:
            return {...state, detailsLoading: action.payload}
        case SHOW_SIP_DETAILS_LOADING:
            return {...state, sipDetailsLoading: action.payload}
        case SHOW_EXPORT_LOADING:
            return {...state, exportLoading: action.payload}
        case SHOW_ERROR_FIELD:
            return {...state, errorField: action.payload}
        case SET_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case HIDE_ERROR_MESSAGE:
            return {...state, errorMessage: null}
        case CHANGED_MONITORING_DATA:
            return {...state, changedMonitoringData: action.payload}
        case GET_AUDIO_URL:
            return {...state, audioUrl: action.payload}
        case AUDIO_LOADING:
            return {...state, audioUrlLoading: action.payload}
        case UPLOAD_AUDIO_LOADING:
            return {...state, audioUrlUploadLoading: action.payload}
        case ADD_MONITORING_OBJECT:
            return {...state, monitoringList: [action.payload, ...state.monitoringList]};
        case SET_CHECK_USER_FOR_BAN:
            return {...state, checkUserForBan: action.payload};
        case ADD_TO_MONITORING_LIST:
            return {...state, monitoringList: [action.payload, ...state.monitoringList]};
        case MONITORING_RELOAD_TOGGLE:
            return {...state, autoReload: action.payload}
        case SET_FIRST_PAGE:
            return {...state, pageChanged: action.payload}
        case GET_STATUS_DICTIONARY:
            return {...state, statusDictionary: action.payload}
        case GET_CALL_RESET_DICTIONARY:
            return {...state, callResetDictionary: action.payload}
        case GET_FORCED_CLOSING_DICTIONARY:
            return {...state, forcedClosingDictionary: action.payload}
        case SET_MONITORING_FILTER:
            return {...state, monitoringFilterData: action.payload}
        default:
            return state
    }
}
