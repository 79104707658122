import React, {useEffect, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from "primereact/inputnumber";
import {setQueueACDField, setQueueField} from "../../../redux/actions/actionsQueue";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getQueueDirectoriesQueueType, getQueueDirectoriesQueueAudioType, getAllQueues} from "../../../redux/api/apiQueue";
import {InputTextarea} from "primereact/inputtextarea";
import {classNames} from "primereact/utils";
import {PROD} from "../../../redux/config";
import {isAudio, languages, voices} from "../../../dictionaries";
import {Button} from "primereact/button";
import {setEditType} from "../../../redux/actions/actions";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getAudioById} from "../../../redux/api/apiAudio";
import AudioDialog from "../../Settings/AudioList/AudioDialog";

const ACDSettingsTab = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    // @ts-ignore
    const queuesDirectoryQueueType = useAppSelector(state => state.QueueListReducer.queuesDirectoryQueueType);
    // @ts-ignore
    const queuesDirectoryQueueAudioType = useAppSelector(state => state.QueueListReducer.queuesDirectoryQueueAudioType);
    // @ts-ignore
    const allQueues = useAppSelector(state => state.QueueListReducer.allQueues);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.QueueListReducer.errorFields);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Audio');
    const detailsLoading = useAppSelector(state => state.AudioListReducer.detailsLoading);
    const [field, setField] = useState<any>(null)

    const {
        visible: hasDialerVisiblePermission,
        update: hasDialerUpdatePermission,
    } = dialerPermissions;


    const initializationOptions = [
        {name: 'Отримувач', id: 'Отримувач'},
    ];

    const filteredList = allQueues?.filter((item: { id: number; }) => item.id !== queueData?.queue?.id);

    const filteredQueueList = filteredList?.map((item: { id: any; name: any; }) => (
        {
            ...item,
            idName: `ID: ${item.id} "${item.name}"`,
            stringId: `${item.id}`
        }));

    useEffect(() => {
        dispatch(getQueueDirectoriesQueueType(jwtToken?.jwtToken));
        dispatch(getQueueDirectoriesQueueAudioType(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        let dictionaryId = 202;
        if (queueData?.queueACD?.schedulerStrategy == dictionaryId || queueData?.queueACD?.mwtStrategy == dictionaryId || queueData?.queueACD?.mwtNoAgentStrategy) {
            dispatch(getAllQueues(jwtToken?.jwtToken));
        }
    }, [queueData?.queueACD?.schedulerStrategy, queueData?.queueACD?.mwtStrategy, queueData?.queueACD?.mwtNoAgentStrategy]);

    const audioItems = (audioId: any, audioField: string) => {
        return queuesDirectoryQueueAudioType?.map((item: any, index: number) => {
            if (audioId === item.audioId) {
                return (
                    <div key={index} className="flex align-items-center justify-content-between mt-2 p-0">
                        {hasDialerVisiblePermission &&
                            <Button
                                className="max-w-4rem p-button-secondary mr-3"
                                style={{minWidth: '40px'}}
                                loading={detailsLoading && (audioField === field)}
                                disabled={!archiveTableToggle || detailsLoading}
                                icon={archiveTableToggle && hasDialerUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasDialerUpdatePermission ? 'pi pi-eye' : ''}
                                onClick={() => {
                                    dispatch(getAudioById(item.audioId, jwtToken?.jwtToken, true));
                                    setField(audioField);
                                    if (hasDialerUpdatePermission) {
                                        dispatch(setEditType(true));
                                    } else {
                                        dispatch(setEditType(false));
                                    }
                                }}/>
                        }
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {item.audioName}
                        </div>
                        <Button name={item.audioId}
                                onClick={() => {
                                    dispatch(setQueueField({section: 'queueACD', fieldName: audioField, value: null}));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text mb-0 field col-12 md:col-1"
                                disabled={!archiveTableToggle || !editType}
                        />
                    </div>
                )
            }
        })
    }

    return (
        <div className="acd-settings grid">
            <div className="p-fluid grid col-12 md:col-12">
                <div className="p-fluid field col-12 md:col-3 mb-0">
                    <div className={classNames('mb-4', {'p-error': errorFields?.mohAudioId})}>Аудіо очікування*</div>
                    <Dropdown
                        value={queueData?.queueACD?.mohAudioId || null}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mohAudioId', value: e.value}));
                        }}
                        className={classNames('w-full mr-2', {'p-invalid': errorFields?.mohAudioId})}
                        options={queuesDirectoryQueueAudioType}
                        optionLabel="audioName"
                        disabled={!archiveTableToggle || !editType}
                        optionValue="audioId"
                        placeholder="Виберіть аудіо:"
                    />
                    <div className="col-12 md:col-12 pt-2 px-0 pb-0">
                        {
                            queueData?.queueACD?.mohAudioId &&
                            <div className="users-list">
                                {audioItems(queueData?.queueACD?.mohAudioId, 'mohAudioId')}
                            </div>
                        }
                    </div>
                    {errorFields?.mohAudioId && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mohAudioId?.mohAudioId}</span></div>}
                </div>
                <div className="flex-column field col-12 md:col-3 mb-0">
                    <div>
                        <div className={classNames('mb-4', {'p-error': errorFields?.mohAudioTime})}>Тривалість
                            відтворення (мін. 10 сек)*
                        </div>
                    </div>
                    <InputNumber
                        min={0}
                        id="maxWaitTime"
                        value={queueData?.queueACD?.mohAudioTime || null}
                        useGrouping={false}
                        className={classNames('', {'p-invalid': errorFields?.mohAudioTime})}
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mohAudioTime', value: e.value}));
                        }}
                        placeholder="0"
                    />
                    {errorFields?.mohAudioTime && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mohAudioTime?.mohAudioTime}</span></div>}
                </div>
                <div className="p-fluid field col-12 md:col-6 mb-0"></div>
            </div>

            {/*scheduler*/}

            <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
            <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.schedulerStrategy})} htmlFor="initialization">Action scheduler*</label>
                    <Dropdown
                        id="initialization"
                        value={queueData?.queueACD?.schedulerStrategy || null}
                        options={queuesDirectoryQueueType}
                        className={classNames('', {'p-invalid': errorFields?.schedulerStrategy})}
                        optionLabel="name"
                        optionValue="dictionaryId"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerStrategy', value: e.value}));
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerStrategyAction', value: null}));
                        }}
                        placeholder="Виберіть дію"
                    />
                    {errorFields?.schedulerStrategy && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.schedulerStrategy?.schedulerStrategy}</span></div>}
                </div>
                {!PROD && <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.schedulerStrategyAction})} htmlFor="initialization">Отримувач</label>
                    {queueData?.queueACD?.schedulerStrategy === 202 ? <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.schedulerStrategyAction || null}
                            options={filteredQueueList}
                            filter
                            optionValue={'stringId'}
                            panelClassName={'max-w-5rem'}
                            className={classNames('', {'p-invalid': errorFields?.schedulerStrategyAction})}
                            optionLabel={'idName'}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerStrategyAction', value: e.value}));
                            }}
                            disabled={!archiveTableToggle || !editType}
                            placeholder="Виберіть дію"/> :
                        <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.schedulerStrategyAction || null}
                            options={initializationOptions}
                            className={classNames('', {'p-invalid': errorFields?.schedulerStrategyAction})}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerStrategyAction', value: e.value}));
                            }}
                            optionValue={'id'}
                            panelClassName={'max-w-3rem'}
                            optionLabel={'name'}
                            disabled={!archiveTableToggle || !editType || queueData?.queueACD?.schedulerStrategy === 201}
                            placeholder="Виберіть дію"/>}
                    {errorFields?.schedulerStrategyAction && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.schedulerStrategyAction?.schedulerStrategyAction}</span></div>}
                </div>}
                <div className="field flex align-items-center col-12 md:col-6 mb-0"></div>
                <div className="field flex align-items-center col-12 md:col-3 mb-0">
                    <Dropdown
                        value={queueData?.queueACD?.isSchedulerAudio || false}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'isSchedulerAudio', value: e.value}));
                            if (!e.value) {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerAudioId', value: null}));
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerAudioTime', value: null}));
                            }
                        }}
                        options={isAudio}
                        disabled={!archiveTableToggle || !editType}
                        optionLabel="name"
                        optionValue="state"
                        placeholder="Виберіть тип"
                        className="w-full"
                    />
                </div>
            </div>
            {queueData?.queueACD?.isSchedulerAudio && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Аудіо Scheduler</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.schedulerAudioId || null}
                        options={queuesDirectoryQueueAudioType}
                        optionLabel="audioName"
                        optionValue="audioId"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerAudioId', value: e.value}));
                        }}
                        placeholder="Виберіть аудіо"/>
                    <div className="col-12 md:col-12 pt-2 px-0 pb-0">
                        {
                            queueData?.queueACD?.schedulerAudioId &&
                            <div className="users-list">
                                {audioItems(queueData?.queueACD?.schedulerAudioId, 'schedulerAudioId')}
                            </div>
                        }
                    </div>
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <div className={classNames('mb-2', {'p-error': errorFields?.schedulerAudioTime})}>Тривалість
                        відтворення (мін. 10 сек):
                    </div>
                    <InputNumber
                        min={0}
                        id="maxWaitTime"
                        value={queueData?.queueACD?.schedulerAudioTime || null}
                        useGrouping={false}
                        className={classNames('', {'p-invalid': errorFields?.schedulerAudioTime})}
                        placeholder="0"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'schedulerAudioTime', value: e.value}));
                        }}
                    />
                    {errorFields?.schedulerAudioTime && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.schedulerAudioTime?.schedulerAudioTime}</span>
                    </div>}
                </div>
            </div>}
            {!queueData?.queueACD?.isSchedulerAudio && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Мова *</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.schedulerTTS?.language || null}
                        options={languages}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'schedulerTTS', acdFieldName: 'language', value: e.value}));
                        }}
                        placeholder="Виберіть мову"/>
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Голос</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.schedulerTTS?.sex || null || null}
                        options={voices}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'schedulerTTS', acdFieldName: 'sex', value: e.value}));
                        }}
                        placeholder="Виберіть голос"/>
                </div>
                <div className="field col-12 md:col-6 mb-0"></div>
                <div className="field col-12 md:col-6 mb-0">
                    <label htmlFor="audioSource">Текст повідомлення:</label>
                    <InputTextarea
                        autoResize
                        value={queueData?.queueACD?.schedulerTTS?.text || ''}
                        onChange={(e) =>
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'schedulerTTS', acdFieldName: 'text', value: e.target.value}))
                        }
                        rows={5}
                        cols={30}
                        maxLength={150}
                        placeholder="Введіть текст повідомлення"
                    />
                </div>
            </div>}

            {/*with agent*/}

            {!PROD &&<div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>}
            {!PROD && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwt})} htmlFor="initialization">Max wait time (мін 10 сек)*</label>
                    <InputNumber
                        min={0} id="maxWaitTime"
                        value={queueData?.queueACD?.mwt || null}
                        placeholder="0"
                        className={classNames('', {'p-invalid': errorFields?.mwt})}
                        useGrouping={false}
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            if (!e.value) {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwt', value: null}));
                            } else {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwt', value: e.value}));
                            }
                        }}

                    />
                    {errorFields?.mwt && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwt?.mwt}</span></div>}
                </div>
                <div className="field col-12 md:col-9 mb-0"></div>
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwtStrategy})} htmlFor="initialization">Action*</label>
                    <Dropdown
                        id="initialization"
                        value={queueData?.queueACD?.mwtStrategy || null}
                        options={queuesDirectoryQueueType}
                        className={classNames('', {'p-invalid': errorFields?.mwtStrategy})}
                        optionLabel="name"
                        optionValue="dictionaryId"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtStrategy', value: e.value}));
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtStrategyAction', value: null}));
                        }}
                        placeholder="Виберіть дію"
                    />
                    {errorFields?.mwtStrategy && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtStrategy?.mwtStrategy}</span></div>}
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwtStrategyAction})} htmlFor="initialization">Отримувач</label>
                    {queueData?.queueACD?.mwtStrategy === 202 ? <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.mwtStrategyAction || null}
                            className={classNames('', {'p-invalid': errorFields?.mwtStrategyAction})}
                            options={filteredQueueList}
                            optionValue={'stringId'}
                            filter
                            panelClassName={'max-w-5rem'}
                            optionLabel={'idName'}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtStrategyAction', value: e.value}));
                            }}
                            disabled={!archiveTableToggle || !editType}
                            placeholder="Виберіть дію"/> :
                        <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.mwtStrategyAction || null}
                            className={classNames('', {'p-invalid': errorFields?.mwtStrategyAction})}
                            options={initializationOptions}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtStrategyAction', value: e.value}));
                            }}
                            optionValue={'id'}
                            panelClassName={'max-w-3rem'}
                            optionLabel={'name'}
                            disabled={!archiveTableToggle || !editType || queueData?.queueACD?.mwtStrategy === 201}
                            placeholder="Виберіть дію"/>}
                    {errorFields?.mwtStrategyAction && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtStrategyAction?.mwtStrategyAction}</span></div>}
                </div>
                <div className="field col-12 md:col-6 mb-0"></div>
                <div className="field flex align-items-center col-12 md:col-3 mb-0">
                    <Dropdown
                        value={queueData?.queueACD?.isMWTAudio || false}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'isMWTAudio', value: e.value}));
                            if (!e.value) {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtAudioId', value: null}));
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtAudioTime', value: null}));
                            }
                        }}
                        options={isAudio}
                        disabled={!archiveTableToggle || !editType}
                        optionLabel="name"
                        optionValue="state"
                        placeholder="Виберіть тип"
                        className="w-full"
                    />
                </div>
            </div>}
            {queueData?.queueACD?.isMWTAudio && !PROD &&
                <div className="p-fluid grid col-12 md:col-12">
                    <div className="field col-12 md:col-3 mb-0">
                        <label htmlFor="audioSource">Аудіо</label>
                        <Dropdown
                            id="audioSource"
                            value={queueData?.queueACD?.mwtAudioId || null}
                            options={queuesDirectoryQueueAudioType}
                            optionValue="audioId"
                            optionLabel="audioName"
                            disabled={!archiveTableToggle || !editType}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtAudioId', value: e.value}));
                            }}
                            placeholder="Виберіть аудіо"/>
                        <div className="col-12 md:col-12 pt-2 px-0 pb-0">
                            {
                                queueData?.queueACD?.mwtAudioId &&
                                <div className="users-list">
                                    {audioItems(queueData?.queueACD?.mwtAudioId, 'mwtAudioId')}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="field col-12 md:col-3 mb-0">
                        <div className={classNames('mb-2', {'p-error': errorFields?.mwtAudioTime})}>Тривалість
                            відтворення (мін. 10 сек):
                        </div>
                        <InputNumber
                            min={0}
                            id="maxWaitTime"
                            value={queueData?.queueACD?.mwtAudioTime || null}
                            useGrouping={false}
                            className={classNames('', {'p-invalid': errorFields?.mwtAudioTime})}
                            placeholder="0"
                            disabled={!archiveTableToggle || !editType}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtAudioTime', value: e.value}));
                            }}
                        />
                        {errorFields?.mwtAudioTime && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                            className="title p-error text-sm">{errorFields?.mwtAudioTime?.mwtAudioTime}</span></div>}
                    </div>
                </div>}
            {!queueData?.queueACD?.isMWTAudio && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Мова *</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.mwttts?.language || null}
                        options={languages}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwttts', acdFieldName: 'language', value: e.value}));
                        }}
                        placeholder="Виберіть мову"/>
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Голос</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.mwttts?.sex || null || null}
                        options={voices}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwttts', acdFieldName: 'sex', value: e.value}));
                        }}
                        placeholder="Виберіть голос"/>
                </div>
                <div className="field col-12 md:col-6 mb-0"></div>
                <div className="field col-12 md:col-6 mb-0">
                    <label htmlFor="audioSource">Текст повідомлення:</label>
                    <InputTextarea
                        autoResize
                        value={queueData?.queueACD?.mwttts?.text || ''}
                        onChange={(e) =>
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwttts', acdFieldName: 'text', value: e.target.value}))
                        }
                        rows={5}
                        cols={30}
                        maxLength={150}
                        placeholder="Введіть текст повідомлення"
                    />
                </div>
            </div>}

            {/*no agent*/}

            {!PROD && <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>}
            {!PROD && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwtNoAgent})} htmlFor="initialization">Max wait time with no agent (мін 1 сек)*</label>
                    <InputNumber min={1}
                                 id="mwtNoAgent"
                                 value={queueData?.queueACD?.mwtNoAgent || null}
                                 useGrouping={false}
                                 className={classNames('', {'p-invalid': errorFields?.mwtNoAgent})}
                                 placeholder="0"
                                 disabled={!archiveTableToggle || !editType}
                                 onChange={(e) => {
                                     if (!e.value) {
                                         dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgent', value: null}));
                                     } else {
                                         dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgent', value: e.value}));
                                     }
                                 }}
                    />
                    {errorFields?.mwtNoAgent && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtNoAgent?.mwtNoAgent}</span></div>}
                </div>
                <div className="field col-12 md:col-9 mb-0"></div>
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwtNoAgentStrategy})} htmlFor="initialization">Action*</label>

                    <Dropdown
                        id="initialization"
                        value={queueData?.queueACD?.mwtNoAgentStrategy || null}
                        options={queuesDirectoryQueueType}
                        optionLabel="name"
                        className={classNames('', {'p-invalid': errorFields?.mwtNoAgentStrategy})}
                        disabled={!archiveTableToggle || !editType}
                        optionValue="dictionaryId"
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentStrategy', value: e.value}));
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentStrategyAction', value: null}));
                        }}
                        placeholder="Виберіть дію"/>
                    {errorFields?.mwtNoAgentStrategy && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtNoAgentStrategy?.mwtNoAgentStrategy}</span></div>}
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <label className={classNames('', {'p-error': errorFields?.mwtNoAgentStrategyAction})} htmlFor="initialization">Отримувач</label>
                    {queueData?.queueACD?.mwtNoAgentStrategy === 202  ? <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.mwtNoAgentStrategyAction || null}
                            options={filteredQueueList}
                            className={classNames('', {'p-invalid': errorFields?.mwtNoAgentStrategyAction})}
                            optionValue={'stringId'}
                            panelClassName={'max-w-5rem'}
                            optionLabel={'idName'}
                            filter
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentStrategyAction', value: e.value}));
                            }}
                            disabled={!archiveTableToggle || !editType}
                            placeholder="Виберіть дію"/> :
                        <Dropdown
                            id="initialization"
                            value={queueData?.queueACD?.mwtNoAgentStrategyAction || null}
                            options={initializationOptions}
                            className={classNames('', {'p-invalid': errorFields?.mwtNoAgentStrategyAction})}
                            onChange={(e) => {
                                dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentStrategyAction', value: e.value}));
                            }}
                            optionValue={'id'}
                            panelClassName={'max-w-3rem'}
                            optionLabel={'name'}
                            disabled={!archiveTableToggle || !editType || queueData?.queueACD?.mwtNoAgentStrategy === 201}
                            placeholder="Виберіть дію"/>}
                    {errorFields?.mwtNoAgentStrategyAction && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtNoAgentStrategyAction?.mwtNoAgentStrategyAction}</span></div>}
                </div>
            </div>}
            {!PROD &&<div className="field flex align-items-center col-12 md:col-3 mb-0">
                <Dropdown
                    value={queueData?.queueACD?.isMWTNoAgentAudio || false}
                    onChange={(e) => {
                        dispatch(setQueueField({section: 'queueACD', fieldName: 'isMWTNoAgentAudio', value: e.value}));
                        if (!e.value) {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentAudioId', value: null}));
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentAudioTime', value: null}));
                        }
                    }}
                    options={isAudio}
                    disabled={!archiveTableToggle || !editType}
                    optionLabel="name"
                    optionValue="state"
                    placeholder="Виберіть тип"
                    className="w-full mr-2"
                />
            </div>}
            {queueData?.queueACD?.isMWTNoAgentAudio && !PROD && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Аудіо</label>
                    <Dropdown id="audioSource" value={queueData?.queueACD?.mwtNoAgentAudioId || null}
                              options={queuesDirectoryQueueAudioType}
                              onChange={(e) => {
                                  dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentAudioId', value: e.value}));
                              }}
                              disabled={!archiveTableToggle || !editType}
                              optionLabel="audioName"
                              optionValue="audioId"
                              placeholder="Виберіть аудіо"/>
                    <div className="col-12 md:col-12 pt-2 px-0 pb-0">
                        {
                            queueData?.queueACD?.mwtNoAgentAudioId &&
                            <div className="users-list">
                                {audioItems(queueData?.queueACD?.mwtNoAgentAudioId, 'mwtNoAgentAudioId')}
                            </div>
                        }
                    </div>
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <div className={classNames('mb-2', {'p-error': errorFields?.mwtNoAgentAudioTime})}>Тривалість
                        відтворення (мін. 10 сек):
                    </div>
                    <InputNumber
                        min={0}
                        id="maxWaitTime"
                        placeholder="0"
                        className={classNames('', {'p-invalid': errorFields?.mwtNoAgentAudioTime})}
                        value={queueData?.queueACD?.mwtNoAgentAudioTime || null}
                        useGrouping={false}
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueField({section: 'queueACD', fieldName: 'mwtNoAgentAudioTime', value: e.value}));
                        }}
                    />
                    {errorFields?.mwtNoAgentAudioTime && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.mwtNoAgentAudioTime?.mwtNoAgentAudioTime}</span>
                    </div>}
                </div>
            </div>}
            {!queueData?.queueACD?.isMWTNoAgentAudio && <div className="p-fluid grid col-12 md:col-12">
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Мова *</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.mwtNoAgentTTS?.language || null}
                        options={languages}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwtNoAgentTTS', acdFieldName: 'language', value: e.value}));
                        }}
                        placeholder="Виберіть мову"/>
                </div>
                <div className="field col-12 md:col-3 mb-0">
                    <label htmlFor="audioSource">Голос</label>
                    <Dropdown
                        id="audioSource"
                        value={queueData?.queueACD?.mwtNoAgentTTS?.sex || null}
                        options={voices}
                        optionLabel="name"
                        optionValue="id"
                        disabled={!archiveTableToggle || !editType}
                        onChange={(e) => {
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwtNoAgentTTS', acdFieldName: 'sex', value: e.value}));
                        }}
                        placeholder="Виберіть голос"/>
                </div>
                <div className="field col-12 md:col-6 mb-0"></div>
                <div className="field col-12 md:col-6 mb-0">
                    <label htmlFor="audioSource">Текст повідомлення:</label>
                    <InputTextarea
                        autoResize
                        value={queueData?.queueACD?.mwtNoAgentTTS?.text || ''}
                        onChange={(e) =>
                            dispatch(setQueueACDField({section: 'queueACD', fieldName: 'mwtNoAgentTTS', acdFieldName: 'text', value: e.target.value}))
                        }
                        rows={5}
                        cols={30}
                        maxLength={150}
                        placeholder="Введіть текст повідомлення"
                    />
                </div>
            </div>}
            <AudioDialog/>
        </div>
    );
};

export default ACDSettingsTab;
