import {
    ADD_ROLE_GROUP_BRANCH,
    ARCHIVE_ROLE_TABLE_TOGGLE, DELETE_ROLE_BRANCH,
    GET_ROLES_VISION, HIDE_ROLE_DETAILS_LOADER, HIDE_ROLE_LOADER,
    HIDE_ROLE_SAVE_MESSAGE,
    ROLE_DIALOG_VISIBLE,
    ROLE_FIELD,
    SELECTED_ROLES_ID, SET_ROLE_CURRENT_PAGE, SET_ROLE_CURRENT_SIZE,
    SET_ROLE_MODULES_FIELD, SET_ROLES_LIST_DATA_SORT, SET_STATUS_MATRIX, SHOW_ROLE_ARCHIVED_MESSAGE,
    SHOW_ROLE_DETAILS_LOADER,
    SHOW_ROLE_SAVE_MESSAGE,
    SHOW_ROLES_LIST_LOADER,
    SHOW_ROLES_VISION_ERROR,
    UPDATE_PERMISSION_ACTIVE,
    UPDATE_PERMISSIONS
} from "../types/typesRoles";
import {SET_ROLE_ERROR_FIELDS} from "../types/types";
import {ADD_QUALIFICATION_GROUP_BRANCH} from "../types/typesQualificationGroup";
import {DELETE_QUEUE_BRANCH} from "../types/typesQueue";

export function setRolesVisible(state: any) {
    return {
        type: GET_ROLES_VISION,
        payload: state
    }
}

export function showRolesErrorMessage(payload: boolean) {
    return {
        type: SHOW_ROLES_VISION_ERROR,
        payload: payload
    }
}

export function setSelectedRolesIndex(state:number) {
    return {
        type: SELECTED_ROLES_ID,
        payload: state,
    }
}

export function setRoleDialogVisible(state:boolean) {
    return {
        type: ROLE_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setRoleField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: ROLE_FIELD,
        payload: state,
    }
}

export function setRoleModuleField(state:any) {
    return {
        type: SET_ROLE_MODULES_FIELD,
        payload: state,
    }
}

export const updatePermissionActive = (moduleName: any, permissionId: any, newIsActive: any) => {
    return {
        type: UPDATE_PERMISSION_ACTIVE,
        moduleName,
        permissionId,
        newIsActive,
    };
};

export function hideRoleSaveMessage() {
    return {
        type: HIDE_ROLE_SAVE_MESSAGE,
    }
}

export function showRoleSaveMessage() {
    return {
        type: SHOW_ROLE_SAVE_MESSAGE,
    }
}

export const updatePermissions = (moduleName: any, updatedPermissions: any) => (
    {
    type: UPDATE_PERMISSIONS,
    moduleName,
    updatedPermissions,
});

export function setRoleArchiveTableToggle(state:boolean) {
    return {
        type: ARCHIVE_ROLE_TABLE_TOGGLE,
        payload: state,
    }
}

export function showRolesListLoader() {
    return {
        type: SHOW_ROLES_LIST_LOADER,
    }
}

export function showRoleDetailsListLoader() {
    return {
        type: SHOW_ROLE_DETAILS_LOADER,
    }
}

export function hideRolesListLoader() {
    return {
        type: HIDE_ROLE_LOADER,
    }
}

export function hideRoleDetailsLoader() {
    return {
        type: HIDE_ROLE_DETAILS_LOADER,
    }
}

export function setCurrentPage(state: any) {
    return {
        type: SET_ROLE_CURRENT_PAGE,
        payload: state
    }
}

export function setCurrentPageSize(state: any) {
    return {
        type: SET_ROLE_CURRENT_SIZE,
        payload: state
    }
}

export function setRoleErrorFields(state:any) {
    return {
        type: SET_ROLE_ERROR_FIELDS,
        payload: state,
    }
}

export function setRoleListDataSort(state: any) {
    return {
        type: SET_ROLES_LIST_DATA_SORT,
        payload: state
    }
}

export function showRoleDeleteMessage(state: boolean) {
    return {
        type: SHOW_ROLE_ARCHIVED_MESSAGE,
        payload: state,
    }
}

export function addRolesGroupBranch(state: {roleId:string, id:any}) {
    return {
        type: ADD_ROLE_GROUP_BRANCH,
        payload: state,
    }
}

export function deleteRoleBranch(state: string) {
    return {
        type: DELETE_ROLE_BRANCH,
        payload: state,
    }
}

export function updateStatusMatrix(state: any) {
    return {
        type: SET_STATUS_MATRIX,
        payload: state,
    }
}
