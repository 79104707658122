import React from "react";
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import {Button} from "primereact/button";
import {setClearFilters, setEditType} from "redux/actions/actions";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {initialStatusData, setStatusArchiveTableToggle, setStatusDialogVisible, setStatusErrorFields, setStatusTime} from "../../../../redux/actions/actionsStatuses";

const StatusesListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Status');
    const archiveStatusTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);

    const {
        create: hasRoleCreatePermission,
    } = dialerPermissions;
    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => { dispatch(setStatusArchiveTableToggle(true))}}
                        label="Статус"
                        className={archiveStatusTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => { dispatch(setStatusArchiveTableToggle(false))}}
                        label="Архів"
                        className={archiveStatusTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasRoleCreatePermission && (<Button label="Створити статус"
                                                       icon="pi pi-plus"
                                                       className="mr-2 mb-2"
                                                       disabled={!(hasRoleCreatePermission)}
                                                       onClick={() => {
                                                           dispatch(setStatusDialogVisible(true));
                                                           dispatch(setStatusErrorFields(null));
                                                           dispatch(setStatusArchiveTableToggle(true));
                                                           dispatch(initialStatusData());
                                                           dispatch(setStatusTime(null));
                                                           if (hasRoleCreatePermission) {
                                                               dispatch(setEditType(true));
                                                           } else {
                                                               dispatch(setEditType(false));
                                                           }
                                                       }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {dispatch(setClearFilters()); props.handleReset()}} />
            </div>
            <div className="mobile">
                {hasRoleCreatePermission && (<Button icon="pi pi-plus"
                                                       className="mr-2 mb-2"
                                                       disabled={!(hasRoleCreatePermission)}
                                                       onClick={() => {
                                                           dispatch(setStatusDialogVisible(true));
                                                           dispatch(setStatusErrorFields(null));
                                                           dispatch(setStatusArchiveTableToggle(true));
                                                           dispatch(initialStatusData());
                                                           dispatch(setStatusTime(null));
                                                           if (hasRoleCreatePermission) {
                                                               dispatch(setEditType(true));
                                                           } else {
                                                               dispatch(setEditType(false));
                                                           }
                                                       }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())} />
            </div>
        </div>
    )
}

export default StatusesListNav;
