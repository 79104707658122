import {
    ADD_STATUS_GROUP_BRANCH,
    ARCHIVE_STATUS_TABLE_TOGGLE, DELETE_STATUS_BRANCH,
    GET_STATUSES_LIST,
    HIDE_STATUS_DETAILS_LOADER,
    HIDE_STATUS_LOADER,
    HIDE_STATUS_SAVE_MESSAGE, INITIAL_STATUS_DATA, SELECTED_STATUS_ID, SET_STATUS_CURRENT_PAGE, SET_STATUS_CURRENT_SIZE, SET_STATUS_ERROR_FIELDS, SET_STATUS_MODULE_FIELD, SET_STATUSES_LIST_DATA_SORT,
    SHOW_STATUS_DETAILS_LOADER,
    SHOW_STATUS_SAVE_MESSAGE, SHOW_STATUSES_ERROR,
    SHOW_STATUSES_LIST_LOADER, STATUS_DIALOG_VISIBLE, STATUS_FIELD,
    UPDATE_STATUS_PERMISSION_ACTIVE,
    UPDATE_STATUS_PERMISSIONS, UPDATE_STATUS_TIME
} from "../types/typesStatusesList";
import {ADD_QUALIFICATION_GROUP_BRANCH} from "../types/typesQualificationGroup";
import {DELETE_QUEUE_BRANCH} from "../types/typesQueue";

export function getStatusesList(state: any) {
    return {
        type: GET_STATUSES_LIST,
        payload: state
    }
}

export function showStatusesErrorMessage(payload: boolean) {
    return {
        type: SHOW_STATUSES_ERROR,
        payload: payload
    }
}

export function setSelectedStatusIndex(state:number) {
    return {
        type: SELECTED_STATUS_ID,
        payload: state,
    }
}

export function setStatusDialogVisible(state:boolean) {
    return {
        type: STATUS_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setStatusField(state:{section: string, fieldName: string, value: any}) {
    return {
        type: STATUS_FIELD,
        payload: state,
    }
}

export function setStatusModuleField(state:any) {
    return {
        type: SET_STATUS_MODULE_FIELD,
        payload: state,
    }
}

export const updateStatusPermissionActive = (moduleName: any, permissionId: any, newIsActive: any) => {
    return {
        type: UPDATE_STATUS_PERMISSION_ACTIVE,
        moduleName,
        permissionId,
        newIsActive,
    };
};

export function hideStatusSaveMessage() {
    return {
        type: HIDE_STATUS_SAVE_MESSAGE,
    }
}

export function showStatusSaveMessage() {
    return {
        type: SHOW_STATUS_SAVE_MESSAGE,
    }
}

export const updateStatusPermissions = (moduleName: any, updatedPermissions: any) => (
    {
    type: UPDATE_STATUS_PERMISSIONS,
    moduleName,
    updatedPermissions,
});

export function setStatusArchiveTableToggle(state:boolean) {
    return {
        type: ARCHIVE_STATUS_TABLE_TOGGLE,
        payload: state,
    }
}

export function showStatusesListLoader() {
    return {
        type: SHOW_STATUSES_LIST_LOADER,
    }
}

export function showStatusDetailsListLoader() {
    return {
        type: SHOW_STATUS_DETAILS_LOADER,
    }
}

export function hideStatusesListLoader() {
    return {
        type: HIDE_STATUS_LOADER,
    }
}

export function hideStatusDetailsLoader() {
    return {
        type: HIDE_STATUS_DETAILS_LOADER,
    }
}

export function setCurrentPage(state: any) {
    return {
        type: SET_STATUS_CURRENT_PAGE,
        payload: state
    }
}

export function setCurrentPageSize(state: any) {
    return {
        type: SET_STATUS_CURRENT_SIZE,
        payload: state
    }
}

export function setStatusErrorFields(state:any) {
    return {
        type: SET_STATUS_ERROR_FIELDS,
        payload: state,
    }
}

export function setStatusListDataSort(state: any) {
    return {
        type: SET_STATUSES_LIST_DATA_SORT,
        payload: state
    }
}

export function initialStatusData() {
    return {
        type: INITIAL_STATUS_DATA,
    }
}

export function setStatusTime(state:any) {
    return {
        type: UPDATE_STATUS_TIME,
        payload: state,
    }
}

export function addStatusGroupBranch(state: {statusId :number, id:any}) {
    return {
        type: ADD_STATUS_GROUP_BRANCH,
        payload: state,
    }
}

export function deleteStatusBranch(state: string) {
    return {
        type: DELETE_STATUS_BRANCH,
        payload: state,
    }
}
